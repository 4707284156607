import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DrawIcon from '@mui/icons-material/Draw'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined'

import { palette } from '../palette'
import typography from '../typography'

const MuiChip = {
  styleOverrides: {
    root: {
      ...typography.body2,
      borderRadius: 6,
      boxShadow:
        '0px 4px 6px -1px rgba(124, 121, 129, 0.1), 0px 2px 4px -1px rgba(124, 121, 129, 0.06)',
      textTransform: 'capitalize',
    },
    colorPrimary: {
      backgroundColor: palette.primary[50],
    },
    colorSecondary: {
      backgroundColor: palette.secondary[50],
      color: palette.secondary[800],
      borderColor: palette.secondary[800],
    },
    colorSuccess: {
      backgroundColor: palette.success.light,
      color: palette.success.main,
    },
    colorError: {
      backgroundColor: palette.error.light,
      color: palette.error.dark,
    },
  },
  defaultProps: {
    size: 'small',
    variant: 'outlined',
  },
  variants: [
    {
      props: { variant: 'onboarding' },
      style: {
        backgroundColor: palette.warning.light,
        color: palette.warning.dark,
        border: `1px solid ${palette.warning.main}`,
        '& .MuiChip-icon': {
          color: palette.warning.main,
        },
      },
    },
    {
      props: { variant: 'active' },

      style: {
        backgroundColor: palette.success.light,
        color: palette.success.dark,
        border: `1px solid ${palette.success.main}`,
        '& .MuiChip-icon': {
          color: palette.success.main,
        },
      },
    },
    {
      props: { variant: 'testing' },
      style: {
        backgroundColor: palette.warning.light,
        color: palette.warning.dark,
        border: `1px solid ${palette.warning.main}`,
        '& .MuiChip-icon': {
          color: palette.warning.main,
        },
      },
    },
    {
      props: { variant: 'draft' },
      style: {
        backgroundColor: palette.warning.light,
        color: palette.warning.dark,
        border: `1px solid ${palette.warning.main}`,
        '& .MuiChip-icon': {
          color: palette.warning.main,
        },
      },
    },
    {
      props: { variant: 'inactive' },
      style: {
        backgroundColor: palette.error.light,
        color: palette.error.dark,
        border: `1px solid ${palette.error.main}`,
        '& .MuiChip-icon': {
          color: palette.error.main,
        },
      },
    },
    {
      props: { variant: 'archived' },
      style: {
        backgroundColor: palette.error.light,
        color: palette.error.dark,
        border: `1px solid ${palette.error.main}`,
        '& .MuiChip-icon': {
          color: palette.error.main,
        },
      },
    },
    {
      props: { variant: 'offboarding' },
      style: {
        backgroundColor: palette.error.light,
        color: palette.error.dark,
        border: `1px solid ${palette.error.main}`,
        '& .MuiChip-icon': {
          color: palette.error.main,
        },
      },
    },
    {
      props: { variant: 'churned' },
      style: {
        backgroundColor: palette.error.light,
        color: palette.error.dark,
        border: `1px solid ${palette.error.main}`,
        '& .MuiChip-icon': {
          color: palette.error.main,
        },
      },
    },
    {
      props: { variant: 'piloting' },
      style: {
        backgroundColor: palette.warning.light,
        color: palette.warning.dark,
        border: `1px solid ${palette.warning.main}`,
        '& .MuiChip-icon': {
          color: palette.warning.main,
        },
      },
    },
    {
      props: { variant: 'running' },
      style: {
        backgroundColor: palette.warning.light,
        color: palette.warning.dark,
        border: `1px solid ${palette.warning.main}`,
        '& .MuiChip-icon': {
          color: palette.warning.main,
        },
      },
    },
    {
      props: { variant: 'pending' },
      style: {
        backgroundColor: palette.error.light,
        color: palette.error.dark,
        border: `1px solid ${palette.error.main}`,
        '& .MuiChip-icon': {
          color: palette.error.main,
        },
      },
    },
    {
      props: { variant: 'onboarding' },
      style: {
        backgroundColor: palette.warning.light,
        color: palette.warning.dark,
        border: `1px solid ${palette.warning.main}`,
        '& .MuiChip-icon': {
          color: palette.warning.main,
        },
      },
    },
    {
      props: { variant: 'prospect' },
      style: {
        backgroundColor: palette.warning.light,
        color: palette.warning.dark,
        border: `1px solid ${palette.warning.main}`,
        '& .MuiChip-icon': {
          color: palette.warning.main,
        },
      },
    },
    {
      props: { variant: 'done' },
      style: {
        backgroundColor: palette.success.light,
        color: palette.success.dark,
        border: `1px solid ${palette.success.main}`,
        '& .MuiChip-icon': {
          color: palette.success.main,
        },
      },
    },
  ],
}

const chipVariantIcons: { [key: string]: { icon: React.ReactElement } } = {
  prospect: {
    icon: <HelpOutlineOutlinedIcon />,
  },
  piloting: {
    icon: <ScienceOutlinedIcon />,
  },
  onboarding: {
    icon: <SchoolOutlinedIcon />,
  },
  active: {
    icon: <CheckCircleOutlineIcon />,
  },
  inactive: {
    icon: <PauseCircleOutlineIcon />,
  },
  offboarding: {
    icon: <UnpublishedOutlinedIcon />,
  },
  churned: {
    icon: <RemoveCircleOutlineOutlinedIcon />,
  },
  deactivated: {
    icon: <RemoveCircleOutlineOutlinedIcon />,
  },
  testing: {
    icon: <ScienceOutlinedIcon />,
  },
  draft: {
    icon: <DrawIcon />,
  },
  archived: {
    icon: <UnpublishedOutlinedIcon />,
  },
  done: {
    icon: <CheckCircleOutlineIcon />,
  },
  pending: {
    icon: <ErrorOutlinedIcon />,
  },
  failed: {
    icon: <ErrorOutlinedIcon />,
  },
  running: {
    icon: <HourglassTopOutlinedIcon />,
  },
  demo: {
    icon: <CampaignOutlinedIcon />,
  },
}

export { chipVariantIcons, MuiChip }
