import { Environment } from './types'
const DIVIDER_WIDTH = 8
const DIV_MIN_WIDTH = 80

export const getLanguages = (environments: Environment[]): string[] => [
  ...new Set(environments.map((item) => item.language.name)),
]

export const getVersions = (
  environments: Environment[],
  language: string | undefined
): string[] => [
  ...new Set(
    environments
      .filter((item) => item.language.name === language || !language)
      .map((item) => item.language.version)
  ),
]

export const getEnvironments = (
  environments: Environment[],
  language: string | undefined,
  version: string | undefined
): string[] =>
  environments
    .filter(
      (item) =>
        (item.language.name === language || !language) &&
        (item.language.version === version || !version)
    )
    .map((item) => item.environment_id)

export const convertOptions = (
  options: string[] | Record<string, string>
): Record<string, string> => {
  if (Array.isArray(options)) {
    return options.reduce((acc, option) => {
      acc[option] = option
      return acc
    }, {} as Record<string, string>)
  }

  return options
}

export const getDivWidths = (editorWidth: number, totalWidth: number) => {
  const newWidth = Math.max(
    DIV_MIN_WIDTH,
    Math.min(totalWidth - DIVIDER_WIDTH - DIV_MIN_WIDTH, editorWidth)
  )
  const editorWidthPercent = (newWidth / totalWidth) * 100
  const outputWidthPercent = ((totalWidth - newWidth - DIVIDER_WIDTH) / totalWidth) * 100
  return { editorWidth: editorWidthPercent, outputWidth: outputWidthPercent }
}

export const getInitialWidths = (element: HTMLDivElement) => {
  const previous = element.previousElementSibling as HTMLElement
  const initialWidth = previous ? previous.offsetWidth : 0
  const totalWidth = element.parentElement ? (element.parentElement as HTMLElement).offsetWidth : 0
  return { initialWidth, totalWidth }
}
