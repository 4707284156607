import { useContext } from '@invisible/trpc/client'
import { useQueryClient } from 'react-query'

import { useBaseRunVariablesWizardUpdate } from '../../../../hooks/useBaseRunVariablesWizardUpdate'
import { useCompleteStepRun } from '../../../../hooks/useCompleteStepRun'
import { completeOperateStep } from './helper'

export const useOperatorSubmit = ({
  stepRunId,
  onFinish,
}: {
  stepRunId: string
  onFinish: () => void
}) => {
  const { mutateAsync: updateBaseRunVariables, isLoading: isUpdateBaseRunVariablesLoading } =
    useBaseRunVariablesWizardUpdate()
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { mutateAsync: markStepRunDone, isLoading: isMarkStepRunDoneLoading } = useCompleteStepRun({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryClient.invalidateQueries('UserActiveWorkingProcessIDs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      reactQueryContext.invalidateQueries('stepRun.findCompletedAssignedToMe')
    },
  })

  const handleOperateSubmit = async (init: Record<string, any>, values: any) => {
    await updateBaseRunVariables({
      stepRunId,
      data: completeOperateStep(init, values),
    })

    await markStepRunDone({ id: stepRunId })
    onFinish()
  }

  return { handleOperateSubmit, isUpdateBaseRunVariablesLoading, isMarkStepRunDoneLoading }
}
