import { useLoggedInUser } from '@invisible/hooks/use-logged-in-user'

import { PromptInput } from '../sub-components/PromptInput'
import { MainContentProps } from '../types'
import { ConversationArea } from './ConversationArea'
import { Header } from './Header'

export const MainContent = ({
  headerProps,
  conversationProps,
  promptInputProps,
  config,
  prompts,
  maxAllowedTurns,
}: MainContentProps) => {
  const [loggedInUser] = useLoggedInUser()

  const customScrollbar = "[&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-white [&::-webkit-scrollbar-thumb]:bg-gray-200"

  return (
    <section className='flex h-full flex-col overflow-auto'>
      <Header {...headerProps} />

      <div className={'box-border h-[calc(100%-50px)] w-full overflow-x-auto overflow-y-scroll ' + customScrollbar}>
        <ConversationArea {...conversationProps} loggedInUser={loggedInUser} />
      </div>
      {config.allowUserPromptInput && (prompts?.length ?? 0) < maxAllowedTurns && (
        <PromptInput {...promptInputProps} loggedInUser={loggedInUser} />
      )}
    </section>
  )
}
