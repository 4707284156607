import { zodResolver } from '@hookform/resolvers/zod'
import { SnackbarContext } from '@invisible/common/providers'
import { fromGlobalId, getErrorMessage } from '@invisible/concorde/gql-client'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useBaseRunsApprove } from './hooks/useBaseRunsApprove'

export interface IExportTaskToApprove {
  exportTaskId: string
  exportTaskName: string
}

interface IProps {
  exportTask: IExportTaskToApprove | null
  setExportTask: Dispatch<SetStateAction<IExportTaskToApprove | null>>
}

const formSchema = z
  .object({
    gcsLink: z.string(),
  })
  .refine(
    (schema) => {
      const validDomains = ['https://storage.cloud.google.com', 'https://storage.googleapis.com']
      const regex = new RegExp(`(${validDomains.join('|')})/(.+?)/(.+\\.jsonl)$`)
      return regex.test(schema.gcsLink.trim())
    },
    {
      message:
        'Invalid GCS link. The link should be in the format: https://storage.cloud.google.com/<bucket_name>/<blob_name>.jsonl',
      path: ['gcsLink'],
    }
  )

type TFormSchema = z.infer<typeof formSchema>

const BaseRunsApproveDialog = ({ exportTask, setExportTask }: IProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
  })

  const { showSnackbar } = useContext(SnackbarContext)

  const { mutateAsync: approveBaseRuns, isLoading } = useBaseRunsApprove({
    onError: (error) => {
      showSnackbar({
        message: `Error approving base runs: ${getErrorMessage(error)}`,
        variant: 'error',
      })
    },
  })

  const handleClose = () => {
    setExportTask(null)
    reset({ gcsLink: '' })
  }

  const handleSave = async (formData: TFormSchema) => {
    if (!exportTask) return
    const data = {
      exportTaskId: fromGlobalId(exportTask.exportTaskId),
      gcsLink: formData.gcsLink,
    }
    const response = await approveBaseRuns(data)
    const updatedCount = response?.data?.count
    if (updatedCount) {
      showSnackbar({
        message: `Successfully approved ${updatedCount} base runs`,
        variant: 'success',
      })
    }
    handleClose()
  }

  return (
    <Dialog open={!!exportTask} onClose={handleClose}>
      <DialogTitle>Approve Base Runs - {exportTask?.exportTaskName}</DialogTitle>

      <DialogContent sx={{ width: '400px' }}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 8,
          }}>
          <CloseIcon />
        </IconButton>
        <Typography>GCS Link (Link to the file containing base runs)</Typography>
        <TextField
          label='GCS Link'
          required
          fullWidth
          size='small'
          sx={{ mt: 1 }}
          error={Boolean(errors.gcsLink)}
          helperText={errors.gcsLink && errors.gcsLink.message}
          {...register('gcsLink')}
          onChange={(e) => {
            setValue('gcsLink', e.target.value)
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          sx={{
            mb: 1,
            mr: 1,
            fontWeight: 'normal',
          }}
          variant='contained'
          startIcon={<CloseIcon />}
          onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          sx={{
            mb: 1,
            mr: 1,
            fontWeight: 'normal',
          }}
          variant='contained'
          startIcon={<DoneIcon />}
          onClick={handleSubmit(handleSave)}>
          APPROVE
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export { BaseRunsApproveDialog }
