import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { Tab, Tabs } from '@invisible/ui/tabs'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard } from '@invisible/ultron/zod'
import { differenceInSeconds } from 'date-fns'
import { useState } from 'react'

import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import Markdown from '../common/Markdown'
import { MetadataForm } from '../MetadataForm'

type TBaseRunVariables = NonNullable<inferQueryOutput<'baseRunVariable.findManyByBaseRunId'>>
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>
type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

interface IProps {
  response: {
    id: string
    text: string
    model: string
    index: number
    originalText: string
  }
  stepRun: TStepRun
  activePromptId: string
  activePromptCreatedAt: Date
  editMode?: boolean
  baseRunVariables: TBaseRunVariables
  wacConfig: Wizard.RLHF2.TSchema
  handleClose?: () => void
  metadataValidationFailures: string[]
  textDirection?: string
}

enum TabValue {
  Edited,
  Original,
}

const PromptResponse = ({
  response: { id, text, index, model, originalText },
  activePromptId,
  baseRunVariables,
  wacConfig,
  stepRun,
  activePromptCreatedAt,
  editMode = false,
  handleClose,
  metadataValidationFailures,
  textDirection,
}: IProps) => {
  const reactQueryContext = useContext()
  const [isMetaDetailsOpen, setIsMetaDetailsOpen] = useState(true)
  const [isSelected, setIsSelected] = useState(editMode)
  const [responseText, setResponseText] = useState(text)
  const { dispatch } = useWizardState()

  const { mutateAsync: createBaseRun } = useBaseRunCreate()

  const { mutateAsync: updateVariables, isLoading } = useBaseRunVariablesWizardUpdate({
    onMutate: async (data) => {
      reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
        [
          'baseRun.findChildBaseRuns',
          {
            baseId: wacConfig?.responsesBaseId as string,
            parentBaseRunId: activePromptId,
          },
        ],
        (prevData) => {
          if (!prevData) return

          return prevData.map((b) => {
            const brv = data.find((d) => d.baseRunId === b.id)
            return brv === undefined
              ? b
              : {
                  ...b,
                  baseRunVariables: b.baseRunVariables.map((v) =>
                    v.baseVariable.id !== brv.baseVariableId ? v : { ...v, value: brv.value }
                  ),
                }
          })
        }
      )
    },
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('baseRun.findManyByParents')
      reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
      reactQueryContext.invalidateQueries('baseRunVariable.findOneByBaseVariableIdAndBaseRunId')
    },
  })

  const { data: promptDurationInSecsBaseVariable, isLoading: isFetching } = useQuery(
    [
      'baseRunVariable.findOneByBaseVariableIdAndBaseRunId',
      {
        baseRunId: activePromptId,
        baseVariableId: wacConfig?.promptDurationInSecsBaseVariableId as string,
      },
    ],
    { enabled: !!wacConfig?.promptDurationInSecsBaseVariableId }
  )

  const handleResponseSelection = async () => {
    const responseBaseRunId = await getOrCreateResponseBaseRunId(id)
    // compute prompt duration in seconds
    const curentDateTime = new Date()

    const updateResponseDuration = differenceInSeconds(curentDateTime, activePromptCreatedAt)
    const canUpdateDurationInSec =
      !isFetching &&
      promptDurationInSecsBaseVariable &&
      (!promptDurationInSecsBaseVariable?.value ||
        isNaN(Number(promptDurationInSecsBaseVariable?.value)))
    const extraFunctions = canUpdateDurationInSec
      ? [
          {
            baseRunId: activePromptId,
            baseVariableId: wacConfig?.promptDurationInSecsBaseVariableId as string,
            value: updateResponseDuration,
          },
        ]
      : []

    await updateVariables({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId: activePromptId,
          baseVariableId: wacConfig?.promptResponseBaseVariableId as string,
          value: responseText,
        },
        {
          baseRunId: activePromptId,
          baseVariableId: wacConfig?.promptAcceptedModelBaseVariableId as string,
          value: model,
        },
        {
          baseRunId: activePromptId,
          baseVariableId: wacConfig?.responseIdBaseVariableId as string,
          value: responseBaseRunId,
        },
        {
          baseRunId: responseBaseRunId,
          baseVariableId: wacConfig?.responseTextBaseVariableId as string,
          value: responseText,
        },
        ...extraFunctions,
      ],
    })
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHF',
      value: true,
    })
    handleClose?.()
  }

  const getOrCreateResponseBaseRunId = async (baseRunId?: string) => {
    if (baseRunId) return baseRunId

    const baseRun = await createBaseRun({
      baseId: wacConfig?.responsesBaseId as string,
      parentBaseRunId: activePromptId,
      stepRunId: stepRun.id,
      initialValues: [
        {
          baseVariableId: wacConfig?.responseTextBaseVariableId as string,
          value: responseText,
        },
        {
          baseVariableId: wacConfig?.responseModelBaseVariableId as string,
          value: model,
        },
        {
          baseVariableId: wacConfig?.responseOriginalTextBaseVariableId as string,
          value: responseText,
        },
        {
          baseVariableId: wacConfig?.responseIndexBaseVariableId as string,
          value: 1,
        },
      ],
    })

    await updateVariables({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId: activePromptId,
          baseVariableId: wacConfig?.responseIdBaseVariableId as string,
          value: baseRun.id,
        },
      ],
    })
    return baseRun.id
  }

  return (
    <div className='bg-weak-3 border-main rounded-md border border-solid p-3'>
      <div className='flex items-center justify-between'>
        <div className='font-medium'>
          Response {index}{' '}
          {!wacConfig.hideResopnseModelName ? (
            <span className='text-gray-400'> - {model}</span>
          ) : null}
        </div>
        {wacConfig.allowEditResponse ? (
          !isSelected ? (
            <Button size='sm' variant='secondary' onClick={() => setIsSelected(true)}>
              Select
            </Button>
          ) : (
            <Button
              size='sm'
              variant='primary'
              onClick={handleResponseSelection}
              loading={isLoading}
              disabled={metadataValidationFailures.includes(activePromptId)}>
              Save
            </Button>
          )
        ) : wacConfig.allowSelectResponse ? (
          <Button
            size='sm'
            variant='secondary'
            loading={isLoading}
            onClick={handleResponseSelection}
            disabled={metadataValidationFailures.includes(activePromptId)}>
            Select
          </Button>
        ) : (
          <Button size='sm' variant='secondary' onClick={() => handleClose?.()}>
            Close
          </Button>
        )}
      </div>
      <Tabs
        tabsBackgroundColor='#fafafa'
        onChange={(tab) => setResponseText(tab !== TabValue.Edited ? text : responseText)}>
        <Tab name='Edited' value={TabValue.Edited}>
          {isSelected ? (
            <div
              className={
                'my-3 box-border inline-block w-full whitespace-pre-wrap rounded border border-solid border-gray-300 bg-white p-2'
              }
              contentEditable={true}
              dir={textDirection}
              onPaste={(e) => {
                e.preventDefault()
                if (!wacConfig.disablePaste) {
                  // Fixes issue with gobbled text when pasting
                  document.execCommand('inserttext', false, e.clipboardData.getData('text/plain'))
                }
              }}
              onInput={(e) => setResponseText(e?.currentTarget?.textContent ?? '')}>
              {text}
            </div>
          ) : (
            <Markdown
              dir={textDirection}
              className='my-3 overflow-auto'
              components={{
                p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
              }}>
              {text}
            </Markdown>
          )}
        </Tab>
        <Tab name='Original' value={TabValue.Original}>
          <Markdown
            dir={textDirection}
            className='my-3 overflow-auto'
            components={{
              p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
            }}>
            {originalText}
          </Markdown>
        </Tab>
      </Tabs>
      <div>
        <h4 className='flex justify-between'>
          {' '}
          <span>Metadata info</span>{' '}
          <span
            className='cursor-pointer font-thin'
            onClick={() => setIsMetaDetailsOpen(!isMetaDetailsOpen)}>
            {isMetaDetailsOpen ? 'Hide' : 'Show'}
          </span>{' '}
        </h4>

        {isMetaDetailsOpen ? (
          <MetadataForm
            form={{ ...wacConfig?.responseMetadata, type: 'update' } as Wizard.Form.TSchema}
            stepRun={stepRun}
            wacName='RLHF2-Response'
            baseRunId={id}
            baseRunVariables={baseRunVariables}
          />
        ) : null}
      </div>
    </div>
  )
}

export { PromptResponse }
