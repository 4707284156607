import { datadogRum } from '@datadog/browser-rum'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { classNames, getUUIDFromNamespace } from '@invisible/common/helpers'
import { useTosSowState } from '@invisible/common/providers'
import { useStore } from '@invisible/common/stores/process-store'
import {
  fromGlobalId,
  getErrorMessage,
  IStepRunEventTypeEnum,
  IStepRunType,
  toGlobalId,
  useGetOrCreateStatementOfWorkMutation,
  useStepRunStartV2Mutation,
} from '@invisible/concorde/gql-client'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { SmallCheckbox } from '@invisible/ui/form'
import {
  BASE_ID_ARGS,
  BASE_VIEW_ID_ARGS,
  useQueryParam,
} from '@invisible/ui/hooks/use-query-params'
import { resetUserActivityStorage } from '@invisible/ui/hooks/use-user-activity'
import type { PrismaAll } from '@invisible/ultron/prisma'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import React, { RefObject, useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import shallow from 'zustand/shallow'

import { DEFAULT_ITEMS_PER_PAGE } from '../common/constants'
import {
  handleSetUserLifecycleStage,
  handleStepRunUpdatesOnLooopResourceStatus,
} from '../common/helpers'
import { useCompleteStepRun } from '../hooks/useCompleteStepRun'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'
import usePollAutoAssignment from '../hooks/usePollAutoAssignment'
import { TOKEN_NAMES, useStepRunEventLogger } from '../hooks/useStepRunEventLogger'

type TFindAssignedToMeData = inferQueryOutput<'stepRun.findAssignedToMe'>

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TStep = TStepRun['step']
type TBaseRunVariable = TBaseRun['baseRunVariables'][number]

type IProps = WizardSchemas.WACConfig.TSchema & {
  stepRun: TStepRun
  baseRun: TBaseRun
  isReadOnly: boolean
}

const ButtonWAC = ({ name, stepRun, isReadOnly }: IProps, wizardContainerRef: RefObject<any>) => {
  const reactQueryContext = useContext()
  const { state: wizardState, dispatch } = useWizardState()
  const { addToast } = useToasts()
  const { dispatch: dispatchSowState } = useTosSowState()
  const reactQueryClient = useQueryClient()

  const [baseId] = useQueryParam(...BASE_ID_ARGS)
  const [baseViewId] = useQueryParam(...BASE_VIEW_ID_ARGS)

  const [awaitingAutoAssignment, setAwaitingAutoAssignment] = useState(false)
  const [skipAutoAssign, setSkipAutoAssign] = useState(false)

  const { itemsPerPage, filterOption, sortOption, searchTerm, currentPage } = useStore(
    useCallback(
      (state) => ({
        itemsPerPage: state.itemsPerPage,
        filterOption: state.filterOption,
        sortOption: state.sortOption,
        searchTerm: state.searchTerm,
        currentPage: state.currentPage,
        hiddenColumns: state.hiddenColumns,
      }),
      []
    ),
    shallow
  )

  const { maybeLogStepRunEvent } = useStepRunEventLogger()

  const { mutateAsync: markStepRunDone, isLoading: isMarkStepRunDoneLoading } = useCompleteStepRun({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryClient.invalidateQueries('UserActiveWorkingProcessIDs')
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      reactQueryContext.invalidateQueries('stepRun.findCompletedAssignedToMe')
    },
    onSuccess: () => {
      datadogRum.addAction('Step run completed', {
        stepRunId: stepRun.id,
        stepId: stepRun.step.id,
        assigneeId: stepRun.assignee?.id,
        baseRunId: stepRun.baseRunId,
      })

      // Update "my tasks" query cache
      reactQueryContext.queryClient.setQueryData<TFindAssignedToMeData | undefined>(
        ['stepRun.findAssignedToMe'],
        (prevData) => {
          if (!prevData) return

          return prevData.map((entry) =>
            entry.stepRuns.some((s) => s.id === stepRun.id)
              ? {
                  ...entry,
                  stepRuns: entry.stepRuns.filter((s) => s.id !== stepRun.id),
                }
              : entry
          )
        }
      )

      // Update "base page" query cache
      reactQueryClient.setQueryData<TBaseRunQueryData | undefined>(
        [
          'get-base-runs',
          {
            baseId,
            baseViewId,
            take: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
            filters: filterOption ?? [],
            sort: sortOption ?? {},
            search: searchTerm ?? '',
            page: currentPage ?? 1,
          },
        ],
        (prevData) => {
          if (!prevData) return prevData

          const updatedBaseRunData = prevData.items.map((baseRun) => {
            if (baseRun.id === stepRun.baseRunId) {
              return {
                ...baseRun,
                stepRuns: baseRun.stepRuns.map((sr) =>
                  sr.id !== stepRun.id ? sr : { ...sr, status: 'done' as PrismaAll.StepRunStatus }
                ),
              }
            }
            return baseRun
          })
          return {
            ...prevData,
            baseRuns: updatedBaseRunData,
          }
        }
      )
      // Create step_run_submitted event log
      maybeLogStepRunEvent({
        name: 'step_run_submitted',
        stepRunId: stepRun.id as string,
        spanType: 'REVIEW',
        spanId: getUUIDFromNamespace([stepRun.id as string, TOKEN_NAMES.REVIEW]),
        type: IStepRunEventTypeEnum.Span,
        timestamp: new Date(),
      })
    },
  })
  const { mutateAsync: getOrCreateSow } = useGetOrCreateStatementOfWorkMutation({
    onError: (error) => {
      const errorMessage = getErrorMessage(error)
      addToast(`Get or create SoW failed: ${errorMessage}`, {
        appearance: 'error',
      })
    },
  })
  const { mutateAsync: startStepRun } = useStepRunStartV2Mutation({
    onSuccess: (response) => {
      if (response.stepRunStartV2.__typename !== 'GraphQLErrorType') {
        const stepRunStartData = response.stepRunStartV2 as IStepRunType
        const transformedData = {
          id: fromGlobalId(stepRunStartData.id),
          stepId: fromGlobalId(stepRunStartData.step.id),
          assigneeId: fromGlobalId(stepRunStartData.assignee?.id),
          baseRunId: fromGlobalId(stepRunStartData.baseRun.id),
        }

        datadogRum.addAction('Step run started', {
          stepRunId: transformedData.id,
          stepId: transformedData.stepId,
          assigneeId: transformedData.assigneeId,
          baseRunId: transformedData.baseRunId,
        })

        reactQueryContext.queryClient.setQueryData<
          inferQueryOutput<'stepRun.findAssignedToMe'> | undefined
        >(['stepRun.findAssignedToMe'], (prevData) => {
          if (!prevData) return

          return prevData.map((p) => ({
            ...p,
            stepRuns: p.stepRuns.map((s) =>
              s.id === fromGlobalId(stepRunStartData.id) ? { ...s, status: 'running' } : s
            ),
          }))
        })
        handleSetUserLifecycleStage({
          stepId: transformedData.stepId,
          userId: transformedData.assigneeId,
        })
        handleStepRunUpdatesOnLooopResourceStatus({
          id: transformedData.id,
          stepId: transformedData.stepId,
          userId: transformedData.assigneeId,
          baseRunId: transformedData.baseRunId,
        })
      }
    },
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryClient.invalidateQueries('UserActiveWorkingProcessIDs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      resetUserActivityStorage()
    },
    onError: (error) => {
      const errorMessage = getErrorMessage(error)
      addToast(`Start failed: ${errorMessage}`, {
        appearance: 'error',
      })
    },
  })

  const hasNextStepStrategy = useMemo(
    () =>
      (stepRun.step.meta as Record<string, any> | undefined)?.canHaveAutoAssignOnNextStep ||
      ['same_step', 'sla'].includes(
        (stepRun.step.meta as Record<string, any> | undefined)?.autoAssignStrategy?.type
      ),
    [stepRun.step.meta]
  )

  const autoAssignmentPolling = usePollAutoAssignment(stepRun, skipAutoAssign)

  const handleClick = async () => {
    try {
      // Create step_run_submit_clicked event log
      maybeLogStepRunEvent({
        name: 'step_run_submit_clicked',
        stepRunId: stepRun.id as string,
        spanType: 'REVIEW',
        spanId: getUUIDFromNamespace([stepRun.id as string, TOKEN_NAMES.REVIEW]),
        type: IStepRunEventTypeEnum.Span,
        timestamp: new Date(),
      })

      const createFormsInTheWizard = wizardState?.wizardData?.filter(
        (wd) => wd.config?.form?.type === 'create'
      )
      for (const createForm of createFormsInTheWizard ?? []) {
        const { baseId, minNumberOfBaseRuns, maxNumberOfBaseRuns } = createForm.config?.form ?? {}
        if (
          minNumberOfBaseRuns &&
          (((wizardState?.baseRun as TBaseRun)?.childCounts[baseId ?? ''] as number) ?? 0) <
            minNumberOfBaseRuns
        ) {
          throw new Error(
            `Please ensure a minimum of ${minNumberOfBaseRuns} and a maximum of ${maxNumberOfBaseRuns} entries are added in ${createForm.config?.name} before submitting.`
          )
        }
      }

      let assignedStepRun:
        | undefined
        | void
        | (TStepRun & {
            baseRun: TBaseRun
            step: TStep
            wizardInitialBaseRunVariables: TBaseRunVariable[]
          })
        | Record<string, any>

      await markStepRunDone({ id: stepRun.id })
      setAwaitingAutoAssignment(true) // This is to prevent the user from clicking the Submit button again while we wait for the auto-assignment to complete

      if (hasNextStepStrategy) {
        assignedStepRun = await autoAssignmentPolling() // This will poll for the next assigned Step Run for a set Polling Period.
      }

      // We dispatch a closeWizard action to reset the wizard state. The Wizard will be opened again if there is a next assigned Step Run.
      dispatch({ type: 'closeWizard' })

      // If we have an assigned Step Run, we open the wizard with the assigned Step Run.
      if (assignedStepRun) {
        const { wizardConfig, trainingLink } = (assignedStepRun?.step?.meta ?? {}) as Record<
          string,
          any
        >

        const openWizard = () => {
          dispatch({
            type: 'openWizard',
            stepRun: assignedStepRun as unknown as TStepRun,
            baseRun: assignedStepRun?.baseRun as unknown as TBaseRun,
            stepName: assignedStepRun?.step?.name,
            wizardInitialBRVs: assignedStepRun?.wizardInitialBaseRunVariables,
            wizardData: wizardConfig,
            trainingLink: trainingLink as string,
            processId: assignedStepRun?.step?.processId,
          })
          // Scroll wizard container to top
          ;(wizardContainerRef as RefObject<any>)?.current?.elementRef?.current?.scrollTo?.(0, 0)
        }

        if (assignedStepRun.status === 'running') {
          openWizard()
        }

        if (assignedStepRun.status === 'pending') {
          const stepRunStartData = await startStepRun({
            id: toGlobalId('StepRunType', assignedStepRun.id),
          })
          if (stepRunStartData.stepRunStartV2.__typename === 'GraphQLErrorType') {
            const { message, code } = stepRunStartData.stepRunStartV2

            if (message === 'User has not acknowledged the latest statement of work') {
              try {
                const request = await getOrCreateSow({
                  stepRunId: toGlobalId('StepRunType', assignedStepRun.id),
                })

                if (request?.getOrCreateStatementOfWork?.__typename !== 'GraphQLErrorType') {
                  dispatchSowState({
                    type: 'setSowToAcknowledge',
                    showSowModal: true,
                    sowToAcknowledge: request.getOrCreateStatementOfWork,
                    // When user acknowledges the SoW we open the wizard, else we just close the SoW modal
                    openWizard,
                    stepRunId: assignedStepRun.id,
                  })
                }
              } catch (err: unknown) {
                addToast(`Fetch Statement of Work failed: ${(err as Error | undefined)?.message}`, {
                  appearance: 'error',
                })
              }
            } else {
              addToast(`${code}: ${message}`, {
                appearance: 'error',
              })
            }
          } else {
            // Start didn't fail, open the wizard
            openWizard()
          }
        }
      }
    } catch (error) {
      sendErrorToSentry(error)
      const message = getErrorMessage(error)
      addToast(`Something went wrong: ${message}`, {
        appearance: 'error',
      })
    }
  }

  return (
    <div
      className={classNames(
        'flex h-full',
        hasNextStepStrategy
          ? 'box-border flex-col gap-4 bg-white p-2.5 shadow-md'
          : 'flex h-full justify-center pb-3'
      )}>
      {hasNextStepStrategy ? (
        <SmallCheckbox checked={skipAutoAssign} onClick={(e) => setSkipAutoAssign(e)}>
          Don't assign a new task after submission
        </SmallCheckbox>
      ) : null}
      <div className='mt-2 flex justify-end'>
        <Button
          variant='primary'
          size='md'
          onClick={handleClick}
          loading={isMarkStepRunDoneLoading}
          disabled={
            isReadOnly ||
            awaitingAutoAssignment ||
            Object.values(wizardState.readyForSubmit).some((k) => !k)
          }>
          {name}
        </Button>
      </div>
    </div>
  )
}

export { ButtonWAC }
