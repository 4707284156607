import Forward10 from '@mui/icons-material/Forward10'
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Replay10 from '@mui/icons-material/Replay10'
import VolumeDown from '@mui/icons-material/VolumeDown'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ReactElement, useCallback, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'

import { useKeyboardShortcuts } from './useKeyboardShortcuts'

const SPEED_OPTIONS = [0.8, 1, 1.2, 1.5, 2, 2.5]

const Controls = ({ waveSurfer }: { waveSurfer?: WaveSurfer | null }) => {
  const isPlaying = waveSurfer?.isPlaying() ?? false
  const [isUnmuted, setIsUnmuted] = useState(() => waveSurfer?.getVolume() !== 0)
  const [currentSpeed, setCurrentSpeed] = useState<number>(waveSurfer?.getPlaybackRate() ?? 1)
  const [speedAnchorEl, setSpeedAnchorEl] = useState<HTMLElement | null>(null)

  const toggleMute = useCallback(() => {
    if (!waveSurfer) return
    const volume = waveSurfer.getVolume()
    waveSurfer?.setVolume(volume > 0 ? 0 : 1)
    setIsUnmuted((p) => !p)
  }, [waveSurfer])

  const toggleSpeedOptions = useCallback(
    (currentSpeed: number) => {
      const currentIndex = SPEED_OPTIONS.indexOf(currentSpeed)
      const nextIndex = (currentIndex + 1) % SPEED_OPTIONS.length
      setCurrentSpeed(SPEED_OPTIONS[nextIndex])
      waveSurfer?.setPlaybackRate(SPEED_OPTIONS[nextIndex])
    },
    [waveSurfer]
  )

  useKeyboardShortcuts({ toggleSpeedOptions, toggleMute, waveSurfer })

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <CustomTooltip title={isPlaying ? 'Pause' : 'Play'} keyboardShortcut='Spacebar'>
        <IconButton disabled={!waveSurfer} onClick={() => waveSurfer?.playPause()}>
          {isPlaying ? <PauseCircleOutlineOutlinedIcon /> : <PlayArrow />}
        </IconButton>
      </CustomTooltip>

      <CustomTooltip title='Step back' keyboardShortcut='Left Arrow'>
        <IconButton disabled={!waveSurfer} onClick={() => waveSurfer?.skip(-10)}>
          <Replay10 />
        </IconButton>
      </CustomTooltip>

      <CustomTooltip title='Step forward' keyboardShortcut='Right Arrow'>
        <IconButton disabled={!waveSurfer} onClick={() => waveSurfer?.skip(10)}>
          <Forward10 />
        </IconButton>
      </CustomTooltip>

      <CustomTooltip title={isUnmuted ? 'Mute' : 'Unmute'} keyboardShortcut='M'>
        <IconButton disabled={!waveSurfer} onClick={toggleMute}>
          {isUnmuted ? <VolumeDown /> : <VolumeOffIcon />}
        </IconButton>
      </CustomTooltip>

      <CustomTooltip title='Play speed' keyboardShortcut='S'>
        <IconButton
          disabled={!waveSurfer}
          onClick={(e) => setSpeedAnchorEl(e.currentTarget)}
          sx={{ width: '40px', height: '40px' }}>
          <Typography variant='body2' fontWeight='bold'>
            {currentSpeed}x
          </Typography>
        </IconButton>
      </CustomTooltip>

      <Popover
        open={Boolean(speedAnchorEl)}
        anchorEl={speedAnchorEl}
        onClose={() => setSpeedAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        disableRestoreFocus>
        <Box p='16px'>
          <Typography fontWeight='500' fontSize='16px'>
            Playback speed
          </Typography>
          <Stack direction='row' alignItems='center' gap='4px' mt='8px'>
            {SPEED_OPTIONS.map((speed) => (
              <Button
                size='small'
                key={speed}
                onClick={() => {
                  waveSurfer?.setPlaybackRate(speed)
                  setCurrentSpeed(speed)
                  setSpeedAnchorEl(null)
                }}
                sx={{
                  height: '40px',
                  width: '40px',
                  minWidth: '40px',
                  borderRadius: 20,
                  p: 0,
                  fontWeight: 'normal',
                }}>
                {speed}x
              </Button>
            ))}
          </Stack>
        </Box>
      </Popover>
    </Stack>
  )
}

const CustomTooltip = ({
  title,
  children,
  keyboardShortcut,
}: {
  title: string
  children: ReactElement
  keyboardShortcut: string
}) => (
  <Tooltip
    title={
      <Stack direction='row' alignItems='center' spacing={1}>
        <Typography fontSize='10px'>{title}</Typography>
        <Typography bgcolor='grey.800' p='0px 6px' borderRadius='6px' fontSize='10px'>
          {keyboardShortcut}
        </Typography>
      </Stack>
    }
    arrow>
    {children}
  </Tooltip>
)

export { Controls }
