import { ConversationAreaProps } from '../types'
import { Turn } from './Turn'

export const ConversationArea = ({
  prompts,
  isFetchingResponses,
  isCreatingBaseRun,
  isCreatingManyBaseRuns,
  isLastResponseEmpty,
  collapseAll,
  loggedInUser,
}: ConversationAreaProps) => (
  <div>
    {prompts?.map((prompt, index) => (
      <Turn
        key={index}
        prompt={{ ...prompt, index }}
        collapsed={collapseAll}
        loggedInUser={loggedInUser}
      />
    ))}
    {(isFetchingResponses || isCreatingBaseRun || isCreatingManyBaseRuns) && (
      <div className='mb-2'>
        <div className='max-w-1/2 relative ml-auto mt-2 mr-1 w-fit min-w-[20%] max-w-[50%] overflow-auto rounded bg-[#F5F5F7] py-2 px-4 text-sm'>
          Generating responses...
        </div>
      </div>
    )}
    {isLastResponseEmpty && (
      <div className='text-paragraphs py-2'>
        Blank response from Model. You can refresh the page & retry or End Conversation.
      </div>
    )}
  </div>
)
