import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'
import { IconButton } from '@mui/material'

import { CustomMultiSelect } from '../sub-components/CustomMultiSelect'
import { HeaderProps } from '../types'

export const Header = ({
  showInfoSection,
  setShowInfoSection,
  tokenCount,
  isMinMaxTurnsVariablesSet,
  normalizedConversationData,
  config,
  numOfResponsesPerPrompt,
  visibleResponseIndices,
  setVisibleResponseIndices,
  isUpdated,
  isUpdatingBaseVariables,
  collapseAll,
  setCollapseAll,
}: HeaderProps) => (
  <div className='text-paragraphs flex items-center py-3 text-sm font-bold border-solid border-0 border-b-2 border-gray-400 [box-shadow:rgba(96,76,164,0.2)_0px_4px_12px] z-[1]'>
    <IconButton onClick={() => setShowInfoSection(!showInfoSection)} className='text-gray-400'>
      <ViewSidebarIcon />
    </IconButton>
    <div className='flex'>
      <span>Your conversations (</span>
      {(config?.minMaxTurns || isMinMaxTurnsVariablesSet) && (
        <span>
          Turns count:{' '}
          {isMinMaxTurnsVariablesSet
            ? normalizedConversationData.minTurn
            : config.minMaxTurns?.[0] ?? 1}{' '}
          Min,{' '}
          {isMinMaxTurnsVariablesSet
            ? normalizedConversationData.maxTurn
            : config.minMaxTurns?.[1] ?? 1}{' '}
          Max
        </span>
      )}
      <span>)</span>
      <span className='ml-2'>Token count: {tokenCount}</span>
    </div>

    <div className='ml-2'>
      <CustomMultiSelect
        label='Visible Responses'
        options={[
          { key: 'Response A', value: 1 },
          { key: 'Response B', value: 2 },
          { key: 'Response C', value: 3 },
          { key: 'Response D', value: 4 },
          { key: 'Response E', value: 5 },
        ].slice(0, numOfResponsesPerPrompt)}
        selectedKeys={visibleResponseIndices}
        onSelect={(selectedKeys) => setVisibleResponseIndices(selectedKeys as number[])}
      />
    </div>
    <div className='ml-2 text-gray-400'>
      {isUpdated ? 'Not Saved' : isUpdatingBaseVariables ? 'Saving...' : 'Saved!'}
    </div>
    <IconButton onClick={() => setCollapseAll(!collapseAll)} className='ml-2 text-gray-400'>
      {collapseAll ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
    </IconButton>
    {config.showPreamble ? (
      <div className='ml-2 text-xs'>{normalizedConversationData.preamble}</div>
    ) : null}
  </div>
)
