export const COLOR_MAP: Record<string, { char: string; bg: string; text: string }> = {
  '1': {
    char: 'A',
    bg: '#3780C80D',
    text: '#3780C8',
  },
  '2': {
    char: 'B',
    bg: '#9159DD0D',
    text: '#9159DD',
  },
  '3': {
    char: 'C',
    bg: '#F19E7314',
    text: '#F4511E',
  },
  '4': {
    char: 'D',
    bg: '#BEF2CA1F',
    text: '#448D57',
  },
  '5': {
    char: 'E',
    bg: '#D9D9D924',
    text: '#000000',
  },
}
