export const COMPANY_STATUS: { [status: string]: string } = {
  prospect: 'Prospect',
  piloting: 'Piloting',
  onboarding: 'Onboarding',
  active: 'Active',
  inactive: 'Inactive',
  offboarding: 'Offboarding',
  churned: 'Churned',
  demo: 'Demo',
}

export const STRIPE_DASHBOARD_URL = 'https://dashboard.stripe.com'
