import { ChangeEvent, DragEvent, MouseEvent, MutableRefObject, useRef, useState } from 'react'

import { Button } from '../button'
import { CheckCircleOutlineIcon } from '../icons'
import { DocUploadIcon as _DocUploadIcon } from '../icons'
import { gray, purple, styled } from '../themes'

const DocUploadIcon = styled(_DocUploadIcon)<{ color?: string }>`
  color: ${({ color }) => color ?? purple(6)};
  display: table;
  margin: 0 auto;
  padding-top: 1rem;
  width: 50%;
`

const PhotoUploadText = styled.div`
  color: ${gray(6)};
  display: table;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: bold;
`

const DragAndDropText = styled.div`
  display: table;
  margin: 0 auto;
  padding-top: 0.5rem;
`

const FileUploadContainer = styled.div`
  display: flex;
  padding-top:1rem;
`

const UploadFileBox = styled.div<{ isActive: boolean; width?: string; height?: string }>`
  display: flex;
  flex-direction:column;
  gap:1rem;
  width: ${({ width }) => width ?? '22rem'};
  height: ${({ height }) => height ?? '13rem'};
  border: ${({ isActive }) => (isActive ? '2px solid #d0cfd2' : 'dotted 2px #d0cfd2')};
  background-color: ${({ isActive }) => (isActive ? gray(3) : '')};
  border-radius: 6px;
  align-items: center;
`

const PhotoUploaded = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  width: ${({ width }) => width ?? '20rem'};
  padding-bottom: 1rem;
`

const PhotoUploadedText = styled.div`
  color: ${gray(6)};
  padding-top: 1rem;
  font-size: 1rem;
`

interface IPhotoUploadField {
  photoName: string
  photoUploaded?: string
  handleOnChange: (e: ChangeEvent) => void
  handleOnDrop: (e: DragEvent<HTMLDivElement>) => void
  isUploadingPicture: boolean
  width?: string
  height?: string
  color?: string
}

const PhotoUpload = ({
  photoName,
  handleOnChange,
  handleOnDrop,
  photoUploaded,
  isUploadingPicture,
  width,
  height,
  color,
}: IPhotoUploadField) => {
  const [isActive, setIsActive] = useState(false)
  const photoInput = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    photoInput?.current?.click()
  }

  const handleOnDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsActive(true)
  }
  const handleOnDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsActive(true)
  }
  const handleOnDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsActive(false)
  }
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsActive(false)
    handleOnDrop(e)
  }

  return (
    <FileUploadContainer>
      {photoUploaded ? (
        <PhotoUploaded width={width}>
          <CheckCircleOutlineIcon color='green' />
          <PhotoUploadedText>
            <div>Photo Successfully Uploaded</div>
          </PhotoUploadedText>
        </PhotoUploaded>
      ) : (
        <UploadFileBox
          isActive={isActive}
          onDragOver={handleOnDragOver}
          onDrop={handleDrop}
          onDragEnter={handleOnDragEnter}
          onDragLeave={handleOnDragLeave}
          width={width}
          height={height}>
          <DocUploadIcon color={color} />
          <input
            ref={photoInput}
            type='file'
            accept='image/png, image/jpeg'
            onChange={handleOnChange}
            name={photoName}
            value={photoUploaded}
            hidden></input>
          <PhotoUploadText>
            <div>Drag And Drop {photoName} to Upload</div>
            <DragAndDropText>OR</DragAndDropText>
          </PhotoUploadText>
          <Button disabled={isUploadingPicture} onClick={handleClick}>
            {isUploadingPicture ? 'Uploading...' : 'Browse Photo'}
          </Button>
        </UploadFileBox>
      )}
    </FileUploadContainer>
  )
}

export { PhotoUpload }
