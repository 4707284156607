import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { useEffect, useState } from 'react'

import { TBaseRunQueryData } from '../../../process-base/src'
import { Progress } from './components/Progress'
import { Review } from './components/Review'
import { Section } from './components/Section'

type TCheckListValue = Record<string, boolean>
type TBaseRun = TBaseRunQueryData['items'][number]
type TBaseRunVariable = TBaseRun['baseRunVariables'][number]

export type DynamicFormProps = {
  form?: WizardSchemas.Form.TSchema
  formValues: Record<string, string | number | boolean | Date | null | undefined | string[]>
  onSetFormValues: (
    val: Record<string, string | number | boolean | Date | null | undefined | string[]>
  ) => void
  onCanSubmit: (value: boolean) => void
  onShowReview: (state: boolean) => void
  setResetState: (func: () => void) => void
  initialFormValues: TBaseRunVariable[]
  isReadOnly?: boolean
}

export function DynamicForm({
  form,
  initialFormValues,
  formValues,
  onSetFormValues,
  setResetState,
  onShowReview,
  onCanSubmit,
  isReadOnly = false,
}: DynamicFormProps) {
  const [completedSectionsCount, setCompletedSectionsCount] = useState(0)

  const [skippedSections] = useState<WizardSchemas.FormSection.TSchema[]>([])
  const [formSubmittedFromSection, setFormSubmittedFromSection] = useState<string | undefined>()
  const [checkListValue, setCheckListValue] = useState<TCheckListValue>({})

  useEffect(() => {
    if (completedSectionsCount === (form?.sections ?? []).length) {
      onShowReview(true)
      onCanSubmit(true)
    }

    return () => {
      onShowReview(false)
      onCanSubmit(false)
    }
  }, [completedSectionsCount])

  /**
   * This is no longer needed because we're using the global state to reset the form.
   * Keeping it here because FormWAC is still using this.
   */
  setResetState(() => {
    setCompletedSectionsCount(0)
    onShowReview?.(false)
    setCheckListValue({})
    setFormSubmittedFromSection(undefined)
    onSetFormValues?.({})
  })

  /*
   * bail if no form
   */
  if (!form) {
    return null
  }

  const { fields, sections, checkLists } = form

  /**
   * bail if no fields, sections, or checkLists
   */
  if (!fields || !sections || !checkLists) {
    return null
  }

  const showReview = completedSectionsCount === sections.length

  /**
   * Refactor Notes: Potential edge cases to review with ProServ:
   * - Are there fields with no sectionId? The builder won't allow it,
   *   but this is a potential edge case.
   * - Can sections be skipped? How's that setup?
   */
  return (
    <div>
      {sections.length > 1 && (
        <Progress {...{ showReview, progress: completedSectionsCount / (sections?.length ?? 0) }} />
      )}

      {showReview ? (
        <Review
          form={form}
          formValues={formValues}
          checkListValue={checkListValue}
          skippedSections={skippedSections}
          formSubmittedFromSection={formSubmittedFromSection}
        />
      ) : null}

      {!showReview
        ? sections.map((section, index) => {
            /**
             * Skip sections that are not completed
             */
            if (index > completedSectionsCount) return null

            /**
             * Refactor Notes: onSetFormValues has to be replaced with an onSubmit handler
             * to integrate with the global state of the FormWAC. Until we refactor the top level
             * component, we'll keep the temporary solution of onSetFormValues.
             */
            return (
              <Section
                key={section.id}
                section={section}
                fields={fields.filter((field) => field.sectionId === section.id)}
                formValues={formValues}
                checkLists={checkLists.filter((checkList) => checkList.sectionId === section.id)}
                checkListValue={checkListValue}
                isSingleSection={sections.length === 1}
                isCompleted={index + 1 <= completedSectionsCount}
                hasNextSection={completedSectionsCount + 1 < (form?.sections ?? []).length}
                onSubmit={(data) => {
                  const baseRunVariables = Object.entries(data).reduce<Record<string, any>>(
                    (acc, [id, value]) => {
                      const baseRunVariableId = fields.find(
                        (field) => field.id === id
                      )?.baseVariableId
                      if (baseRunVariableId) {
                        acc[baseRunVariableId] = value
                      }
                      return acc
                    },
                    {}
                  )

                  onSetFormValues(baseRunVariables)
                  setCompletedSectionsCount((prev) => prev + 1)
                }}
              />
            )
          })
        : null}
    </div>
  )
}

export default DynamicForm
