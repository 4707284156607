import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'

import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'

export interface ExecuteResponse {
  status: 'success' | 'failed'
  execution_id: string
  error: string
}

export interface StatusResponse {
  execution_id: string
  // Todo: get more specific types
  status: string
  error: string
  execution_metadata: {
    // Todo: get more specific types
    execution_state: string
    // Format: '2024-08-27T13:15:13.980Z'
    start_time: string
    end_time: string
    stdout: string
    stderr: string
    result: string
  }
}

export type Environment = {
  environment_id: string
  language: {
    name: string
    version: string
  }
  packages: { name: string; version: string }[]
  use_case: 'step_default' | 'cem_testing' | 'wac_default'
}

export type EnvironmentsResponse = {
  status: string
  error: string | null
  environments: Environment[]
}

export type TStepRun = NonNullable<inferQueryOutput<'stepRun.findById'>>
export type TBaseRun = TBaseRunQueryData['items'][number]
export type TCodeWAC2 = WizardSchemas.CodeWAC2.TSchema
export interface CodeWACProps {
  readOnly: boolean
  stepRun: TStepRun
  code2: TCodeWAC2
  baseRun: TBaseRun
}

export type UploadedFile = {
  fileUuid: string
  fileName: string
  url: string
  size: number
  type: string
  filePath: string
}

export enum CEMCodeStatus {
  NotStarted,
  Executing,
  Success = 'Success',
  Failed = 'Failed',
}
