import { useEffect, useState } from 'react'
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js'

interface IProps {
  options: Partial<WaveSurferOptions>
  container: HTMLDivElement | null
}

const useWaveSurfer = ({ options, container }: IProps) => {
  const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | null>(null)
  const [playbackProgress, setPlaybackProgress] = useState(0)
  const [audioLength, setAudioLength] = useState(0)
  const [percentLoaded, setPercentLoaded] = useState(0)

  // Initialize wavesurfer
  useEffect(() => {
    if (!container) return

    const instance = WaveSurfer.create({
      ...options,
      container,
    })
    setWaveSurfer(instance)

    return () => {
      instance?.destroy()
    }
  }, [container, options])

  // Update playback progress
  useEffect(() => {
    if (!waveSurfer) return
    const handleProgress = (time: number) => {
      setPlaybackProgress(time)
    }
    const handleReset = () => {
      setPlaybackProgress(0)
      waveSurfer.setTime(0)
    }
    const handleInitialAudioLength = (seconds: number) => {
      setAudioLength(seconds)
    }
    const handleLoading = (v: number) => {
      setPercentLoaded(v)
    }

    waveSurfer.on('audioprocess', handleProgress)
    waveSurfer.on('finish', handleReset)
    waveSurfer.on('decode', handleInitialAudioLength)
    waveSurfer.on('loading', handleLoading)
    return () => {
      waveSurfer.un('audioprocess', handleProgress)
      waveSurfer.un('finish', handleReset)
      waveSurfer.un('decode', handleInitialAudioLength)
      waveSurfer.un('loading', handleLoading)
    }
  }, [waveSurfer])

  return { waveSurfer, playbackProgress, audioLength, isLoaded: percentLoaded === 100 }
}

export { useWaveSurfer }
