import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { CheckCircleOutlineIcon, CheckIcon, CloseCircleIcon, CloseIcon } from '../icons'

const CircleWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
`
const PercentCircle = styled.span`
  font-size: 24px;
  color: #423f4c;
  font-weight: 400;
  z-index: 99;
  position: relative;
  left: 51%;
  top: 50%;
`

const ShowProgress = styled.span`
  font-size: 14px;
  color: #a19fa5;
  margin-left: 10px;
  font-weight: 300;
  z-index: 99;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

type TStatus = 'Success' | 'Exception' | 'In Progress'
export interface ICircleBar {
  size: any
  progress: number
  strokeWidth: number
  circleOneStroke: string
  showIcon?: boolean
  percentage?: boolean
  status?: TStatus
  color?: string
}
export interface IProgress {
  progress: number
  height?: number
  width: number | string
  percentage?: boolean
  status?: TStatus
  circle?: boolean
  color?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ProgressCircle: FC<ICircleBar> = ({
  size,
  progress,
  strokeWidth,
  circleOneStroke,
  percentage,
  status,
  color,
}) => {
  const [offset, setOffset] = useState(0)
  const circleRef = useRef(null)
  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  const progressStat = status === 'Success' ? 100 : progress

  const infoIcon =
    progressStat === 100 ? (
      <CheckIcon style={{ color: '#52C41A', width: '48px', height: '48px' }} />
    ) : (
      <CloseIcon style={{ color: '#F5222D', width: '48px', height: '48px' }} />
    )

  const fillColor =
    status === 'Exception'
      ? '#F5222D'
      : status === 'Success'
      ? '#52C41A'
      : color
      ? color
      : '#1890FF'

  const progressColor =
    progress === 100
      ? '#52C41A'
      : status === 'In Progress'
      ? color
        ? color
        : '#1890FF'
      : fillColor

  const showStatus = (status: TStatus | undefined) =>
    status === 'Success' || status === 'Exception' ? (
      <PercentCircle>{infoIcon}</PercentCircle>
    ) : percentage ? (
      <PercentCircle>{progress}%</PercentCircle>
    ) : null

  useEffect(() => {
    const progressOffset = circumference * (1 - progressStat / 100)
    setOffset(progressOffset)
  }, [progressStat, circumference, offset])

  return (
    <CircleWrapper>
      {showStatus(status)}
      <svg width={size} height={size} fill='none'>
        <circle
          ref={circleRef}
          stroke={circleOneStroke}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          stroke={progressColor}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          transform={`rotate(-90 ) translate(${-size} 0)`}
        />
      </svg>
    </CircleWrapper>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Progress: FC<IProgress> = ({
  progress,
  width,
  height,
  status,
  circle,
  percentage = true,
  color,
}) => {
  const progressStat = status === 'Success' ? 1 : progress

  const infoIcon =
    progressStat === 1 ? (
      <CheckCircleOutlineIcon
        width={14}
        height={14}
        style={{
          color: 'white',
          background: '#52C41A',
          borderRadius: '50%',
        }}
      />
    ) : status === 'Exception' ? (
      <CloseCircleIcon
        width={14}
        height={14}
        style={{
          background: '#F5222D',
          color: 'white',
          borderRadius: '50%',
        }}
      />
    ) : null

  const progressColor =
    progressStat === 1
      ? '#52C41A'
      : status === 'In Progress' || status === undefined
      ? color
        ? color
        : '#1890FF'
      : '#F5222D'

  const circleProgress = progress * 100

  const showStatus = (status: TStatus | undefined) =>
    status === 'Success' || status === 'Exception' ? (
      <ShowProgress> {infoIcon} </ShowProgress>
    ) : percentage ? (
      <ShowProgress> {Math.floor(progressStat * 100)}% </ShowProgress>
    ) : null

  const realHeight = height ? height : Number(width) <= 120 ? '6px' : '8px'

  const realRx = height ? height / 2 : Number(width) <= 120 ? '3' : '4'

  return !circle ? (
    <Wrapper>
      <svg width={width || 800} height={realHeight} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect x='0' y='0' width={width || 800} height={realHeight} rx={realRx} fill='#F2F3F2' />
        <rect
          x='0'
          y='0'
          width={`${progressStat * 100}%`}
          height={realHeight}
          rx={realRx}
          fill={progressColor}
        />
      </svg>
      {showStatus(status)}
    </Wrapper>
  ) : (
    <ProgressCircle
      size={width || 240}
      color={color}
      progress={circleProgress}
      strokeWidth={8}
      circleOneStroke='#F2F3F2'
      status={status}
      percentage={percentage}
    />
  )
}
