import { flow, toLower, trim } from 'lodash/fp'

import { COMPANY_STATUS } from './constants'
import { Company } from './types'

export const getCompanyStatus = (company: Company) => {
  const { status } = company
  return COMPANY_STATUS[status]
}

export const getDifference = (
  object1: Record<string, unknown>,
  object2: Record<string, unknown>
) => {
  const differentKeys = []
  const difference: Record<string, unknown> = {}

  for (const key in object1) {
    if (
      Object.prototype.hasOwnProperty.call(object1, key) &&
      Object.prototype.hasOwnProperty.call(object2, key) &&
      (object1[key] as string)?.toString() !== (object2[key] as string)?.toString()
    ) {
      differentKeys.push(key.toString())
      difference[key] = object1[key]
    }
  }

  return { differentKeys, difference }
}

export const generateEmail = flow(
  trim,
  toLower,
  (cleanedName) => `${cleanedName.replace(/\s+/g, '.')}@inv.tech`
)
