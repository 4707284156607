import create from 'zustand'

export interface BaseRunVariablesState {
  code: undefined | string
  language: undefined | 'python' | 'javascript' | 'go' | 'cobol'
  version: undefined | string
  environment: undefined | string
  image: undefined | string
  reason: undefined | string
  isSuccess: undefined | string
  updateState: (value: Partial<BaseRunVariablesState>) => void
}

export const useBaseRunVariablesStore = create<BaseRunVariablesState>((set) => ({
  code: undefined,
  language: undefined,
  version: undefined,
  environment: undefined,
  image: undefined,
  reason: undefined,
  isSuccess: undefined,
  updateState: (partialState: Partial<BaseRunVariablesState>) =>
    set((state) => ({
      ...state,
      ...partialState,
    })),
}))
