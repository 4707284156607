import { Button } from '@invisible/ui/button'
import { TextArea } from '@invisible/ui/form'
import clsx from 'clsx'
import { ChangeEvent, useState } from 'react'

import { ScreenshotUpload } from './ScreenshotUpload'
import { useBaseRunVariablesStore } from './stores/baseRunVariablesStore'
import { CEMCodeStatus, TCodeWAC2 } from './types'

type LocalTesterProps = {
  config: TCodeWAC2
  updateBRV: (id: undefined | string, value: undefined | string) => Promise<void>
}

export type UploadedFile = {
  fileUuid: string
  fileName: string
  url: string
  size: number
  type: string
  filePath: string
}

export const LocalTester = ({
  config: { reasonBaseRunVariableId, imageBaseRunVariableId },
  updateBRV,
}: LocalTesterProps) => {
  const [status, setStatus] = useState<CEMCodeStatus>(CEMCodeStatus.NotStarted)
  const reason = useBaseRunVariablesStore((state) => state.reason)
  const updateState = useBaseRunVariablesStore((state) => state.updateState)

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (status) {
      setStatus(CEMCodeStatus.NotStarted)
    }
    updateState({ reason: e.currentTarget.value })
  }

  const handleSubmit = () => {
    setStatus(CEMCodeStatus.Executing)
    updateBRV(reasonBaseRunVariableId, reason)
      .then(() => setStatus(CEMCodeStatus.Success))
      .catch(() => setStatus(CEMCodeStatus.Failed))
  }

  return (
    <div>
      <h4>Local code output check</h4>
      <p>
        Please upload a screenshot of the code executing on your local environment and tell us why
        the in app check was not possible.
      </p>
      <ScreenshotUpload updateBRV={updateBRV} variable={imageBaseRunVariableId} />

      <div className='my-8'>
        <div className='font-medium'>Reason</div>
        <TextArea
          value={reason || ''}
          rows={10}
          onChange={handleTextAreaChange}
          style={{ resize: 'none' }}
          className='outline-theme-main border-1 max-h-20 max-w-[480px] border-solid '
        />
      </div>
      <div className='my-4 flex items-center'>
        <div>
          <Button
            size='md'
            onClick={handleSubmit}
            disabled={[CEMCodeStatus.Success, CEMCodeStatus.Executing].includes(status)}>
            Submit
          </Button>
        </div>
        {status !== CEMCodeStatus.NotStarted && (
          <p className='ml-2'>
            Status:{' '}
            <span
              className={clsx({
                'text-[#D32F2F]': status === CEMCodeStatus.Failed,
                'text-[#2E7D32]': status === CEMCodeStatus.Success,
              })}>
              {status === CEMCodeStatus.Executing ? 'Loading...' : status}
            </span>
          </p>
        )}
      </div>
    </div>
  )
}
