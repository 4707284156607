import React, { useEffect, useRef, useState } from 'react'
import { FilterProps, UseFiltersColumnProps } from 'react-table'
import { Box } from 'rebass'

import { DateRangePicker, IRange } from '../../date-range-picker'
import { useClickAway } from '../../hooks/use-click-away'
import { FilledFilterIcon } from '../../icons'
import { styled, useTheme } from '../../themes'
import { IconContainer } from '../ui-react-table'

type TPositionXProps = 'left' | 'right' | 'center'

const DatePickerContainer = styled.div<{ positionX: TPositionXProps }>`
  position: absolute;
  left: ${({ positionX }) =>
    positionX === 'center' ? '-50%' : positionX === 'left' ? '-100%' : '0%'};
`
interface IProps {
  column: FilterProps<any> & UseFiltersColumnProps<any> & { id: number | string }
  positionX?: TPositionXProps
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DateFilter: React.FC<IProps> = ({
  column: { filterValue: defaultFilter, setFilter, preFilteredRows, id },
  positionX = 'center',
}) => {
  const [filterOpen, setFilterOpen] = useState(false)
  const ref = useRef<null | HTMLDivElement>(null)
  const { grayScale } = useTheme()
  const [selectedDateRange, setSelectedDateRange] = useState<
    {
      startDate: Date | null
      endDate: Date | null
      key: string
    }[]
  >([{ startDate: null, endDate: new Date(''), key: 'selection' }])

  useEffect(() => {
    if (
      selectedDateRange[0].startDate &&
      selectedDateRange[0].endDate instanceof Date &&
      !isNaN(selectedDateRange[0].endDate as any)
    ) {
      setFilter({
        startDate: selectedDateRange[0].startDate,
        endDate: selectedDateRange[0].endDate,
      })
    }
  }, [selectedDateRange])

  useClickAway(ref, () => {
    setFilterOpen(false)
  })

  return (
    <Box ref={ref}>
      <IconContainer ml='5px' alignItems='center' visible={defaultFilter?.startDate}>
        <FilledFilterIcon
          width={14}
          height={14}
          color={defaultFilter?.startDate ? 'black' : grayScale[5]}
          onClick={() => setFilterOpen((prev) => !prev)}
        />
      </IconContainer>

      <DatePickerContainer positionX={positionX}>
        <DateRangePicker
          ranges={selectedDateRange as IRange[]}
          onChange={(range) => {
            setSelectedDateRange(range)
          }}
          standalone
          moveRangeOnFirstSelection={true}
          isOpen={filterOpen}
          onOpenChange={(open) => {
            if (open) setFilterOpen(true)
          }}
          onClear={() => {
            setSelectedDateRange([{ startDate: null, endDate: new Date(''), key: 'selection' }])
            setFilter([])
            setFilterOpen(false)
          }}
        />
      </DatePickerContainer>
    </Box>
  )
}

export { DateFilter }
