import { ThemeProvider } from '@mui/material/styles'

import { theme } from '../../mui-theme-v2'
import { VISUALIZATION_ICONS } from './constants'

export const VisualizationSelector: React.FunctionComponent<{
  selected: string
  visualizations: string[]
  onClick: (visName: string) => void
  justifyContent: string
}> = ({ selected, visualizations, onClick, justifyContent }) => {
  const visIcons = visualizations.map((visualization, index) => {
    const Icon = VISUALIZATION_ICONS[visualization]
    if (!Icon) return null
    return (
      <Icon
        id={`icon-${visualization}`}
        className={`!h-4 !w-4 p-1 ${
          selected === visualization ? 'bg-theme-main rounded-[50px] text-white' : ''
        }`}
        key={index}
        onClick={() => onClick(visualization)}
      />
    )
  })
  return (
    <ThemeProvider theme={theme}>
      <div id='visualization-selector' className='flex' style={{ justifyContent }}>
        <div className='border-box flex h-fit w-fit items-center rounded-[70px] border border-solid border-gray-300 bg-gray-200 px-1 py-0.5'>
          {visIcons}
        </div>
      </div>
    </ThemeProvider>
  )
}
