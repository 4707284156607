export const TABLE_SKELETON_DATA = [
  {
    Header: '',
    accessor: 'firstName',
  },
  {
    Header: '',
    accessor: 'lastName',
  },
  {
    Header: '',
    accessor: 'three',
  },
  {
    Header: '',
    accessor: 'four',
  },
  {
    Header: '',
    accessor: 'five',
  },
  {
    Header: '',
    accessor: 'six',
  },
  {
    Header: '',
    accessor: 'seven',
  },
  {
    Header: '',
    accessor: 'eight',
  },
] as const
