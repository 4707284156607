import classNames from 'classnames'
import { useState } from 'react'

import { NumberRankProps } from '../types'

const NumberRank = ({
  value,
  onChange,
  disabled,
  selectedValueColor,
  numOfRank,
}: NumberRankProps) => {
  const [selected, setSelected] = useState<number | null>(value)

  const options = [
    { key: '1st', value: 1 },
    { key: '2nd', value: 2 },
    { key: '3rd', value: 3 },
    { key: '4th', value: 4 },
    { key: '5th', value: 5 },
  ]
  return (
    <div
      className={classNames(
        'flex justify-between gap-x-4 py-1',
        disabled ? 'cursor-not-allowed' : ''
      )}>
      {options.slice(0, numOfRank ?? options.length).map((option, index) => (
        <div className='relative flex items-center justify-center p-1' key={index}>
          {selected === option.value ? (
            <div
              style={selected === option.value ? { color: selectedValueColor } : undefined}
              className={classNames(
                'center absolute top-[-12px] h-full align-top font-bold',
                selected === option.value ? `font-bold` : ''
              )}>
              .
            </div>
          ) : null}
          <span
            style={selected === option.value ? { color: selectedValueColor } : undefined}
            className={classNames(
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              selected === option.value ? `font-bold` : ''
            )}
            onClick={
              disabled
                ? () => undefined
                : () => {
                    setSelected(option.value)
                    onChange(option.value)
                  }
            }>
            {option.key}
          </span>
        </div>
      ))}
    </div>
  )
}

export { NumberRank }
