import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { FormEvent, useEffect, useRef, useState } from 'react'

import { formatDuration } from './helpers'
import { TSegment } from './ProcessAudioWac'

const Segments = ({
  configuredTags,
  segmentBeingPlayed,
  segments,
  handleSegmentEdit,
  handleSegmentDelete,
}: {
  configuredTags: string[]
  segmentBeingPlayed: string | null
  segments: TSegment[]
  handleSegmentEdit: (arg: { id: string; note?: string; tags?: string[] }) => void
  handleSegmentDelete: (id: string) => void
}) => {
  if (!segments?.length) return null

  return (
    <Box overflow='auto' mt='24px'>
      <Typography position='sticky' top={0} fontWeight={600} bgcolor='white' pb='20px' zIndex={10}>
        Segments ({segments.length})
      </Typography>
      {segments.map((segment) => (
        <SingleSegment
          key={segment.id}
          configuredTags={configuredTags}
          segmentBeingPlayed={segmentBeingPlayed}
          segment={segment}
          handleSegmentEdit={handleSegmentEdit}
          handleSegmentDelete={handleSegmentDelete}
        />
      ))}
    </Box>
  )
}

const SingleSegment = ({
  segment,
  segmentBeingPlayed,
  handleSegmentEdit,
  handleSegmentDelete,
  configuredTags,
}: {
  segment: TSegment
  segmentBeingPlayed: string | null
  handleSegmentEdit: (arg: { id: string; note?: string; tags?: string[] }) => void
  handleSegmentDelete: (id: string) => void
  configuredTags: string[]
}) => {
  const [isBeingEdited, setIsBeingEdited] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  const handleNoteSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as HTMLFormElement
    const note = (target.elements[0] as HTMLInputElement).value
    handleSegmentEdit({ id: segment.id, note })
    setIsBeingEdited(false)
  }

  useEffect(() => {
    if (segmentBeingPlayed !== segment.id) return

    ref.current?.scrollIntoView({ block: 'end' })
  }, [segmentBeingPlayed, segment.id])

  return (
    <>
      <Stack
        direction='row'
        mb='16px'
        gap='16px'
        border='1px solid'
        borderColor={segmentBeingPlayed === segment.id ? 'primary.main' : 'grey.100'}
        bgcolor={segmentBeingPlayed === segment.id ? 'primary.50' : 'grey.100'}
        borderRadius='4px'
        p='8px 12px'
        ref={ref}>
        <Stack width='150px'>
          <Typography fontWeight={600}>
            {formatDuration(Math.round(segment.start))} - {formatDuration(Math.round(segment.end))}
          </Typography>
          <Typography fontSize='10px' color='text.secondary'>
            {(Math.round(segment.end) - Math.round(segment.start)).toFixed(0)} secs
          </Typography>
        </Stack>

        <Stack width='40%' gap='4px'>
          <Typography fontWeight={600}>Notes</Typography>
          {!isBeingEdited ? (
            <Button
              sx={{
                fontWeight: 'normal',
                justifyContent: 'flex-start',
                textAlign: 'left',
                color: 'inherit',
                height: !segment.note ? '38px' : 'auto',
              }}
              onClick={() => setIsBeingEdited(true)}>
              {!segment.note ? 'Add note...' : segment.note}
            </Button>
          ) : (
            <Box component='form' onSubmit={handleNoteSubmit}>
              <TextField
                multiline
                fullWidth
                minRows={5}
                defaultValue={segment.note}
                onKeyDown={(e) => e.stopPropagation()}
                inputProps={{ style: { resize: 'both' } }}
                autoFocus
                onFocus={(e) =>
                  e.currentTarget.setSelectionRange(
                    e.currentTarget.value.length,
                    e.currentTarget.value.length
                  )
                }
              />
              <Stack direction='row' justifyContent='flex-end' gap='4px' mt='4px'>
                <Button
                  variant='contained'
                  sx={{ width: '30px', height: '30px', minWidth: 0 }}
                  onClick={() => setIsBeingEdited(false)}>
                  <CloseIcon fontSize='small' />
                </Button>
                <Button
                  variant='contained'
                  sx={{ width: '30px', height: '30px', minWidth: 0 }}
                  type='submit'>
                  <CheckIcon fontSize='small' />
                </Button>
              </Stack>
            </Box>
          )}
        </Stack>

        <Stack width='40%' gap='4px'>
          <Typography fontWeight={600}>Tags</Typography>
          <Autocomplete
            multiple
            size='small'
            disableClearable
            defaultValue={segment.tags}
            options={configuredTags}
            onKeyDown={(e) => e.stopPropagation()}
            sx={{ mb: '16px' }}
            renderInput={(params) => (
              <TextField {...params} placeholder='Add tags...' size='small' />
            )}
            onChange={(_, tags) => handleSegmentEdit({ id: segment.id, tags })}
          />
        </Stack>

        <Box width='150px'>
          <Button
            color='error'
            sx={{ fontWeight: 'normal' }}
            startIcon={<DeleteOutlinedIcon />}
            onClick={() => setIsDeleteDialogOpen(true)}>
            Delete segment
          </Button>
        </Box>
      </Stack>

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Delete segment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the segment for{' '}
            <Typography component='span' fontWeight='bold'>
              {formatDuration(Math.round(segment.start))} -{' '}
              {formatDuration(Math.round(segment.end))}
            </Typography>
            ?<br /> This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontWeight: 'normal' }} onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            sx={{ fontWeight: 'normal' }}
            onClick={() => {
              handleSegmentDelete(segment.id)
              setIsDeleteDialogOpen(false)
            }}
            autoFocus>
            Confirm & delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export { Segments }
