import { TUuid } from '@invisible/zod'

export const isProcessCustomConfig = (processId: string) =>
  CUSTOM_CONFIG_PROCESSES.some((process) => process.processId === processId)

export interface IRbapProcess {
  processId: TUuid
  processKey: string
}

export const CUSTOM_CONFIG_PROCESSES: IRbapProcess[] = [
  // DOORDASH
  {
    processId: '48d36c35-9cfe-4598-87a3-07b1580978eb',
    processKey: 'photoSourcing',
  },
  {
    processId: 'e1dc3185-e8fe-4386-8f31-1e31fb0eab58',
    processKey: 'photoSourcing',
  },
  {
    processId: '5578d2aa-4367-4684-8d2c-81df654251ae',
    processKey: 'newMenus',
  },
  //GRUBHUB
  {
    processId: '63d9ce9b-9ee7-4b46-8bf0-cd997e3837c2',
    processKey: 'menuUpdates',
  },
  {
    processId: '7a7130f7-0c3b-41f1-8095-6a25ae5ecbbd',
    processKey: 'menuUpdateTriage',
  },
  // BOLT
  {
    processId: 'd31e3380-133c-487e-9167-dc5f414d7513',
    processKey: 'competitorCleanup',
  },
  {
    processId: '2c3208ca-ba3f-4285-898f-0b5b478d3961',
    processKey: 'leadCleaning',
  },
  // GETRO
  {
    processId: '870e6ebe-0958-4a77-a95e-cd50accc3812',
    processKey: 'processingJobsMissingLocations',
  },
  {
    processId: '55e41a6e-a38d-4495-a3c8-4662580e7358',
    processKey: 'reviewingJobsMissingLocations',
  },
  {
    processId: '7c7b1db4-4866-4b02-be2d-2bbba6b7f565',
    processKey: 'reviewingTrainedJobLocations',
  },
  // ZEN WEALTH GROUP
  {
    processId: '2831fc45-4a48-4f90-8699-ae2dc106a743',
    processKey: 'extractTaxData',
  },
]