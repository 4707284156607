import { TBaseRunQueryData } from '@invisible/common/components/process-base'
import { IReadOnlyStepRunsByIdQuery } from '@invisible/concorde/gql-client'
import { useContext as useInvisibleTrpcContext } from '@invisible/trpc/client'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { createContext, FC, useContext, useReducer } from 'react'

const TYPE_MAPPINGS = {
  number: 'number',
  string: 'string',
  boolean: 'boolean',
  any: 'any',
  enum: 'string',
  date: 'string',
  datetime: 'string',
  duration: 'number',
  email: 'string',
  html: 'string',
  url: 'string',
  object: 'object',
  money: 'string',
  base_id: 'string',
  base_run_id: 'string',
  process_id: 'string',
  step_id: 'string',
  step_run_id: 'string',
  base_variable_id: 'string',
  base_run_variable_id: 'string',
  a_any: 'object',
  a_boolean: 'object',
  a_date: 'object',
  a_datetime: 'object',
  a_duration: 'object',
  a_email: 'object',
  a_enum: 'object',
  a_html: 'object',
  a_number: 'object',
  a_string: 'object',
  a_url: 'object',
  a_object: 'object',
  a_a_any: 'object',
  a_a_object: 'object',
  a_a_string: 'object',
  dataset: 'object',
}

export type TBaseRunVariable = NonNullable<
  inferQueryOutput<'baseRunVariable.findWizardDataForStepRun'>
>[number]
export type TBaseRun = TBaseRunQueryData['items'][number]
export type TStepRun = TBaseRun['stepRuns'][number]
interface OpenWizardAction {
  type: 'openWizard'
  stepRun: TStepRun
  baseRun: TBaseRun
  stepName: string
  wizardInitialBRVs: TBaseRunVariable[]
  wizardData: WizardSchemas.WizardConfig.TSchema
  trainingLink?: string
  processId: string
}

export type TTypeMismatch = {
  baseRunVariableId: string
  label: string
  expected: string
  actual: string
}

type TStepRunReadOnlyNode = IReadOnlyStepRunsByIdQuery['stepRuns']['edges'][0]['node'] & {
  baseRunId: string
  stepId: string
  snoozeActivities: undefined
}
type TBaseRunReadOnly = TStepRunReadOnlyNode['baseRun']
type TWizardInitialBRVsReadOnly = TStepRunReadOnlyNode['baseRun']['baseRunVariables']

interface OpenWizardReadOnlyAction {
  type: 'openWizardReadOnly'
  stepRun: TStepRunReadOnlyNode
  baseRun: TBaseRunReadOnly
  stepName: string
  wizardInitialBRVs: TWizardInitialBRVsReadOnly
  wizardData: WizardSchemas.WizardConfig.TSchema
}

interface CloseWizardAction {
  type: 'closeWizard'
}

interface SetBaseRunAction {
  type: 'setBaseRun'
  baseRun: TBaseRun | null
}

interface SetStepRunAction {
  type: 'setStepRun'
  stepRun: TStepRun | null
}

interface SetBaseRunVariableValueAction {
  type: 'setBaseRunVariableValue'
  baseRunVariableId: string
  value: unknown
}

interface SetReadyForSubmitAction {
  type: 'setReadyForSubmit'
  key: string
  value: boolean
}

interface SetInitialBRVsACtion {
  type: 'setInitialBrvs'
  wizardInitialBRVs: TBaseRunVariable[]
}

interface SetIsWacSubmittingAction {
  type: 'setIsWacSubmitting'
  key: string
  value: boolean
}

type TAction =
  | OpenWizardAction
  | OpenWizardReadOnlyAction
  | CloseWizardAction
  | SetBaseRunAction
  | SetBaseRunVariableValueAction
  | SetStepRunAction
  | SetReadyForSubmitAction
  | SetInitialBRVsACtion
  | SetIsWacSubmittingAction

type Dispatch = (action: TAction) => void

export interface State {
  stepRun: TStepRun | TStepRunReadOnlyNode | null
  baseRun: TBaseRun | TBaseRunReadOnly | null
  stepName: string | null
  isWizardOpen: boolean
  wizardData: WizardSchemas.WizardConfig.TSchema
  trainingLink?: string
  processId: string | null
  wizardInitialBRVs: TBaseRunVariable[] | TWizardInitialBRVsReadOnly
  readyForSubmit: { [key: string]: boolean }
  isWacSubmitting: { [key: string]: boolean }
  typeMismatches: TTypeMismatch[]
}

const WizardStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
)

export const getTypeMismatches = (baseRunVariables: TBaseRunVariable[]): TTypeMismatch[] => {
  const seenBaseVariableIds = new Set<string>()
  return baseRunVariables
    .filter((brv: TBaseRunVariable) => {
      if (seenBaseVariableIds.has(brv.baseVariable.id)) {
        return false
      }
      seenBaseVariableIds.add(brv.baseVariable.id)
      const expectedType = TYPE_MAPPINGS[brv.baseVariable.type]
      return expectedType !== 'any' && brv.value && expectedType !== typeof brv.value
    })
    .map((brv) => ({
      baseRunVariableId: brv.id,
      label: brv.baseVariable.name,
      expected: brv.baseVariable.type,
      actual: typeof brv.value,
    }))
}

const initialState: State = {
  stepRun: null,
  baseRun: null,
  stepName: null,
  isWizardOpen: false,
  wizardData: [],
  trainingLink: undefined,
  processId: null,
  wizardInitialBRVs: [],
  readyForSubmit: {},
  isWacSubmitting: {},
  typeMismatches: [],
}

const wizardStateReducer = (state: State, action: TAction, reactQueryContext: any) => {
  switch (action.type) {
    case 'openWizard': {
      reactQueryContext.invalidateQueries('StepRunWithTimeLogDuration')
      return {
        ...state,
        isWizardOpen: true,
        stepRun: action.stepRun,
        baseRun: action.baseRun,
        stepName: action.stepName,
        wizardData: action.wizardData,
        trainingLink: action.trainingLink,
        processId: action.processId,
        wizardInitialBRVs: action.wizardInitialBRVs,
        typeMismatches: getTypeMismatches(action.wizardInitialBRVs),
      }
    }

    case 'openWizardReadOnly': {
      reactQueryContext.invalidateQueries('StepRunWithTimeLogDuration')
      return {
        ...state,
        isWizardOpen: true,
        stepRun: action.stepRun,
        baseRun: action.baseRun,
        stepName: action.stepName,
        wizardData: action.wizardData,
        wizardInitialBRVs: action.wizardInitialBRVs,
      }
    }

    case 'setInitialBrvs': {
      return {
        ...state,
        wizardInitialBRVs: action.wizardInitialBRVs,
      }
    }

    case 'setBaseRun': {
      return {
        ...state,
        baseRun: action.baseRun,
      }
    }

    case 'setStepRun': {
      return {
        ...state,
        stepRun: action.stepRun,
      }
    }

    case 'setBaseRunVariableValue': {
      return {
        ...state,
        wizardInitialBRVs: state.wizardInitialBRVs.map((brv) =>
          brv.id === action.baseRunVariableId ? { ...brv, value: action.value } : brv
        ),
        ...(state.baseRun
          ? {
              baseRun: {
                ...state.baseRun,
                baseRunVariables: (
                  state.baseRun.baseRunVariables as TBaseRun['baseRunVariables']
                ).map((brv) =>
                  brv.id === action.baseRunVariableId ? { ...brv, value: action.value } : brv
                ),
              },
            }
          : {}),
      }
    }

    case 'closeWizard': {
      return { ...initialState }
    }

    case 'setReadyForSubmit': {
      return {
        ...state,
        readyForSubmit: {
          ...state.readyForSubmit,
          [action.key]: action.value,
        },
      }
    }
    case 'setIsWacSubmitting':
      return {
        ...state,
        isWacSubmitting: {
          ...state.isWacSubmitting,
          [action.key]: action.value,
        },
      }

    default: {
      throw new Error('Unhandled action type')
    }
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const WizardStateProvider: FC = ({ children }) => {
  const reactQueryContext = useInvisibleTrpcContext()

  const [state, dispatch] = useReducer(
    (state: State, action: TAction) => wizardStateReducer(state, action, reactQueryContext),
    initialState
  )

  const value = { state, dispatch }
  return <WizardStateContext.Provider value={value}>{children}</WizardStateContext.Provider>
}

export const useWizardState = () => {
  const context = useContext(WizardStateContext)
  if (context === undefined) {
    throw new Error('useWizardState must be used within a WizardStateProvider')
  }
  return context
}
