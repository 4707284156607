import { TextArea as UITextArea } from '@invisible/ui/form'

type FieldProps = {
  value: string
  isReadOnly: boolean
}

const TextArea = ({ value, isReadOnly }: FieldProps) => (
  <UITextArea width='100%' value={value} readOnly={isReadOnly} />
)

export { TextArea }
