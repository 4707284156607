import { zodResolver } from '@hookform/resolvers/zod'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RRule } from 'rrule'
import { z } from 'zod'

import { CustomRecurrence } from './CustomRecurrence'
import { TReducerAction, TReducerState } from './reducer'
import {
  DEFAULT_RRULE,
  DEFAULT_SCHEDULE_DESCRIPTION,
  DEFAULT_SCHEDULE_STATE,
  WEEKDAYS,
} from './SchedulerConstants'
import {
  capitalizeString,
  getOrdinalSuffix,
  getRRuleWeekday,
  getText,
  schedulerSchema,
} from './schedulerHelpers'
import { ScheduleOptionSelector } from './SheduleOptionSelector'

interface IProps {
  state: TReducerState
  dispatch: Dispatch<TReducerAction>
  onEdit: boolean
  setIsReset: (value: boolean) => void
  setCurrentScheduleDate: Dispatch<SetStateAction<{ schedule: string; date: Date }>>
}

// Allow UTC timezones
dayjs.extend(utc)

export type TForm = z.infer<typeof schedulerSchema>

/**
 * Renders the export task schedule configuration component.
 *
 * @param {IProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const ExportTaskScheduleConfiguration = ({
  state,
  dispatch,
  onEdit,
  setIsReset,
  setCurrentScheduleDate,
}: IProps) => {
  const { control, watch, setValue } = useForm<TForm>({
    resolver: zodResolver(schedulerSchema),
    defaultValues: DEFAULT_SCHEDULE_STATE,
  })

  // Watch form fields to dynamically update the RRule description
  const formValues = watch()
  const formValuesRef = useRef(formValues)
  const rrule = new RRule({
    freq: formValues.freq ? formValues.freq.valueOf() : DEFAULT_RRULE.freq.valueOf(),
    interval: formValues.interval === null ? undefined : formValues.interval,
    byweekday: formValues.byweekday,
    byhour: formValues.byhour,
    byminute: formValues.byminute,
    dtstart: formValues.dtstart,
    until: formValues.until,
    count: formValues.count,
    bymonthday: formValues.bymonthday,
    bysetpos: formValues.bysetpos,
  })

  // Keep the ref updated whenever formValues changes
  useEffect(() => {
    formValuesRef.current = formValues
  }, [formValues])

  // On mount sync state with form values.
  // On dismount persist form values to state.
  useEffect(() => {
    syncState()
    return () => {
      reversedStateSync()
      setIsReset(false)
    }
  }, [])

  /**
   * Reverses the state synchronization by persisting form values to state.
   */
  const reversedStateSync = () => {
    // Persist form values to state
    dispatch({
      type: 'UPDATE_SCHEDULER_PAGE_CONFIG',
      payload: formValuesRef.current,
    })
  }

  /**
   * Synchronizes the state with the scheduler page configuration.
   */
  const syncState = () => {
    if (!onEdit) return
    Object.entries(state.schedulerPageConfig).forEach(([key, value]) => {
      setValue(key as keyof TForm, value)
    })
    setCurrentScheduleDate({
      schedule: state.schedulerPageConfig.taskSchedule,
      date: state.schedulerPageConfig.dtstart,
    })
  }

  /**
   * Updates the state of the component with the provided fields.
   *
   * @param fields - An object containing the fields to update.
   */
  const updateState = (fields: { [key: string]: any }, updateDescription = true) => {
    Object.entries(fields).forEach(([name, value]) => {
      setValue(name as keyof TForm, value)
      // Avoid asynchronous rrule updates
      ;(rrule.options as any)[name] = value
      ;(rrule.origOptions as any)[name] = value
    })

    if (updateDescription) {
      setValue('description', getText(rrule))
      setValue('rruleString', rrule.toString())
    }
  }

  /**
   * Updates the schedule option based on the provided value.
   * Then, synchronizes the state with the scheduler page configuration.
   * @param value - The selected schedule option.
   */
  const updateScheduleOption = (value: string | null) => {
    if (value === null) return
    resetState()

    const fields: { [key: string]: unknown } = {
      isCustom: value === 'custom',
      taskSchedule: value,
    }

    if (value === 'default') {
      updateState(fields, false)
      setValue('description', DEFAULT_SCHEDULE_DESCRIPTION)
      setValue('rruleString', '')
      setCurrentScheduleDate({ schedule: value, date: formValues.dtstart })
      return
    }

    if (value === 'once') {
      fields.freq = 3
      fields.count = 1
      fields.until = null
      fields.byweekday = []
      fields.byhour = null
      fields.byminute = null
      fields.endCondition = 'after'
    } else {
      fields.freq = DEFAULT_RRULE.freq
      fields.interval = DEFAULT_RRULE.interval
      fields.dtstart = DEFAULT_RRULE.dtstart
      fields.byweekday = DEFAULT_RRULE.byweekday
      fields.count = DEFAULT_RRULE.count
      fields.until = null
      fields.endCondition = 'never'
    }

    updateState(fields)
    setCurrentScheduleDate({ schedule: value, date: formValues.dtstart })
  }

  /**
   * Updates the monthly frequency options based on the provided value.
   * Then, synchronizes the state with the scheduler page configuration.
   * @param value - The selected schedule option.
   */
  const updateMonthlyOption = (value: string | null) => {
    if (value === null) return

    const fields: { [key: string]: unknown } = {
      monthOption: value,
    }

    if (value === 'day') {
      fields.bymonthday = [dayNumber]
      fields.byweekday = []
      fields.bysetpos = []
    } else {
      fields.bymonthday = []
      fields.byweekday = [dayOfWeek]
      fields.bysetpos = [monthlyDayNumber]
    }

    updateState(fields)
  }

  /**
   * Resets the state of the export task schedule configuration.
   * This function synchronizes the state and updates the description to the default value.
   */
  const resetState = () => {
    syncState()
    updateState({ description: DEFAULT_SCHEDULE_DESCRIPTION })
  }

  const today = formValues.dtstart ?? new Date()
  const dayNumber = today.getDate()
  const monthlyDayNumber = Math.ceil(dayNumber / 7)
  const dayOfWeek = getRRuleWeekday(today.getDay())
  const monthOptions = [
    { label: `Monthly on day ${dayNumber}`, value: 'day' },
    {
      label: `Monthly on ${monthlyDayNumber}${getOrdinalSuffix(monthlyDayNumber)} ${
        Object.keys(WEEKDAYS)[today.getDay()]
      }`,
      value: 'month',
    },
  ]

  const handleDateTimeChange = (newValue: Date, fieldValue: Date, type: 'date' | 'time') => {
    if (type === 'date') {
      fieldValue.setFullYear(newValue.getFullYear())
      fieldValue.setMonth(newValue.getMonth())
      fieldValue.setDate(newValue.getDate())
    }
    if (type === 'time') {
      fieldValue.setHours(newValue.getHours())
      fieldValue.setMinutes(newValue.getMinutes())
    }

    setValue('dtstart', fieldValue)
    setCurrentScheduleDate({
      schedule: formValues.taskSchedule,
      date: fieldValue,
    })
    updateState({ dtstart: fieldValue })
  }

  const handleDateChange = (date: dayjs.Dayjs | null, field: { value: Date }) => {
    if (date) {
      handleDateTimeChange(dayjs.utc(date).toDate(), field.value, 'date')
    }
  }

  const handleTimeChange = (time: dayjs.Dayjs | null, field: { value: Date }) => {
    if (time) {
      handleDateTimeChange(dayjs.utc(time).toDate(), field.value, 'time')
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={9}>
        <form>
          <Stack gap='25px'>
            <Stack direction='row' justifyContent='space-between' gap='8px'>
              <Stack gap='4px'>
                <Typography fontWeight='bold'>
                  Configure when and how often you want to export process data
                </Typography>
              </Stack>
            </Stack>

            <ScheduleOptionSelector
              control={control}
              onUpdateScheduleOption={updateScheduleOption}
            />

            {formValues.taskSchedule === 'custom' || formValues.taskSchedule === 'once' ? (
              <>
                <Stack direction='row' justifyContent='space-between' gap='8px'>
                  <Stack gap='4px'>
                    <Typography fontWeight='bold'>
                      {formValues.taskSchedule === 'custom'
                        ? 'Setup custom recurrence'
                        : 'Setup Schedule'}
                    </Typography>
                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                      {formValues.taskSchedule === 'custom'
                        ? 'Set how regularly your export is sent. Recurring exports can be deactivated or paused at any time.'
                        : 'Set the date and time your export is sent.'}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction='row' alignItems='center' gap='16px'>
                  <Typography width='180px'>Starts </Typography>
                  <Controller
                    name='dtstart'
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display='flex' alignItems='center' gap='8px' width='fit-content'>
                          <DatePicker
                            slotProps={{
                              textField: { size: 'small', fullWidth: false },
                            }}
                            label='Start date'
                            value={dayjs.utc(field.value)}
                            disablePast
                            minDate={dayjs.utc().add(15, 'minute')}
                            onChange={(date) => {
                              if (date) {
                                const selectedDate = dayjs.utc(date).toDate()
                                const existingTime = dayjs.utc(field.value).toDate()
                                selectedDate.setHours(existingTime.getHours())
                                selectedDate.setMinutes(existingTime.getMinutes())
                                setValue('dtstart', selectedDate)
                                setCurrentScheduleDate({
                                  schedule: formValues.taskSchedule,
                                  date: selectedDate,
                                })
                                updateState({ dtstart: selectedDate })
                              }
                            }}
                          />
                          <Typography sx={{ mx: 1 }}>at</Typography>{' '}
                          <TimePicker
                            slotProps={{
                              textField: { size: 'small', fullWidth: false },
                            }}
                            label='Start time'
                            value={dayjs.utc(field.value)}
                            ampm
                            onChange={(time) => {
                              if (time) {
                                const selectedTime = dayjs.utc(time).toDate()
                                const existingDate = dayjs.utc(field.value).toDate()
                                existingDate.setHours(selectedTime.getHours())
                                existingDate.setMinutes(selectedTime.getMinutes())
                                setValue('dtstart', existingDate)
                                setCurrentScheduleDate({
                                  schedule: formValues.taskSchedule,
                                  date: existingDate,
                                })
                                updateState({ dtstart: existingDate })
                              }
                            }}
                          />
                        </Box>
                      </LocalizationProvider>
                    )}
                  />
                  <Typography>UTC</Typography>
                </Stack>{' '}
              </>
            ) : null}

            {formValues.isCustom ? (
              <CustomRecurrence
                control={control}
                formValues={formValues}
                updateState={updateState}
                monthOptions={monthOptions}
                onUpdateMonthlyOption={updateMonthlyOption}
              />
            ) : null}
          </Stack>
        </form>
      </Grid>

      <Grid item xs={12} md={3}>
        <Stack gap='16px'>
          <Box
            sx={{
              minHeight: '650px',
              maxHeight: '70vh',
              height: '100%',
              p: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
            }}>
            <Stack gap='8px'>
              <Stack direction='row' alignItems='center' gap='8px'>
                <CalendarTodayIcon fontSize='small' />
                <Typography fontWeight='bold'>Schedule summary</Typography>
              </Stack>
              <Typography fontWeight='bold' sx={{ paddingY: '6px' }}>
                Current{' '}
              </Typography>
              <Typography>
                {capitalizeString(formValues.description ?? DEFAULT_SCHEDULE_DESCRIPTION)}
              </Typography>

              <Typography sx={{ paddingY: '12px', color: 'rgba(0, 0, 0, 0.6)' }}>
                You can pause/resume recurring exports or edit frequency at any time.
              </Typography>
            </Stack>

            <Typography sx={{ color: 'rgba(0, 0, 0, 0.3)' }}>
              As you configure your schedule, your selection will display above.
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}

export { ExportTaskScheduleConfiguration }
