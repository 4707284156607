import { classNames } from '@invisible/common/helpers'
import { Button } from '@invisible/ui/button'
import { CaretDownIcon, CheckCircleFilledIcon, CloseCircleIcon } from '@invisible/ui/icons'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { TConditionGoTo, TOperand, TOperator } from 'libs/ultron/zod/src/models/Wizard/FormField'
import { isNil } from 'lodash/fp'
import { ReactNode } from 'react'

type TCheckListValue = Record<string, boolean>

const getOperand = (
  operand: TOperand | null | undefined,
  formValues: Record<string, any> | undefined
) => {
  if (operand === null || operand === undefined) {
    return null
  }
  const value =
    operand.type === 'constant'
      ? operand.value
      : formValues?.[operand?.baseVariableId ?? ''] ?? null
  if (value !== null && operand.valueType === 'number') {
    return Number(value)
  }
  return value
}

const conditionIsTrue = (operator: TOperator, left: string, right: string) => {
  let condition = false
  switch (operator) {
    case 'eq':
      condition = left === right
      break
    case 'neq':
      condition = left !== right
      break
    case 'isNull':
      condition = left === null
      break
  }
  return condition
}

const conditionIsTrueNumeric = (operator: TOperator, left: number, right: number) => {
  let condition = false
  switch (operator) {
    case 'gt':
      condition = left > right
      break
    case 'lt':
      condition = left < right
      break
  }
  return condition
}

const checkEachConditionGoTo = (
  conditionGoTos: TConditionGoTo[],
  formValues: Record<string, any> | undefined
) => {
  let nextSection, shouldSubmit
  conditionGoTos.forEach(
    ({ condition: { leftOperand, rightOperand, operator }, goToSection, goToSectionAndSubmit }) => {
      let next = false
      const left = getOperand(leftOperand, formValues)
      const right = getOperand(rightOperand, formValues)
      if (operator === 'gt' || operator === 'lt') {
        if (typeof left === 'number' && typeof right === 'number') {
          next = conditionIsTrueNumeric(operator, left, right)
        }
      } else {
        next = conditionIsTrue(operator, left, right)
      }
      if (next) {
        nextSection = goToSection
        shouldSubmit = goToSectionAndSubmit
      }
    }
  )
  return [nextSection, shouldSubmit]
}

const Section = ({
  section,
  isSkipped,
  isOnlySection,
  fields,
  formValues,
  isCompleted,
  hasNextSection,
  checkLists,
  checkListValue,
  baseVariableIdsInError,
  onNextSection,
  goToSection,
  onReview,
  formSubmittedFromSection,
  children,
}: {
  section: WizardSchemas.FormSection.TSchema
  isSkipped?: boolean
  isOnlySection?: boolean
  fields?: WizardSchemas.FormField.TSchema[]
  checkLists?: WizardSchemas.CheckList.TSchema[]
  checkListValue?: TCheckListValue
  formValues?: Record<string, any>
  isCompleted: boolean
  hasNextSection?: boolean
  formSubmittedFromSection?: string
  baseVariableIdsInError?: string[]
  onNextSection?: (nextSection: string | undefined, shouldSubmit: boolean | undefined) => void
  goToSection?: (nextSection: string | undefined) => void
  onReview?: () => void
  children?: ReactNode
}) => {
  const formInputError = fields
    ? baseVariableIdsInError?.includes(fields?.[0]?.baseVariableId ?? '') ?? false
    : false

  const hasMissingRequiredField = (fields ?? [])
    .filter((f) => f.required)
    .reduce((result, requiredField) => {
      if (
        formValues &&
        requiredField.baseVariableId &&
        (isNil(formValues[requiredField.baseVariableId]) ||
          formValues[requiredField.baseVariableId] === '')
      )
        result = true
      return result
    }, false)

  const hasMissingChecks = (checkLists ?? [])
    .map((checkList) => checkList.checkItems ?? [])
    .reduce((acc, val) => acc.concat(val), [])
    .filter((checkItem) => checkItem.isRequired)
    .reduce((result, checkItem) => {
      if (!checkListValue?.[checkItem.id]) result = true
      return result
    }, false)

  const handleNextSection = () => {
    let nextSection
    let shouldSubmit

    const conditionalField = (fields ?? []).find((field) => field.isConditionalInput)
    const conditionalFieldWithFormula = (fields ?? []).find(
      (field) => field.isConditionalWithFormula
    )

    if (conditionalField) {
      if (conditionalField.type === 'boolean') {
        if (formValues && formValues[conditionalField?.baseVariableId ?? ''] === true) {
          nextSection = conditionalField.goToSectionWhenTrue
          shouldSubmit = conditionalField.goToSectionWhenTrueAndSubmit
        } else if (formValues && formValues[conditionalField?.baseVariableId ?? ''] === false) {
          nextSection = conditionalField.goToSectionWhenFalse
          shouldSubmit = conditionalField.goToSectionWhenFalseAndSubmit
        } else {
          nextSection = conditionalField.goToSectionWhenUnset
          shouldSubmit = conditionalField.goToSectionWhenUnsetAndSubmit
        }
      } else {
        const option = (conditionalField.options ?? []).find(
          (option) => option.value === formValues![conditionalField?.baseVariableId ?? '']
        )
        nextSection = option?.goToSection ?? conditionalField.goToSectionWhenUnset
        shouldSubmit = option?.goToSectionAndSubmit
      }
    }

    if (conditionalFieldWithFormula) {
      ;[nextSection, shouldSubmit] = checkEachConditionGoTo(
        conditionalFieldWithFormula.conditionGoTos ?? [],
        formValues
      )
    }
    if (onNextSection) onNextSection(nextSection, shouldSubmit)
  }

  return (
    <div
      className={classNames(
        `border-r-6 mt-2 mb-2 rounded-md border border-solid border-gray-300 ${
          isSkipped ? 'bg-gray-100' : ''
        } pl-4 pt-2 pb-2 pr-4 shadow-sm`
      )}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          {isCompleted && !isSkipped ? (
            <div className='text-green-500'>
              <CheckCircleFilledIcon width={20} />
            </div>
          ) : null}
          {isSkipped ? (
            <div className='text-gray-400'>
              <CloseCircleIcon width={20} />
            </div>
          ) : null}
          <h3 className={classNames(`whitespace-pre-line ${isSkipped ? 'text-gray-400' : ''}`)}>
            {section.label}
          </h3>
        </div>

        {isCompleted && !isSkipped ? (
          <div
            className='cursor-pointer text-gray-400'
            onClick={() => goToSection && goToSection(section.id)}>
            <CaretDownIcon />
          </div>
        ) : null}
      </div>
      {!isCompleted && !isSkipped ? children : null}
      {!isCompleted && !isOnlySection ? (
        <div className='flex justify-end'>
          {hasNextSection && section.id !== (formSubmittedFromSection ?? '') ? (
            <Button
              disabled={hasMissingRequiredField || hasMissingChecks || formInputError}
              onClick={handleNextSection}>
              Next
            </Button>
          ) : (
            <Button
              disabled={hasMissingRequiredField || hasMissingChecks || formInputError}
              onClick={() => onReview && onReview()}>
              Review
            </Button>
          )}
        </div>
      ) : null}
    </div>
  )
}

export { checkEachConditionGoTo, conditionIsTrue, conditionIsTrueNumeric, Section }
