import { z } from '@invisible/zod'

const schema = z.object({
  recordedAudioBaseVariableId: z.string().optional(),
  fileNameSegments: z.array(z.string()).optional(),
  format: z.string().optional(),
  minAudioLength: z.number().optional(),
  maxAudioLength: z.number().optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
