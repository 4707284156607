import { SVGProps } from 'react'

function RecordAudioWac(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <mask
        id='mask0_952_37616'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='25'
        height='25'>
        <rect x='0.373291' y='0.0742188' width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_952_37616)'>
        <path
          d='M12.3733 17.0742C13.7566 17.0742 14.9358 16.5867 15.9108 15.6117C16.8858 14.6367 17.3733 13.4576 17.3733 12.0742C17.3733 10.6909 16.8858 9.51172 15.9108 8.53672C14.9358 7.56172 13.7566 7.07422 12.3733 7.07422C10.99 7.07422 9.81079 7.56172 8.83579 8.53672C7.86079 9.51172 7.37329 10.6909 7.37329 12.0742C7.37329 13.4576 7.86079 14.6367 8.83579 15.6117C9.81079 16.5867 10.99 17.0742 12.3733 17.0742ZM12.3733 22.0742C10.99 22.0742 9.68996 21.8117 8.47329 21.2867C7.25662 20.7617 6.19829 20.0492 5.29829 19.1492C4.39829 18.2492 3.68579 17.1909 3.16079 15.9742C2.63579 14.7576 2.37329 13.4576 2.37329 12.0742C2.37329 10.6909 2.63579 9.39089 3.16079 8.17422C3.68579 6.95755 4.39829 5.89922 5.29829 4.99922C6.19829 4.09922 7.25662 3.38672 8.47329 2.86172C9.68996 2.33672 10.99 2.07422 12.3733 2.07422C13.7566 2.07422 15.0566 2.33672 16.2733 2.86172C17.49 3.38672 18.5483 4.09922 19.4483 4.99922C20.3483 5.89922 21.0608 6.95755 21.5858 8.17422C22.1108 9.39089 22.3733 10.6909 22.3733 12.0742C22.3733 13.4576 22.1108 14.7576 21.5858 15.9742C21.0608 17.1909 20.3483 18.2492 19.4483 19.1492C18.5483 20.0492 17.49 20.7617 16.2733 21.2867C15.0566 21.8117 13.7566 22.0742 12.3733 22.0742ZM12.3733 20.0742C14.6066 20.0742 16.4983 19.2992 18.0483 17.7492C19.5983 16.1992 20.3733 14.3076 20.3733 12.0742C20.3733 9.84089 19.5983 7.94922 18.0483 6.39922C16.4983 4.84922 14.6066 4.07422 12.3733 4.07422C10.14 4.07422 8.24829 4.84922 6.69829 6.39922C5.14829 7.94922 4.37329 9.84089 4.37329 12.0742C4.37329 14.3076 5.14829 16.1992 6.69829 17.7492C8.24829 19.2992 10.14 20.0742 12.3733 20.0742Z'
          fill='currentColor'
        />
      </g>
    </svg>
  )
}

export default RecordAudioWac
