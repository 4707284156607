import Markdown, { Options } from 'react-markdown'

interface TMarkdownProps extends Options {
  dir?: string
}

const CustomMarkdown = ({ children, dir, ...rest }: TMarkdownProps) => (
  <div dir={dir ?? 'auto'}>
    <Markdown
      {...rest}
      components={{
        h1: ({ node, ...props }) => (
          <h1 style={{ fontSize: '2em', marginBlock: '0.67em' }} {...props}>
            {props.children}
          </h1>
        ),
        ...(rest?.components || {}),
      }}>
      {children}
    </Markdown>
  </div>
)

export default CustomMarkdown
