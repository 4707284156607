import { values } from 'lodash/fp'

import { DEFAULT_ITEMS_PER_PAGE } from '../constants'
import { StoreSlice } from '../index'

type TSortOption = Record<string, 'asc' | 'desc' | undefined>

export type TFilterOption = {
  id: string
  value: string[] | (boolean | null)[] | { startDate: Date; endDate: Date }
  isStepAssignee?: boolean
  isStepStatus?: boolean
  isBaseRunStatus?: boolean
}

export type TBaseViewDownloadFormat = 'csv' | 'jsonl'

export interface IBaseViewSlice {
  isFreshNew: boolean // We use this to know if we can delete a fresh new base view just by pressing cancel button
  stageId: string | null
  baseViewName: string
  isPrivate: boolean
  editedPermissions: string[]
  currentPage: number
  itemsPerPage: number
  hiddenColumns: string[]
  columnOrder: string[]
  filterableColumns: string[]
  sortOption: TSortOption
  filterOption: TFilterOption[]
  searchTerm: string
  baseViewSettingsModalOpen: boolean
  selectedBaseRuns: string[]
  selectedStepRuns: string[]
  createdAtTZ: string
  deliveredAtTZ: string
  downloadFormat: TBaseViewDownloadFormat
  setIsFreshNew: (isFreshNew: boolean) => void
  setBaseViewSettingsModalOpen: (open: boolean) => void
  setPage: (currentPage: number) => void
  setItemCount: (itemsPerPage: number) => void
  setHiddenColumns: (hiddenColumns: string[]) => void
  setColumnOrder: (columnOrder: string[]) => void
  setFilterableColumns: (filterableColumns: string[]) => void
  setSortOption: (sortOption: TSortOption) => void
  setFilterOption: (filterOption: TFilterOption[]) => void
  setSearchTerm: (searchTerm: string, is3CharMinEnabled?: boolean) => void
  setEditedPermissions: (permissions: string[]) => void
  setIsPrivate: (privacy: boolean) => void
  setStageId: (stageId: string | null) => void
  setBaseViewName: (name: string) => void
  setSelectedBaseRuns: (selectedBaseRuns: string[]) => void
  setSelectedStepRuns: (selectedBaseRuns: string[]) => void
  setCreatedAtTZ: (createdAtTZ: string) => void
  setDeliveredAtTZ: (deliveredAtTZ: string) => void
  setDownloadFormat: (format: TBaseViewDownloadFormat) => void
}

export const createBaseViewSlice: StoreSlice<IBaseViewSlice> = (set, get) => ({
  isFreshNew: false,
  baseViewName: '',
  stageId: null,
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  currentPage: 1,
  hiddenColumns: [],
  columnOrder: [],
  filterableColumns: [],
  sortOption: {},
  filterOption: [],
  searchTerm: '',
  editedPermissions: [],
  selectedBaseRuns: [],
  selectedStepRuns: [],
  isPrivate: false,
  baseViewSettingsModalOpen: false,
  createdAtTZ: 'UTC',
  deliveredAtTZ: 'UTC',
  downloadFormat: 'csv',
  // Functions

  setIsFreshNew: (isFreshNew: boolean) => {
    set((prev) => ({ ...prev, isFreshNew }))
  },

  setPage: (currentPage: number) => {
    set((prev) => ({ ...prev, currentPage }))
  },

  setItemCount: (itemsPerPage: number) => {
    set((prev) => ({ ...prev, itemsPerPage }))
  },

  setHiddenColumns: (hiddenColumns: string[]) => {
    set((prev) => ({ ...prev, hiddenColumns }))
  },

  setColumnOrder: (columnOrder: string[]) => {
    set((prev) => ({ ...prev, columnOrder }))
  },

  setFilterableColumns: (filterableColumns: string[]) => {
    set((prev) => ({ ...prev, filterableColumns }))
  },

  setSortOption: (sortOption: TSortOption) => {
    set((prev) => ({ ...prev, sortOption: values(sortOption)[0] ? sortOption : {} }))
  },

  setFilterOption: (filterOption: TFilterOption[]) => {
    set((prev) => ({ ...prev, filterOption }))
  },

  setSearchTerm: (searchTerm?: string, is3CharMinEnabled?: boolean) => {
    if (is3CharMinEnabled) {
      // Only enable full text search term if it's at least 3 characters long
      if (searchTerm !== undefined && searchTerm?.length >= 3) {
        set((prev) => ({ ...prev, searchTerm }))
      } else {
        set((prev) => ({ ...prev, searchTerm: '' }))
      }
    } else {
      set((prev) => ({ ...prev, searchTerm }))
    }
  },

  setEditedPermissions: (permissions: string[]) => {
    set((prev) => ({ ...prev, editedPermissions: permissions }))
  },

  setIsPrivate: (privacy: boolean) => {
    set((prev) => ({ ...prev, isPrivate: privacy }))
  },

  setStageId: (stageId: string | null) => {
    set((prev) => ({ ...prev, stageId }))
  },

  setBaseViewName: (name: string) => {
    set((prev) => ({ ...prev, baseViewName: name }))
  },

  setBaseViewSettingsModalOpen: (open: boolean) => {
    set((prev) => ({ ...prev, baseViewSettingsModalOpen: open }))
  },

  setSelectedBaseRuns: (selectedBaseRuns: string[]) => {
    set((prev) => ({ ...prev, selectedBaseRuns }))
  },

  setSelectedStepRuns: (selectedStepRuns: string[]) => {
    set((prev) => ({ ...prev, selectedStepRuns }))
  },

  setCreatedAtTZ: (createdAtTZ: string) => {
    set((prev) => ({ ...prev, createdAtTZ }))
  },

  setDeliveredAtTZ: (deliveredAtTZ: string) => {
    set((prev) => ({ ...prev, deliveredAtTZ }))
  },

  setDownloadFormat: (format: TBaseViewDownloadFormat) => {
    set((prev) => ({ ...prev, downloadFormat: format }))
  },
})
