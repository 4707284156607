import ContentCutIcon from '@mui/icons-material/ContentCut'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useMemo, useRef, useState } from 'react'
import { useEffect } from 'react'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline'

import { NEXT_PUBLIC_AUDIO_FILE_RECORDINGS_BUCKET } from '../../../../config/env'
import { Controls } from './Controls'
import { formatDuration, trimBlob } from './helpers'
import { useWaveSurfer } from './useWaveSurfer'

const WAVEFORM_STYLE_OVERRIDES = {
  position: 'relative',
  '& ::part(wrapper)': { backgroundColor: '#EDE7F6', borderRadius: '4px' },
  '& ::part(progress)': {
    backgroundColor: 'primary.100',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  '& ::part(timeline)': { zIndex: 10 },
  '& ::part(cursor):after': {
    content: '""',
    position: 'absolute',
    top: '-4px',
    left: '-4px',
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '8px solid rgba(255, 0, 0, 0.5)',
  },
}

const LOADER_STYLES = { position: 'absolute', top: '30%', left: '50%', zIndex: '100' }

const Player = ({
  visible,
  recordedAudioProperties,
  handleRecordReset,
  handleRecordSave,
  uploadRecording,
  isRecordingUploading,
  baseRunVariableValue,
  gcsDirectory,
}: {
  visible: boolean
  recordedAudioProperties: { url: string; fileName: string } | null
  handleRecordReset: () => Promise<void>
  handleRecordSave: (arg: {
    url: string
    fileName: string
    length: number
    micLabel: string
  }) => Promise<void>
  uploadRecording: (arg: { blob: Blob; localUrl: string; fileName: string }) => Promise<void>
  isRecordingUploading: boolean
  baseRunVariableValue: Record<string, unknown>
  gcsDirectory: string
}) => {
  const playbackContainerRef = useRef<HTMLDivElement | null>(null)
  const [regionPlugin, setRegionPlugin] = useState<RegionsPlugin | null>(null)

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const actualFileName = recordedAudioProperties?.fileName.split('/').pop()

  const options = useMemo(
    () => ({
      progressColor: 'rgba(96, 76, 165, 1)',
      waveColor: '#9c8fcc',
      cursorColor: 'rgba(255, 0, 0, 0.5)',
      cursorWidth: 2,
      barWidth: 4,
      barGap: 10,
      barRadius: 4,
      height: 100,
      barHeight: 0.7,
      url: recordedAudioProperties?.url,
      plugins: [
        TimelinePlugin.create({
          insertPosition: 'beforebegin',
          formatTimeCallback: formatDuration,
        }),
      ],
    }),
    [recordedAudioProperties?.url]
  )

  const { waveSurfer, playbackProgress, audioLength, isLoaded } = useWaveSurfer({
    container: playbackContainerRef.current,
    options,
  })

  useEffect(() => {
    if (!waveSurfer) return

    const plugin = RegionsPlugin.create()
    waveSurfer?.registerPlugin(plugin)
    plugin.enableDragSelection({ color: 'rgb(148 122 255 / 60%)' })

    setRegionPlugin(plugin)

    return () => {
      setRegionPlugin(null)
      plugin.destroy()
    }
  }, [waveSurfer])

  // Setup listeners for region events
  useEffect(() => {
    if (!regionPlugin) return

    regionPlugin.on('region-created', (region) => {
      const wavesurferRegions = regionPlugin?.getRegions()
      const existingRegions = wavesurferRegions.filter((r) => r.id !== region.id)
      existingRegions.forEach((r) => r.remove())
    })
  }, [regionPlugin, waveSurfer])

  const handleTrim = async () => {
    const region = regionPlugin?.getRegions()?.[0]
    if (!region || !waveSurfer) return

    const start = region.start
    const end = region.end
    const trimResult = trimBlob(waveSurfer, start, end)
    if (!trimResult) return

    waveSurfer.load(trimResult.url)
    region.remove()

    await uploadRecording({
      blob: trimResult.blob,
      localUrl: trimResult.url,
      fileName: `${actualFileName}`,
    })
    await handleRecordSave({
      ...baseRunVariableValue,
      url: `gs://${NEXT_PUBLIC_AUDIO_FILE_RECORDINGS_BUCKET}/${gcsDirectory}/${actualFileName}.webm`,
      fileName: `${actualFileName}.webm`,
      length: end - start,
      micLabel: baseRunVariableValue.micLabel as string,
    })
  }

  return (
    <>
      <Box display={visible ? 'block' : 'none'}>
        <Typography fontSize='14px' mb='16px'>
          {actualFileName}
        </Typography>
        <Box ref={playbackContainerRef} sx={WAVEFORM_STYLE_OVERRIDES}>
          {isLoaded ? null : <CircularProgress sx={LOADER_STYLES} />}
        </Box>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          mt='4px'
          position='relative'>
          <Controls waveSurfer={waveSurfer} />
          <Typography
            fontWeight={500}
            fontSize='16px'
            position='absolute'
            left='50%'
            sx={{ transform: 'translateX(-50%)' }}>
            {formatDuration(playbackProgress)}/{formatDuration(audioLength)}
          </Typography>
          <Stack direction='row' justifyContent='center' gap='4px'>
            <Button
              sx={{ fontWeight: 'normal' }}
              onClick={handleTrim}
              size='small'
              startIcon={<ContentCutIcon />}
              disabled={isRecordingUploading}>
              Trim
            </Button>
            <Button
              color='error'
              startIcon={<DeleteOutline />}
              size='small'
              onClick={() => setIsDeleteDialogOpen(true)}
              sx={{ fontWeight: 'normal' }}>
              Delete
            </Button>
          </Stack>
        </Stack>
      </Box>

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Delete recording</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete{' '}
            <Typography component='span' fontWeight='bold'>
              {actualFileName}
            </Typography>
            ? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontWeight: 'normal' }} onClick={() => setIsDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            sx={{ fontWeight: 'normal' }}
            onClick={() => {
              handleRecordReset()
              setIsDeleteDialogOpen(false)
            }}
            autoFocus>
            Confirm & delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { Player }
