import { Skeleton } from '@invisible/ui/skeleton'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'

import TextRender from '../common/TextRender'
import { TTextDirection } from '../common/types'
import { useRLHFContext } from './context'
import { Metadata } from './Metadata'

type TBaseRun = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>[0]
type TBaseRunVariables = TBaseRun['baseRunVariables']

interface IProps {
  prompt: {
    id: string
    text: string
    index: number
    createdAt: Date
  }
  disablePromptEdit?: boolean
  disableMetadataEdit?: boolean
  defaultRenderMode?: 'plaintext' | 'markdown' | 'latex'
  isLoadingBaseRunVariables?: boolean
  baseRunVariables: TBaseRunVariables
  textDirection?: string
  handleClose: () => void
}

const PromptEdit = ({
  prompt: { id, text, index, createdAt },
  baseRunVariables,
  disablePromptEdit,
  disableMetadataEdit,
  defaultRenderMode,
  isLoadingBaseRunVariables,
  textDirection,
  handleClose,
}: IProps) => {
  const [promptText, setPromptText] = useState(text)
  const { config, updateBaseRunVariables, firstManualStepRunCreatedAt, isReadOnly } =
    useRLHFContext()

  const handleSavePrompt = async () => {
    await updateBaseRunVariables([
      {
        baseRunId: id,
        baseVariableId: config?.promptTextBaseVariableId as string,
        value: promptText,
      },
    ])
  }
  const editPromptTextEnabled =
    !disablePromptEdit &&
    (config.allowEditingImportedTurns || createdAt > firstManualStepRunCreatedAt)

  return (
    <div
      className='border-main border-t-solid w-[500px] border-t-2 bg-[#D9D9D924] p-3'
      style={{ backgroundColor: '#D9D9D924', borderTop: `4px solid #000002` }}>
      <div className='flex items-center justify-between'>
        <div className='font-medium'>Prompt {index + 1}</div>
        <div className='flex gap-2'>
          {editPromptTextEnabled && (
            <IconButton disabled={text === promptText} onClick={() => handleSavePrompt()}>
              <CheckIcon />
            </IconButton>
          )}
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <div className='mt-4'>
        {isLoadingBaseRunVariables ? (
          <div className='my-4 flex flex-col justify-center gap-4'>
            <Skeleton.Rectangle height={40} width='100%' />
            <Skeleton.Rectangle height={40} width='100%' />
            <Skeleton.Rectangle height={40} width='100%' />
            <Skeleton.Rectangle height={40} width='100%' />
          </div>
        ) : (
          <Metadata
            isReadOnly={isReadOnly || disableMetadataEdit}
            data={config?.promptMetadata?.fields ?? []}
            baseRunId={id}
            baseRunVariables={baseRunVariables}
            updateBaseRunVariables={updateBaseRunVariables}
            selectedValueColor={'#000000'}
          />
        )}
      </div>
      {editPromptTextEnabled ? (
        <div
          className={
            'my-3 box-border inline-block w-full whitespace-pre-wrap  border-gray-300 bg-white p-2 outline-none'
          }
          contentEditable={true}
          onInput={(e) => setPromptText(e?.currentTarget?.textContent ?? '')}>
          {text}
        </div>
      ) : (
        <TextRender
          text={text as string}
          renderMode={defaultRenderMode ?? 'markdown'}
          markdownProps={{
            components: {
              p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
            },
            className: 'my-3 overflow-auto',
            dir: textDirection as TTextDirection,
          }}
          plaintextProps={{
            className: 'my-3 overflow-auto',
          }}
          latexProps={{
            containerClassName: 'my-3 overflow-auto',
            className: 'whitespace-pre-wrap',
            textDirection: textDirection as TTextDirection,
          }}
        />
      )}
    </div>
  )
}

export { PromptEdit }
