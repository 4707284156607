import { getUUIDFromNamespace } from '@invisible/common/helpers'
import { IStepRunEventTypeEnum } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'

import { useBaseRunCreate } from '../../../hooks/useBaseRunCreate'
import { useBaseRunCreateMany } from '../../../hooks/useBaseRunCreateManyWizardAction'
import { useBaseRunDeleteWithStepRunReference } from '../../../hooks/useBaseRunDeleteWithStepRunReference'
import { useBaseRunVariablesWizardUpdate } from '../../../hooks/useBaseRunVariablesWizardUpdate'
import { MutationsProps, TPrevDataType } from '../types'

export const useMutations = ({
  stepRun,
  showSnackbar,
  reactQueryContext,
  maybeLogStepRunEvent,
  nextSpanLocation,
  config,
  isResponseRegenerating,
  setIsResponseRegenerating,
}: MutationsProps) => {
  const { mutateAsync: createBaseRun, isLoading: isCreatingBaseRun } = useBaseRunCreate({
    onSuccess: () => {
      maybeLogStepRunEvent({
        name: 'prompt_registered',
        spanId: getUUIDFromNamespace([stepRun.id as string, String(nextSpanLocation)]),
        stepRunId: stepRun.id as string,
        spanType: 'TURN',
        spanLocation: nextSpanLocation,
        type: IStepRunEventTypeEnum.Span,
        timestamp: new Date(),
      })
    },
  })

  const { mutateAsync: deleteBaseRuns, isLoading: isDeletingBaseRuns } =
    useBaseRunDeleteWithStepRunReference({
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
      onError: (error) => {
        showSnackbar({
          message: 'Something went wrong while deleting prompts or responses.',
          variant: 'error',
        })
        logger.error('Failed to delete prompts or responses.', error)
      },
    })

  const { mutateAsync: createBaseRuns, isLoading: isCreatingManyBaseRuns } = useBaseRunCreateMany({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      setIsResponseRegenerating(false)
    },
    onSuccess: () => {
      const spanLocation = isResponseRegenerating ? nextSpanLocation - 1 : nextSpanLocation
      maybeLogStepRunEvent({
        name: 'response_generated',
        stepRunId: stepRun.id as string,
        spanId: getUUIDFromNamespace([stepRun.id as string, String(spanLocation)]),
        spanType: 'TURN',
        spanLocation: spanLocation,
        type: IStepRunEventTypeEnum.Span,
        timestamp: new Date(),
      })
    },
  })

  const { mutateAsync: updateBaseRunVariables, isLoading: isUpdatingBaseVariables } =
    useBaseRunVariablesWizardUpdate({
      onMutate: async (data) => {
        reactQueryContext.queryClient.setQueryData(
          [
            'baseRun.findChildBaseRuns',
            {
              baseId: config?.promptsBaseId as string,
              parentBaseRunId: stepRun.id,
            },
          ],
          (prevData: TPrevDataType | undefined) => {
            if (!prevData) return []

            return prevData.map((b) => {
              const brv = data.find((d) => d.baseRunId === b.id)
              return brv === undefined
                ? b
                : {
                    ...b,
                    baseRunVariables: b.baseRunVariables.map((v) =>
                      v.baseVariable.id !== brv.baseVariableId ? v : { ...v, value: brv.value }
                    ),
                  }
            })
          }
        )
      },
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
        reactQueryContext.invalidateQueries('baseRun.findManyByParents')
        reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
        reactQueryContext.invalidateQueries('baseRunVariable.findOneByBaseVariableIdAndBaseRunId')
      },
      onError: (error) => {
        showSnackbar({
          message: `Failed to save changes.`,
          variant: 'error',
        })
        logger.error('Failed to update base variable changes.', error)
      },
    })

  return {
    mutations: {
      createBaseRun,
      deleteBaseRuns,
      createBaseRuns,
      updateBaseRunVariables,
    },
    mutationStates: {
      isCreatingBaseRun,
      isDeletingBaseRuns,
      isCreatingManyBaseRuns,
      isUpdatingBaseVariables,
    },
  }
}
