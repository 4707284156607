import EditIcon from '@mui/icons-material/Edit'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'

import Markdown from '../../common/Markdown'
import { AcceptedResponseProps } from '../types'
import MultimodalDisplay from './MultimodalDisplay'

const AcceptedResponse = ({
  prompt,
  textDirection,
  firstManualStepRunCreatedAt,
  config,
  onEditResponse,
}: AcceptedResponseProps) => (
  <div className='flex justify-start'>
    <Avatar
      sx={{
        height: '24px',
        width: '24px',
        marginRight: '16px',
        marginTop: 1,
      }}
    >
      <SmartToyIcon sx={{width: '16px'}} />
    </Avatar>
    <div className='relative mt-2 mr-1 w-fit min-w-[20%] max-w-[90%] overflow-auto rounded bg-[#F5F5F7] py-1 px-2 text-sm'>
      <Markdown
        dir={textDirection}
        components={{
          p: ({ children }) => <p className='whitespace-pre-wrap leading-relaxed'>{children}</p>,
        }}>
        {prompt?.acceptedResponse as string}
      </Markdown>
      <MultimodalDisplay
        content={(() => {
          try {
            return JSON.parse(prompt?.acceptedContent ?? '[]')
          } catch (e) {
            console.error('Failed to parse JSON:', e)
            return []
          }
        })()}
      />
    </div>
    {prompt?.createdAt > firstManualStepRunCreatedAt || config?.allowEditingImportedTurns ? (
      <div className='mr-2 mt-2 flex items-start'>
        <IconButton onClick={onEditResponse} className='text-gray-400'>
          <EditIcon />
        </IconButton>
      </div>
    ) : null}
  </div>
)

export default AcceptedResponse
