const palette = {
  primary: {
    light: '#aea4d2',
    main: '#604CA5',
    dark: '#42398c',
    900: '#332e79',
    800: '#42398c',
    700: '#4b3f95',
    600: '#57479f',
    500: '#5f4ca5',
    400: '#7665b2',
    300: '#8e80c0',
    200: '#aea4d2',
    100: '#cec8e4',
    50: '#ece9f4',
  },
  secondary: {
    light: '#73feff',
    main: '#00effd',
    dark: '#00b1b5',
    900: '#008c86',
    800: '#00b1b5',
    700: '#00c6cd',
    600: '#00deea',
    500: '#00effd',
    400: '#00f4fd',
    300: '#38f9ff',
    200: '#73feff',
    100: '#ffedb5',
    50: '#deffff',
  },
  error: {
    light: '#FCE8E8',
    main: '#E21B1B',
    dark: '#BF0C0C',
  },
  warning: {
    light: '#FEF4CC',
    main: '#EDA304',
    dark: '#A7680A',
  },
  success: {
    light: '#F6FFE2',
    main: '#4DBC25',
    dark: '#218712',
  },
  info: {
    main: '#018BD9',
  },
  grey: {
    paragraph: '#535157',
    main: '#F0F0F0',
    dark: '#E0E0E0',
  },
  black: {
    main: '#212121',
    contrastText: '#fff',
    light: '#2f2f2f',
    dark: '#212121',
  },
  light: {
    main: '#fff',
    contrastText: '#212121',
    light: '#fff',
  },
}

const adminPalette = {
  primary: {
    light: '#D2E8E9',
    main: '#1e8c93',
    dark: '#125458',
    900: '#004d40',
    800: '#00695c',
    700: '#00796b',
    600: '#00897b',
    500: '#009688',
    400: '#26a69a',
    300: '#4db6ac',
    200: '#80cbc4',
    100: '#b2dfdb',
    50: '#e0f2f1',
  },
  secondary: {
    light: '#73feff',
    main: '#00effd',
    dark: '#00b1b5',
    900: '#008c86',
    800: '#00b1b5',
    700: '#00c6cd',
    600: '#00deea',
    500: '#00effd',
    400: '#00f4fd',
    300: '#38f9ff',
    200: '#73feff',
    100: '#ffedb5',
    50: '#deffff',
  },
  error: {
    light: '#FCE8E8',
    main: '#E21B1B',
    dark: '#BF0C0C',
  },
  warning: {
    light: '#FEF4CC',
    main: '#EDA304',
    dark: '#A7680A',
  },
  success: {
    light: '#F6FFE2',
    main: '#4DBC25',
    dark: '#218712',
  },
  info: {
    main: '#018BD9',
  },
  grey: {
    paragraph: '#535157',
    main: '#F0F0F0',
    dark: '#E0E0E0',
  },
}

const portalPalette = {
  primary: {
    light: '#6f92c5',
    main: '#0f499f',
    dark: '#092c5f',
    900: '#6f92c5',
    800: '#cfdbec',
    700: '#f0f5fb',
    600: '#f0f5fb',
    500: '#f0f5fb',
    400: '#f0f5fb',
    300: '#f0f5fb',
    200: '#f0f5fb',
    100: '#f0f5fb',
    50: '#f0f5fb',
  },
  secondary: {
    light: '#73feff',
    main: '#00effd',
    dark: '#00b1b5',
    900: '#008c86',
    800: '#00b1b5',
    700: '#00c6cd',
    600: '#00deea',
    500: '#00effd',
    400: '#00f4fd',
    300: '#38f9ff',
    200: '#73feff',
    100: '#ffedb5',
    50: '#deffff',
  },
  error: {
    light: '#FCE8E8',
    main: '#E21B1B',
    dark: '#BF0C0C',
  },
  warning: {
    light: '#FEF4CC',
    main: '#EDA304',
    dark: '#A7680A',
  },
  success: {
    light: '#F6FFE2',
    main: '#4DBC25',
    dark: '#218712',
  },
  info: {
    main: '#018BD9',
  },
  grey: {
    paragraph: '#535157',
    main: '#F0F0F0',
    dark: '#E0E0E0',
  },
}

export { adminPalette, palette, portalPalette }
