import { InputBaseProps, Paper, Popper, styled, Theme } from '@mui/material'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import * as React from 'react'

const ResizableTextarea = styled('textarea')<{ theme?: Theme }>(({ theme }) => ({
  minHeight: '50px',
  padding: theme.spacing(1),
  resize: 'both',
  overflow: 'auto',
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  '&:focus': {
    outline: 'none',
  },
}))

type TProps = GridRenderEditCellParams<any, string>

export function EditTextarea(props: TProps) {
  const { id, field, value, colDef, hasFocus } = props
  const [valueState, setValueState] = React.useState<string | undefined>(value)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const inputRef = React.useRef<HTMLTextAreaElement | null>(null)
  const apiRef = useGridApiContext()

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [hasFocus, inputRef])

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el)
  }, [])

  const handleChange = React.useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event) => {
      event.preventDefault()
      const newValue = event.target.value
      setValueState(newValue)
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event)
    },
    [apiRef, field, id]
  )

  const handleMouseUp = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
  }, [])

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        apiRef.current.setEditCellValue({ id, field, value: valueState })
        apiRef.current.stopCellEditMode({ id, field })
        setAnchorEl(null)
      }
    },
    [apiRef, field, id, valueState]
  )

  React.useEffect(() => {
    if (anchorEl) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [anchorEl, handleClickOutside])

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper
          id='test-poper-id'
          className='z-[500]'
          open={true}
          anchorEl={anchorEl}
          placement='bottom-start'>
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <ResizableTextarea
              rows={4}
              id='standard-multiline-flexible'
              className='MuiDataGrid-editInputCell '
              value={valueState}
              sx={{
                minWidth: colDef.computedWidth,
              }}
              ref={inputRef}
              onChange={handleChange}
              onMouseUp={handleMouseUp}
            />
          </Paper>
        </Popper>
      )}
    </div>
  )
}
