import { useState } from 'react'

import { Card } from '../../card'
import { CloseIcon, InfoFilledIcon, InfoOutlineIcon } from '../../icons'

// eslint-disable-next-line @typescript-eslint/ban-types
export const DescriptionIcon: React.FC<{ description: string }> = ({ description }) => {
  const [showDescription, setShowDescription] = useState(false)
  if (!showDescription) {
    return (
      <InfoOutlineIcon
        className='text-paragraphs hover:cursor-pointer'
        width={20}
        height={20}
        onClick={() => setShowDescription(true)}
      />
    )
  }
  return (
    <>
      <InfoFilledIcon className='text-paragraphs hover:cursor-pointer' width={17} height={17} />
      <div className='relative'>
        <Card className='absolute left-0 top-[-10px] z-10 !min-h-fit !w-[350px] border-[1px_solid_#D0CFD2] bg-white shadow-[0px_4px_6px_-1px_rgba(124,121,129,0.1),0px_2px_4px_-1px_rgba(124,121,129,0.06)]'>
          <div className='flex w-full max-w-[350px] justify-between'>
            <div className='max-w-[288px]'>
              <h5 className='!m-0 text-[14px] font-medium leading-5	text-black'>Description</h5>
              <p className='!m-0 whitespace-pre-line text-xs font-normal text-[#535157]'>
                {description}
              </p>
            </div>
            <CloseIcon
              className='hover:cursor-pointer'
              width={12}
              height={12}
              color='#0C0C0D'
              onClick={() => setShowDescription(false)}
            />
          </div>
        </Card>
      </div>
    </>
  )
}
