import { MultiSelect as UIMultiSelect } from '@invisible/ui/multi-select'

type FieldProps = {
  value: string[]
  isReadOnly: boolean
}

const MultiSelect = ({ value, isReadOnly }: FieldProps) => (
  <UIMultiSelect
    maxHeight='200px'
    name={'label'}
    options={[]}
    disabled={isReadOnly}
    selectedKeys={value}
    // onSelect={(selected) => onChange(selected.map(({ key }) => key))}
  />
)

export { MultiSelect }
