import React, { createContext, useContext, useReducer } from 'react'

interface SetInitialTaxIdStateAction {
  type: 'setInitialTaxIdState'
  publicKey?: string | null
  showTaxIdModal: boolean
  taxIdTicketSubmitted: boolean
}

interface SetPublicKeyAction {
  type: 'setTaxIdPublicKey'
  publicKey?: string | null
}

interface SetShowTaxIdModalAction {
  type: 'setShowTaxIdModal'
  showTaxIdModal: boolean
}

interface SetShowTaxIdSuccessModalAction {
  type: 'setShowTaxIdSuccessModal'
  showTaxIdSuccessModal: boolean
}

interface SetTaxIdTicketSubmittedAction {
  type: 'setTaxIdTicketSubmitted'
  taxIdTicketSubmitted: boolean
}

type TAction =
  | SetInitialTaxIdStateAction
  | SetPublicKeyAction
  | SetShowTaxIdModalAction
  | SetShowTaxIdSuccessModalAction
  | SetTaxIdTicketSubmittedAction

type Dispatch = (action: TAction) => void

interface State {
  publicKey?: string | null
  showTaxIdModal: boolean
  showTaxIdSuccessModal: boolean
  taxIdTicketSubmitted: boolean
}

const TaxIdStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

const initialState = {
  publicKey: null,
  showTaxIdModal: false,
  showTaxIdSuccessModal: false,
  taxIdTicketSubmitted: false,
}

export const taxIdStateReducer = (state: State, action: TAction) => {
  switch (action.type) {
    case 'setInitialTaxIdState': {
      return {
        ...state,
        publicKey: action.publicKey,
        showTaxIdModal: action.showTaxIdModal,
      }
    }

    case 'setTaxIdPublicKey': {
      return {
        ...state,
        publicKey: action.publicKey,
      }
    }

    case 'setShowTaxIdModal': {
      return {
        ...state,
        showTaxIdModal: action.showTaxIdModal,
      }
    }

    case 'setShowTaxIdSuccessModal': {
      return {
        ...state,
        showTaxIdSuccessModal: action.showTaxIdSuccessModal,
      }
    }
    case 'setTaxIdTicketSubmitted': {
      return {
        ...state,
        taxIdTicketSubmitted: action.taxIdTicketSubmitted,
      }
    }
    default: {
      return state
    }
  }
}

export const TaxIdStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(taxIdStateReducer, initialState)
  const value = { state, dispatch }
  return <TaxIdStateContext.Provider value={value}>{children}</TaxIdStateContext.Provider>
}

export const useTaxIdState = () => {
  const context = useContext(TaxIdStateContext)
  if (context === undefined) {
    throw new Error('useTaxIdState must be used within a TaxIdStateProvider')
  }
  return context
}
