import { ReactElement } from 'react'

import { Icons } from '../icons'

const TAG_COLORS = [
  'default',
  'theme',
  'agent',
  'client',
  'admin',
  'success',
  'warning',
  'danger',
  'info',
  'energy',
  'red',
  'yellow',
  'blue',
  'orange',
  'green',
  'purple',
  'teal',
  'pink',
  'gray',
] as const

type TagColor = typeof TAG_COLORS[number]

type Size = 'sm' | 'md' | 'sm-no-h'

const ColorStyles: Record<TagColor, string> = {
  default: 'text-paragraphs bg-weak-3 border-weak',
  theme: 'text-theme-strong bg-theme-weak-3 border-theme-weak',
  agent: 'text-agent-strong bg-agent-weak-3 border-agent-weak',
  client: 'text-client-strong bg-client-weak-3 border-client-weak',
  admin: 'text-admin-strong bg-admin-weak-3 border-admin-weak',
  success: 'text-green-strong bg-green-weak border-green-main',
  warning: 'text-amber-strong bg-amber-weak border-amber-main',
  danger: 'text-red-strong bg-red-weak border-red-main',
  info: 'text-sky-strong bg-sky-weak border-sky-main',
  energy: 'text-orange-strong bg-orange-weak border-orange-weak',
  red: 'text-red-strong bg-red-weak border-red-strong',
  yellow: 'text-yellow-700 bg-yellow-100 border-yellow-500',
  blue: 'text-blue-700 bg-blue-100 border-blue-500',
  orange: 'text-orange-strong bg-orange-100 border-orange-500',
  green: 'text-green-strong bg-green-weak border-green-',
  purple: 'text-agent-strong bg-agent-weak-3 border-agent-weak',
  teal: 'text-admin-strong bg-admin-weak-3 border-admin-weak',
  pink: 'text-fuchsia-strong bg-fuchsia-weak border-fuchsia-main',
  gray: 'text-paragraphs bg-weak-2 border-main',
}

const SizeStyles: Record<Size, string> = {
  sm: 'text-xs h-6',
  md: 'text-sm h-8',
  'sm-no-h': 'text-xs',
}

const IconSizeStyles: Record<Size, string> = {
  sm: 'w-3 h-3',
  md: 'w-4 h-4',
  'sm-no-h': 'w-3 h-3',
}

type AllowedChild = string | number | ReactElement

type TagProps = {
  children: AllowedChild | AllowedChild[]
  color?: TagColor
  size?: Size
  iconLeft?: keyof typeof Icons
  iconRight?: keyof typeof Icons
  onIconLeftClick?: () => void
  onIconRightClick?: () => void
  onClick?: () => void
  uppercase?: boolean
}

const Tag = ({
  children,
  color = 'default',
  size = 'sm',
  iconLeft,
  iconRight,
  onClick,
  onIconLeftClick,
  onIconRightClick,
  uppercase,
}: TagProps) => (
  <div
    onClick={() => onClick?.()}
    className={`box-border flex w-fit items-center gap-1 whitespace-pre-wrap rounded-md border border-solid py-0.5 px-2 font-medium shadow-md ${
      ColorStyles[color]
    } ${SizeStyles[size]} ${onClick ? 'cursor-pointer' : ''}
    ${uppercase ? 'uppercase' : ''}`}>
    {iconLeft
      ? Icons[iconLeft]({
          className: `${IconSizeStyles[size]} ${onIconLeftClick ? 'cursor-pointer' : ''}`,
          onClick: () => onIconLeftClick?.(),
        })
      : null}
    {children}
    {iconRight
      ? Icons[iconRight]({
          className: `${IconSizeStyles[size]} ${onIconRightClick ? 'cursor-pointer' : ''}`,
          onClick: () => onIconRightClick?.(),
        })
      : null}
  </div>
)

export { Tag, TAG_COLORS }
export type { TagColor, TagProps }
