import { ErrorBoundary } from '@invisible/common/components/error-boundary'
import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { theme } from '@invisible/ui/mui-theme-v2'
import { Text } from '@invisible/ui/text'
import { gray } from '@invisible/ui/themes'
import { useToasts } from '@invisible/ui/toasts'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'
import {
  DataGridPro,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowModel,
} from '@mui/x-data-grid-pro'
import { map, reduce } from 'lodash/fp'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { DEFAULT_ITEMS_PER_PAGE } from '../../common/constants'
import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import {
  calculateTextWidth,
  getColumnType,
  isValidFieldValue,
  parsColumnValue,
  themeStyleOverides,
} from './helpers'
import { CustomFooter, TCustomFooterProps } from './slots/CustomFooter'
import { CustomGridRow } from './slots/CustomGridRow'
import { CustomToolBar } from './slots/CustomToolBar'
import { EditTextarea } from './sub-components/EditTextArea'
import { GridCellExpand } from './sub-components/GridCellExpand'

export interface TModel extends Record<string, unknown> {
  [key: string]: any
}

interface CellValue {
  id: string
  baseRunId: string
  actualValue: string
  isEditable: boolean
  required: boolean
  baseVariableId: string
  expandable?: boolean
}

type TStepRun = NonNullable<inferQueryOutput<'stepRun.findById'>>

type IProps = WizardSchemas.WACConfig.TSchema & {
  stepRun: TStepRun
  isReadOnly: boolean
}

export const TableWAC = ({ showName, name, table, stepRun, isReadOnly }: IProps) => {
  const reactQueryContext = useContext()
  const reactQueryClient = useQueryClient()
  const { addToast } = useToasts()
  const { dispatch } = useWizardState()
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({})
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: table?.rowsLimit && table?.rowsLimit > 0 ? table?.rowsLimit : DEFAULT_ITEMS_PER_PAGE,
  })

  const fields: Record<string, any> = useMemo(
    () => reduce((acc, f) => ({ ...acc, [f.baseVariableId as string]: f }), {}, table?.fields),
    [table?.fields]
  )
  const tableBaseId = table?.fields[0].baseId as string
  const { data: validationData } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      parentBaseRunId: stepRun.baseRunId,
      baseId: tableBaseId,
    },
  ])

  const { data, isLoading } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      parentBaseRunId: stepRun.baseRunId,
      baseId: tableBaseId,
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      filters: (table?.filters ?? [])
        .filter((f) => f.baseVariableId)
        .map((f) => ({
          id: f.baseVariableId,
          value: f.value.map((v) => (!v ? null : v)),
        })),
      sort: {
        ...(table?.sortField
          ? { [table?.sortField]: table?.sortOrder as 'asc' | 'desc' }
          : { createdAt: 'desc' }),
      },
    },
  ])

  const rowCountRef = useRef(data?.[0]?.totalCount ?? 0)
  const itemCount = useMemo(() => {
    if (data?.[0]?.totalCount) {
      rowCountRef.current = data?.[0]?.totalCount
    }
    return rowCountRef.current
  }, [data])

  const [rowCount, setRowCountState] = useState(itemCount)
  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      itemCount !== undefined ? itemCount : prevRowCountState
    )
  }, [itemCount, setRowCountState])

  const { mutateAsync: updateBaseRunVariableMutation } = useMutation('baseRunVariable.update', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      reactQueryClient.invalidateQueries('get-base-runs')
    },
  })

  const { mutateAsync: createBaseRun, isLoading: isCreatingNewBaseRun } = useBaseRunCreate({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: deleteBaseRuns } = useBaseRunDeleteWithStepRunReference({
    onSuccess: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
    onError: (error) => {
      addToast(`Delete failed: ${error?.message}`, {
        appearance: 'error',
      })
    },
  })

  const updateBaseRunVariable = useCallback(
    ({ baseRunVariableId, value }: { baseRunVariableId: string; value: any }) => {
      updateBaseRunVariableMutation({ id: baseRunVariableId, value, isBaseViewTable: false })
    },
    [updateBaseRunVariableMutation]
  )

  const gridColumns: GridColDef[] = map(
    (field) => {
      if (field.type === 'serial') {
        return {
          field: 'index',
          headerName: '#',
          filterable: false,
          sortable: false,
          width: 20,
          renderCell: (params) => {
            const index = params.api.getRowIndexRelativeToVisibleRows(params.row.id.baseRunId)
            return paginationModel.page * paginationModel.pageSize + (index + 1)
          },
        }
      }
      const width = calculateTextWidth(field.label as string) + 120
      return {
        field: field.baseVariableId as string,
        type: getColumnType(field.type),
        headerName: field.label,
        minWidth: width,
        flex: 1,
        editable: field.editable && !isReadOnly,
        placeholder: 'Enter value',
        renderHeader: () => (
          <strong>
            {field.label}
            {field.required && (
              <span role='img' aria-label='enjoy'>
                *
              </span>
            )}
          </strong>
        ),
        valueGetter: (_, row) =>
          parsColumnValue(row[field.baseVariableId as string]?.actualValue, field.type),
        valueSetter: (value, row) => ({
          ...row,
          [field.baseVariableId as string]: {
            ...row?.[field.baseVariableId as string],
            actualValue: value,
            _updated: true,
          },
        }),
        valueOptions: ({ row }) =>
          row[field.baseVariableId as string]?.options?.map((o: string) => ({
            value: o,
            label: o,
          })),
        ...(getColumnType(field.type) === 'string'
          ? {
              renderEditCell: (props) => <EditTextarea {...props} />,
              renderCell: (params: GridRenderCellParams<any, string>) => (
                <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
              ),
            }
          : {}),
      }
    },
    [{ type: 'serial' }, ...(table?.fields as any[])]
  )

  const parsedData = useMemo(
    () =>
      (data ?? [])?.map((br) => ({
        id: {
          id: '',
          baseRunId: br.id,
          actualValue: br.id,
          isEditable: false,
          baseVariableId: '',
        },
        ...br.baseRunVariables.reduce(
          (acc, brv) => ({
            ...acc,
            [brv.baseVariable.id]: {
              id: brv.id,
              baseVariableId: brv.baseVariable.id,
              actualValue: brv.value,
              baseRunId: br.id,
              isValid: fields[brv.baseVariable.id]?.required ? brv.value !== null : true,
              isEditable: fields[brv.baseVariable.id]?.editable,
              required: fields[brv.baseVariable.id]?.required,
              options: fields[brv.baseVariable.id]?.options,
              expandable: fields[brv.baseVariable.id]?.expandable,
            },
          }),
          {}
        ),
      })),
    [data]
  )

  // Check if all required fields are filled
  const isDataValid = useMemo(
    () =>
      (validationData ?? [])
        ?.map((br) =>
          [
            ...br.baseRunVariables.map((brv) => ({
              actualValue: brv.value,
              required: fields[brv.baseVariable.id]?.required,
            })),
          ].filter((row) => row.required && isValidFieldValue(row.actualValue) === false)
        )
        .filter((row) => row.length > 0).length === 0,
    [validationData]
  )

  const invalidCellsCount = useMemo(
    () =>
      (data ?? [])
        ?.map((br) =>
          [
            ...br.baseRunVariables.map((brv) => ({
              actualValue: brv.value,
              required: fields[brv.baseVariable.id]?.required,
            })),
          ].filter((row) => row.required && isValidFieldValue(row.actualValue) === false)
        )
        .reduce((acc, row) => [...acc, ...row], []).length,
    [data]
  )

  // Notify the wizard that the table is ready for submit
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: `TableWAC-${tableBaseId}`,
      value: isDataValid,
    })
  }, [parsedData])

  const processRowUpdate = async (updatedRow: GridRowModel, oldRow: GridRowModel) => {
    Object.values(updatedRow).forEach((cell) => {
      if (cell._updated && oldRow[cell.baseVariableId]?.actualValue !== cell.actualValue) {
        updateBaseRunVariable({
          baseRunVariableId: cell.id,
          value: cell.actualValue,
        })
      }
    })
    return updatedRow
  }

  const handlePaginationModelChange = useCallback((newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel)
  }, [])

  const createNewBaseRun = () => {
    createBaseRun({
      baseId: tableBaseId,
      stepRunId: stepRun.id,
      parentBaseRunId: stepRun.baseRunId,
      initialValues: [],
    })
  }

  const handleDeleteBaseRun = (baseRunId: string) => {
    if (window.confirm('Are you sure you want to delete this base run?') === false) return
    deleteBaseRuns({ baseRunIds: [baseRunId], stepRunId: stepRun.id })
  }

  return (
    <Box
      sx={{
        height: '100%',
        borderRadius: '8px',
        backgroundColor: 'white',
        padding: '10px',
        border: `1px solid ${gray(4)}`,
        overflow: 'auto',
        boxSizing: 'border-box',
        boxShadow: 'rgba(0, 0, 0, 0.024) 0px 2px 4px',
      }}>
      <div className='mb-2 flex items-center'>
        {showName ? <Text fontWeight='bold'>{name}</Text> : null}
      </div>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <DataGridPro
            loading={isLoading}
            rows={parsedData}
            getRowId={(row) => row.id.baseRunId}
            columns={gridColumns}
            cellModesModel={cellModesModel}
            onCellModesModelChange={setCellModesModel}
            onCellEditStop={(params, event: any) => {
              // Check if the target is inside a Portal
              if (event.target?.nodeType === 1 && !event.currentTarget.contains(event.target)) {
                event.defaultMuiPrevented = true
              }
            }}
            processRowUpdate={processRowUpdate}
            hideFooterPagination={table?.rowsLimit && table?.rowsLimit > 0 ? true : false}
            pageSizeOptions={[5, 10, 25, 40, 75, 100]}
            paginationMode='server'
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            rowCount={rowCount}
            showColumnVerticalBorder={true}
            showCellVerticalBorder={true}
            slots={{
              toolbar: CustomToolBar,
              footer: CustomFooter,
              row: CustomGridRow,
            }}
            slotProps={{
              footer: {
                createNewBaseRun: table?.allowAddBaseRun ? createNewBaseRun : undefined,
                isCreatingNewBaseRun: isCreatingNewBaseRun,
              } as TCustomFooterProps,
              toolbar: {
                refresh: () => reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns'),
                errorsCount: invalidCellsCount,
              },
              row: {
                deleteBaseRun: table?.allowDeleteBaseRun ? handleDeleteBaseRun : undefined,
              },
            }}
            sx={themeStyleOverides}
            getCellClassName={(params: GridCellParams<any, any, number>) => {
              const cell = params.row[params.field] as CellValue
              return cell?.required && !isValidFieldValue(params.value) ? 'Mui-error' : ''
            }}
          />
        </ThemeProvider>
      </ErrorBoundary>
    </Box>
  )
}
