// import { Button } from '@invisible/ui/button'
// import dynamic from 'next/dynamic'
// import { useState } from 'react'

import { ChangeEvent } from 'react'

import { EmbedLoomVideo } from '../EmbedLoomVideo'

type FieldProps = {
  value: string
  isReadOnly: boolean
  onChange: ChangeEvent<HTMLInputElement>
}

// const Loading = () => (
//   <div className='mb-5 h-8 w-full'>
//     <Button variant='secondary' size='md' disabled={true}>
//       Loom is starting up
//     </Button>
//   </div>
// )

const LoomVideo = ({ value, isReadOnly, onChange }: FieldProps) => {
  // const [loomVideos, setLoomVideos] = useState<string[]>([])

  // const LoomButton = dynamic(() => import('@invisible/loom'), {
  //   ssr: false,
  //   loading: Loading,
  // })
  const url = value

  return (
    <div className='flex flex-col gap-2'>
      {url ? <EmbedLoomVideo url={url} /> : null}
      {/* <LoomButton
        loomVideos={loomVideos}
        setLoomVideos={(videos) => setLoomVideos(videos)}
        handleChange={(_, videos) => onChange(videos.at(-1))}
        label={url ? 'Update the Loom Video' : 'Add a Loom Video'}
      /> */}
    </div>
  )
}

export { LoomVideo }
