import { FC } from 'react'

import { DateTimePicker, IDateTimePickerProps } from '../../date-time-picker'

interface IProps extends IDateTimePickerProps {
  width?: string
  disabled?: boolean
  forceUtcDate?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const DateCell: FC<IProps> = (props) => <DateTimePicker {...props} hideTime />
