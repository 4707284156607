import { classNames } from '@invisible/common/helpers'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { motion } from 'framer-motion'
import { isEmpty, isUndefined } from 'lodash'
import {
  ChangeEvent,
  CSSProperties,
  Dispatch,
  FC,
  forwardRef,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Virtuoso } from 'react-virtuoso'

import { CaretDownIcon } from '../icons'
import { TIconName } from '../icons'
import { Input } from '../input'

interface IOption {
  key: string
  value: string | number | string[]
}
interface IProps {
  width?: string
  disabled?: boolean
  name?: string
  placeholder?: string
  search?: boolean
  options: IOption[]
  selectedKey?: string | null
  renderDropdownOption?: (option: IOption) => React.ReactElement //utilize when adding custom styling to the values
  defaultKey?: string | null // useful when you want an uncontrolled dropdown with an initial value
  onChange?: (arg: { key: string; value: string | number | string[] }) => void
  defaultOpen?: boolean
  maxHeight?: string
  onBlur?: () => void
  dropdownWidth?: string
  alignment?: 'start' | 'center' | 'end'
  clearText?: string
  onClear?: () => void
  modal?: boolean
  isVirtualized?: boolean
  custom?: boolean
  activeText?: string
}

interface IOption {
  key: string
  value: string | number | string[]
  disabled?: boolean
}

interface IMenuContent {
  icon?: TIconName
  dropdownWidth?: string
  options: IOption[]
  onChange?: (arg: { key: string; value: string | number | string[] }) => void
  renderDropdownOption?: (option: IOption) => React.ReactElement
  maxHeight?: string
  internalKey: string | null
  setInternalKey: Dispatch<SetStateAction<string | null>>
  search?: boolean
  searchInput?: string
  setSearchInput?: Dispatch<SetStateAction<string>>
  alignment?: 'start' | 'center' | 'end'
  clearText?: string
  onClear?: () => void
  isVirtualized?: boolean
  custom?: boolean
  handleCreateOption?: (option: IOption) => void
}

interface IContent {
  children: ReactNode
  style?: CSSProperties
  className?: string
  alignment?: 'start' | 'center' | 'end'
}

interface IItem {
  children: ReactNode
  key: string
  internalKey: string | null
  disabled?: boolean
  textValue?: string
  className?: string
  onSelect?: (event: Event) => void
}

const DropdownMenu = DropdownMenuPrimitive.Root
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

// eslint-disable-next-line @typescript-eslint/ban-types
const DropdownMenuContent: FC<IContent> = forwardRef<HTMLDivElement, IContent>(
  ({ children, alignment, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        asChild
        align={alignment ?? 'start'}
        className='border-main bg-void z-[100] box-border overflow-auto rounded-md border border-solid py-1'
        {...props}
        ref={forwardedRef}>
        <motion.div
          initial={{
            opacity: 0.6,
            scaleX: 0.8,
            scaleY: 0.6,
          }}
          animate={{ opacity: 1, scaleX: 1, scaleY: 1 }}>
          {children}
        </motion.div>
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  )
)

// eslint-disable-next-line @typescript-eslint/ban-types
const DropdownMenuItem: FC<IItem> = forwardRef<HTMLDivElement, IItem>(
  ({ children, internalKey, key, ...props }, forwardedRef) => (
    <DropdownMenuPrimitive.Item
      className={classNames(
        'data-highlighted:bg-theme-weak-3 data-highlighted:text-theme-main data-highlighted:font-medium',
        'border-t-main min-h-8 h-auto cursor-pointer border-0 border-t border-solid px-2 py-2 leading-normal outline-none',
        'data-disabled:text-disabled',
        'last:border-b-main last:border-b last:border-solid',
        internalKey === key ? 'text-theme-main font-medium' : '',
        'overflow-visible'
      )}
      {...props}
      ref={forwardedRef}>
      {children}
    </DropdownMenuPrimitive.Item>
  )
)

// eslint-disable-next-line @typescript-eslint/ban-types
const MenuContent: FC<IMenuContent> = ({
  maxHeight,
  dropdownWidth,
  options,
  internalKey,
  setInternalKey,
  onChange,
  search,
  searchInput,
  setSearchInput,
  alignment,
  clearText,
  onClear,
  isVirtualized,
  custom,
  handleCreateOption,
  renderDropdownOption,
}) => {
  const [newOptionInput, setNewOptionInput] = useState('')

  const results = searchInput
    ? options.filter((option) =>
        String(option.value).toLowerCase().includes(searchInput.toLowerCase())
      )
    : options

  return (
    <DropdownMenuContent
      style={{
        maxHeight: maxHeight ?? '400px',
        width: dropdownWidth ?? '200px',
      }}
      alignment={alignment}>
      {search && setSearchInput ? (
        <Input
          value={searchInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchInput(e.target.value)
          }}
          placeholder='Search'
          className='p-2'
          prefix='SearchOutlineIcon'
        />
      ) : null}
      {clearText ? (
        <DropdownMenuItem
          key=''
          internalKey={internalKey}
          textValue=''
          onSelect={() => {
            setInternalKey(null)
            onClear?.()
          }}>
          {clearText}
        </DropdownMenuItem>
      ) : null}
      {custom ? (
        <>
          <DropdownMenuItem
            textValue=''
            key=''
            internalKey={internalKey}
            onSelect={() => {
              if (newOptionInput && !isEmpty(newOptionInput))
                handleCreateOption?.({ key: newOptionInput, value: newOptionInput })
            }}>
            Create: {newOptionInput}
          </DropdownMenuItem>
          <Input
            value={newOptionInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setNewOptionInput(e.target.value)
            }}
            placeholder='Enter new option'
            className='p-2'
          />
        </>
      ) : null}
      {isVirtualized ? (
        <Virtuoso
          style={{
            height: `calc(${maxHeight ?? '400px'} - ${
              search && setSearchInput ? '60px' : '0px'
            } - ${clearText ? '32px' : '0px'})`,
          }}
          fixedItemHeight={24}
          data={results}
          itemContent={(index, { key, value, disabled }) => (
            <DropdownMenuItem
              textValue=''
              key={key}
              internalKey={internalKey}
              disabled={disabled}
              onSelect={() => {
                setInternalKey(key)
                onChange?.({ key, value })
              }}>
              {renderDropdownOption?.({ key, value }) ?? value}
            </DropdownMenuItem>
          )}
        />
      ) : (
        results.map(({ key, value, disabled }) => (
          <DropdownMenuItem
            textValue=''
            key={key}
            internalKey={internalKey}
            disabled={disabled}
            onSelect={() => {
              setInternalKey(key)
              onChange?.({ key, value })
            }}>
            {renderDropdownOption?.({ key, value }) ?? value}
          </DropdownMenuItem>
        ))
      )}
    </DropdownMenuContent>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Dropdown: FC<IProps> = ({
  width,
  disabled,
  name,
  placeholder,
  search = true,
  options,
  defaultKey,
  selectedKey,
  defaultOpen,
  onChange,
  onBlur,
  maxHeight,
  dropdownWidth,
  alignment,
  clearText,
  onClear,
  renderDropdownOption,
  modal = true,
  isVirtualized = false,
  custom = false,
  activeText,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const [open, setOpen] = useState(defaultOpen ?? false)
  const [internalKey, setInternalKey] = useState(() => selectedKey ?? defaultKey ?? null)
  const [allOptions, setAllOptions] = useState(() => options)

  useEffect(() => {
    if (isUndefined(selectedKey) || isEmpty(options)) return
    setAllOptions(options)
    setInternalKey(selectedKey)
  }, [selectedKey, options])

  const activeOption = allOptions.find((o) => o.key === internalKey)

  const handleCreateOption = (newOption: IOption) => {
    setAllOptions((prev) => [...prev, newOption])
    setInternalKey(newOption.key)
    onChange?.(newOption)
    setOpen(false)
  }

  return (
    <div>
      <DropdownMenuPrimitive.Root
        open={open}
        modal={modal}
        onOpenChange={(state) => {
          if (!state) {
            onBlur?.()
            setSearchInput('')
          }
          setOpen(state)
        }}>
        <DropdownMenuPrimitive.Trigger
          name={name}
          disabled={disabled}
          className={classNames(
            'border-main focus-visible:border-theme-weak flex h-8',
            'cursor-pointer items-center justify-between rounded-md',
            'bg-void border border-solid px-3 text-left outline-none',
            'data-disabled:bg-weak-2',
            'truncate'
          )}
          style={{ width: width ?? '200px' }}>
          {activeText ? (
            activeText
          ) : activeOption?.value ? (
            Array.isArray(activeOption.value) ? (
              activeOption.value.join(' ')
            ) : (
              activeOption.value
            )
          ) : (
            <div className='text-main'>{placeholder ?? 'Select a value'}</div>
          )}

          {!open ? (
            <CaretDownIcon width={14} height={14} className='text-main' />
          ) : (
            <CaretDownIcon width={14} height={14} className='text-main rotate-180' />
          )}
        </DropdownMenuPrimitive.Trigger>

        <DropdownMenuPrimitive.Portal>
          <MenuContent
            {...{
              maxHeight,
              dropdownWidth,
              options: allOptions,
              onChange,
              search,
              searchInput,
              setSearchInput,
              internalKey,
              setInternalKey,
              alignment,
              clearText,
              onClear,
              isVirtualized,
              custom,
              handleCreateOption,
              renderDropdownOption,
            }}
          />
        </DropdownMenuPrimitive.Portal>
      </DropdownMenuPrimitive.Root>
    </div>
  )
}

export type { IOption }
export {
  Dropdown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  MenuContent,
}
