import { IInterval } from '@invisible/common/types'
import { useQuery } from '@invisible/trpc/client'
import { format } from 'date-fns'
import { filter, flow, includes, isEmpty, lowerCase, map, some } from 'lodash/fp'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { REPORT_FAC } from '../../constants'
import { Dropdown } from '../../dropdown'
import { SmallCheckbox } from '../../form'
import { Input } from '../../input'
import { LogoSpinner } from '../../logo-spinner'
import { DateCell } from '../../react-table'
import { TagInput } from '../../reports/tag-input'
import {
  ICheckBoxItem,
  IFilterInputProps,
  IList,
  IOption,
  IRange,
  ISearchableList,
  ISimpleSelect,
} from './types'

const MAX_CHECKBOX_COUNT = 5

// eslint-disable-next-line @typescript-eslint/ban-types
const SingleInput: React.FC<IFilterInputProps> = ({ type, value, onChange }) => (
  <div className='mb-2'>
    <Input
      type={type as never}
      value={(value as string) ?? ''}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
      }}
      hasMinWidth={false}
      autoFocus={false}
      backgroundColor='white'
    />
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const DualInput: React.FC<IFilterInputProps> = ({ type, value, onChange }) => {
  const castedValue = (value ?? { from: null, to: null }) as IRange

  return (
    <div className='mb-2 flex gap-1'>
      <Input
        type={type as never}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange({ ...(castedValue as IInterval), from: e.target.value })
        }
        value={(castedValue.from ?? '') as string}
        hasMinWidth={false}
        autoFocus={false}
        backgroundColor='white'
      />
      <Input
        type={type as never}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange({ ...(castedValue as IInterval), to: e.target.value })
        }
        value={(castedValue.to ?? '') as string}
        hasMinWidth={false}
        autoFocus={false}
        backgroundColor='white'
      />
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CheckBoxItem: React.FC<ICheckBoxItem> = ({ checked, label, onClick }) => (
  <li className='mb-1 flex cursor-pointer' onClick={() => onClick(!checked)}>
    <SmallCheckbox checked={checked} onClick={(value) => onClick(!value)}>
      {label}
    </SmallCheckbox>
  </li>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const CheckBoxList: React.FC<IList> = ({ items, onChange, value }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const formattedItems: IOption[] = useMemo(
    () =>
      items.map((item: string) => ({
        key: item,
        value: (value as string[])?.length
          ? some((val: string) => val === item)(value as string[])
          : false,
      })),
    [value]
  )

  const checkAll = () => onChange(items)

  const resetSelections = () => onChange([])

  const handleCheckBoxSelect = (updatedValue: boolean, key: string) => {
    const item = formattedItems.find((item) => item.key === key)
    const updatedItems = flow(
      filter((item: IOption) => item.key !== key),
      (filteredItems) => [...filteredItems, { ...item, value: updatedValue }]
    )(formattedItems)

    onChange(updatedItems.filter((item) => item.value).map((item) => item.key) as string[])
  }

  return (
    <div className='flex flex-col gap-2'>
      <div>
        <TagInput
          tags={(value as string[]) ?? []}
          onTagChange={onChange}
          placeholder='Search for'
          inputText={searchTerm}
          onInputChange={setSearchTerm}
        />
      </div>
      {!isEmpty(items) && (
        <div className='text-theme-main flex gap-2 text-xs'>
          <span onClick={checkAll} className='hover: cursor-pointer text-blue-700'>
            Check All
          </span>
          <span onClick={resetSelections} className='hover: cursor-pointer text-blue-700'>
            Reset
          </span>
        </div>
      )}
      <ul className='scrollbar-hide m-0 max-h-[270px] list-none overflow-auto whitespace-nowrap p-[1px_0_0_1px]'>
        {flow(
          filter((item: IOption) =>
            !isEmpty(searchTerm) ? includes(lowerCase(searchTerm))(lowerCase(item.key)) : true
          ),
          map((item: IOption) => (
            <CheckBoxItem
              onClick={(updatedValue) => handleCheckBoxSelect(updatedValue, item.key)}
              key={item.key}
              label={item.key}
              checked={item.value}
            />
          ))
        )(formattedItems)}
      </ul>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SearchableCheckBoxList: React.FC<ISearchableList> = ({
  onChange,
  value,
  filterKey,
  sourceInfo,
}) => {
  const [page, setPage] = useState(1)

  const { data = [], isLoading } = useQuery(
    [
      'report.getColumnValues',
      // @ts-expect-error Bad Typings
      {
        ...sourceInfo,
        reportName: sourceInfo?.reportName || '',
        page,
        limit: 10,
        columnName: filterKey,
      },
    ],
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  if (isLoading && isEmpty(data))
    return (
      <div className='flex h-full w-full items-center justify-center'>
        <LogoSpinner width={12} height={12} />
      </div>
    )

  return (
    <div className='flex-col gap-2 pt-1'>
      <CheckBoxList items={data} onChange={onChange} value={value} />
      {data.length >= MAX_CHECKBOX_COUNT && !isLoading && (
        <div
          className='text-theme-main cursor-pointer text-xs hover:text-blue-700'
          onClick={() => setPage((prev) => prev + 1)}>
          Show More
        </div>
      )}
      {isLoading && (
        <div className='flex h-full w-full items-center justify-center'>
          <LogoSpinner width={12} height={12} />
        </div>
      )}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const SimpleSelect: React.FC<ISimpleSelect> = ({ onChange, options, value }) => (
  <div className='mb-2'>
    <Dropdown
      maxHeight='150px'
      width='146px'
      search={false}
      options={options}
      onChange={(option) => onChange(option.value)}
      selectedKey={
        value != null ? options.find((op) => op.key === String(value))?.key : options[0].key
      }
    />
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const BooleanSelect: React.FC<IFilterInputProps> = ({ onChange, value }) => (
  <div className='mb-2'>
    <SimpleSelect
      options={[
        { key: 'true', value: 'true' },
        { key: 'false', value: 'false' },
      ]}
      onChange={(value) => onChange(Boolean(value))}
      value={value}
    />
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const NullSelect: React.FC<IFilterInputProps> = ({ onChange }) => {
  useEffect(() => onChange(null), [])
  return null
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DateSingleInput: React.FC<IFilterInputProps> = ({ value, onChange }) => (
  <div className='mb-2'>
    <DateCell
      forceUtcDate={true}
      width='100%'
      value={value ? new Date(value as string) : null}
      onChange={(date) => onChange(format(date ?? new Date(), 'yyyy-MM-dd'))}
    />
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const DateRangeInput: React.FC<IFilterInputProps> = ({ value, onChange }) => {
  const castedValue = (value ?? { from: null, to: null }) as IRange
  return (
    <div className='mb-2 text-sm'>
      <div>
        from:
        <div>
          <DateCell
            width='173.5px'
            onChange={(date) =>
              onChange({
                ...((castedValue as IInterval) ?? {}),
                from: format(date ?? new Date(), 'yyyy-MM-dd'),
              })
            }
            value={castedValue.from ? new Date(castedValue.from) : null}
          />
        </div>
      </div>
      <div>
        to:
        <div>
          <DateCell
            width='173.5px'
            onChange={(date) =>
              onChange({
                ...((castedValue as IInterval) ?? {}),
                to: format(date ?? new Date(), 'yyyy-MM-dd'),
              })
            }
            value={castedValue.to ? new Date(castedValue.to) : null}
          />
        </div>
      </div>
    </div>
  )
}

export const FilterAtomicComponents = {
  [REPORT_FAC.generic_input]: SingleInput,
  [REPORT_FAC.generic_range]: DualInput,
  [REPORT_FAC.checkbox_list]: SearchableCheckBoxList,
  [REPORT_FAC.boolean_select]: BooleanSelect,
  [REPORT_FAC.null_select]: NullSelect,
  [REPORT_FAC.date_single]: DateSingleInput,
  [REPORT_FAC.date_range]: DateRangeInput,
}
