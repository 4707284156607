// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { combineRefs } from '@invisible/common/helpers'
import { classNames } from '@invisible/common/helpers'
import { motion } from 'framer-motion'
import {
  createContext,
  CSSProperties,
  FC,
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import ResizeObserver from 'resize-observer-polyfill'

import { Button } from '../button'
import { CloseIcon, TIconName } from '../icons'
import { Icons } from '../icons'

type TSize = 'small' | 'medium' | 'large'

export interface ITabs {
  centered?: boolean
  value?: string | number
  onChange?: (tab: string | number) => void
  orientation?: 'vertical' | 'horizontal'
  onTabAdd?: () => void
  onTabDelete?: (tab: string | number) => void
  onReorder?: (tabs: string[]) => void
  buttons?: boolean
  size?: TSize
  draggable?: boolean
  children: Record<'props', ITab>[]
  brandColors?: boolean
  tabsBackgroundColor?: string
  stickyPosition?: boolean
  currentActiveTab?: string | null
  headerWidth?: string
  style?: CSSProperties
}

export interface ITab {
  icon?: TIconName | JSX.Element
  disabled?: boolean
  value: string | number
  name: string | number
  customHeader?: JSX.Element
  preserveWhenInactive?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Container: React.FC<{ horizontal: boolean }> = ({ horizontal, children }) => (
  <div
    data-cy='tabsContainer'
    className={classNames('flex flex-auto pl-4', `${horizontal ? 'flex-col' : 'flex-row'}`)}>
    {children}
  </div>
)
interface IHeaderWrapperProps {
  centered: boolean
  horizontal: boolean
  size: TSize
  canScrollLeft: boolean
  canScrollRight: boolean
  brandColors: boolean
  stickyPosition: boolean
  tabsBackgroundColor?: string
  headerWidth?: string
  style?: CSSProperties
  children: ReactNode
}

const HeaderWrapper = forwardRef<HTMLDivElement, IHeaderWrapperProps>(
  (
    {
      children,
      headerWidth,
      style,
      centered,
      horizontal,
      size,
      canScrollLeft,
      canScrollRight,
      brandColors,
      stickyPosition,
      tabsBackgroundColor,
    },
    ref
  ) => (
    <div
      data-cy='tabsHeaderContainer'
      ref={ref}
      style={{
        boxShadow: !horizontal ? 'none' : generateBoxShadow(canScrollLeft, canScrollRight),
        width: headerWidth ?? 'auto',
        backgroundColor: tabsBackgroundColor && !brandColors ? tabsBackgroundColor : 'white',
        ...(style ?? {}),
      }}
      className={classNames(
        'scrollbar-hide top-0 z-[0] box-border items-center gap-8 overflow-x-auto overflow-y-visible',
        `${
          !horizontal
            ? 'inline-flex flex-col border-r border-solid border-[color:#f0f0f0]'
            : `flex flex-row border-r-0`
        }`,
        `${
          !horizontal
            ? 'auto'
            : size === 'medium'
            ? 'h-[48px]'
            : size === 'small'
            ? 'h-[38px]'
            : 'h-[55px]'
        }`,
        `${centered ? 'justify-center' : 'justify-start'}`,
        `${stickyPosition ? 'sticky' : 'relative'}`,
        `${brandColors ? 'bg-theme-main' : ''}`,
        `${brandColors ? 'pl-5' : 'pl-0'}`
      )}>
      {children}
    </div>
  )
)
interface ITabHeader {
  hasCustomHeader: boolean
  horizontal: boolean
  buttons: boolean
  brandColors: boolean
  draggable: boolean
  onClick: () => void
  tabValue: string | number
  activeTab: string | number
  disabled?: boolean
  children: ReactNode
}

const TabHeader = forwardRef<unknown, ITabHeader>(
  (
    {
      hasCustomHeader,
      horizontal,
      buttons,
      draggable,
      onClick,
      tabValue,
      activeTab,
      disabled,
      children,
    },
    ref
  ) => {
    const classes = {
      disabled: `pointer-events-none cursor-not-allowed opacity-30 ${buttons ? 'bg-gray-400' : ''}`,
      selected: `text-theme-main font-medium ${!buttons ? 'border-b-0' : 'border-b border-solid'} ${
        buttons ? 'bg-theme-weak-3' : 'bg-transparent'
      }`,
      unselected: ` -z-[1] ${'hover:fill-theme-main text-[color:#423f4c] hover:text-theme-main'} ${
        buttons ? 'border border-solid border-gray-200' : 'border-none'
      }`,
    }
    return (
      <div
        ref={ref as any}
        onClick={onClick}
        key={tabValue}
        id={tabValue as string}
        data-testid={tabValue as string}
        className={classNames(
          'relative box-border flex cursor-pointer items-center leading-[100%] outline-none',
          `${!draggable && !hasCustomHeader ? 'py-0' : 'p-0'}`,
          `${horizontal ? 'mr-[5px] h-full w-auto' : 'mr-0 h-10 w-full'}`,
          'rounded-[6px_6px_0px_0px]',
          buttons && activeTab === tabValue ? 'border-theme-weak-2 border border-solid' : '',
          buttons ? 'border-b-0' : '',
          disabled
            ? classes.disabled
            : activeTab === tabValue
            ? classes.selected
            : classes.unselected
        )}>
        {children}
      </div>
    )
  }
)

interface IHorizontalTabSlider {
  left?: number
  right?: number
}
const HorizontalTabSlider = forwardRef<HTMLDivElement, IHorizontalTabSlider>(
  ({ left, right }, ref) => (
    <motion.div
      ref={ref}
      variants={{
        slider: {
          left: left ?? 0,
          right: right ?? 0,
        },
      }}
      animate={['slider']}
      className='absolute bottom-0 flex justify-center'>
      <div className='border-theme-main w-full border-0 border-b-[3px] border-solid' />
    </motion.div>
  )
)

interface IVerticalTabSlider {
  top?: number
  bottom?: number
}

const VerticalTabSlider = forwardRef<HTMLDivElement, IVerticalTabSlider>(({ top, bottom }, ref) => (
  <motion.div
    ref={ref}
    variants={{
      slider: {
        top: top ?? 0,
        bottom: bottom ?? 0,
      },
    }}
    animate={['slider']}
    className={`border-theme-main absolute right-0 h-10 border-0 border-r-2 border-solid`}
  />
))

// Create shadow scroll indicators
const generateBoxShadow = (canScrollLeft: boolean, canScrollRight: boolean) => {
  let shadow = 'inset 0px -1px 0px 0px #ddd9ed'

  if (canScrollLeft) shadow += ', 30px 0 30px -30px #ccc inset'

  if (canScrollRight) shadow += ', -30px 0 30px -30px #ccc inset'

  return shadow
}

const TabsContext = createContext<{
  activeTab: string | number | null
}>({
  activeTab: '',
})

const ConditionalWrap = ({
  condition,
  wrap,
  children,
}: {
  condition: boolean
  wrap: (wrappedChildren: JSX.Element) => JSX.Element
  children: JSX.Element
}) => (condition ? wrap(children) : children)

// eslint-disable-next-line @typescript-eslint/ban-types
const Tabs: FC<ITabs> = (props) => <TabsComponent {...props} />

// eslint-disable-next-line @typescript-eslint/ban-types
const TabsComponent: FC<ITabs> = ({
  children,
  value,
  onChange,
  onTabAdd,
  onTabDelete,
  tabsBackgroundColor,
  centered = false,
  orientation = 'horizontal',
  buttons = false,
  size = 'medium',
  style,
  draggable = false,
  onReorder,
  brandColors = false,
  stickyPosition = false,
  currentActiveTab = null,
  headerWidth,
}) => {
  const [activeTab, setActiveTab] = useState(value ?? children?.[0]?.props?.value)
  const [tabs, setTabs] = useState(() => children.map((o) => o))
  const [refs, setRefs] = useState<HTMLElement[]>([])
  const tabsRef = useRef<HTMLDivElement | null>(null)
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const [root, setRoot] = useState(() => tabsRef.current?.getBoundingClientRect())

  const [canScroll, setCanScroll] = useState({ left: false, right: false })

  useEffect(() => {
    if (currentActiveTab) setActiveTab(currentActiveTab)
  }, [currentActiveTab])

  //Callback ref to get all tab DOM nodes for animated switching
  const ref = useCallback(
    (node) => {
      if (node) setRefs((prev) => [...prev, node])
    },
    [tabs]
  )

  // Get left, right, top and bottom offset of each tab
  const tabsSizes = useMemo(
    () =>
      refs.reduce<Record<string, { left: number; right: number; top: number; bottom: number }>>(
        (acc, curr) => {
          const dimensions = curr.getBoundingClientRect()

          const left = dimensions.left - (root?.left ?? 0)
          const right = (root?.right ?? 0) - dimensions.right
          const top = dimensions.top - (root?.top ?? 0)
          const bottom = dimensions.bottom - (root?.bottom ?? 0)

          acc[curr.id] = { left, right, top, bottom }
          return acc
        },
        {}
      ),
    [refs, root?.bottom, root?.left, root?.right, root?.top]
  )

  const handleTabClick = (tab: string | number) => {
    setActiveTab(tab)
    if (onChange) {
      onChange(tab)
    }
  }

  const handleScroll = () => {
    if (tabsRef.current) {
      setCanScroll({
        left: tabsRef.current?.scrollLeft > 0,
        // 1px added to scrollLeft because of an occasional bug where the scrollLeft is 1px less than it should be even after being fully scrolled
        right:
          tabsRef.current?.scrollWidth - tabsRef.current?.clientWidth >
          tabsRef.current?.scrollLeft + 1,
      })
    }
  }

  const handleReorder = useCallback(
    (source: Record<'props', ITab>, destination: string) => {
      setTabs((prev) => {
        const copy = [...prev]
        const sourceIndex = copy.findIndex((t) => t.props.value === source.props.value)
        const destinationIndex = copy.findIndex((t) => t.props.value === destination)
        copy.splice(sourceIndex, 1)
        copy.splice(destinationIndex, 0, source)
        onReorder?.(copy.map((c) => c.props.value as string))
        return copy
      })
    },
    [setTabs]
  )

  useEffect(() => {
    handleScroll()
    const currentTabsRef = tabsRef.current
    currentTabsRef?.addEventListener('scroll', handleScroll)
    return () => {
      currentTabsRef?.removeEventListener('scroll', handleScroll)
    }
  }, [tabsRef])

  useEffect(() => {
    setTabs(children.map((o) => o))
  }, [children, children.length])

  useEffect(() => {
    if (value) {
      setActiveTab(value)
    }
  }, [value])

  // observes the tab container's dimensions and updates internal state on change
  useEffect(() => {
    if (!tabsRef.current) return
    const observer = new ResizeObserver((o) => setRoot(o[0].target.getBoundingClientRect()))
    observer.observe(tabsRef?.current)
    return () => {
      tabsRef.current && observer.unobserve(tabsRef.current)
    }
  }, [])
  const isHorizontal = orientation === 'horizontal'

  return (
    <TabsContext.Provider value={{ activeTab: activeTab as string }}>
      <Container horizontal={isHorizontal}>
        <HeaderWrapper
          headerWidth={headerWidth}
          style={style}
          stickyPosition={stickyPosition}
          data-cy='tabsHeaderContainer'
          centered={centered}
          horizontal={orientation === 'horizontal'}
          size={size}
          ref={tabsRef}
          canScrollLeft={canScroll.left}
          canScrollRight={canScroll.right}
          brandColors={brandColors}
          tabsBackgroundColor={tabsBackgroundColor}>
          {tabs.map((tab, index) => {
            const ParsedIcon = (
              typeof tab.props.icon === 'string' ? Icons[tab.props.icon] : null
            ) as (props: React.SVGProps<SVGSVGElement>) => JSX.Element
            return (
              <TabHeader
                key={index}
                hasCustomHeader={Boolean(tab.props.customHeader)}
                activeTab={activeTab}
                tabValue={tab.props.value}
                buttons={buttons}
                brandColors={brandColors}
                disabled={tab.props.disabled}
                draggable={draggable}
                horizontal={isHorizontal}
                onClick={() => handleTabClick(tab.props.value)}
                ref={ref}>
                <ConditionalWrap
                  condition={draggable}
                  wrap={(wrappedChildren) => (
                    <TabNameWrapper
                      tab={tab}
                      handleReorder={handleReorder}
                      index={index}
                      draggable={draggable}>
                      {wrappedChildren}
                    </TabNameWrapper>
                  )}>
                  <>
                    {typeof tab.props.icon === 'string' ? (
                      <ParsedIcon
                        className='mr-[10px]'
                        color={activeTab === tab.props.value ? '#604CA5' : '#423F4C'}
                        height={12}
                        width={12}
                      />
                    ) : (
                      tab.props.icon ?? null
                    )}
                    <div className='whitespace-nowrap' title={String(tab.props.name)}>
                      {tab.props.customHeader ? tab.props.customHeader : tab.props.name}
                    </div>
                    {onTabDelete ? (
                      <div
                        className='ml-2 cursor-pointer border-none bg-transparent outline-none'
                        onClick={(e) => {
                          e.stopPropagation()
                          onTabDelete?.(tab.props.value)
                        }}>
                        <CloseIcon width={15} height={15} color='#A19FA5' />
                      </div>
                    ) : null}
                  </>
                </ConditionalWrap>
              </TabHeader>
            )
          })}
          {onTabAdd ? (
            <div className='min-w-[35px]'>
              <Button
                variant='secondary'
                size='md'
                icon='PlusIcon'
                shape='square'
                onClick={onTabAdd}
              />
            </div>
          ) : null}
          {/* Horizontal tab slider */}
          {Object.keys(tabsSizes).length && !buttons && orientation !== 'vertical' ? (
            <HorizontalTabSlider
              ref={sliderRef}
              left={tabsSizes[activeTab]?.left}
              right={tabsSizes[activeTab]?.right}
            />
          ) : null}
          {/* Vertical tab slider */}
          {Object.keys(tabsSizes).length && !buttons && orientation === 'vertical' ? (
            <VerticalTabSlider
              ref={sliderRef}
              top={tabsSizes[activeTab]?.top}
              bottom={tabsSizes[activeTab]?.bottom}
            />
          ) : null}
        </HeaderWrapper>

        {children}
      </Container>
    </TabsContext.Provider>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const TabNameWrapper: FC<{
  tab: Record<'props', ITab>
  handleReorder: (source: Record<'props', ITab>, destination: string) => void
  index: number
  draggable: boolean
}> = ({ children, tab, handleReorder, index, draggable }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'tab',
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: {
        tab,
        index,
      },
    }),
    [tab]
  )

  const [, drop] = useDrop(
    () => ({
      accept: 'tab',
      hover: (
        item: {
          tab: Record<'props', ITab>
          index: number
        },
        monitor
      ) => {
        if (item.tab.props.value === tab.props.value) return

        const dragIndex = item.index
        const hoverIndex = index
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        if (!hoverBoundingRect) return
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
        const clientOffset = monitor.getClientOffset()
        const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left

        // Dragging right
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return
        }

        // Dragging left
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return
        }

        handleReorder(item.tab, tab.props.value as string)
        item.index = hoverIndex
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [handleReorder, index]
  )
  const opacity = isDragging ? 0 : 1

  return (
    <div
      ref={draggable ? combineRefs<HTMLDivElement>(drag, drop, ref) : null}
      style={{
        opacity,
      }}
      className={`flex h-full w-full items-center px-4`}>
      {children}
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Tab: FC<ITab> = ({ children, value, preserveWhenInactive }) => {
  const { activeTab } = useContext(TabsContext)

  const selected = activeTab === value

  if (!selected && !preserveWhenInactive) return null

  return (
    <div data-cy='tab' className={!selected ? 'hidden' : 'block flex-auto'}>
      {children}
    </div>
  )
}

Object.assign(Tabs, { Tab })

export { Tab, Tabs }
