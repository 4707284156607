import { classNames } from '@invisible/common/helpers'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { ReactNode } from 'react'

interface ITooltip {
  children: ReactNode
  color?: 'dark' | 'light'
  arrow?: boolean
  align?: 'start' | 'center' | 'end'
  side?: 'top' | 'right' | 'bottom' | 'left'
  content: string | ReactNode
  open?: boolean
}

const ColorStyles = {
  dark: 'bg-header text-void fill-header',
  light: 'bg-void text-header fill-void',
}

const Tooltip = ({
  children,
  color = 'light',
  align = 'start',
  side = 'top',
  arrow,
  content,
  open,
}: ITooltip) => (
  <TooltipPrimitive.Provider>
    <TooltipPrimitive.Root open={open}>
      <TooltipPrimitive.Trigger asChild>
        <div className='cursor-default'>{children}</div>
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          side={side}
          align={align}
          sideOffset={6}
          className={classNames(
            'data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade',
            'data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade',
            ColorStyles[color],
            'border-main z-[2147483647] select-none rounded border border-solid px-2.5 py-1.5 text-xs shadow-sm will-change-[transform,opacity]'
          )}>
          {content}
          {arrow ? <TooltipPrimitive.Arrow className={ColorStyles[color]} /> : null}
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  </TooltipPrimitive.Provider>
)

export { Tooltip }
