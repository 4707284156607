import { DiffEditor } from '@monaco-editor/react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { editor } from 'monaco-editor'

import { DiffViewerProps } from '../types'

const diffEditorOptions: editor.IDiffEditorConstructionOptions = {
  renderSideBySide: true,
  readOnly: true,
  minimap: { enabled: false },
  scrollbar: {
    vertical: 'visible',
    horizontal: 'auto',
  },
  scrollBeyondLastLine: false,
}

const DiffViewer = ({ originalText, editedText }: DiffViewerProps) => (
  <Paper
    elevation={2}
    sx={{
      height: '350px',
      p: 2,
      borderRadius: 2,
      backgroundColor: '#ffffff',
    }}>
    <Stack
      direction='row'
      justifyContent='space-between'
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        pb: 1,
        mb: 2,
      }}>
      <Typography variant='subtitle1' sx={{ fontWeight: 500, color: 'text.primary' }}>
        Original
      </Typography>
      <Typography variant='subtitle1' sx={{ fontWeight: 500, color: 'text.primary' }}>
        Edited
      </Typography>
    </Stack>
    <Box sx={{ height: '100%' }}>
      <DiffEditor
        height='100%'
        language='markdown'
        original={originalText}
        modified={editedText}
        theme='vs-light'
        options={diffEditorOptions}
      />
    </Box>
  </Paper>
)

export { DiffViewer }
