import { Dropdown as UIDropdown } from '@invisible/ui/dropdown'

type FieldProps = {
  value: string
  isReadOnly: boolean
}

const Dropdown = ({ value, isReadOnly }: FieldProps) => (
  <UIDropdown
    width='100%'
    selectedKey={value}
    options={[]}
    disabled={isReadOnly}
    placeholder={'placeholder'}
  />
)

export { Dropdown }
