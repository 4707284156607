import { FC } from 'react'

import { NullSwitch } from '../../null-switch'

interface IBooleanCellProps {
  isOn?: boolean | null
  onToggle: (value: boolean | null) => void
  disabled?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const BooleanCell: FC<IBooleanCellProps> = (props) => <NullSwitch {...props} />
