import AudioFileIcon from '@mui/icons-material/AudioFile'
import DescriptionIcon from '@mui/icons-material/Description'
import { Box, Button, CardMedia, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import AudioPlayer from '../sub-components/AudioPlayer'
import { IContent } from '../types'

interface ThumbnailProps {
  type: string
  url: string
  className?: string
}

const Thumbnail = ({ type, url, className }: ThumbnailProps) => {
  if (type === 'image_url') {
    return (
      <Box className={className}>
        <CardMedia component='img' image={url} alt='Response' sx={{ width: '100%' }} />
      </Box>
    )
  }

  let fileIcon = <DescriptionIcon style={{ fontSize: 48 }} />

  if (type === 'audio_url') {
    fileIcon = <AudioFileIcon style={{ fontSize: 48 }} />
  }

  return (
    <Stack alignItems='center'>
      {fileIcon}
      <Typography
        variant='body2'
        color='textSecondary'
        noWrap
        sx={{ width: '100%', textAlign: 'left' }}>
        {url.split('/').pop()}
      </Typography>
    </Stack>
  )
}

interface FileDisplayProps {
  type: string
  url: string
}

const FileDisplay = ({ type, url }: FileDisplayProps) => {
  if (type === 'image_url') {
    return (
      <Box sx={{ m: 2, height: '50%', width: 'fit-content', overflow: 'hidden' }}>
        <CardMedia
          component='img'
          image={url}
          alt={url}
          sx={{
            maxHeight: '400px',
            padding: '4px',
            borderRadius: 1,
            border: 2,
            borderColor: 'grey.200',
          }}
        />
      </Box>
    )
  }

  if (type === 'audio_url') {
    return <AudioPlayer url={url} />
  }

  return (
    <Box
      sx={{
        m: 2,
        borderRadius: 1,
        border: 2,
        borderColor: 'grey.400',
        maxHeight: '400px',
        width: 'fit-content',
        overflow: 'hidden',
        bgcolor: 'var(--color-agent-weak-3)',
      }}>
      <Stack alignItems='center' sx={{ p: 2 }}>
        <Typography variant='body2' color='textSecondary' noWrap sx={{ mt: 0 }}>
          Trouble displaying file, click to view
        </Typography>
        <Button
          sx={{ mt: 1 }}
          variant='contained'
          color='primary'
          onClick={() => window.open(url, '_blank')}>
          View
        </Button>
      </Stack>
    </Box>
  )
}

interface MultimodalDisplayProps {
  content: IContent[]
  width?: string
}

const MultimodalDisplay = ({ content }: MultimodalDisplayProps) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null)

  const handleClick = (index: number) => {
    setSelectedItem(index)
    if (selectedItem === index) {
      setSelectedItem(null)
    }
  }

  return (
    <Stack>
      <Box sx={{ display: 'flex' }}>
        {content?.map((item, index) => {
          const outlineColor = selectedItem === index ? 'var(--color-theme-weak)' : 'transparent'
          return (
            <Box
              key={index}
              sx={{
                m: 1,
                borderRadius: 1,
                border: 2,
                borderColor: 'grey.200',
                width: 64,
                height: 64,
                overflow: 'hidden',
                outline: selectedItem === index ? 2 : 0,
                outlineColor: outlineColor,
                cursor: 'pointer',
              }}
              onClick={() => handleClick(index)}>
              <Thumbnail key={index} type={item.type} url={item.url} />
            </Box>
          )
        })}
      </Box>
      <Box>
        {content?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: selectedItem === index ? 'block' : 'none',
            }}>
            <FileDisplay type={item.type} url={item.url} />
          </Box>
        ))}
      </Box>
    </Stack>
  )
}

export default MultimodalDisplay
