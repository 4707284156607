import MUITextField from '@mui/material/TextField'
import { Controller, useFormContext } from 'react-hook-form'

type FieldProps = {
  field: Record<string, any>
}

const TextField = ({ field }: FieldProps) => {
  const {
    formState: { errors },
  } = useFormContext()

  const { id, label, placeholder, required, isReadOnly } = field

  return (
    <Controller
      name={id}
      render={({ field }) => (
        <MUITextField
          size='small'
          fullWidth
          label={label}
          placeholder={placeholder}
          required={required}
          disabled={isReadOnly}
          error={!!errors[id]}
          helperText={errors[id]?.message}
          {...field}
        />
      )}
    />
  )
}

export { TextField }
