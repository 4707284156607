import { logger } from '@invisible/logger/client'
import { useMutation as useTrpcMutation, useQuery } from '@invisible/trpc/client'
import { CopyButton } from '@invisible/ui/copy-button'
import { format } from '@invisible/ui/helpers'
import { adminTheme, chipVariantIcons } from '@invisible/ui/mui-theme-v2'
import { useToasts } from '@invisible/ui/toasts'
import CheckBox from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  DataGridPro,
  GridColDef,
  GridInitialState,
  GridRenderEditCellParams,
  GridRowsProp,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'

import OnboardCompanyModal from './components/OnboardCompanyModal'
import { Company } from './types'

interface CompaniesTableProps {
  pageSize?: 10 | 25 | 50 | 75 | 100
}

export const CompaniesTable = ({ pageSize = 25 }: CompaniesTableProps) => {
  const { addToast } = useToasts()
  const router = useRouter()

  const [modalOpen, setModalOpen] = useState(false)
  const [managers, setManagers] = useState<Record<string, string[]>>({})

  const { data: companies, isLoading } = useQuery(['company.findMany'])
  const { data: allUsers } = useQuery(['user.findMany'])
  const { data: allCompaniesReportData, isLoading: allCompaniesReportDataIsLoading } = useQuery([
    'report.companiesOverview',
  ])
  const { mutateAsync: updateCompanyManagers } = useTrpcMutation(
    'company.updateCompanyManagers',
    {}
  )

  useMemo(() => {
    let obj = {}
    companies?.forEach((company) => {
      const data: Record<string, string[]> = {}
      company?.companyToOperationsManager?.forEach((opManager) => {
        const name = opManager.user?.preferredName ?? opManager.user?.name
        if (!name) return
        if (!data[company.id]) data[company.id] = []
        data[company.id]?.push(name)
      })
      obj = {
        ...obj,
        ...data,
      }
    })
    setManagers({
      ...obj,
    })
  }, [companies])

  const getManagers = (company: Company) => {
    const data: { name: string; id: string }[] = []
    company?.companyToOperationsManager?.forEach((opManager) => {
      const name = opManager.user?.preferredName ?? opManager.user?.name
      const id = opManager?.user?.id
      if (!name || !id) return
      data.push({ name, id })
    })
    return data
  }

  const tableData: GridRowsProp = useMemo(
    () =>
      (companies || []).map((company) => ({
        ...company,
        operationsManager: getManagers(company) ?? [],
      })),
    [companies]
  )

  const initialState: GridInitialState = {
    pagination: { paginationModel: { pageSize } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pinnedColumns: { left: ['id', 'name'] },
    filter: {
      filterModel: {
        items: [{ field: 'status', operator: 'is', value: 'active' }],
      },
    },
  }

  const CustomEditComponent = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props
    const apiRef = useGridApiContext()
    const sortedOptions = useMemo(
      () =>
        allUsers &&
        allUsers
          .filter((user) => user.email.endsWith('@invisible.email'))
          .sort((a, b) => {
            const order = 1
            const firstName = a.name ?? ''
            const secondName = b.name ?? ''

            // first sorting based on selected users
            if (managers[id]?.indexOf(firstName) > -1 && managers[id]?.indexOf(secondName) === -1) {
              return -1 * order
            } else if (
              managers[id]?.indexOf(secondName) > -1 &&
              managers[id]?.indexOf(firstName) === -1
            ) {
              return 1 * order
            }
            // final sorting based on name alphabetically
            else {
              return order * firstName.localeCompare(secondName)
            }
          }),
      [id]
    )
    const handleChange = useCallback(
      (event: React.SyntheticEvent<Element, Event>, newValue: { name: string; id: string }[]) => {
        event.stopPropagation()
        apiRef.current.setEditCellValue({ id: id, field: field, value: newValue })
      },
      [apiRef, id, field]
    )

    const getValue = useCallback(() => {
      if (value) return value

      return []
    }, [value])

    return (
      <Autocomplete
        id='demo-multiple-name'
        multiple
        limitTags={0}
        value={getValue() ?? []}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        disableCloseOnSelect
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} placeholder='Users' />}
        getOptionLabel={(option) => option.name ?? ''}
        options={sortedOptions ?? []}
        groupBy={(option) =>
          option?.name && managers[id]?.indexOf(option.name) > -1
            ? 'Current Selections'
            : 'Sort A-Z'
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize='small' />}
              checkedIcon={<CheckBox fontSize='small' />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            'flex-wrap': 'wrap !important',
          },
          '& .MuiInputBase-root': { height: '4rem' },
          width: '100%',
        }}
      />
    )
  }
  const CustomDiscountEditCell = (params: GridRenderEditCellParams) => (
    <CustomEditComponent {...params} />
  )

  const tableColumns: GridColDef[] = [
    {
      field: 'id',
      display: 'flex',
      headerName: 'ID',
      renderCell: ({ row: { id } }) => (
        <CopyButton message='ID successfully copied to clipboard' text={id} />
      ),
      width: 50,
    },
    {
      field: 'name',
      display: 'flex',
      headerName: 'Name',
      align: 'left',
      width: 200,
      renderCell: ({ row: { id, name } }) => (
        <Button variant='text' onClick={() => router.push(`/companies/${id}`)}>
          {name}
        </Button>
      ),
    },
    {
      field: 'status',
      display: 'flex',
      headerName: 'Status',
      align: 'left',
      renderCell: ({ row: { status } }) => (
        <Chip variant={status} icon={chipVariantIcons[status].icon} label={status} />
      ),
      width: 150,
      type: 'singleSelect',
      valueOptions: [
        'prospect',
        'piloting',
        'onboarding',
        'active',
        'demo',
        'inactive',
        'offboarding',
        'churned',
      ],
    },
    {
      field: 'ownerName',
      display: 'flex',
      headerName: 'Owner Name',
      align: 'left',
      width: 200,
      valueGetter: (_, { owner }) => owner?.name ?? '-',
    },
    {
      field: 'ownerEmail',
      display: 'flex',
      headerName: 'Owner Email',
      align: 'left',
      width: 250,
      renderCell: ({ row: { owner } }) =>
        owner?.email ? (
          <>
            <CopyButton message='Email successfully copied to clipboard' text={owner.email} />
            <Typography variant='body2' noWrap>
              {owner.email}
            </Typography>
          </>
        ) : (
          '-'
        ),
    },
    {
      field: 'lifetimeRevenue',
      display: 'flex',
      headerName: 'Lifetime Revenue',
      align: 'left',
      width: 200,
      type: 'number',
      headerAlign: 'left',
      valueGetter: (_, { id }) => {
        const companyReportData = allCompaniesReportData?.find(
          (company) => company?.manticore_company_id === id
        )
        return companyReportData?.lifetime_usage_revenue || 0
      },
      renderCell: ({ row: { id, createdAt } }) => {
        const companyReportData = allCompaniesReportData?.find(
          (company) => company?.manticore_company_id === id
        )

        const lifetimeRevenue = companyReportData?.lifetime_usage_revenue

        return lifetimeRevenue ? (
          <Stack justifyContent='space-between'>
            <Typography variant='body2'>{format.formatCurrency(lifetimeRevenue)}</Typography>
            {/* The createdAt datum here is the date when
            the company was added to Ultron */}
            <Chip label={createdAt ? `since ${new Date(createdAt).toDateString()}` : ''} />
          </Stack>
        ) : (
          <Typography variant='body2'>$0.00</Typography>
        )
      },
    },
    {
      field: 'stripeCustomerId',
      display: 'flex',
      headerName: 'Stripe ID',
      align: 'left',
      sortable: false,
      filterable: true,
      type: 'boolean',
      headerAlign: 'left',
      valueGetter: (_, { stripeCustomerId }) => stripeCustomerId != null,
      renderCell: ({ row: { stripeCustomerId } }) =>
        stripeCustomerId ? (
          <CopyButton
            message='Stripe Customer ID successfully copied to clipboard'
            text={stripeCustomerId}
          />
        ) : (
          '-'
        ),
      width: 100,
    },
    {
      field: 'xeroContactId',
      display: 'flex',
      headerName: 'Xero ID',
      align: 'left',
      sortable: false,
      filterable: true,
      type: 'boolean',
      headerAlign: 'left',
      valueGetter: (_, { xeroContactId }) => xeroContactId != null,
      renderCell: ({ row: { xeroContactId } }) =>
        xeroContactId ? (
          <CopyButton
            message='Xero Contact ID successfully copied to clipboard'
            text={xeroContactId}
          />
        ) : (
          '-'
        ),
      width: 100,
    },
    {
      field: 'operationsManager',
      display: 'flex',
      headerName: 'Operations Manager',
      width: 220,
      editable: true,
      type: 'singleSelect',
      valueOptions: allUsers && allUsers.filter((user) => user.email.endsWith('@invisible.email')),
      renderEditCell: CustomDiscountEditCell,
      valueFormatter: (value: { name: string }[]) =>
        value?.length > 0 ? value?.map((item) => item?.name) : '',
    },
  ]

  return (
    <ThemeProvider theme={adminTheme}>
      <Stack spacing={2}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack spacing={2}>
            <Typography variant='h6' fontWeight='bold'>
              Companies
            </Typography>
            <Typography variant='body2'>Who do we work for?</Typography>
          </Stack>
          <Button variant='contained' size='medium' onClick={() => setModalOpen(true)}>
            Onboard Company
          </Button>
        </Stack>

        <Box height='calc(100vh - 300px)' overflow='auto' width='100%'>
          <DataGridPro
            sx={{ overflow: 'auto' }}
            initialState={initialState}
            checkboxSelection={false}
            rows={tableData ?? []}
            columns={tableColumns}
            pageSizeOptions={[10, 25, 50, 75, 100]}
            processRowUpdate={(updatedRow) => {
              const operationsManagerIds: string[] = updatedRow.operationsManager?.map(
                (item: { id: string; name: string }) => item.id
              )
              const operationsManagerName: string[] = updatedRow.operationsManager?.map(
                (item: { id: string; name: string }) => item.name
              )

              setManagers({ ...managers, [updatedRow.id]: operationsManagerName })
              updateCompanyManagers({
                id: updatedRow.id,
                operationsManagerIds,
              })
              return updatedRow
            }}
            onProcessRowUpdateError={(error) => {
              addToast('Failed to update company', { appearance: 'error' })
              logger.error('Failed to update company managers', { error })
            }}
            loading={isLoading || allCompaniesReportDataIsLoading}
            slots={{
              ...adminTheme.components?.MuiDataGrid?.defaultProps?.slots,
            }}
          />
        </Box>
        <OnboardCompanyModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </Stack>
    </ThemeProvider>
  )
}
