// import { CodeWAC } from '@invisible/common/components/process-base'

type FieldProps = {
  value: string
  isReadOnly: boolean
}

const CodeField = ({ value, isReadOnly }: FieldProps) => (
  // <CodeWAC readOnly={isReadOnly} code={value} onChangeMethod={() => {}} />
  <p>{value}</p>
)

export { CodeField }
