import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import classNames from 'classnames'

type CustomSelectProps = {
  onSelect: (value: string) => void
  disabled?: boolean
  selectedKey: string
  options: { key: string; value: string | number }[]
}
const CustomSelect = ({ onSelect, disabled, selectedKey, options }: CustomSelectProps) => (
  <Select
    className={classNames('flex justify-between', disabled ? 'cursor-not-allowed' : '')}
    disabled={disabled}
    value={selectedKey}
    displayEmpty
    size='small'
    onChange={(event: SelectChangeEvent) => onSelect(event.target.value)}>
    {options.map((option, index) => (
      <MenuItem
        className='relative flex items-center justify-center p-1'
        key={index}
        value={option.value}>
        {option.key}
      </MenuItem>
    ))}
  </Select>
)

export { CustomSelect }
