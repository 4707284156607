import { Flex } from 'rebass'

import { Modal } from './ui-modal'

const NoAccessModal = ({
  modalsToClose,
  modalOpen,
}: {
  modalOpen: boolean
  modalsToClose: Array<(value: boolean) => void>
}) => {
  const handleClose = () => {
    modalsToClose.map((modalToClose: (value: boolean) => void) => {
      modalToClose(false)
    })
  }

  return (
    <Modal onClose={handleClose} visible={modalOpen} title='Forbidden'>
      <Flex flexDirection='column'>
        <Flex className={`text-xl font-semibold text-black`} flexDirection='row'>
          Sorry you do not have access
        </Flex>
      </Flex>
    </Modal>
  )
}

export { NoAccessModal }
