import type {
  TCommentTree,
  TQuestionnaireAnswerEntries,
  TQuestionTree,
} from '@invisible/common/types'
import { sendErrorToSentry } from '@invisible/errors'
import { useContext, useMutation } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { TextArea } from '@invisible/ui/form'
import { HistoryIcon, ReplyIcon } from '@invisible/ui/icons'
import { Modal } from '@invisible/ui/modal'
import { TUuid } from '@invisible/zod'
import * as dateFns from 'date-fns'
import { capitalize } from 'lodash/fp'
import { FC, ReactElement, useMemo, useState } from 'react'

import { MultiChoice, SingleChoice } from '../'
import { OpenText } from '../OpenText'

export interface ICommentProps {
  comment: TCommentTree
  question: TQuestionTree
  isDPOView: boolean
  userId: TUuid
  questionnaireRunId: TUuid
  onResolverClick: (replyToId: TUuid) => void
  hasUnresolved: boolean
  handleQuestionInOpenModal: (id: TUuid) => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Comment: FC<ICommentProps> = ({
  comment,
  question,
  isDPOView,
  questionnaireRunId,
  userId,
  onResolverClick,
  hasUnresolved,
  handleQuestionInOpenModal,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [reply, setReply] = useState('')
  const [modalType, setModalType] = useState<'reply' | 'snapshot'>()
  const [isSending, setIsSending] = useState(false)
  const [isIssue, setIsIssue] = useState(false)

  const reactQueryContext = useContext()

  const { mutateAsync: createComment } = useMutation('questionnaireComment.createComment', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('questionnaireQuestion.findQuestionsAndAnswers')
    },
  })

  const { mutateAsync: updateComment } = useMutation('questionnaireComment.update', {
    onSettled: () => {
      reactQueryContext.invalidateQueries('questionnaireQuestion.findQuestionsAndAnswers')
    },
  })

  const handleRaiseIssue = () => {
    setIsIssue(true)
    setModalType('reply')
    setModalOpen(true)
  }

  const handleConcur = async () =>
    await updateComment({ id: comment.id, status: 'assigner_resolved' })

  const handleSendReply = async () => {
    setIsSending(true)
    try {
      await createComment({
        comment: reply,
        questionnaireRunId,
        commenterId: userId,
        replyToId: comment.id,
        questionId: question.id,
        type: isIssue ? 'issue' : 'resolver',
        ...(comment.type === 'issue' && !isDPOView ? { status: 'assignee_resolved' } : {}),
        ...(comment.type === 'resolver' && isDPOView ? { status: 'assigner_resolved' } : {}),
      })
      handleQuestionInOpenModal('')
      setIsSending(false)
      setModalOpen(false)
      if (isIssue) setIsIssue(false)
    } catch (e) {
      sendErrorToSentry(e)
      handleQuestionInOpenModal('')
      setIsSending(false)
      setModalOpen(false)
      if (isIssue) setIsIssue(false)
      throw new Error(JSON.stringify(e))
    }
  }

  const getSnapshotJSX = (comment: TCommentTree) => {
    let NewValueComponent: ReactElement | null = null
    let OldValueComponent: ReactElement | null = null

    if (!comment.auditEntry) return <p>No Audit Found</p>

    const { oldValue, newValue } =
      (comment.auditEntry as {
        oldValue: TQuestionnaireAnswerEntries[string]
        newValue: TQuestionnaireAnswerEntries[string]
      }) || {}

    const oldChoiceFreeText = oldValue?.freeText
    const newChoiceFreeText = newValue?.freeText

    if (question.type === 'single_choice') {
      const oldChoice = oldValue?.choice ?? ''
      const newChoice = newValue?.choice ?? ''
      NewValueComponent = (
        <SingleChoice
          question={question}
          choice={newChoice}
          readOnly={true}
          freeText={newChoiceFreeText}
        />
      )
      OldValueComponent = (
        <SingleChoice
          question={question}
          choice={oldChoice}
          readOnly={true}
          freeText={oldChoiceFreeText}
        />
      )
    } else if (question.type === 'multi_choice') {
      const oldChoices = oldValue?.choices ?? []
      const newChoices = newValue?.choices ?? []
      NewValueComponent = (
        <MultiChoice
          question={question}
          choices={newChoices}
          readOnly={true}
          freeText={newChoiceFreeText}
        />
      )
      OldValueComponent = (
        <MultiChoice
          question={question}
          choices={oldChoices}
          readOnly={true}
          freeText={oldChoiceFreeText}
        />
      )
    } else {
      const oldAnswer = oldValue?.text ?? ''
      const newAnswer = newValue?.text ?? ''
      NewValueComponent = <OpenText question={question} text={newAnswer} readOnly={true} />
      OldValueComponent = <OpenText question={question} text={oldAnswer} readOnly={true} />
    }

    return (
      <div>
        <div className='py-2 text-center'>{question.text}</div>
        <div>
          <div className='text-md mb-4 mt-2 text-center font-sans font-medium text-purple-800'>
            From:
          </div>
          <div>{OldValueComponent}</div>
        </div>
        <div>
          <div className='text-md mb-4 mt-8 text-center font-sans font-medium text-purple-800'>
            To:
          </div>
          <div>{NewValueComponent}</div>
        </div>
      </div>
    )
  }

  const Snapshot = () => <div>{getSnapshotJSX(comment)}</div>

  const ChildComments = useMemo(
    () =>
      (comment.children || []).map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          isDPOView={isDPOView}
          questionnaireRunId={questionnaireRunId}
          question={question}
          userId={userId}
          hasUnresolved={hasUnresolved}
          onResolverClick={onResolverClick}
          handleQuestionInOpenModal={handleQuestionInOpenModal}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comment]
  )

  const isResponseControlHidden = Boolean(comment.children.length)

  const computeResolverControls = (comment: TCommentTree) => {
    if (!hasUnresolved) return null
    if (comment.type === 'resolver' && isDPOView) {
      return !isResponseControlHidden ? (
        <div className='flex'>
          {
            <Button size='sm' variant='primary' onClick={handleRaiseIssue}>
              RAISE ISSUE
            </Button>
          }
          <div className='ml-3 cursor-pointer text-xs hover:text-purple-600'>
            <Button onClick={handleConcur} variant='primary' size='sm' aria-label='ConcurButton'>
              CONCUR
            </Button>
          </div>
        </div>
      ) : null
    }

    if (comment.type === 'issue') {
      return !isResponseControlHidden ? (
        <Button
          size='sm'
          variant='primary'
          onClick={() => onResolverClick(comment.id)}
          aria-label='ResolveWithChangeButton'>
          RESOLVE WITH CHANGE
        </Button>
      ) : null
    }

    return null
  }

  return (
    <div
      key={comment.id}
      className={`flex flex-col space-y-2 border-2 border-gray-600 bg-purple-50 p-4 ${
        comment.replyToId ? 'ml-3' : ''
      }`}>
      <div className='italics flex flex-col justify-between space-y-3 text-xs'>
        <div className='italics flex w-full justify-between text-xs text-gray-600'>
          <div>{`${comment.commenter?.name} - ${dateFns.format(comment.createdAt, 'Pp')}`}</div>
          {!comment.replyToId && comment.type === 'resolver' ? (
            <div>{!hasUnresolved ? 'APPROVED' : 'UNAPPROVED'}</div>
          ) : null}
        </div>
        <div className='text-gray-500'>{capitalize(comment.type ?? '')}</div>
      </div>

      <div className='py-1'>{comment.comment}</div>

      <div className='flex justify-between'>
        <div className='flex items-center py-2'>
          {comment.type === 'resolver' ? (
            <div className='mr-2 cursor-pointer hover:text-purple-600'>
              <HistoryIcon
                aria-label='HistoryIcon'
                width={20}
                role='button'
                name='HistoryIcon'
                onClick={() => {
                  setModalType('snapshot')
                  setModalOpen(true)
                  handleQuestionInOpenModal(comment.questionId)
                }}
              />
            </div>
          ) : null}
          {!isResponseControlHidden && comment.type === 'issue' ? (
            <div className='cursor-pointer hover:text-purple-600'>
              <ReplyIcon
                aria-label='ReplyIcon'
                width={20}
                role='button'
                onClick={() => {
                  setModalType('reply')
                  setModalOpen(true)
                  handleQuestionInOpenModal(comment.questionId)
                }}
              />
            </div>
          ) : null}
        </div>

        <div className='py-2'>{computeResolverControls(comment)}</div>
      </div>

      <div className='relative flex w-full justify-center'>
        {modalOpen ? (
          <Modal
            onClose={() => {
              setModalOpen(!modalOpen)
              handleQuestionInOpenModal('')
            }}
            minWidth='450px'
            absolute={true}
            title={modalType === 'reply' ? 'Compose a Reply' : 'Response Snapshot'}
            icon={modalType === 'reply' ? 'ReplyIcon' : 'HistoryIcon'}>
            {modalType === 'reply' ? (
              <div className='w-full'>
                <div>
                  <TextArea
                    alt='PSQ comment reply'
                    placeholder='Compose your reply here'
                    value={reply}
                    rows={3}
                    onChange={(e) => setReply(e.currentTarget.value)}
                  />
                </div>
                <div className='flex justify-end py-4'>
                  <div>
                    <Button size='sm' variant='secondary' onClick={() => setModalOpen(false)}>
                      Cancel
                    </Button>
                  </div>
                  <div className='ml-2'>
                    <Button size='sm' variant='primary' onClick={handleSendReply}>
                      {isSending ? 'Sending' : 'Send Reply'}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <Snapshot />
            )}
          </Modal>
        ) : null}
      </div>

      <div className='ml-1'>{ChildComments}</div>
    </div>
  )
}

export default Comment
