import { classNames } from "@invisible/common/helpers"

interface LayoutProps {
  showInfoSection: boolean
  isUpdatingBaseVariables: boolean
  children: React.ReactNode
}

export const Layout = ({ showInfoSection, isUpdatingBaseVariables, children }: LayoutProps) => (
  <div
    className={classNames(
      'box-border grid h-full w-full bg-white',
      showInfoSection ? 'grid-cols-[30%_70%]' : 'grid-cols-1',
      isUpdatingBaseVariables ? 'cursor-progress' : ''
    )}
    style={{ minHeight: 0 }}
  >
    {children}
  </div>
)
