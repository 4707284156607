import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ErrorIcon from '@mui/icons-material/Error'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { ConfirmDialog } from './ConfirmDialog'
import { useRLHFContext } from './context'
import { Step, TStep, TStepField } from './Step'
import { TBaseRunVariables } from './types'

interface IStepsProps {
  baseRunId: string
  baseRunVariables: TBaseRunVariables
  activeResponseColor?: string
  numOfRank?: number
  isReadOnly?: boolean
  responseText: string
}

function parseSteps(text: string, splitBy: string): string[] {
  const regex = new RegExp(splitBy, 'g')
  // TODO: probably need to trim the text
  return text.split(regex).filter((s) => s.trim() !== '')
}

export const Steps = ({
  baseRunId,
  baseRunVariables,
  numOfRank,
  activeResponseColor,
  isReadOnly,
  responseText,
}: IStepsProps) => {
  const { config, updateBaseRunVariables } = useRLHFContext()
  const [steps, setSteps] = useState<TStep[]>(() => {
    let steps = baseRunVariables.find(
      (brv) => brv.baseVariable.id === config?.responseStepsBaseVariableId
    )?.value as TStep[]
    // This fixes an issue where editing basevariable in baseview changes the type to string
    if (typeof steps === 'string') {
      steps = JSON.parse(steps) as TStep[]
    }
    return steps ?? []
  })
  const [activeStepId, setActiveStepId] = useState<string | null>(null)
  const [isAddingNewStep, setIsAddingNewStep] = useState(false)
  const [confirmGenerate, setConfirmGenerate] = useState(false)
  const [stepsWithMissingRequiredFields, setStepsWithMissingRequiredFields] = useState<string[]>([])

  const getStepItemKeys = () => config.stepMetadata?.fields.map((f) => f.key) ?? []

  const handleAddStep = async () => {
    const initItems = getStepItemKeys().map((key) => ({ key, value: null })) as TStep['items']
    const newSteps = [...steps, { id: uuid(), label: `Step ${steps.length + 1}`, items: initItems }]
    setIsAddingNewStep(true)
    await updateBaseRunVariables([
      {
        baseRunId,
        baseVariableId: config?.responseStepsBaseVariableId as string,
        value: newSteps,
      },
    ])
    setIsAddingNewStep(false)
    setSteps(newSteps)
  }

  const generateSteps = async () => {
    const newSteps = parseSteps(
      responseText,
      config?.stepAnnotationSplitResponseTextBy ?? '\n\n'
    ).map((text, index) => {
      // Warning: this very specific regex is not extensible
      const stepLabel = text.match(/Step\s+[A-Za-z0-9]+/)?.[0]
      // Checking the type of text to be 100% sure we don't trim a non string value
      const cleanText = typeof text === 'string' ? text.trim() : text
      const items = getStepItemKeys().map((key) => ({
        key,
        value: key === config.stepAnnotationSplitTextSaveKey ? cleanText : null,
      })) as TStep['items']
      return { id: uuid(), label: stepLabel ?? `Step ${index + 1}`, items }
    })
    await updateBaseRunVariables([
      {
        baseRunId,
        baseVariableId: config?.responseStepsBaseVariableId as string,
        value: newSteps,
      },
    ])
    setSteps(newSteps)
    setConfirmGenerate(false)
  }

  const handleGenerateSteps = () => {
    setConfirmGenerate(true)
  }

  const handleDeleteStep = async (stepId: string, label: string) => {
    const msg = `You are about to delete "${label}". Are you sure?`
    if (!window.confirm(msg)) return

    const newSteps = [...steps.filter((step) => step.id !== stepId)]
    await updateBaseRunVariables([
      {
        baseRunId,
        baseVariableId: config?.responseStepsBaseVariableId as string,
        value: newSteps,
      },
    ])
    setSteps(newSteps)
  }

  const handleUpdateStep = async (step: TStep) => {
    const newSteps = [...steps.map((s) => (s.id === step.id ? step : s))]
    await updateBaseRunVariables([
      {
        baseRunId,
        baseVariableId: config?.responseStepsBaseVariableId as string,
        value: newSteps,
      },
    ])
    setSteps(newSteps)
  }

  useEffect(() => {
    // validate steps required fields
    const stepsWithMissingRequiredFields = getInvalidStepIds(
      config.stepMetadata?.fields ?? [],
      steps
    )
    setStepsWithMissingRequiredFields(stepsWithMissingRequiredFields)
  }, [steps])

  return (
    <div>
      <h3>Steps Annotations</h3>
      <div className='flex flex-col'>
        {steps.map((step, index) => (
          <div className='mb-2 flex flex-col bg-white pl-1' key={index}>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <span>{step.label ?? `Step ${index + 1}`} </span>
                <Tooltip title='Please ensure all required fields are filled.'>
                  <span className='cursor-pointer text-red-400'>
                    {stepsWithMissingRequiredFields.includes(step.id) ? (
                      <ErrorIcon sx={{ fontSize: '15px' }} />
                    ) : null}
                  </span>
                </Tooltip>
              </div>
              <div>
                <IconButton onClick={() => setActiveStepId(step.id)}>
                  {isReadOnly ? <VisibilityIcon /> : <EditIcon />}
                </IconButton>
                <IconButton
                  disabled={isReadOnly}
                  onClick={() => handleDeleteStep(step.id, step.label ?? `Step ${index + 1}`)}>
                  <DeleteIcon />{' '}
                </IconButton>
              </div>
            </div>

            <Modal open={step.id === activeStepId} onClose={() => undefined}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 600,
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  borderRadius: '3px',
                  maxHeight: '80vh',
                  overflowY: 'auto',
                  p: 2,
                }}>
                <IconButton
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                  onClick={() => setActiveStepId(null)}>
                  <CloseIcon />{' '}
                </IconButton>
                <Step
                  data={step}
                  fields={config?.stepMetadata?.fields ?? []}
                  updateStep={handleUpdateStep}
                  selectedValueColor={activeResponseColor}
                  numOfRank={numOfRank}
                  isReadOnly={isReadOnly}
                />
              </Box>
            </Modal>
          </div>
        ))}
      </div>
      <button
        className='cursor-pointer rounded-sm border-none p-2 text-white outline-none disabled:cursor-not-allowed disabled:!bg-slate-600'
        style={{ backgroundColor: activeResponseColor }}
        disabled={isReadOnly || isAddingNewStep}
        onClick={handleAddStep}>
        Add Step
      </button>
      <button
        className='ml-4 cursor-pointer rounded-sm border-none p-2 text-white outline-none disabled:cursor-not-allowed disabled:!bg-slate-600'
        style={{ backgroundColor: activeResponseColor }}
        disabled={isReadOnly || isAddingNewStep}
        onClick={handleGenerateSteps}>
        Generate Steps
      </button>
      <ConfirmDialog
        open={confirmGenerate}
        onClose={() => setConfirmGenerate(false)}
        onConfirm={generateSteps}>
        <div>Are you sure you want to generate steps from the response text?</div>
        <div>This will override the current steps.</div>
      </ConfirmDialog>
    </div>
  )
}

export function getInvalidStepIds(fields: TStepField[], steps: TStep[]) {
  const requiredFields = fields.filter((f) => f.required)
  const stepsWithMissingRequiredFields = steps
    .filter((s) => {
      // if new field is added to the step fields, it will be include here
      const items = requiredFields.map((f) => {
        const item = s.items.find((i) => i.key === f.key)
        return { key: f.key, value: item?.value }
      })
      return items.some((i) => {
        const field = requiredFields?.find((f) => f.key === i.key)
        return (
          field?.required &&
          ((field.type === 'multiselect' &&
            typeof i.value === 'string' &&
            i.value.split(',').filter((v) => v).length === 0) ||
            [null, undefined, ''].includes(i.value as any))
        )
      })
    })
    .map((s) => s.id)
  return stepsWithMissingRequiredFields
}
