import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'

import Markdown from '../../common/Markdown'
import { SidebarProps } from '../types'

export const Sidebar = ({ tab, setTab, instruction, preamble, textDirection }: SidebarProps) => (
  <section className='flex h-full flex-col overflow-auto' style={{ borderRight: '1px solid grey' }}>
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(_, tab) => setTab(tab)} aria-label='lab API tabs example'>
          <Tab label='Instructions' value='1' />
          <Tab label='Preamble' value='2' />
        </TabList>
      </Box>
      <TabPanel value='1'>
        <Markdown dir={textDirection} className='my-3 overflow-auto'>
          {instruction ?? 'No instructions provided.'}
        </Markdown>
      </TabPanel>
      <TabPanel value='2'>
        <Markdown dir={textDirection} className='my-3 overflow-auto'>
          {preamble ?? 'No preamble provided.'}
        </Markdown>
      </TabPanel>
    </TabContext>
  </section>
)
