'use client'

import {
  Forward10,
  PauseCircleFilled,
  PlayCircleOutlineOutlined,
  Replay10,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'

interface AudioPlayerProps {
  url: string
}

const formatTime = (seconds: number): string =>
  [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')

const AudioPlayer = ({ url }: AudioPlayerProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const waveSurferRef = useRef<WaveSurfer | null>(null)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [playbackRate, setPlaybackRate] = useState<number>(1)
  const [isMuted, setIsMuted] = useState<boolean>(false)

  useEffect(() => {
    if (!containerRef.current) return

    waveSurferRef.current = WaveSurfer.create({
      container: containerRef.current,
      height: 54,
      waveColor: '#604CA580',
      progressColor: 'rgb(100, 0, 100)',
      barWidth: 5,
      barGap: 5,
      barRadius: 10,
    })

    waveSurferRef.current.load(url)

    waveSurferRef.current.on('ready', () => {
      if (waveSurferRef.current !== null) {
        setDuration(waveSurferRef.current.getDuration())
      }
    })

    waveSurferRef.current.on('audioprocess', () => {
      if (waveSurferRef.current !== null) {
        setCurrentTime(waveSurferRef.current.getCurrentTime())
      }
    })

    return () => {
      if (waveSurferRef.current !== null) {
        waveSurferRef.current.destroy()
      }
    }
  }, [])

  const onPlayPause = useCallback((): void => {
    if (waveSurferRef.current) {
      waveSurferRef.current.playPause()
    }
  }, [])

  const seekBy = (seconds: number): void => {
    if (waveSurferRef.current) {
      const currentTime = waveSurferRef.current.getCurrentTime()
      const duration = waveSurferRef.current.getDuration()
      const newTime = Math.max(0, Math.min(duration, currentTime + seconds))
      waveSurferRef.current.seekTo(newTime / duration)
    }
  }

  const toggleMute = (): void => {
    if (waveSurferRef.current) {
      const isCurrentlyMuted = waveSurferRef.current.getVolume() === 0
      waveSurferRef.current.setVolume(isCurrentlyMuted ? 1 : 0)
      setIsMuted(!isCurrentlyMuted)
    }
  }

  const changePlaybackRate = (): void => {
    if (waveSurferRef.current) {
      const newRate = playbackRate < 5 ? playbackRate + 1 : 1
      waveSurferRef.current.setPlaybackRate(newRate)
      setPlaybackRate(newRate)
    }
  }

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Paper
        sx={{
          bgcolor: '#EDE7F6',
          p: '8px 16px 16px 16px',
          borderRadius: 1,
          width: '600px',
          boxShadow: 'unset',
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
          <Typography
            variant='caption'
            fontFamily='Inter'
            sx={{ color: 'rgba(0, 0, 0, 0.87)', letterSpacing: '-0.012em' }}>
            {formatTime(currentTime)}
          </Typography>
          <Typography
            variant='caption'
            fontFamily='Inter'
            sx={{ color: 'rgba(0, 0, 0, 0.87)', letterSpacing: '-0.012em' }}>
            {formatTime(duration)}
          </Typography>
        </Box>
        <Box ref={containerRef} />
      </Paper>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 1,
        }}>
        <Box sx={{ display: 'flex', gap: 0.5, width: '33.33%' }}>
          <IconButton onClick={onPlayPause}>
            {waveSurferRef.current?.isPlaying() ? (
              <PauseCircleFilled />
            ) : (
              <PlayCircleOutlineOutlined />
            )}
          </IconButton>
          <IconButton onClick={() => seekBy(-10)}>
            <Replay10 />
          </IconButton>
          <IconButton onClick={() => seekBy(10)}>
            <Forward10 />
          </IconButton>
          <IconButton onClick={toggleMute}>{isMuted ? <VolumeOff /> : <VolumeUp />}</IconButton>
          <IconButton onClick={changePlaybackRate}>
            <Typography variant='button'>{playbackRate}X</Typography>
          </IconButton>
        </Box>
        <Typography
          variant='subtitle1'
          fontFamily='Roboto'
          fontWeight={500}
          sx={{ color: 'rgba(0, 0, 0, 0.87)', letterSpacing: '0.58px', width: '33.33%' }}>
          {formatTime(currentTime)}/{formatTime(duration)}
        </Typography>
        <Box sx={{ width: '33.33%' }} />
      </Box>
    </Box>
  )
}

export default AudioPlayer
