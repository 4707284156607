import { useAbilityContext } from '@invisible/authorization/client'
import { NextPageContext } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export function redirect({ res }: NextPageContext, path: string) {
  if (res && !res.writableEnded) {
    res.writeHead(301, { Location: path })
    res.end()
  }
}

type TRoute = {
  path: string
  restricted: boolean
  includeSubRoutes: boolean
}

export const ROUTES = [
  {
    path: '/companies',
    restricted: true,
    includeSubRoutes: true,
  },
  {
    path: '/finance',
    restricted: true,
    includeSubRoutes: true,
  },
  {
    path: '/technologies',
    restricted: true,
    includeSubRoutes: true,
  },
  {
    path: '/operations',
    restricted: true,
    includeSubRoutes: true,
  },
  {
    path: '/people',
    restricted: true,
    includeSubRoutes: true,
  },
  {
    path: '/configurator',
    restricted: true,
    includeSubRoutes: true,
  },
  {
    path: '/usecases',
    restricted: true,
    includeSubRoutes: true,
  },
] as TRoute[]

export const PATHS = ROUTES.map((route) => route.path)

export const RESTRICTED_PATHS = ROUTES.filter((route) => route.restricted).map(
  (route) => route.path
)

export const getParentPaths = (path: string): string[] => {
  const parts = path.split('/').filter((part) => part !== '')

  const parentPaths = [] as string[]

  let currentPath = ''
  for (let i = 0; i < parts.length - 1; i++) {
    currentPath += `/${parts[i]}`
    parentPaths.push(currentPath)
  }

  return parentPaths
}

export const useRouteAccessControl = (ROUTES: TRoute[]) => {
  const router = useRouter()
  const [, ability] = useAbilityContext()
  useEffect(() => {
    if (!ability || !router) return
    if (router.pathname === '/' || router.pathname === '') return
    const parentPaths = getParentPaths(router.pathname)
    let canAccess = false

    const route = ROUTES.find((r) => r.path === router.pathname)
    if (route && !route.restricted) {
      canAccess = true
    }
    if (ability?.can('access', 'Page', { path: router.pathname })) {
      canAccess = true
    }
    parentPaths.forEach((path) => {
      const parentRoute = ROUTES.find((r) => r.path === path)
      if (!parentRoute) return
      if (!parentRoute.restricted && parentRoute.includeSubRoutes) {
        canAccess = true
      }
      if (
        parentRoute.restricted &&
        parentRoute.includeSubRoutes &&
        ability?.can('access', 'Page', { path })
      ) {
        canAccess = true
      }
    })
    if (!canAccess) {
      router.push('/')
    }
  }, [ROUTES, ability, router])
}
