// import { QuillEditor } from '@invisible/ui/quill-editor'

type FieldProps = {
  value: string
  isReadOnly: boolean
}

const RichText = ({ value, isReadOnly }: FieldProps) => (
  // <QuillEditor width='100%' value={value} readOnly={isReadOnly} onChange={() => {}} />
  <p>{value}</p>
)

export { RichText }
