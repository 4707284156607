import classNames from 'classnames'
import { useState } from 'react'

import { CustomSwitchProps } from '../types'

const YesNoSwitch = ({ value, onChange, disabled, selectedValueColor }: CustomSwitchProps) => {
  const [selected, setSelected] = useState<boolean | null | undefined>(value)
  const options = [
    { key: 'Yes', value: true },
    { key: 'No', value: false },
  ]
  return (
    <div
      className={classNames(
        'flex justify-between gap-x-4 py-1',
        disabled ? 'cursor-not-allowed' : ''
      )}>
      {options.map((option, index) => (
        <div className='relative flex items-center justify-center p-1' key={index}>
          {selected === option.value ? (
            <div
              style={selected === option.value ? { color: selectedValueColor } : undefined}
              className={classNames(
                'center absolute top-[-12px] h-full align-top font-bold',
                selected === option.value ? `font-bold` : ''
              )}>
              .
            </div>
          ) : null}
          <span
            style={selected === option.value ? { color: selectedValueColor } : undefined}
            className={classNames(
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              selected === option.value ? `font-bold` : ''
            )}
            onClick={
              disabled
                ? () => undefined
                : () => {
                    setSelected(option.value)
                    onChange(option.value)
                  }
            }>
            {option.key}
          </span>
        </div>
      ))}
    </div>
  )
}

export { YesNoSwitch }
