import { zodResolver } from '@hookform/resolvers/zod'
import { classNames } from '@invisible/common/helpers'
import { Button } from '@invisible/ui/button'
import { CaretDownIcon, CheckCircleFilledIcon } from '@invisible/ui/icons'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { generateValidationSchema } from '../generate-validation-schema'
import { FormField } from './FormField'

type TCheckListValue = Record<string, boolean>
type FormValues = Record<string, string | number | boolean | string[] | Date | null | undefined>

const computeDefaultValues = (
  formValues: Record<string, any> = {},
  fields: WizardSchemas.FormField.TSchema[]
): { [key: string]: Record<string, any> | undefined } =>
  Object.keys(formValues).reduce((acc, key) => {
    const field = fields.find((field) => field.baseVariableId === key)
    if (field && field.id) {
      acc[field.id] = formValues[key] || undefined
    }
    return acc
  }, {} as { [key: string]: Record<string, any> | undefined })

const Section = ({
  section,
  fields = [],
  formValues,
  checkLists,
  checkListValue,
  isSingleSection = false,
  onSubmit,
  isCompleted = false,
  hasNextSection = false,
  goToSection,
  onReview,
}: {
  section: WizardSchemas.FormSection.TSchema
  fields: WizardSchemas.FormField.TSchema[]
  formValues?: FormValues
  checkLists?: WizardSchemas.CheckList.TSchema[]
  checkListValue?: TCheckListValue
  onSubmit: (data: Record<string, unknown>) => void
  isSingleSection: boolean
  isCompleted: boolean
  hasNextSection?: boolean
  formSubmittedFromSection?: string
  baseVariableIdsInError?: string[]
  goToSection?: (nextSection: string | undefined) => void
  onReview?: () => void
}) => {
  /**
   * React Hook Form configuration using the default values
   * and the generated validation schema
   */
  const methods = useForm({
    mode: 'onChange',
    defaultValues: computeDefaultValues(formValues, fields),
    resolver: zodResolver(generateValidationSchema(fields ?? [])),
  })

  /**
   * We need this effect because the default values are controlled by `FormWAC`
   * via state, themselves computed with an effect when the stepRun is set.
   * This causes that in the first render, the default values are not set.
   * React Hook Form caches the default values, so we need to reset the form
   * to make sure the default values are set.
   *
   * This is a temporary fix until we refactor `FormWAC` to remove those unnecessary
   * effects.
   */
  useEffect(() => {
    const defaultValues = computeDefaultValues(formValues, fields)
    methods.reset(defaultValues)
  }, [formValues, fields, methods])

  const {
    formState: { isValid, isDirty },
    handleSubmit,
  } = methods

  /**
   * Refactor Notes:
   * - we need to replace the Section with a native Collapsible component.
   */
  return (
    <div
      className={classNames(
        `border-r-6 mt-2 mb-2 rounded-md border border-solid border-gray-300  pl-4 pt-2 pb-2 pr-4 shadow-sm`
      )}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          {isCompleted ? (
            <div className='text-green-500'>
              <CheckCircleFilledIcon width={20} />
            </div>
          ) : null}
          <h3 className={classNames(`whitespace-pre-line `)}>{section.label}</h3>
        </div>
        {isCompleted ? (
          <div
            className='cursor-pointer text-gray-400'
            onClick={() => goToSection && goToSection(section.id)}>
            <CaretDownIcon />
          </div>
        ) : null}
      </div>
      {!isCompleted ? (
        <FormProvider {...methods}>
          {fields?.map((field) => (
            <FormField field={field} />
          ))}
          <div className='flex justify-end'>
            <Button disabled={!isValid || !isDirty} onClick={handleSubmit(onSubmit)}>
              {!isSingleSection && !isCompleted && hasNextSection ? 'Next' : 'Review'}
            </Button>
          </div>
        </FormProvider>
      ) : null}
    </div>
  )
}

export { Section }
