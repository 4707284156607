import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { equals } from 'lodash/fp'
import { MouseEvent, useState } from 'react'

import { CustomMultiSelectProps } from '../types'

const CustomMultiSelect = ({
  selectedKeys,
  onSelect,
  options,
  disabled,
  selectedValueColor,
  showSelectKeys,
  label,
}: CustomMultiSelectProps) => {
  const [selected, setSelected] = useState<(string | number)[]>(selectedKeys ?? [])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    if (!equals(selected, selectedKeys ?? [])) {
      onSelect(selected)
    }
    setAnchorEl(null)
  }

  return (
    <div>
      <div
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
        onClick={disabled ? () => undefined : handleClick}>
        {showSelectKeys && selected.length > 0 ? (
          <div className='flex flex-wrap overflow-x-auto py-1'>
            {selected.map((key) => (
              <div
                style={{ backgroundColor: selectedValueColor ?? '#604CA5' }}
                className='mx-1 my-[1px] inline rounded-md px-2 py-1 text-white'>
                {key}
              </div>
            ))}
          </div>
        ) : (
          <>
            <span
              style={{ backgroundColor: selectedValueColor ?? '#604CA5' }}
              className='rounded-full px-2 py-1  text-white'>
              {selected.length}
            </span>{' '}
            {label ?? 'Chosen Options'}
          </>
        )}
      </div>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (selected.includes(option.value)) {
                setSelected((values) => values.filter((value) => value !== option.value))
              } else {
                setSelected((values) => [...values, option.value])
              }
            }}>
            <Checkbox checked={selected.includes(option.value)} />
            <ListItemText primary={option.key} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export { CustomMultiSelect }
