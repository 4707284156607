import { useEffect, useRef } from 'react'
import React from 'react'

import { getShortcut } from './helpers'
import { IHighlightedTextProps } from './types'

const HighlightedText = ({
  id,
  start,
  end,
  text,
  entity,
  option,
  overlapLength = 0,
  actualText = '',
  dispatcher,
  isHighlighted,
  rightOffset = 0,
  entities = [],
}: IHighlightedTextProps) => {
  const ref = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    if (isHighlighted) {
      dispatcher({ anchorEl: ref.current })
    }
  }, [isHighlighted, dispatcher])

  const openContextMenu = (inputEntity: string | null = null) => {
    dispatcher({
      anchorEl: ref.current,
      annotation: {
        start: overlapLength > 0 ? Math.abs(start - overlapLength) : start,
        end,
        text: overlapLength > 0 ? actualText : text,
        id,
        option,
        entity: inputEntity ? inputEntity : entity,
      },
    })
  }

  const shiftedRightPosition =
    rightOffset === 2 ? 'right-[10px]' : rightOffset === 1 ? 'right-[20px]' : 'right-0'

  return (
    <mark
      ref={ref}
      data-start={start}
      data-end={end}
      className='relative inline-block leading-none'>
      {text}
      {entity && entities && entities.length > 0 ? (
        <span
          className={`${shiftedRightPosition} absolute -top-[10px] ml-0.5 select-none text-[0.65rem]`}
          data-shortcut={entities.map(getShortcut).join(',')}>
          {entities.map((row) => (
            <mark className='ml-0.5 px-0.5' onClick={() => openContextMenu(row)}>
              {getShortcut(row)}
            </mark>
          ))}
        </span>
      ) : null}
    </mark>
  )
}

const Split = (props: IHighlightedTextProps) => {
  if (props.mark) return <HighlightedText {...props} />

  return (
    <span data-start={props.start} data-end={props.end}>
      {props.text}
    </span>
  )
}

export default Split
