import '@invisible/utils/datadog/client'
import '@invisible/segment'

import { SessionProvider } from '@invisible/authentication/client'
import { ErrorBoundary } from '@invisible/common/components/error-boundary'
import { light, ThemeProvider } from '@invisible/ui/themes'
import { ToastProvider } from '@invisible/ui/toasts'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { AppType } from 'next/dist/shared/lib/utils'
import Head from 'next/head'
import type { Session } from 'next-auth'
import type { FC, ReactElement } from 'react'

import { SnackbarProvider } from './SnackbarProvider'
import { StatsigProvider } from './StatsigProvider'
import { TaxIdStateProvider } from './TaxIdProvider'
import { TosSowStateProvider } from './TosSowProvider'

interface Options {
  /* eslint-disable @typescript-eslint/ban-types */
  Layout?: FC
  pageTitle?: string
}

export type NextPageWithLayout = NextPage<{ session?: Session }> & {
  getLayout?: (page: ReactElement) => JSX.Element
}

type AppPropsWithLayout = Omit<AppProps<{ session?: Session }>, 'router'> & {
  Component: NextPageWithLayout
}

const SecondaryLayout = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />)
  } else {
    return <Component {...pageProps} />
  }
}

export const createApp: (options: Options) => AppType<{ session?: Session }> =
  ({ Layout = ({ children }) => <main>{children}</main>, pageTitle }) =>
  ({ Component, pageProps }) =>
    (
      <SessionProvider session={pageProps?.session} refetchOnWindowFocus={false}>
        <ErrorBoundary>
          <ThemeProvider theme={light}>
            <ToastProvider>
              <SnackbarProvider>
                <StatsigProvider>
                  <TosSowStateProvider>
                    <TaxIdStateProvider>
                      <Layout>
                        {pageTitle ? (
                          <Head>
                            <title>{pageTitle}</title>
                          </Head>
                        ) : null}
                        <SecondaryLayout Component={Component} pageProps={pageProps} />
                      </Layout>
                    </TaxIdStateProvider>
                  </TosSowStateProvider>
                </StatsigProvider>
              </SnackbarProvider>
            </ToastProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </SessionProvider>
    )
