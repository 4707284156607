import { range } from 'lodash/fp'
import { FC, useEffect, useState } from 'react'

import { Button } from '../button'

const Divider = () => (
  <div className='text-muted flex h-8 w-8 items-center justify-center text-2xl'>...</div>
)

interface IPagination {
  onClick: (page: number) => void | Promise<void>
  pages: number
  startingPage?: number
  selectedPage?: number
  alwaysVisible?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Pagination: FC<IPagination> = ({
  onClick,
  pages,
  startingPage = 1,
  selectedPage,
  alwaysVisible,
}) => {
  const [page, setPage] = useState(selectedPage || startingPage)

  const handlePage = async (page: number) => {
    await onClick(page)
    setPage(page)
  }

  const handleNext = () => {
    if (page < pages) handlePage(page + 1)
  }

  const handlePrevious = () => {
    if (page > 1) handlePage(page - 1)
  }

  // keeps internal state in sync with external state
  useEffect(() => {
    if (selectedPage && selectedPage !== page) setPage(selectedPage)
  }, [selectedPage])

  const hidePagination = pages <= 1 && !alwaysVisible

  if (hidePagination) return null

  const PagingButtons = () => {
    if (pages <= 7) {
      return (
        <>
          {range(1, pages + 1).map((i: number) => (
            <Button
              key={i}
              variant={page === i ? 'primary' : 'secondary'}
              color={page === i ? 'void' : 'paragraphs'}
              size='md'
              shape='square'
              onClick={() => handlePage(i)}
              data-active={page === i}>
              {i}
            </Button>
          ))}
        </>
      )
    }

    if (page <= 4)
      return (
        <>
          {range(1, 6).map((i: number) => (
            <Button
              key={i}
              variant={page === i ? 'primary' : 'secondary'}
              color={page === i ? 'void' : 'paragraphs'}
              size='md'
              shape='square'
              onClick={() => handlePage(i)}
              data-active={page === i}>
              {i}
            </Button>
          ))}
          <Divider />
          <Button
            variant='secondary'
            color='paragraphs'
            size='md'
            shape='square'
            onClick={() => handlePage(pages)}>
            <span className='text-paragraphs'>{pages}</span>
          </Button>
        </>
      )

    if (page < pages - 3) {
      return (
        <>
          <Button
            variant='secondary'
            color='paragraphs'
            size='md'
            shape='square'
            onClick={() => handlePage(1)}>
            <span className='text-paragraphs'>1</span>
          </Button>
          <Divider />
          {range(page - 1, page + 2).map((i: number) => (
            <Button
              key={i}
              variant={page === i ? 'primary' : 'secondary'}
              color={page === i ? 'void' : 'paragraphs'}
              size='md'
              shape='square'
              onClick={() => handlePage(i)}
              data-active={page === i}>
              {i}
            </Button>
          ))}
          <Divider />
          <Button
            variant='secondary'
            color='paragraphs'
            size='md'
            shape='square'
            onClick={() => handlePage(pages)}>
            <span className='text-paragraphs'>{pages}</span>
          </Button>
        </>
      )
    }

    return (
      <>
        <Button
          variant='secondary'
          color='paragraphs'
          size='md'
          shape='square'
          onClick={() => handlePage(1)}>
          <span className='text-paragraphs'>1</span>
        </Button>
        <Divider />
        {range(pages - 4, pages + 1).map((i: number) => (
          <Button
            key={i}
            variant={page === i ? 'primary' : 'secondary'}
            color={page === i ? 'void' : 'paragraphs'}
            size='md'
            shape='square'
            onClick={() => handlePage(i)}
            data-active={page === i}>
            {i}
          </Button>
        ))}
      </>
    )
  }

  return (
    <div className='flex gap-2' data-cy='pagination'>
      <Button
        variant='subtle'
        color='paragraphs'
        size='md'
        shape='square'
        icon='CaretLeftIcon'
        onClick={handlePrevious}
        disabled={page === 1}
      />
      <PagingButtons />
      <Button
        variant='subtle'
        color='paragraphs'
        size='md'
        shape='square'
        icon='CaretRightIcon'
        onClick={handleNext}
        disabled={page === pages}
      />
    </div>
  )
}

export { Pagination }
