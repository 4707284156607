import { classNames } from '@invisible/common/helpers'

import { FunnelIcon } from '../../icons'

// eslint-disable-next-line @typescript-eslint/ban-types
export const FilterIcon: React.FC<{
  selected?: boolean
  width: number
  height: number
  onClick?: () => void
}> = ({ selected, width, height, onClick }) => (
  <FunnelIcon
    onClick={onClick}
    className={classNames('cursor-pointer', selected ? 'text-theme-main' : 'text-main')}
    width={width}
    height={height}
  />
)
