import { Input } from '@invisible/ui/input'
import { TagInput } from '@invisible/ui/tag-input'
import { Wizard } from '@invisible/ultron/zod'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import { ChangeEvent, useMemo, useState } from 'react'

import { CustomMultiSelect } from './sub-components/CustomMultiSelect'
import { CustomRadio } from './sub-components/CustomRadio'
import { CustomSelect } from './sub-components/CustomSelect'
import { NumberRank } from './sub-components/NumberRank'
import { NumberRating } from './sub-components/NumberRating'
import { YesNoSwitch } from './sub-components/YesNoSwitch'

export type TStepItem = {
  key: string
  value: unknown
}

export type TStep = {
  id: string
  label?: string
  items: TStepItem[]
}

export type TStepField = NonNullable<Wizard.RLHFPlusMeta.TSchema['stepMetadata']>['fields'][0]

interface IStepProps {
  data: TStep
  fields: TStepField[]
  selectedValueColor?: string
  isReadOnly?: boolean
  numOfRank?: number
  updateStep: (data: TStep) => unknown
}

const Step = ({
  fields: metadata,
  data,
  updateStep,
  selectedValueColor,
  numOfRank,
  isReadOnly,
}: IStepProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const [items, setItems] = useState<TStepItem[]>(data.items)
  const [stepLabel, setStepLabel] = useState(data.label)

  const changesMade = useMemo(
    () =>
      items.some((i) => {
        const item = data.items.find((it) => it.key === i.key)
        return i.value !== item?.value && metadata?.find((f) => f.key === i.key)
      }) || data.label !== stepLabel,
    [data, items, stepLabel]
  )

  const handleItemUpdate = ({ value, key }: TStepItem) => {
    setItems((prev) => [...prev.filter((item) => item.key !== key), { key, value }])
  }

  const handleSaveAllFields = async () => {
    if (isSaving) return
    setIsSaving(true)
    await updateStep({
      ...data,
      label: stepLabel,
      items,
    })
    setIsSaving(false)
  }

  const singleRowTypes = [
    'a_string',
    'boolean',
    'radio',
    'number',
    'rating',
    'ranking',
    'multiselect',
  ]
  return (
    <div className='flex w-full flex-col gap-2'>
      <h2 className='text-center'>{data.label ?? `Step {index + 1}`}</h2>
      <div className='flex items-center gap-2 pl-2'>
        <span>Step Label:</span>
        <TextField
          placeholder='Step Label'
          value={stepLabel ?? 'Untitled Step'}
          size='small'
          disabled={isReadOnly}
          onChange={(e) => {
            setStepLabel(e.target.value)
          }}
        />
      </div>

      <h4 className='ml-2 mb-1'>Annotation Fields</h4>
      {(metadata ?? []).map((field) => (
        <div
          key={field.key}
          className={classNames(
            'flex  bg-white p-2',
            singleRowTypes.includes(field.type) ? 'items-center' : 'flex-col gap-2'
          )}>
          <div className='mr-2'>
            {field.label}{' '}
            {field?.required ? (
              <span style={{ color: selectedValueColor ?? 'darkred' }}>*</span>
            ) : null}
          </div>
          {field.type === 'a_string' ? (
            <TagInput
              tags={(items.find((item) => item.key === field.key)?.value ?? []) as string[]}
              disabled={isReadOnly ? true : field.editable === undefined ? false : !field.editable}
              onChange={(value) =>
                handleItemUpdate({
                  key: field.key as string,
                  value,
                })
              }
            />
          ) : field.type === 'boolean' ? (
            <YesNoSwitch
              disabled={isReadOnly ? true : field.editable === undefined ? false : !field.editable}
              value={
                (items.find((item) => item.key === field.key)?.value ?? null) as boolean | null
              }
              selectedValueColor={selectedValueColor}
              onChange={(value) =>
                handleItemUpdate({
                  key: field.key as string,
                  value,
                })
              }
            />
          ) : field.type === 'dropdown' ? (
            <CustomSelect
              options={field.options ?? []}
              disabled={isReadOnly ? true : field.editable === undefined ? false : !field.editable}
              selectedKey={(items.find((item) => item.key === field.key)?.value ?? '') as string}
              onSelect={(selectedValue) => {
                handleItemUpdate({
                  key: field.key as string,
                  value: selectedValue,
                })
              }}
            />
          ) : field.type === 'multiselect' ? (
            <CustomMultiSelect
              showSelectKeys
              selectedValueColor={selectedValueColor}
              name={field.label as string}
              options={field.options ?? []}
              disabled={isReadOnly ? true : field.editable === undefined ? false : !field.editable}
              selectedKeys={
                ((items.find((item) => item.key === field.key)?.value ?? '') as string)
                  .split(',')
                  .filter((v: string) => v) ?? []
              }
              onSelect={(selectedValues) => {
                handleItemUpdate({
                  key: field.key as string,
                  value: selectedValues.toString(),
                })
              }}
            />
          ) : field.type === 'radio' ? (
            <CustomRadio
              selectedValueColor={selectedValueColor}
              options={field.options ?? []}
              disabled={isReadOnly ? true : field.editable === undefined ? false : !field.editable}
              value={(items.find((item) => item.key === field.key)?.value ?? '') as string}
              onChange={({ value }) =>
                handleItemUpdate({
                  key: field.key as string,
                  value,
                })
              }
            />
          ) : field.type === 'rating' ? (
            <NumberRating
              disabled={isReadOnly ? true : field.editable === undefined ? false : !field.editable}
              value={(items.find((item) => item.key === field.key)?.value ?? null) as number | null}
              selectedValueColor={selectedValueColor}
              onChange={(value) =>
                handleItemUpdate({
                  key: field.key as string,
                  value,
                })
              }
            />
          ) : field.type === 'ranking' ? (
            <NumberRank
              disabled={isReadOnly ? true : field.editable === undefined ? false : !field.editable}
              value={(items.find((item) => item.key === field.key)?.value ?? null) as number | null}
              numOfRank={numOfRank}
              selectedValueColor={selectedValueColor}
              onChange={(value) =>
                handleItemUpdate({
                  key: field.key as string,
                  value,
                })
              }
            />
          ) : field.type === 'number' ? (
            <Input
              disabled={isReadOnly ? true : field.editable === undefined ? false : !field.editable}
              value={(items.find((item) => item.key === field.key)?.value ?? '') as string}
              type='number'
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleItemUpdate({ key: field.key, value: e.target.value })
              }}
            />
          ) : (
            <div className='flex flex-col'>
              <TextField
                value={(items.find((item) => item.key === field.key)?.value ?? '') as string}
                onChange={(e) => {
                  handleItemUpdate({ key: field.key, value: e.target.value })
                }}
                size='small'
                multiline
                fullWidth
                maxRows={4}
                minRows={1}
                disabled={
                  isReadOnly ? true : field.editable === undefined ? false : !field.editable
                }
              />
            </div>
          )}
        </div>
      ))}

      {isReadOnly ? null : (
        <div className='mb-1 flex justify-start'>
          <span
            onClick={() => (changesMade ? handleSaveAllFields() : undefined)}
            className={`${
              changesMade ? 'cursor-pointer' : 'cursor-not-allowed'
            } rounded-sm px-4 py-2`}
            style={{
              color: '#fff',
              backgroundColor: isSaving || !changesMade ? 'gray' : selectedValueColor,
            }}>
            {isSaving ? 'Saving...' : 'Save Step'}
          </span>
        </div>
      )}
    </div>
  )
}

export { Step }
