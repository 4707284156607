import { TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

type FieldProps = {
  field: Record<string, any>
  defaultValue: number
}

const getNumberHelperText = (min: number | undefined, max: number | undefined) => {
  if (Number.isInteger(min) && Number.isInteger(max)) {
    return `Value must be in range [${min}-${max}].`
  } else if (Number.isInteger(min) && !Number.isInteger(max)) {
    return `Value must be greater than or equal to ${min}.`
  } else if (!Number.isInteger(min) && Number.isInteger(max)) {
    return `Value must be less than or equal to ${max}.`
  } else {
    return ''
  }
}

const NumericField = ({ field, defaultValue }: FieldProps) => {
  const {
    formState: { errors },
  } = useFormContext()

  const { id, label, placeholder, required, isReadOnly, min, max } = field

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ...restField } }) => (
        <TextField
          size='small'
          fullWidth
          type='number'
          label={label}
          placeholder={placeholder}
          required={required}
          disabled={isReadOnly}
          value={value}
          onChange={onChange}
          {...restField}
          error={!!errors[id]}
          helperText={errors[id] ? errors[id]?.message : getNumberHelperText(min, max)}
          inputProps={{
            min: min || '',
            max: max || '',
          }}
        />
      )}
    />
  )
}

export { NumericField }
