// import { NullSwitch } from '@invisible/ui/null-switch'

type FieldProps = {
  value: string
  isReadOnly: boolean
}

const BooleanField = ({ value = 'false', isReadOnly }: FieldProps) => (
  // <NullSwitch isOn={value} disabled={isReadOnly} onToggle={() => {}} />
  <p>{value}</p>
)

export { BooleanField }
