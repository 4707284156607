import { formatInTimeZone } from 'date-fns-tz'
import WaveSurfer from 'wavesurfer.js'

const formatDuration = (time: number, isMilliseconds?: boolean) => {
  const timeInSeconds = isMilliseconds ? time / 1000 : time
  // format time in seconds to mm:ss
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = Math.floor(timeInSeconds % 60)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

// Converts an array of file name segements into the string representation
const getComputedName = ({
  segments,
  stepName,
  recordTime,
  companyName,
  recordingLength,
}: {
  segments: string[]
  stepName: string
  recordTime: Date
  companyName: string
  recordingLength: number
}) =>
  segments
    .map((segment) => {
      switch (segment) {
        case 'Task':
          return `${stepName}`
        case 'Date & time':
          return `on ${formatInTimeZone(recordTime, 'UTC', 'dd MMMM, yyyy')} at ${formatInTimeZone(
            recordTime,
            'UTC',
            'hh:mm a'
          )} UTC`
        case 'Client':
          return `for ${companyName}`
        case 'Duration':
          return `- ${formatDuration(recordingLength, true)}`
        default:
          return ''
      }
    })
    .join(' ')

/**
 * Converts an AudioBuffer to a blob
 * Source: https://stackoverflow.com/a/62173861
 */
const bufferToWave = (abuffer: AudioBuffer, offset: number, len: number) => {
  const numOfChan = abuffer.numberOfChannels
  const length = len * numOfChan * 2 + 44
  const buffer = new ArrayBuffer(length)
  const view = new DataView(buffer)
  const channels: Float32Array[] = []
  let pos = 0

  const setUint16 = (data: number) => {
    view.setUint16(pos, data, true)
    pos += 2
  }

  const setUint32 = (data: number) => {
    view.setUint32(pos, data, true)
    pos += 4
  }

  // Write WAVE header
  setUint32(0x46464952) // "RIFF"
  setUint32(length - 8) // file length - 8
  setUint32(0x45564157) // "WAVE"

  setUint32(0x20746d66) // "fmt " chunk
  setUint32(16) // length = 16
  setUint16(1) // PCM (uncompressed)
  setUint16(numOfChan)
  setUint32(abuffer.sampleRate)
  setUint32(abuffer.sampleRate * 2 * numOfChan) // avg. bytes/sec
  setUint16(numOfChan * 2) // block-align
  setUint16(16) // 16-bit (hardcoded in this demo)

  setUint32(0x61746164) // "data" - chunk
  setUint32(length - pos - 4) // chunk length

  // Write interleaved data
  for (let i = 0; i < abuffer.numberOfChannels; i++) {
    channels.push(abuffer.getChannelData(i))
  }

  while (pos < length) {
    for (let i = 0; i < numOfChan; i++) {
      // Interleave channels
      const sample = Math.max(-1, Math.min(1, channels[i][offset])) // clamp
      const intSample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
      view.setInt16(pos, intSample, true) // update data chunk
      pos += 2
    }
    offset++ // next source sample
  }

  // Create Blob
  const blob = new Blob([buffer], { type: 'audio/webm' })
  const url = URL.createObjectURL(blob)
  return { blob, url }
}

/**
 * Given a blob, and a start and end time, trims the blob to the specified time range
 */
const trimBlob = (waveSurfer: WaveSurfer | null, start: number, end: number) => {
  if (!waveSurfer) return null

  const originalAudioBuffer = waveSurfer.getDecodedData()
  if (!originalAudioBuffer) return null

  const lengthInSamples = Math.floor((end - start) * originalAudioBuffer.sampleRate)
  const offlineAudioContext = new OfflineAudioContext(
    originalAudioBuffer.numberOfChannels,
    lengthInSamples,
    originalAudioBuffer.sampleRate
  )

  const newAudioBuffer = offlineAudioContext.createBuffer(
    originalAudioBuffer.numberOfChannels,
    lengthInSamples,
    originalAudioBuffer.sampleRate
  )

  for (let channel = 0; channel < originalAudioBuffer.numberOfChannels; channel++) {
    const newChannelData = newAudioBuffer.getChannelData(channel)
    const originalChannelData = originalAudioBuffer.getChannelData(channel)

    const trimmedData = originalChannelData.subarray(
      Math.floor(start * originalAudioBuffer.sampleRate),
      Math.floor(end * originalAudioBuffer.sampleRate)
    )
    newChannelData.set(trimmedData)
  }

  return bufferToWave(newAudioBuffer, 0, lengthInSamples)
}

export { formatDuration, getComputedName, trimBlob }
