import { TAnnotationState } from './types'

export const TEXT_ANNOTATION_STATE_INITIAL_VALUES: TAnnotationState = {
  anchorEl: null,
  annotation: null,
  selections: [],
  isHighlighting: false,
  isKeyPressed: false,
  entities: [],
}

export const CATEGORY_HOVER_OR_ACTIVE_SX = {
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  fontWeight: 500,
  borderRadius: '6px',
}
