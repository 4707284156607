import { logger } from '@invisible/logger/client'

export const copyToClipboard = async (text: string | number): Promise<void> => {
  const clipboardWritePermission = 'clipboard-write' as PermissionName

  // Ensure document is focused else early return
  if (!document.hasFocus()) return

  try {
    const result = await navigator.permissions.query({
      name: clipboardWritePermission,
    })
    if (result.state === 'granted' || result.state === 'prompt') {
      if (document.hasFocus() && navigator.clipboard) {
        return navigator.clipboard.writeText(String(text))
      }
    }
  } catch (error) {
    logger.error('copyToClipboard: Error copying text to clipboard', { error })
  }
}
