import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

const Guidelines = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        sx={{ fontWeight: 'normal' }}
        startIcon={<InfoOutlinedIcon />}
        onClick={() => setIsOpen(true)}>
        Read a quick guide here
      </Button>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Box p='24px' pb='0px'>
          <Typography fontSize='20px' fontWeight={500}>
            How to create and edit audio segments
          </Typography>
          <Typography mt='4px'>
            Easily create and adjust segments with just a few clicks and drags!
          </Typography>
        </Box>

        <DialogContent>
          <Stack gap='16px'>
            <Stack mb='8px'>
              <Typography fontSize='16px' fontWeight={600} mb='8px'>
                Creating a segment (clip)
              </Typography>
              <Typography>
                1. Click and hold on the part of the audio waveform where you’d like to start your
                segment.
              </Typography>
              <Typography mt='12px'>
                2. Drag your mouse to the left or right to highlight the desired length of the
                audio.
              </Typography>
              <Typography mt='12px'>3. Release the mouse to create your segment.</Typography>
            </Stack>
            <Stack mb='8px'>
              <Typography fontSize='16px' fontWeight={600} mb='8px'>
                Editing a segment’s length
              </Typography>
              <Typography>
                1. Hover over the edges (left or right) of your created segment.
              </Typography>
              <Typography mt='12px'>
                2. When the cursor changes, click and hold, then drag left or right to adjust the
                length of the segment.
              </Typography>
              <Typography mt='12px'>
                3. Release the mouse once you’re happy with the new length!
              </Typography>
            </Stack>
            <Stack mb='8px'>
              <Typography fontSize='16px' fontWeight={600} mb='8px'>
                Moving a segment (Clip)
              </Typography>
              <Typography>1. Click and hold on the segment you want to move.</Typography>
              <Typography mt='12px'>
                2. Drag the segment left or right along the timeline to reposition it.
              </Typography>
              <Typography mt='12px'>
                3. Release the mouse once you’ve reached the desired location.
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export { Guidelines }
