import { useQuery } from '@invisible/trpc/client'
import { format } from '@invisible/ui/helpers'
import { ArrowIndicatorIcon } from '@invisible/ui/icons'
import { adminTheme, theme } from '@invisible/ui/mui-theme-v2'
import { Tag } from '@invisible/ui/tag'
import LinearProgress from '@mui/material/LinearProgress'
import { ThemeProvider } from '@mui/material/styles'
import { format as dateFormat, sub } from 'date-fns'

import Chart from './Chart'

const MANTICORE_APP = 'manticore'

const CompanyOverview = ({
  companyId,
  showOverview = true,
  source = MANTICORE_APP,
  isLoadingProps,
}: {
  companyId?: string | null // Manticore company id
  showOverview?: boolean
  source: 'manticore' | 'admin'
  isLoadingProps?: boolean
}) => {
  const { data: companyData, isLoading: companyDataIsLoading } = useQuery(
    ['company.findById', { id: companyId ?? '' }],
    { enabled: !!companyId }
  )
  const { data: reportData, isLoading: reportDataIsLoading } = useQuery(
    ['report.companyOverview', { companyId: companyId ?? '' }],
    { enabled: !!companyId }
  )

  const isLoading = companyDataIsLoading || reportDataIsLoading || isLoadingProps
  if (isLoading)
    return (
      <ThemeProvider theme={source === MANTICORE_APP ? theme : adminTheme}>
        <LinearProgress color='primary' />
      </ThemeProvider>
    )

  const currenMonth = dateFormat(new Date(), 'LLL')
  const lastMonth = dateFormat(sub(new Date(), { months: 1 }), 'LLL')
  const twoMonthsAgo = dateFormat(sub(new Date(), { months: 2 }), 'LLL')

  const formattedData = [
    {
      id: 'Company revenue',
      color: 'hsl(253, 37%, 47%)',
      data: [
        { x: 'Baseline', y: 0 },
        { x: twoMonthsAgo, y: reportData?.two_months_ago_usage_revenue ?? 0 },
        { x: lastMonth, y: reportData?.previous_month_usage_revenue ?? 0 },
        { x: currenMonth, y: reportData?.current_mtd_usage_revenue ?? 0 },
      ],
    },
    {
      id: 'Direct Labor',
      color: 'hsl(253, 10%, 10%)',
      data: [
        { x: 'Baseline', y: 0 },
        { x: twoMonthsAgo, y: reportData?.two_months_ago_direct_labor ?? 0 },
        { x: lastMonth, y: reportData?.previous_month_direct_labor ?? 0 },
        { x: currenMonth, y: reportData?.current_mtd_direct_labor ?? 0 },
      ],
    },
  ]

  const revenueChange =
    (reportData?.previous_month_usage_revenue ?? 0) -
    (reportData?.two_months_ago_usage_revenue ?? 0)
  const dlMarginChange =
    (reportData?.previous_month_dl_margin ?? 0) - (reportData?.two_months_ago_dl_margin ?? 0)
  const gmMarginChange =
    (reportData?.previous_month_client_gm ?? 0) - (reportData?.two_months_ago_client_gm ?? 0)

  const reportChanges = { revenueChange, dlMarginChange, gmMarginChange }

  if (!companyId || !companyData || !reportData) return null

  return (
    <div className='grid w-full grid-cols-2 gap-4 lg:grid-cols-4'>
      {showOverview && (
        <div className='border-main col-span-2 flex h-80 flex-col gap-4 rounded-md border border-solid p-4 text-sm shadow-sm lg:col-span-1'>
          <div className='text-xl font-medium'>Company Overview</div>
          <div className='flex flex-col gap-2'>
            <div className='flex justify-between'>
              <div className='text-header font-medium'>Created:</div>
              <div className='text-paragraphs text-right '>
                {companyData?.createdAt ? new Date(companyData?.createdAt).toDateString() : ''}
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='font-medium'>Last Updated:</div>
              <div className='text-paragraphs text-right '>
                {companyData?.updatedAt ? new Date(companyData?.updatedAt).toDateString() : ''}
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`border-main col-span-2 rounded-md border border-solid shadow-sm ${
          showOverview ? 'lg:col-span-3' : 'lg:col-span-4'
        }`}>
        <div className=''>
          <div className='h-64 w-full'>
            <Chart data={formattedData} />
          </div>
        </div>
      </div>
      <div className='border-main h-[74px] rounded-md border border-solid shadow-sm'>
        <div className='flex items-center justify-between gap-2 p-4'>
          <div className='text-theme-main text-xl font-medium'>
            {reportData?.previous_month_usage_revenue
              ? format.formatCurrency(reportData.previous_month_usage_revenue)
              : 0}
            <div className='text-paragraphs text-sm font-normal'>Last month Revenue</div>
          </div>
          <div className='flex justify-center'>
            {reportChanges?.revenueChange && reportChanges.revenueChange < 0 ? (
              <Tag color='red'>
                <ArrowIndicatorIcon className='rotate-180' />{' '}
                {format.formatCurrency(Math.abs(reportChanges?.revenueChange))}
              </Tag>
            ) : (
              <Tag color='success'>
                <ArrowIndicatorIcon /> {format.formatCurrency(reportChanges?.revenueChange)}
              </Tag>
            )}
          </div>
        </div>
      </div>
      <div className='border-main h-[74px] rounded-md border border-solid shadow-sm'>
        <div className='flex items-center justify-between gap-2 p-4'>
          <div className='text-theme-main text-xl font-medium'>
            {((reportData?.previous_month_dl_margin ?? 0) * 100).toFixed(2) + '%'}
            <div className='text-paragraphs text-sm font-normal'>Last month DL Margin</div>
          </div>

          <div className='flex justify-center'>
            {reportChanges?.dlMarginChange && reportChanges.dlMarginChange < 0 ? (
              <Tag color='red'>
                <ArrowIndicatorIcon className='rotate-180' />{' '}
                {(Math.abs(reportChanges?.dlMarginChange) * 100).toFixed(2) + '%'}
              </Tag>
            ) : (
              <Tag color='success'>
                <ArrowIndicatorIcon /> {(reportChanges?.dlMarginChange * 100).toFixed(2) + '%'}
              </Tag>
            )}
          </div>
        </div>
      </div>
      <div className='border-main h-[74px] rounded-md border border-solid shadow-sm'>
        <div className='flex items-center justify-between gap-2 p-4'>
          <div className='text-theme-main text-xl font-medium'>
            {((reportData?.previous_month_client_gm ?? 0) * 100).toFixed(2) + '%'}
            <div className='text-paragraphs text-sm font-normal'>Last month GM</div>
          </div>

          <div className='flex justify-center'>
            {reportChanges?.gmMarginChange && reportChanges.gmMarginChange < 0 ? (
              <Tag color='red'>
                <ArrowIndicatorIcon className='rotate-180' />{' '}
                {(Math.abs(reportChanges?.gmMarginChange) * 100).toFixed(2) + '%'}
              </Tag>
            ) : (
              <Tag color='success'>
                <ArrowIndicatorIcon /> {(reportChanges?.gmMarginChange * 100).toFixed(2) + '%'}
              </Tag>
            )}
          </div>
        </div>
      </div>
      <div className='border-main h-[74px] rounded-md border border-solid shadow-sm'>
        <div className='flex items-center justify-between gap-2 p-4'>
          <div className='text-theme-main text-xl font-medium'>
            {reportData?.lifetime_usage_revenue
              ? format.formatCurrency(reportData.lifetime_usage_revenue)
              : ''}
            <div className='text-paragraphs text-sm font-normal'>Lifetime Revenue</div>
          </div>
          <div className='flex justify-center'>
            <Tag color='gray'>
              Since {companyData?.createdAt ? new Date(companyData?.createdAt).toDateString() : ''}
            </Tag>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CompanyOverview }
