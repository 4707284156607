import { Dropdown } from '@invisible/ui/dropdown'
import Editor from '@monaco-editor/react'
import { useState } from 'react'

import { _SUPPORTED_LANGUAGES, DEFAULT_CODE_SNIPPETS } from '../common/constants'

interface CodeWACProps {
  readOnly: boolean
  code: string
  onChangeMethod: (inputText: string) => void | undefined
}

const CodeWAC = ({ readOnly, code, onChangeMethod }: CodeWACProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState('python')
  const editorOptions = {
    contextmenu: true,
    fontFamily: 'monospace',
    fontSize: 13,
    lineHeight: 24,
    hideCursorInOverviewRuler: true,
    minimap: {
      enabled: true,
    },
    scrollbar: {
      horizontalSliderSize: 4,
      verticalSliderSize: 18,
    },
    selectOnLineNumbers: true,
    roundedSelection: false,
    readOnly: readOnly,
    automaticLayout: true,
  }

  const handleOnChange = (inputText: string | undefined) => {
    if (typeof inputText === 'string') {
      onChangeMethod(inputText)
    }
  }

  return (
    <div className='!rounded border-2 border-solid border-indigo-200'>
      <Dropdown
        name='Select Language'
        width='100%'
        maxHeight='200px'
        options={_SUPPORTED_LANGUAGES.map((language) => ({
          key: language.toLowerCase(),
          value: language,
        }))}
        selectedKey={selectedLanguage || 'python'}
        onChange={(e) => {
          setSelectedLanguage((e.value as string).toLowerCase())
        }}
      />
      <div className='h-[20vh] max-h-[80vh] min-h-[20vh] resize-y overflow-auto'>
        <Editor
          height='100%'
          width='100%'
          theme='vs-light'
          defaultLanguage='python'
          language={selectedLanguage || 'python'}
          defaultValue={
            DEFAULT_CODE_SNIPPETS[
              selectedLanguage.toUpperCase() as keyof typeof DEFAULT_CODE_SNIPPETS
            ]
          }
          value={code || ''}
          options={editorOptions}
          onChange={handleOnChange}
        />
      </div>
    </div>
  )
}

export { CodeWAC }
