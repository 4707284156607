import { formatInTimeZone } from '@invisible/common/date'
import { SnackbarContext } from '@invisible/common/providers'
import {
  fromGlobalId,
  getErrorMessage,
  parseResponse,
  toGlobalId,
  useExportConfigurationsQuery,
  useTaskSchedulePauseOrResumeMutation,
} from '@invisible/concorde/gql-client'
import { TimezoneToolbar } from '@invisible/ui/datagrid-toolbars'
import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import { ToolbarProps } from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { DataGridPro, GridColDef, GridSortDirection } from '@mui/x-data-grid-pro'
import { useRouter } from 'next/router'
import { useContext, useMemo, useState } from 'react'

import { Activity } from './Activity'
import { CreateExportDialog } from './CreateExportDialog'
import { useTriggerExport } from './hooks/useTriggerExport'
import { capitalizeString } from './schedulerHelpers'

type TRow = {
  id: string
  name: string
  created: Date
  description: string
  schema: string
  createdBy: string
  lastModified: Date
  repeats: string
}

const PINNED_COLUMN_STYLE_OVERRIDES = {
  '& .MuiDataGrid-pinnedColumnHeaders': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'none',
    '& .MuiDataGrid-cell': {
      padding: 0,
    },
  },
}

const EXPORTS_GRID_STYLES = {
  ...PINNED_COLUMN_STYLE_OVERRIDES,
  flex: '1 1 100%',
}

const INITIAL_TABLE_STATE = {
  sorting: { sortModel: [{ field: 'created', sort: 'desc' as GridSortDirection }] },
  pinnedColumns: {
    right: ['actions'],
  },
}

const ExportsHome = () => {
  const { showSnackbar } = useContext(SnackbarContext)
  const { query } = useRouter()
  const [selectedTab, setSelectedTab] = useState('exports')
  const [createExportDialogOpen, setCreateExportDialogOpen] = useState(false)
  const [idBeingEdited, setIdBeingEdited] = useState<string | null>(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState<{
    element: null | HTMLElement
    id: string | null
  }>({ element: null, id: null })

  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const {
    data: exportConfigurationData,
    isLoading,
    refetch,
  } = useExportConfigurationsQuery(
    {
      filters: {
        processId: toGlobalId('Process', query.id as string),
      },
    },
    {
      enabled: !!query.id,
      refetchOnMount: 'always',
    }
  )
  const { triggerExport, isExportTriggerLoading } = useTriggerExport({
    selectedExports: (exportConfigurationData?.exportConfigurations.edges ?? [])
      .filter((config) => selectedRows.includes(config.node.id))
      .map((config) => ({ id: fromGlobalId(config.node.id), name: config.node.name ?? '' })),
  })

  const getScheduleStatus = (exportConfigurationId: string | null) => {
    if (!exportConfigurationId) return
    const operatedExport = findOperatedExport(exportConfigurationId)
    return operatedExport?.exportConfigurationTaskScheduleConfigs[0]?.status
  }

  const findOperatedExport = (id: string) =>
    exportConfigurationData?.exportConfigurations.edges.find((edge) => edge.node.id === id)?.node

  const columns: GridColDef<TRow>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Export name',
        width: 400,
      },
      {
        field: 'created',
        headerName: 'Created',
        width: 200,
        type: 'dateTime',
        renderCell: ({ row: { created } }) => formatInTimeZone({ date: created, tz: 'UTC' }),
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 200,
      },
      {
        field: 'schema',
        headerName: 'Export schema',
        width: 200,
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        align: 'left',
        width: 200,
      },
      {
        field: 'lastModified',
        headerName: 'Last modified',
        width: 200,
        type: 'dateTime',
        renderCell: ({ row: { lastModified } }) =>
          formatInTimeZone({ date: lastModified, tz: 'UTC' }),
      },
      {
        field: 'repeats',
        headerName: 'Repeats on',
        width: 400,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 40,
        getActions: (row) => [
          <IconButton
            size='small'
            onClick={(e) => {
              setMenuAnchorEl({
                element: e.currentTarget,
                id: row.id as string,
              })
            }}>
            <MoreVertIcon />
          </IconButton>,
        ],
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      exportConfigurationData
        ? exportConfigurationData?.exportConfigurations.edges.map((config) => ({
            id: config.node.id,
            name: config.node.name ?? '',
            created: new Date(config.node.createdAt),
            description: config.node.description ?? '',
            schema: config.node.transformationRule.name ?? '',
            createdBy: config.node.createdByUser.name ?? '-',
            lastModified: new Date(config.node.updatedAt),
            repeats: String(
              config.node.exportConfigurationTaskScheduleConfigs.length > 0
                ? `${
                    getScheduleStatus(config.node.id) === 'paused' ? '(paused) ' : ''
                  }${capitalizeString(
                    config.node.exportConfigurationTaskScheduleConfigs[0].description as string
                  )}` // Grabbing first schedule's description
                : 'Does not repeat'
            ),
          }))
        : [],
    [exportConfigurationData]
  )

  const handleExportTrigger = () => {
    const exportConfigs = exportConfigurationData?.exportConfigurations.edges.filter((config) =>
      selectedRows.includes(config.node.id)
    )

    // We are allowing to trigger 3 exports at a time
    if (exportConfigs && exportConfigs.length > 0 && exportConfigs.length <= 3) {
      triggerExport({
        exportConfigIds: exportConfigs.map((config) => fromGlobalId(config.node.id)),
      })
    }
  }

  const { mutateAsync: updateTaskScheduleAction, isLoading: isTaskScheduleUpdateLoading } =
    useTaskSchedulePauseOrResumeMutation({
      onError: (error) => {
        const errorMessage = getErrorMessage(error)
        showSnackbar({
          message: errorMessage,
          variant: 'error',
        })
      },
    })

  const handlePauseAndResume = async (exportConfigurationId: string | null) => {
    if (!menuAnchorEl || !exportConfigurationId) return
    // Get the relevant export configuration
    const operatedExport = findOperatedExport(
      exportConfigurationId as string
    )?.exportConfigurationTaskScheduleConfigs
    if (!operatedExport || !operatedExport.length) return

    const action = operatedExport[0].status === 'active' ? 'pause' : 'resume'
    const taskScheduleId = operatedExport[0].id

    const response = await updateTaskScheduleAction({
      taskScheduleId: taskScheduleId,
      action: action,
    })
    parseResponse(response, (_, message) => {
      showSnackbar({
        message,
        variant: 'error',
      })
    })
    refetch()
  }

  const menuOptions = [
    {
      text: getScheduleStatus(menuAnchorEl.id) === 'active' ? 'Pause' : 'Resume',
      icon:
        getScheduleStatus(menuAnchorEl.id) === 'active' ? (
          <PauseIcon fontSize='small' />
        ) : (
          <PlayArrowIcon fontSize='small' />
        ),
      onClick: () => handlePauseAndResume(menuAnchorEl.id),
      disabled: !getScheduleStatus(menuAnchorEl.id),
    },
    {
      text: 'Edit',
      icon: <EditIcon fontSize='small' />,
      onClick: () => setIdBeingEdited(menuAnchorEl.id),
    },
    {
      text: 'Delete',
      icon: <DeleteIcon fontSize='small' />,
      onClick: () => undefined,
      disabled: true,
    },
  ]

  const exportConfigBeingEdited = exportConfigurationData?.exportConfigurations.edges.find(
    (edge) => edge.node.id === idBeingEdited
  )?.node

  return (
    <MUIThemeProvider>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: '16px' }}>
          <TabList onChange={(_, newValue) => setSelectedTab(newValue)}>
            <Tab label='EXPORTS' value='exports' sx={{ fontWeight: 'normal' }} />
            <Tab label='ACTIVITY' value='activity' sx={{ fontWeight: 'normal' }} />
          </TabList>
        </Box>
        <TabPanel value='exports' sx={{ height: '100%' }}>
          <DataGridPro
            rows={data}
            columns={columns}
            loading={isLoading}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(rows) => setSelectedRows(rows as string[])}
            initialState={INITIAL_TABLE_STATE}
            sx={EXPORTS_GRID_STYLES}
            slots={{
              toolbar: TimezoneToolbar as (p: ToolbarProps) => JSX.Element,
              noRowsOverlay: () => (
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  className='box-border h-full'
                  py={4}
                  px={2}
                  gap={1}>
                  <InfoIcon sx={{ color: 'grey.600' }} />
                  <Typography color='grey.600'>No exports have been created yet.</Typography>
                  <Button
                    variant='text'
                    sx={{ fontWeight: 'normal' }}
                    onClick={() => setCreateExportDialogOpen(true)}>
                    Create export
                  </Button>
                </Stack>
              ),
            }}
            slotProps={{
              toolbar: {
                autoFocus: false,
                isExportable: false,
                trailing: (
                  <>
                    <Tooltip title='Trigger Export'>
                      <Button
                        size='small'
                        startIcon={<PlayArrowIcon sx={{ marginRight: '-4px' }} />}
                        disabled={selectedRows.length > 3 || isExportTriggerLoading}
                        onClick={handleExportTrigger}
                        sx={{ fontWeight: 'normal' }}>
                        Start
                      </Button>
                    </Tooltip>
                    <Tooltip title='Create new Export'>
                      <Button
                        size='small'
                        startIcon={<AddIcon sx={{ marginRight: '-4px' }} />}
                        onClick={() => setCreateExportDialogOpen(true)}
                        sx={{ fontWeight: 'normal' }}>
                        Create export
                      </Button>
                    </Tooltip>
                  </>
                ),
              },
            }}
          />
        </TabPanel>

        <TabPanel value='activity' sx={{ height: '100%' }}>
          <div className='box-border h-full'>
            <Activity processId={query.id as string} />
          </div>
        </TabPanel>
      </TabContext>

      <Menu
        anchorEl={menuAnchorEl.element}
        open={Boolean(menuAnchorEl.id)}
        onClose={() => setMenuAnchorEl({ element: null, id: null })}
        PaperProps={{
          style: {
            width: '150px',
          },
        }}>
        {menuOptions.map(({ text, icon, onClick, disabled }) => (
          <MenuItem onClick={onClick} disabled={disabled}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>

      <CreateExportDialog
        isOpen={createExportDialogOpen || Boolean(exportConfigBeingEdited)}
        onClose={() => {
          setCreateExportDialogOpen(false)
          setIdBeingEdited(null)
        }}
        exportConfigBeingEdited={exportConfigBeingEdited}
      />
    </MUIThemeProvider>
  )
}

export { ExportsHome }
