import { trackClickEvent, trackHoverEvent } from '@invisible/segment'

import { FullScreenIcon } from '../../icons'

// eslint-disable-next-line @typescript-eslint/ban-types
export const FullScreenLinkIcon: React.FC<{ reportFullViewURL: string }> = ({
  reportFullViewURL,
}) => (
  <div
    onMouseOver={() => trackHoverEvent('reports_FullScreen')}
    onClick={() => trackClickEvent('reports_FullScreen')}>
    <a
      className='cursor-pointer'
      data-test='detail-view'
      href={reportFullViewURL}
      target='_blank'
      rel='noreferrer'>
      <FullScreenIcon className='text-theme-main' height={16} width={16} />
    </a>
  </div>
)
