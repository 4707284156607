import { useEffect, useState } from 'react'

import { purple, styled } from '../themes'

interface SwitchComponentProps {
  isOn: boolean | null
}

interface ISwitchProps {
  isOn?: boolean | null
  onToggle: (value: boolean | null) => void
  disabled?: boolean
}

const SwitchButton = styled.span<SwitchComponentProps>`
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  background-color: #ffffff;
  transform: ${(props) =>
    props.isOn === null
      ? 'translateX(11px)'
      : props.isOn === true
      ? 'translateX(22px)'
      : 'inherit'};
  transition: 0.5s;
  filter: drop-shadow(0px 2px 4px rgba(0, 35, 11, 0.2));
`

const SwitchContainer = styled.button<SwitchComponentProps>`
  position: relative;
  width: 44px;
  height: 22px;
  background-color: ${(props) =>
    props.isOn === null ? '#DFDBED' : props.isOn === true ? '#604CA5' : '#DCDBDD'};
  border-radius: 22px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:focus {
    outline: 1px solid ${purple(2)};
  }
`

const NullSwitch = (props: ISwitchProps) => {
  const [isOn, setIsOn] = useState<boolean | null>(props.isOn ?? null)
  const isControlled = props.isOn !== undefined

  const handleOnClick = () => {
    if (!isControlled) {
      setIsOn((on) => !on)
    }
    props.onToggle(!isOn)
  }

  useEffect(() => {
    if (!isControlled) props.onToggle(isOn)
  }, [isControlled, isOn, props])

  useEffect(() => {
    if (props.isOn !== undefined) setIsOn(props.isOn)
  }, [props.isOn])

  return (
    <SwitchContainer
      onClick={handleOnClick}
      isOn={isOn}
      data-cy='nullSwitch'
      disabled={props.disabled}>
      <SwitchButton isOn={isOn} />
    </SwitchContainer>
  )
}

export { NullSwitch }
