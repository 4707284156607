import { SnackbarContext } from '@invisible/common/providers'
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record'

import { NEXT_PUBLIC_AUDIO_FILE_RECORDINGS_BUCKET } from '../../../../config/env'
import { Controls } from './Controls'
import { formatDuration, getComputedName } from './helpers'
import { useWaveSurfer } from './useWaveSurfer'

interface IProps {
  maxLength?: number
  minLength?: number
  fileNameSegments: string[]
  companyName: string
  setRecordProperties: (arg: { url: string; fileName: string }) => void
  visible: boolean
  selectedMicId: string
  selectedMicLabel: string
  showPlayer: () => void
  gcsDirectory: string
  stepName: string
  handleRecordSave: (arg: {
    url: string
    fileName: string
    length: number
    micLabel: string
  }) => Promise<void>
  resetRecorder: () => void
  uploadRecording: (arg: { blob: Blob; localUrl: string; fileName: string }) => Promise<void>
  isRecordingUploading: boolean
}

const Recorder = ({
  visible,
  selectedMicId,
  showPlayer,
  maxLength = 24 * 60 * 60,
  minLength = 0,
  companyName,
  fileNameSegments,
  gcsDirectory,
  handleRecordSave,
  stepName,
  selectedMicLabel,
  resetRecorder,
  uploadRecording,
  isRecordingUploading,
}: IProps) => {
  const { showSnackbar } = useContext(SnackbarContext)
  const [recorderContainer, setRecordContainer] = useState<HTMLDivElement | null>(null)
  const recordPlugin = useRef<RecordPlugin | null>(
    RecordPlugin.create({
      scrollingWaveform: true,
      renderRecordedAudio: true,
      mimeType: 'audio/webm',
    })
  )
  const [recordProgress, setRecordProgress] = useState(0)
  const timeLeft = Math.max(maxLength - recordProgress / 1000, 0)

  const options = useMemo(
    () => ({
      waveColor: 'black',
      barWidth: 3,
      barHeight: 0.7,
      barGap: 10,
      barRadius: 4,
      height: 100,
      plugins: [...(recordPlugin.current ? [recordPlugin.current] : [])],
    }),
    []
  )

  useWaveSurfer({
    container: recorderContainer,
    options,
  })

  // Init record plugin event listeners
  useEffect(() => {
    const record = recordPlugin.current
    if (!record) return

    const handleRecordProgress = (time: number) => {
      if (time > maxLength * 1000) {
        // Handle maximum recording time
        record.stopRecording()
        setTimeout(() => showPlayer(), 100)
      }
      setRecordProgress(time)
    }
    const handleRecordEnd = async (blob: Blob) => {
      try {
        const url = URL.createObjectURL(blob)
        const fileName = getComputedName({
          companyName,
          stepName,
          segments: fileNameSegments,
          recordingLength: recordProgress,
          recordTime: new Date(),
        })

        // Initiate upload to gcs
        await uploadRecording({ blob, localUrl: url, fileName: `${fileName}.webm` })
        await handleRecordSave({
          url: `gs://${NEXT_PUBLIC_AUDIO_FILE_RECORDINGS_BUCKET}/${gcsDirectory}/${fileName}.webm`,
          fileName: `${fileName}.webm`,
          length: recordProgress / 1000,
          micLabel: selectedMicLabel,
        })
      } catch (_) {
        showSnackbar({
          message: 'Something went wrong. Please try again.',
          variant: 'error',
        })
      }
    }
    record.on('record-progress', handleRecordProgress)
    record.once('record-end', handleRecordEnd)

    return () => {
      record?.un('record-progress', handleRecordProgress)
      record?.un('record-end', handleRecordEnd)
    }
  }, [
    companyName,
    fileNameSegments,
    gcsDirectory,
    handleRecordSave,
    maxLength,
    recordProgress,
    showPlayer,
    stepName,
    uploadRecording,
    selectedMicLabel,
  ])

  const handlePausePlay = async () => {
    if (!recordPlugin.current) return

    if (recordPlugin.current.isPaused()) {
      recordPlugin.current.resumeRecording()
      return
    }
    if (recordPlugin.current.isRecording()) {
      recordPlugin.current.pauseRecording()
      return
    }
    await recordPlugin.current.startRecording({ deviceId: selectedMicId })
  }

  const handleDone = () => {
    if (!recordPlugin.current) return
    recordPlugin.current.stopRecording()
    showPlayer()
  }

  const getPausePlayButtonProps = () => {
    if (!recordPlugin.current)
      return { text: 'RECORD', icon: <RadioButtonCheckedIcon />, color: 'error' } as const
    if (recordPlugin.current.isPaused())
      return { text: 'RESUME', icon: <RadioButtonCheckedIcon />, color: 'error' } as const
    if (recordPlugin.current.isRecording())
      return { text: 'PAUSE', icon: <PauseCircleOutlineOutlinedIcon />, color: 'inherit' } as const
    return { text: 'RECORD', icon: <RadioButtonCheckedIcon />, color: 'error' } as const
  }

  return (
    <Box display={visible ? 'block' : 'none'}>
      <Typography fontSize='14px' mb='16px'>
        New audio
      </Typography>
      <Box
        ref={(node) => setRecordContainer(node as HTMLDivElement)}
        sx={{
          '& ::part(wrapper)': { backgroundColor: 'grey.200', borderRadius: '4px' },
        }}
      />

      <Stack direction='row' justifyContent='space-between' alignItems='center' position='relative'>
        <Controls />
        <Typography
          fontWeight={500}
          fontSize='16px'
          position='absolute'
          left='50%'
          sx={{ transform: 'translateX(-50%)' }}>
          {formatDuration(recordProgress, true)}
        </Typography>
        {timeLeft < maxLength / 4 ? (
          <Typography color='red' variant='body2'>
            Recording ending in {timeLeft.toFixed(0)} second(s)...
          </Typography>
        ) : null}
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between' mt='16px'>
        <LoadingButton
          size='small'
          disabled={isRecordingUploading}
          sx={{ fontWeight: 'normal' }}
          onClick={handlePausePlay}
          startIcon={getPausePlayButtonProps().icon}
          variant='contained'
          color={getPausePlayButtonProps().color}>
          {getPausePlayButtonProps().text}
        </LoadingButton>
        <Stack direction='row' alignItems='center' justifyContent='space-between' gap='8px'>
          <Button
            size='small'
            sx={{ fontWeight: 'normal' }}
            onClick={() => {
              recordPlugin.current?.pauseRecording()
              resetRecorder()
            }}>
            CANCEL
          </Button>
          <LoadingButton
            loading={isRecordingUploading}
            size='small'
            sx={{ fontWeight: 'normal' }}
            variant='outlined'
            disabled={recordProgress < minLength * 1000}
            onClick={handleDone}>
            DONE
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  )
}

export { Recorder }
