import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { SnackbarContext } from '@invisible/common/providers'
import { logger } from '@invisible/logger/client'
import { useContext, useQuery } from '@invisible/trpc/client'
import { Button } from '@invisible/ui/button'
import { theme } from '@invisible/ui/mui-theme-v2'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import InfoIcon from '@mui/icons-material/Info'
import { Tooltip } from '@mui/material'
import MUIButton from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { compact, flatten, groupBy, sampleSize, shuffle } from 'lodash/fp'
import pMap from 'p-map'
import pTimes from 'p-times'
import { useContext as useReactContext, useEffect, useMemo, useState } from 'react'

import { useBaseRunCreate } from '../../hooks/useBaseRunCreate'
import { useBaseRunCreateMany } from '../../hooks/useBaseRunCreateManyWizardAction'
import { useBaseRunDeleteWithStepRunReference } from '../../hooks/useBaseRunDeleteWithStepRunReference'
import { useBaseRunFindManyByParents } from '../../hooks/useBaseRunFindManyByParents'
import { useBaseRunVariableFindManyByBaseRunId } from '../../hooks/useBaseRunVariableFindManyByBaseRunId'
import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { useFirstManualStepForBaseRun } from '../../hooks/useFirstManualStepForBaseRun'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import Markdown from '../common/Markdown'
import { PromptEdit } from './PromptEdit'
import { PromptResponse } from './Response'

type TManyBaseRunData = NonNullable<inferQueryOutput<'baseRun.findManyByParents'>>
type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]
type TFindChildBaseRunsData = NonNullable<inferQueryOutput<'baseRun.findChildBaseRuns'>>

interface IProps extends WizardSchemas.WACConfig.TSchema {
  baseRun: TBaseRun
  stepRun: TStepRun
  rlhf2: WizardSchemas.RLHF2.TSchema
  isReadOnly: boolean
}

type TModelConfig = NonNullable<WizardSchemas.RLHF2.TSchema['models']>[number]

const RLHFWAC2 = ({ baseRun, rlhf2: config, stepRun, isReadOnly }: IProps) => {
  const reactQueryContext = useContext()
  const [promptText, setPromptText] = useState('')
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isLastResponseEmpty, setIsLastResponseEmpty] = useState(false)
  const [editPromptInfo, setEditPromptInfo] = useState<Record<string, boolean>>({})
  const [getResponseLoading, setGetResponseLoading] = useState(false)
  const [editLastResponse, setEditLastResponse] = useState<Record<string, boolean>>({})
  const [promptMetadataValidationFailures, setPromptMetadataValidationFailures] = useState<
    Array<string>
  >([])
  const { showSnackbar } = useReactContext(SnackbarContext)

  const responseMessageStyles = {
    invalid: 'bg-red-600',
    valid: 'bg-pink-100',
  }
  const promptMessageStyles = {
    invalid: 'bg-red-600',
    valid: 'bg-indigo-100',
  }

  const { dispatch } = useWizardState()

  const conversationData = useBaseRunVariableFindManyByBaseRunId({
    baseRunIds: [baseRun.id],
  })
  const { data: prompts } = useQuery([
    'baseRun.findChildBaseRuns',
    {
      baseId: config?.promptsBaseId as string,
      parentBaseRunId: baseRun.id,
    },
  ])
  const { data: firstManualStepRun } = useFirstManualStepForBaseRun({
    baseRunId: baseRun.id,
  })
  const firstManualStepRunCreatedAt = useMemo(
    () => firstManualStepRun?.createdAt ?? '',
    [firstManualStepRun]
  )

  // Parses prompts base runs into a typed object with its base run variables
  const normalizedPrompts = useMemo(
    () =>
      (prompts ?? [])
        .map((prompt) => ({
          id: prompt.id,
          text: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.promptTextBaseVariableId
          )?.value as string,
          index: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.promptIndexBaseVariableId
          )?.value as number,
          acceptedResponse: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.promptResponseBaseVariableId
          )?.value as string,
          acceptedModel: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.promptAcceptedModelBaseVariableId
          )?.value as string,
          responseId: prompt.baseRunVariables.find(
            (variable) => variable.baseVariable.id === config?.responseIdBaseVariableId
          )?.value as string,
          createdAt: prompt.createdAt,
        }))
        .sort((a, b) => a.index - b.index),
    [prompts, config]
  )
  const tokenCount = useMemo(
    () =>
      (normalizedPrompts ?? [])
        .map((prompt) => (prompt?.acceptedResponse?.length ?? 0) + (prompt?.text?.length ?? 0))
        .reduce((sum, length) => sum + length, 0),
    [normalizedPrompts]
  )

  const unsubmittedPrompt = normalizedPrompts?.find(
    (prompt) => !prompt.acceptedResponse || editLastResponse[prompt.responseId]
  )

  const { data: activePromptResponses } = useQuery(
    [
      'baseRun.findChildBaseRuns',
      {
        baseId: config?.responsesBaseId as string,
        parentBaseRunId: unsubmittedPrompt?.id as string,
      },
    ],
    {
      enabled: !!unsubmittedPrompt,
    }
  )

  // Uses the Prompt IDs as the parent IDs to fetch all responses.
  const allResponses = useBaseRunFindManyByParents({
    parentIds: normalizedPrompts?.map((prompt) => prompt.id) ?? [],
    includeBaseVariableIds: (config.responseMetadata?.fields ?? [])
      .map((metadata) => metadata.baseVariableId as string)
      .filter(Boolean),
  })

  // Checks if required metadata are filled in the response
  const validateResponseMetadata = (response: TManyBaseRunData[number]) => {
    if (!response.baseRunVariables) return true

    for (const variable of response.baseRunVariables) {
      if (
        (config.responseMetadata?.fields ?? []).some(
          (metadata) =>
            metadata.required &&
            metadata.baseVariableId === variable.baseVariableId &&
            variable.value === null
        )
      ) {
        return false
      }
    }

    return true
  }

  const validatePromptMetadata = (prompt: TFindChildBaseRunsData[number]) => {
    if (!prompt.baseRunVariables) return true

    for (const variable of prompt.baseRunVariables) {
      if (
        (config.promptMetadata?.fields ?? []).some(
          (metadata) =>
            metadata.required &&
            metadata.baseVariableId === variable.baseVariable.id &&
            variable.value === null
        )
      ) {
        return false
      }
    }

    return true
  }

  // Checks if all responses have valid metadata. Creates an array of all PromptIDs that are failing validation.
  const responseMetadataValidationFailures = (allResponses ?? [])
    .filter((response) => !validateResponseMetadata(response))
    .map((r) => r.parentId as string)

  // Checks if all prompts have valid metadata. Creates an array of all PromptIDs that are failing validation.
  useEffect(() => {
    setPromptMetadataValidationFailures([])
    setPromptMetadataValidationFailures((prev) => [
      ...prev,
      ...(prompts ?? [])
        .filter((prompt) => !validatePromptMetadata(prompt))
        .map((p) => p.id as string),
    ])
  }, [prompts])

  // If there are 0 validation failures, set the RLHF2-ResponseMetadata in setReadyForSubmit to true, so the Wizard Submit button can be activated.
  const isResponseMetadataReadyForSubmit = responseMetadataValidationFailures.length === 0
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHF2-ResponseMetadata',
      value: responseMetadataValidationFailures.length === 0,
    })
  }, [isResponseMetadataReadyForSubmit])

  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHF2-PromptMetadata',
      value: promptMetadataValidationFailures.length === 0,
    })
  }, [promptMetadataValidationFailures])

  // If there are no unsubmitted prompts, set the RLHF2 in setReadyForSubmit to true, so the Wizard Submit button can be activated.
  useEffect(() => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHF2',
      value:
        (config.allowEndingWithoutResponse || !unsubmittedPrompt) &&
        (prompts?.length ?? 0) >= (config.minMaxTurns?.[0] ?? 1),
    })
  }, [unsubmittedPrompt, prompts])

  const normalizedActivePromptResponses = useMemo(() => {
    const sortedResponses = (activePromptResponses ?? [])
      .map((response) => ({
        id: response.id,
        text: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseTextBaseVariableId
        )?.value as string,
        originalText: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseOriginalTextBaseVariableId
        )?.value as string,
        index: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseIndexBaseVariableId
        )?.value as number,
        model: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseModelBaseVariableId
        )?.value as string,
      }))
      .filter((response) => response.text)
      .sort((a, b) => a.index - b.index)

    const groupedResponses = groupBy('model', sortedResponses)
    return { flattened: sortedResponses, grouped: groupedResponses }
  }, [activePromptResponses, config])

  const { mutateAsync: createBaseRun, isLoading: createBaseRunLoading } = useBaseRunCreate()

  const { mutateAsync: deleteBaseRuns, isLoading: deletingBaseRuns } =
    useBaseRunDeleteWithStepRunReference({
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
      },
      onError: (error) => {
        showSnackbar({
          message: 'Something went wrong while deleting prompts or responses.',
          variant: 'error',
        })
        logger.error('Failed to delete prompts or responses.', error)
      },
    })

  const { mutateAsync: createBaseRuns, isLoading: createBaseRunsLoading } = useBaseRunCreateMany({
    onSettled: () => {
      reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
    },
  })

  const { mutateAsync: updateVariable, isLoading: isUpdatingVBaseRunVariable } =
    useBaseRunVariablesWizardUpdate({
      onMutate: async (data) => {
        reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
          [
            'baseRun.findChildBaseRuns',
            {
              baseId: config?.promptsBaseId as string,
              parentBaseRunId: baseRun.id,
            },
          ],
          (prevData) => {
            if (!prevData) return

            return prevData.map((b) =>
              b.id !== data[0].baseRunId
                ? b
                : {
                    ...b,
                    baseRunVariables: b.baseRunVariables.map((v) =>
                      v.baseVariable.id !== data[0].baseVariableId
                        ? v
                        : { ...v, value: data[0].value }
                    ),
                  }
            )
          }
        )
      },
      onSettled: () => {
        reactQueryContext.invalidateQueries('baseRun.findChildBaseRuns')
        reactQueryContext.invalidateQueries('baseRun.findManyByParents')
        reactQueryContext.invalidateQueries('baseRunVariable.findManyByBaseRunId')
        reactQueryContext.invalidateQueries('baseRunVariable.findOneByBaseVariableIdAndBaseRunId')
      },
    })

  const responsesBaseRunVariables = useBaseRunVariableFindManyByBaseRunId({
    baseRunIds: compact(activePromptResponses?.map((res) => res.id) ?? []),
  })

  const promptBaseRunVariables = useBaseRunVariableFindManyByBaseRunId({
    baseRunIds: compact(normalizedPrompts?.map((prompt) => prompt.id) ?? []),
  })

  useEffect(() => {
    const isWacSubmitting = createBaseRunLoading || createBaseRunsLoading
    dispatch({
      type: 'setIsWacSubmitting',
      key: 'RLHF2',
      value: isWacSubmitting,
    })
  }, [dispatch, createBaseRunLoading, createBaseRunsLoading])

  const conversationPreamble = config?.conversationPreambleBaseVariableId
    ? (conversationData?.find(
        (v) => v.baseVariableId === config?.conversationPreambleBaseVariableId
      )?.value as string)
    : null

  const queryModel = async (input: {
    query: string
    model: string
    chatHistory: { id: string; text: string; acceptedResponse: string }[]
    perModelCallCount?: number
    meta?: Record<string, any>
  }) => {
    const count = input?.perModelCallCount ?? 1
    const results = await pTimes(
      count < 1 ? 1 : count,
      async () =>
        await fetch('/api/wacs/rlhf/query-model', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            baseRunId: baseRun.id,
            chatHistory: input.chatHistory,
            query: input.query,
            model: input.model,
            meta: input.meta,
            preamble: conversationPreamble,
          }),
        }).then(async (res) => {
          try {
            return res.json()
          } catch (error) {
            return {
              message: await res.text(),
            }
          }
        })
    )
    return results.reduce((acc, curr) => {
      if (!Array.isArray(curr)) {
        showSnackbar({
          message: `Unable to fetch response for model: ${input.model}. \n${curr?.message ?? ''}`,
          variant: 'error',
        })
        logger.error(`Error fetching model: ${input.model} response.`, {
          message: curr.message ?? '',
        })
        return acc
      }
      return [...acc, ...curr]
    }, [])
  }

  const randomizeModels = (models: TModelConfig[]) => {
    if (config.allowRandomSampling && config.numOfModelsToSample) {
      return sampleSize(config.numOfModelsToSample, models)
    }
    return models
  }

  const randomizeModelResponses = (
    responses: {
      model: string
      index: number
      message: string
      metaFields: { value: any; baseVariableId: string }[]
    }[]
  ) => {
    if (config.allowRandomizeModelResponses) {
      return shuffle(responses)
    }
    return responses
  }

  const resubmitPrompt = async (failedPrompt: { id: string; text: string }) => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHF2',
      value: config.allowEndingWithoutResponse ?? false,
    })
    setGetResponseLoading(true)
    setIsLastResponseEmpty(false)
    const modelResponses = await pMap(randomizeModels(config.models ?? []), async (model) => {
      const metaParams = model.responseMetaParams ?? []
      const responses = await queryModel({
        query: failedPrompt.text,
        model: model.name,
        chatHistory:
          flatten(
            normalizedPrompts?.filter((prompt: { id: string }) => failedPrompt.id !== prompt.id) // Exclude failed prompt
          ) ?? [],
        perModelCallCount: model?.numOfCalls,
        meta: model.params.reduce((acc, param) => ({ ...acc, [param.name]: param.value }), {}),
      })
      return responses.map(
        (response: { text: string; model?: string; meta?: any }, index: number) => ({
          message: response.text,
          model: model.name,
          index: index + 1,
          metaFields: metaParams
            .map((p) => ({
              value: response.meta?.[p.key],
              baseVariableId: p.baseVariableId,
            }))
            .filter((p) => p.baseVariableId),
        })
      )
    })
    setGetResponseLoading(false)

    if (modelResponses && flatten(modelResponses).every((res) => res.message)) {
      // Create responses
      await createBaseRuns({
        baseId: config?.responsesBaseId as string,
        parentBaseRunId: failedPrompt.id,
        initialValuesArray: randomizeModelResponses(flatten(modelResponses)).map(
          (response, index) => [
            {
              baseVariableId: config?.responseTextBaseVariableId as string,
              value: response.message,
            },
            {
              baseVariableId: config?.responseOriginalTextBaseVariableId as string,
              value: response.message,
            },
            {
              baseVariableId: config?.responseIndexBaseVariableId as string,
              value: index + 1,
            },
            {
              baseVariableId: config?.responseModelBaseVariableId as string,
              value: response.model,
            },
            ...response.metaFields,
          ]
        ),
        sourceStepRunId: stepRun.id,
      })
    } else if (modelResponses) {
      setIsLastResponseEmpty(true)
    }
  }

  const handlePromptSubmission = async () => {
    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHF2',
      value: config.allowEndingWithoutResponse ?? false,
    })
    setGetResponseLoading(true)
    setIsLastResponseEmpty(false)
    const modelResponses = await pMap(randomizeModels(config.models ?? []), async (model) => {
      const metaParams = model.responseMetaParams ?? []
      const responses = await queryModel({
        query: promptText,
        model: model.name,
        chatHistory: flatten(normalizedPrompts) ?? [],
        perModelCallCount: model?.numOfCalls,
        meta: model.params.reduce((acc, param) => ({ ...acc, [param.name]: param.value }), {}),
      })
      return responses.map(
        (response: { text: string; model?: string; meta?: any }, index: number) => ({
          message: response.text,
          model: model.name,
          index: index + 1,
          metaFields: metaParams
            .map((p) => ({
              value: response.meta?.[p.key],
              baseVariableId: p.baseVariableId,
            }))
            .filter((p) => p.baseVariableId),
        })
      )
    })
    setGetResponseLoading(false)

    const prompt = await createBaseRun({
      baseId: config?.promptsBaseId as string,
      parentBaseRunId: baseRun.id,
      stepRunId: stepRun.id,
      initialValues: [
        {
          baseVariableId: config?.promptTextBaseVariableId as string,
          value: promptText,
        },
        {
          baseVariableId: config?.promptIndexBaseVariableId as string,
          value: (prompts?.length ?? 0) + 1,
        },
      ],
    })

    if (modelResponses && flatten(modelResponses).every((res) => res.message)) {
      // Create new responses
      await createBaseRuns({
        baseId: config?.responsesBaseId as string,
        parentBaseRunId: prompt.id,
        initialValuesArray: randomizeModelResponses(flatten(modelResponses)).map(
          (response, index) => [
            {
              baseVariableId: config?.responseTextBaseVariableId as string,
              value: response.message,
            },
            {
              baseVariableId: config?.responseOriginalTextBaseVariableId as string,
              value: response.message,
            },
            {
              baseVariableId: config?.responseModelBaseVariableId as string,
              value: response.model,
            },
            {
              baseVariableId: config?.responseIndexBaseVariableId as string,
              value: index + 1,
            },
            ...response.metaFields,
          ]
        ),
        sourceStepRunId: stepRun.id,
      })
    }

    setPromptText('')
  }

  const handleDeletePrompt = async (prompt: { id: string; responseId: string }) => {
    if (
      !window.confirm(
        "You are about to delete this prompt and it's response. \nThis will also delete subsequent prompts and responses if any. \nAre you sure?"
      )
    )
      return

    const currentPromptCreatedAt = prompts?.find((p) => p.id === prompt.id)?.createdAt ?? ''
    const subsequentPrompts = prompts?.filter(
      (p) => p.createdAt > currentPromptCreatedAt && p.id !== prompt.id
    )
    const promptsResponses = await Promise.all(
      [...(subsequentPrompts || []), prompt]?.map((p) =>
        reactQueryContext.fetchQuery([
          'baseRun.findChildBaseRuns',
          {
            baseId: config?.responsesBaseId as string,
            parentBaseRunId: p.id as string,
          },
        ])
      )
    )
    const baseRunsIdsToDelete = [
      prompt.id,
      ...(subsequentPrompts || []).map((p) => p.id),
      ...flatten(promptsResponses).map((r) => r.id),
    ]
    await deleteBaseRuns({
      baseRunIds: baseRunsIdsToDelete,
      stepRunId: stepRun.id,
    })
    reactQueryContext.queryClient.setQueryData<TFindChildBaseRunsData | undefined>(
      [
        'baseRun.findChildBaseRuns',
        {
          baseId: config?.promptsBaseId as string,
          parentBaseRunId: baseRun.id,
        },
      ],
      (prevData) => {
        if (!prevData) return
        return prevData.filter((baseRun) => !baseRunsIdsToDelete.includes(baseRun.id))
      }
    )
  }

  const handleRefetchPromptResponses = async (prompt: { id: string; text: string }) => {
    if (
      !window.confirm(
        "You are about to delete this prompt's responses and fetch new ones. \nAre you sure?"
      )
    )
      return
    setGetResponseLoading(true)

    await updateVariable({
      stepRunId: stepRun.id,
      data: [
        {
          baseRunId: prompt.id,
          baseVariableId: config?.promptResponseBaseVariableId as string,
          value: null,
        },
        {
          baseRunId: prompt.id,
          baseVariableId: config?.promptAcceptedModelBaseVariableId as string,
          value: null,
        },
        {
          baseRunId: prompt.id,
          baseVariableId: config?.responseIdBaseVariableId as string,
          value: null,
        },
      ],
    })
    const promptsResponses = await Promise.all(
      [{ id: prompt.id }]?.map((p) =>
        reactQueryContext.fetchQuery([
          'baseRun.findChildBaseRuns',
          {
            baseId: config?.responsesBaseId as string,
            parentBaseRunId: p.id as string,
          },
        ])
      )
    )
    const baseRunsIdsToDelete = [...flatten(promptsResponses).map((r) => r.id)]
    if (baseRunsIdsToDelete.length > 0) {
      await deleteBaseRuns({
        baseRunIds: baseRunsIdsToDelete,
        stepRunId: stepRun.id,
      })
    }
    await resubmitPrompt(prompt)
  }

  const textDirection = config.promptResponseTextDirection ?? 'auto'

  return (
    <div className='box-border h-full w-full bg-white p-2'>
      <ThemeProvider theme={theme}>
        <div className='text-paragraphs py-2 text-sm'>
          Your conversations (
          {config.minMaxTurns ? (
            <span>
              Turns count: {config.minMaxTurns?.[0] ?? 1} Min, {config.minMaxTurns?.[1] ?? 1} Max
            </span>
          ) : null}
          )<span className='ml-3'>Token count: {tokenCount}</span>
        </div>
        <div className='relative box-border flex max-h-[calc(100%-100px)] w-full flex-col justify-between gap-4 rounded-md border border-solid border-gray-300 p-3'>
          <div className='flex h-full w-full flex-col gap-2 overflow-auto'>
            {normalizedPrompts?.map((prompt, index) => (
              <div key={index}>
                {editPromptInfo[prompt.id] ? (
                  <PromptEdit
                    stepRunId={stepRun.id}
                    parentBaseRunId={baseRun.id}
                    baseRunVariables={(promptBaseRunVariables ?? []).filter(
                      (b) => b.baseRunId === prompt.id
                    )}
                    stepRun={stepRun}
                    editMode={config.allowEditPrompt && !isReadOnly}
                    prompt={prompt}
                    wacConfig={config}
                    textDirection={textDirection}
                    handleClose={() =>
                      setEditPromptInfo({
                        [prompt.id]: !editPromptInfo[prompt.id],
                      })
                    }
                  />
                ) : (
                  <div className='flex items-center'>
                    <div
                      className={`relative w-fit max-w-[50%] rounded-xl py-2 px-4 text-sm shadow ${
                        promptMessageStyles[
                          promptMetadataValidationFailures.includes(prompt.id) ? 'invalid' : 'valid'
                        ]
                      }`}>
                      <span className='absolute right-1 top-0 p-1 text-[8px]'>{prompt.index}</span>
                      <Markdown
                        dir={textDirection}
                        className='overflow-auto'
                        components={{
                          p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
                        }}>
                        {prompt.text}
                      </Markdown>{' '}
                    </div>
                    <div className='ml-1 flex items-center'>
                      {(prompt.createdAt > firstManualStepRunCreatedAt ||
                        config.allowEditingImportedTurns) && (
                        <>
                          <Button
                            icon='EditOutlineIcon'
                            size='md'
                            variant='subtle'
                            shape='square'
                            title='Edit'
                            disabled={isReadOnly}
                            onClick={() =>
                              setEditPromptInfo({
                                [prompt.id]: !editPromptInfo[prompt.id],
                              })
                            }
                          />
                          {config.allowDeletePrompt && !getResponseLoading && (
                            <Button
                              icon='DeleteOutlineIcon'
                              size='md'
                              variant='subtle'
                              shape='square'
                              title='Delete'
                              disabled={deletingBaseRuns || isReadOnly}
                              onClick={() => handleDeletePrompt(prompt)}
                            />
                          )}
                        </>
                      )}
                      {/* Enable refetch response on last prompt */}
                      {!getResponseLoading && normalizedPrompts.length === index + 1 && (
                        <Button
                          icon='RedoIcon'
                          size='md'
                          variant='subtle'
                          shape='square'
                          title='Refetch Response'
                          disabled={
                            deletingBaseRuns ||
                            createBaseRunsLoading ||
                            isUpdatingVBaseRunVariable ||
                            isReadOnly
                          }
                          onClick={() => handleRefetchPromptResponses(prompt)}
                        />
                      )}
                      {/* Resubmit prompt if no responses */}
                      {!prompt.acceptedResponse &&
                        !getResponseLoading &&
                        normalizedPrompts.length !== index + 1 &&
                        !(normalizedActivePromptResponses.flattened.length > 0) && (
                          <span className='flex'>
                            <Button
                              icon='RedoIcon'
                              size='md'
                              variant='subtle'
                              shape='square'
                              color='theme'
                              title='Resubmit prompt'
                              disabled={
                                getResponseLoading ||
                                createBaseRunsLoading ||
                                createBaseRunLoading ||
                                isReadOnly
                              }
                              onClick={() => resubmitPrompt(prompt)}
                            />
                          </span>
                        )}
                    </div>
                  </div>
                )}
                {prompt.acceptedResponse ? (
                  <div className='flex items-center'>
                    {editLastResponse[prompt.responseId] ? (
                      <div className='mt-3 flex gap-4'>
                        <div>
                          <div className='mt-3 flex gap-4'>
                            {(normalizedActivePromptResponses.flattened ?? []).map(
                              (response, index) => (
                                <div className='min-w-[600px]'>
                                  <PromptResponse
                                    textDirection={textDirection}
                                    response={{
                                      ...response,
                                      index: index + 1,
                                      text:
                                        response.id === prompt.responseId
                                          ? prompt.acceptedResponse
                                          : response.text,
                                    }}
                                    stepRun={stepRun}
                                    activePromptId={prompt.id}
                                    activePromptCreatedAt={prompt.createdAt}
                                    editMode={
                                      config.allowEditResponse && !isReadOnly
                                        ? response.id === prompt.responseId
                                        : false
                                    }
                                    key={response.id}
                                    wacConfig={config}
                                    baseRunVariables={(responsesBaseRunVariables ?? []).filter(
                                      (b) => b.baseRunId === response.id
                                    )}
                                    handleClose={() =>
                                      setEditLastResponse({
                                        [prompt.responseId]: !editLastResponse[prompt.responseId],
                                      })
                                    }
                                    metadataValidationFailures={responseMetadataValidationFailures}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`max-w-1/2 relative ml-auto mt-2 mr-1 w-fit max-w-[50%] overflow-auto rounded-xl py-2 px-4 text-sm shadow ${
                          responseMessageStyles[
                            responseMetadataValidationFailures.includes(prompt.id)
                              ? 'invalid'
                              : 'valid'
                          ]
                        }`}>
                        <span className='absolute right-1 top-0 p-1 text-[8px]'>
                          {prompt.index}
                        </span>
                        <Markdown
                          dir={textDirection}
                          components={{
                            p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
                          }}>
                          {prompt.acceptedResponse as string}
                        </Markdown>
                      </div>
                    )}
                    {!editLastResponse[prompt.responseId] &&
                    (prompt.createdAt > firstManualStepRunCreatedAt ||
                      config.allowEditingImportedTurns) ? (
                      <Button
                        icon='EditOutlineIcon'
                        size='md'
                        variant='subtle'
                        shape='square'
                        title='Edit'
                        disabled={isReadOnly}
                        onClick={() =>
                          setEditLastResponse({
                            [prompt.responseId]: !editLastResponse[prompt.responseId],
                          })
                        }
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            ))}

            {!getResponseLoading &&
            unsubmittedPrompt &&
            !Object.values(editLastResponse).some((value) => value) &&
            normalizedActivePromptResponses.flattened ? (
              <div className='mt-4 flex gap-4'>
                <div>
                  <div className='mt-3 flex gap-4'>
                    {(normalizedActivePromptResponses.flattened ?? []).map((response, index) => (
                      <div className='min-w-[600px]'>
                        <PromptResponse
                          textDirection={textDirection}
                          response={{ ...response, index: index + 1 }}
                          activePromptId={unsubmittedPrompt.id}
                          activePromptCreatedAt={unsubmittedPrompt.createdAt}
                          key={response.id}
                          wacConfig={config}
                          stepRun={stepRun}
                          baseRunVariables={(responsesBaseRunVariables ?? []).filter(
                            (b) => b.baseRunId === response.id
                          )}
                          metadataValidationFailures={responseMetadataValidationFailures}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {getResponseLoading || createBaseRunLoading || createBaseRunsLoading ? (
              <div className='text-paragraphs bg-weak-3 border-main mx-auto mt-4 w-fit rounded-md border border-solid p-2 text-xs'>
                Waiting for response...
              </div>
            ) : null}
          </div>
          {isLastResponseEmpty ? (
            <div className='text-red-strong text-paragraphs py-2'>
              Blank response from Model. You can refresh the page & retry or End Conversation.
            </div>
          ) : null}
          {config.disableAddNewPrompt ||
          (prompts?.length ?? 0) >= (config.minMaxTurns?.[1] ?? 1) ? null : (
            <div className='flex w-full max-w-[70%] items-center gap-3'>
              <TextField
                placeholder='Enter your prompt here...'
                value={promptText}
                dir={textDirection}
                onChange={(e) => {
                  setPromptText(e.target.value)
                }}
                onPaste={(e) => {
                  if (config.disablePaste) {
                    e.preventDefault()
                    showSnackbar({
                      message: 'Copy paste is not allowed',
                      variant: 'warning',
                    })
                    return false
                  }
                }}
                multiline
                fullWidth
                maxRows={20}
                minRows={1}
                disabled={
                  Boolean(unsubmittedPrompt) ||
                  createBaseRunLoading ||
                  getResponseLoading ||
                  createBaseRunsLoading ||
                  isReadOnly
                }
              />
              <MUIButton
                variant='contained'
                onClick={handlePromptSubmission}
                disabled={
                  createBaseRunLoading ||
                  getResponseLoading ||
                  createBaseRunsLoading ||
                  !promptText ||
                  Boolean(unsubmittedPrompt) ||
                  isReadOnly
                }>
                Send
              </MUIButton>
            </div>
          )}
          <div className='border-main flex items-center'>
            Read Preamble{' '}
            <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
              <div>
                <Tooltip
                  open={tooltipOpen}
                  onClose={() => setTooltipOpen(false)}
                  title={<p className='whitespace-pre-wrap'>{conversationPreamble}</p>}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow>
                  <IconButton aria-label='preamble-info' onClick={() => setTooltipOpen(true)}>
                    <InfoIcon width={20} height={20} />
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </div>
        </div>
      </ThemeProvider>
    </div>
  )
}

export { RLHFWAC2 }
