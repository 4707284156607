import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Portal from '@mui/material/Portal'
import Snackbar from '@mui/material/Snackbar'
import { useState } from 'react'

type CopyButtonProps = {
  text: string
  message: string
  color?:
    | 'primary'
    | 'disabled'
    | 'action'
    | 'inherit'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
}

const CopyButton = ({ text, message, color = 'primary' }: CopyButtonProps) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(text)
  }

  return (
    <>
      <IconButton aria-label='copy' onClick={() => handleClick()} size='small'>
        <ContentCopyIcon color={color} fontSize='inherit' />
      </IconButton>
      <Portal>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={() => setOpen(false)} severity='success'>
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    </>
  )
}

export { CopyButton }
