import { Button } from '../button'
import { RectSkeleton } from '../skeleton'

type BannerVariants = 'primary' | 'secondary' | 'PRIMARY' | 'SECONDARY'
interface BannerProps {
  variant: BannerVariants
  title: string
  subtitle: string
  bannerImageUrl: string
  ctaLabel?: string
  ctaOnClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const TitleStyles = {
  primary: 'text-void',
  PRIMARY: 'text-void',
  secondary: '',
  SECONDARY: '',
} as const

const BgStyles = {
  primary: 'bg-theme-main',
  PRIMARY: 'bg-theme-main',
  secondary: 'bg-void',
  SECONDARY: 'bg-void',
} as const

const SubtitleStyles = {
  primary: 'text-void',
  PRIMARY: 'text-void',
  secondary: '',
  SECONDARY: '',
} as const

const ButtonStyles = {
  primary: 'secondary',
  PRIMARY: 'secondary',
  secondary: 'primary',
  SECONDARY: 'primary',
} as const

const BannerSkeleton = () => <RectSkeleton width={'100%'} height={320} />

const Banner = ({
  variant,
  title,
  subtitle,
  bannerImageUrl,
  ctaLabel,
  ctaOnClick,
}: BannerProps) => (
  <div className='border-weak flex h-80 w-full overflow-hidden rounded-lg border border-solid'>
    <div className={`${BgStyles[variant]} flex w-1/2 flex-col justify-center space-y-4 p-8`}>
      <div className={`${TitleStyles[variant]} max-w-[300px] text-3xl font-medium`}>{title}</div>
      <div className={`${SubtitleStyles[variant]} text-lg`}>{subtitle}</div>
      {ctaLabel && (
        <Button
          variant={ButtonStyles[variant]}
          size='md'
          onClick={(e) => {
            ctaOnClick?.(e)
          }}>
          {ctaLabel}
        </Button>
      )}
    </div>
    <div className='flex w-1/2 items-center justify-center'>
      <div
        className={`h-full w-full bg-cover bg-center`}
        style={{
          backgroundImage: `url(${bannerImageUrl})`,
        }}>
        <div className='bg-theme-main h-full w-full opacity-5'></div>
      </div>
    </div>
  </div>
)

export { Banner, BannerSkeleton }
