import type { UploadedFile } from '@invisible/ui/file-uploader'
import { FileUploaderDropzone } from '@invisible/ui/file-uploader'

type FieldProps = {
  value: string
  isReadOnly: boolean
  // Change from ChangeEvent to a function that accepts string or string[]
  uploadMeta?: {
    acceptedFiles?: string
    directoryName?: string
    linkFormat?: 'url' | 'file_path'
    outputFormat?: 'string' | 'a_string'
  }
}

const FileInput = ({ value, isReadOnly, uploadMeta }: FieldProps) => (
  <FileUploaderDropzone
    disabled={isReadOnly}
    acceptedFileTypes={uploadMeta?.acceptedFiles}
    directoryName={uploadMeta?.directoryName ?? 'manticore'}
    saveUploadedFiles={(files: UploadedFile[]) => {
      // We get the array of all fileLinks in the format specified in the Form Configuration
      const fileLinks = files.map((file: UploadedFile) => {
        switch (uploadMeta?.linkFormat ?? 'url') {
          case 'url':
            return file.url
          case 'file_path':
            return file.filePath
          default:
            throw Error('This code path should not be reached')
        }
      })

      switch (uploadMeta?.outputFormat ?? 'string') {
        case 'string':
          // onChange(fileLinks.join(', '))
          break

        case 'a_string':
          //onChange(fileLinks as string[])
          break
      }
    }}
  />
)

export { FileInput }
