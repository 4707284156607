import { SearchOutlineIcon, SortDownIcon, SortUpIcon } from '@invisible/ui/icons'
import { Input } from '@invisible/ui/input'
import { gray, styled } from '@invisible/ui/themes'
import InfoIcon from '@mui/icons-material/Info'
import { Box, LinearProgress, Typography } from '@mui/material'
import { debounce, groupBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { IAnnotationSidebarProps } from '../types'
import { AnnotationAccordion } from './AnnotationAccordion'

const Container = styled.div`
  border-left: 1px solid ${gray(4)};
  height: 95%;
  overflow: auto;
  box-sizing: border-box;
`

export const AnnotationSidebar = ({
  annotations,
  deleteAnnotation,
  isLoading,
}: IAnnotationSidebarProps) => {
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState<'asc' | 'desc'>('asc')
  const [searchEnabled, setSearchEnabled] = useState(false)

  const filteredAnnotations = useMemo(() => {
    const filtered = annotations.filter((annotation) =>
      annotation?.entity?.toLowerCase().includes(search.toLowerCase())
    )

    return filtered.sort((a, b) => {
      const entityA = a?.entity?.toLowerCase() || ''
      const entityB = b?.entity?.toLowerCase() || ''
      return sortBy === 'asc' ? entityA.localeCompare(entityB) : entityB.localeCompare(entityA)
    })
  }, [annotations, search, sortBy])

  const groupedAnnotations = useMemo(
    () => groupBy(filteredAnnotations, 'entity'),
    [filteredAnnotations]
  )

  const SortIcon = useMemo(() => (sortBy === 'asc' ? SortDownIcon : SortUpIcon), [sortBy])

  const debouncedHandleBlur = useCallback(
    debounce((inputValue: string) => {
      if (inputValue.length === 0) {
        setSearchEnabled(false)
        setSearch('')
      }
    }, 300),
    []
  )

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    debouncedHandleBlur(inputValue)
  }

  return (
    <>
      {isLoading ? <LinearProgress color='secondary' /> : null}
      <Box
        borderLeft='1px solid rgba(0, 0, 0, 0.08)'
        style={{
          position: 'sticky',
          padding: '0px 16px',
        }}>
        <Typography fontWeight='bold' fontSize={16} style={{ padding: '8px 0px' }}>
          Sub Strings ({filteredAnnotations.length})
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
          {searchEnabled ? (
            <Input
              suffix='SearchOutlineIcon'
              value={search}
              backgroundColor='white'
              width='100%'
              placeholder='Search...'
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
              onBlur={handleBlur}
            />
          ) : (
            <>
              <Typography fontSize={12} color='#363636'>
                Sort By
                <Box display='inline-block' px='8px'>
                  <span>{sortBy === 'asc' ? 'A - Z' : 'Z - A'}</span>
                  <SortIcon
                    className='h-3 w-3 cursor-pointer pl-2'
                    onClick={() => setSortBy(sortBy === 'asc' ? 'desc' : 'asc')}
                  />
                </Box>
              </Typography>
              <SearchOutlineIcon className='h-4 w-4' onClick={() => setSearchEnabled(true)} />
            </>
          )}
        </Box>
      </Box>

      <Container>
        {filteredAnnotations.length > 0
          ? Object.keys(groupedAnnotations).map((entity) => (
              <AnnotationAccordion
                key={entity}
                entity={entity}
                annotations={groupedAnnotations[entity]}
                deleteAnnotation={deleteAnnotation}
              />
            ))
          : !isLoading && (
              <Box sx={{ p: '10px' }}>
                {annotations.length === 0 && !search ? (
                  <Box
                    sx={{ p: '16px', backgroundColor: 'rgba(0, 0, 0, 0.08)', borderRadius: '6px' }}
                    display='flex'
                    flexDirection='row'
                    columnGap={1}>
                    <InfoIcon fontSize='small' sx={{ color: 'gray' }} />
                    <Typography variant='body2'>
                      You don't have substrings created yet. Start adding your annotations and it
                      will be display here.
                    </Typography>
                  </Box>
                ) : (
                  <span className='p-3 text-[#363636]'>No substring found</span>
                )}
              </Box>
            )}
      </Container>
    </>
  )
}
