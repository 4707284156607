import { motion } from 'framer-motion'
import { filter, flow, map, values } from 'lodash/fp'
import { FC, KeyboardEvent as RKeyboardEvent, useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'rebass'

import { useClickAway } from '../hooks/use-click-away'
import { gray, purple, styled, textColor } from '../themes'

export const DropdownSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const
const _Size = values(DropdownSize)
type DSize = typeof _Size[number]

const Wrapper = styled.div<{
  width?: string
  disabled?: boolean
  size?: DSize
  borderRadius?: string
  displayFontSize?: string
  displayColor?: string
}>`
  background-color: #ffffff;
  font-size: 14px;
  border-radius: 6px;
  display: inline-block;
  white-space: nowrap;
  width: ${({ width }) => (width ? width : '100%')};
  padding: ${({ size }) =>
    size === DropdownSize.SMALL
      ? '0 12px'
      : size === DropdownSize.MEDIUM
      ? '5px 12px'
      : '8px 12px'};
  height: ${({ size }) =>
    size === DropdownSize.SMALL ? '24px' : size === DropdownSize.MEDIUM ? '32px' : '40px'};
  color: ${({ displayColor }) => displayColor ?? 'inherit'};
  display: flex;
  align-content: baseline;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid ${gray(5)};
  ${({ disabled }) =>
    disabled
      ? `pointer-events: none;
      opacity: 0.7;
        `
      : ''}
`

const CursorWrapper = styled.div``

const SelectedKey = styled(Box)<{ displayTextOverflow?: string }>`
  overflow: ${({ displayTextOverflow }) => displayTextOverflow ?? 'hidden'};
  word-break: break-all;
  text-overflow: ellipsis;
`

const Label = styled.div<{ placeHolderFontSize?: string }>`
  color: ${textColor('disabled')};
  font-size: ${({ placeHolderFontSize }) => placeHolderFontSize ?? '14px'};
  text-align: left;
`
const DropMenu = styled.div<{ displayFontSize?: string }>`
  font-size: ${({ displayFontSize }) => displayFontSize ?? '14px'};
  position: relative;
  flex-grow: 1;
  cursor: pointer;
`
const DropMenuList = styled(motion.div)<{
  maxHeight?: string | number
  placement: string
}>`
  display: flex;
  padding: 5px 0;
  opacity: 0;
  display: 'none';
  flex-direction: column;
  position: absolute;
  top: ${({ placement }) => (placement === 'bottom' ? '28px' : 'unset')};
  bottom: ${({ placement }) => (placement === 'top' ? '28px' : 'unset')};
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  z-index: 1000;
  width: calc(100% + 16px);
  left: -8px;
  height: fit-content;
  max-height: ${({ maxHeight }) =>
    maxHeight ? (typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight) : 'fit-content'};
  overflow-y: ${({ maxHeight }) => (maxHeight ? 'scroll' : 'auto')};
  a,
  a:hover,
  a:active,
  a:visited {
    cursor: pointer;
    color: #000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-decoration: none;
    padding: 9px 10px;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid #f0f0f0;

    &:hover {
      background-color: ${purple(2)};
    }
    &:last-child {
      border-bottom: none;
    }
  }
`

const InvisibleInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
`

const ArrowDiv = styled.div<{ showArrow?: boolean }>`
  display: ${({ showArrow }) => (!showArrow ? 'none' : 'inline')};
`

const HorizontalRule = styled.div<{ horizontalRuleWidth?: string }>`
  border-radius: 0px;
  width: ${({ horizontalRuleWidth }) => horizontalRuleWidth ?? '278px'};
  left: 0px;
  top: 40px;
  background: ${purple(1)};
  border: 1px solid ${purple(1)};
`

const Down = () => (
  <svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.1612 0.433105H9.15673C9.08843 0.433105 9.02415 0.466588 8.98397 0.521498L5.17906 5.76614L1.37414 0.521498C1.33397 0.466588 1.26968 0.433105 1.20138 0.433105H0.196912C0.109859 0.433105 0.0589657 0.532213 0.109859 0.603195L4.83218 7.11346C5.00361 7.34918 5.3545 7.34918 5.52459 7.11346L10.2469 0.603195C10.2991 0.532213 10.2483 0.433105 10.1612 0.433105Z'
      fill='black'
      fillOpacity='0.25'
    />
  </svg>
)
const Up = () => (
  <svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.2484 7.12016L5.52611 0.609891C5.35468 0.374177 5.00379 0.374177 4.8337 0.609891L0.11004 7.12016C0.0984157 7.13617 0.091452 7.15509 0.0899209 7.17481C0.0883898 7.19454 0.0923511 7.21431 0.101366 7.23192C0.110381 7.24953 0.124096 7.26431 0.140993 7.2746C0.15789 7.2849 0.177308 7.29031 0.197094 7.29025H1.20156C1.26986 7.29025 1.33415 7.25677 1.37433 7.20186L5.17924 1.95721L8.98415 7.20186C9.02433 7.25677 9.08861 7.29025 9.15692 7.29025H10.1614C10.2484 7.29025 10.2993 7.19114 10.2484 7.12016Z'
      fill='black'
      fillOpacity='0.25'
    />
  </svg>
)

interface IOption {
  key: string
  value: string | number | Record<string, unknown>
  color?: string
  isSelected?: boolean
  isClickable?: boolean
}

interface IDropdown {
  name: string
  options: IOption[]
  defaultKey?: string
  onSelect: (value: any, key: string) => void
  width?: string
  disabled?: boolean
  selectedKey?: string | null
  search?: boolean
  custom?: boolean
  clearText?: string
  onClear?: () => void
  onBlur?: () => void
  maxHeight?: string | number
  size?: DSize
  showArrow?: boolean
  horizontalRuleWidth?: string
  showHorizontalRule?: boolean
  openOnLoad?: boolean
  displayFontSize?: string
  displayColor?: string
  displayTextOverflow?: string
  placeHolderText?: string
  innerRef?: any
  onChange?: () => void
  placeHolderFontSize?: string
  placement?: string
  className?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const DropdownComponent: FC<IDropdown> = ({
  defaultKey,
  name,
  options,
  onSelect,
  width,
  disabled,
  selectedKey,
  onClear,
  clearText,
  onBlur,
  maxHeight,
  openOnLoad,
  displayFontSize,
  displayColor,
  displayTextOverflow,
  showArrow = true,
  horizontalRuleWidth,
  showHorizontalRule,
  placeHolderText,
  placeHolderFontSize,
  innerRef,
  onChange,
  search = false,
  custom = false,
  placement = 'bottom',
  size = 'medium',
  ...rest
}) => {
  const [isOpen, setOpen] = useState(Boolean(openOnLoad))
  const [key, selectKey] = useState<string | null | undefined>(selectedKey ?? defaultKey)
  const [searchTerm, setSearchTerm] = useState('')

  const ref = useRef<null | HTMLDivElement>(null)

  const values = flow(
    filter(({ key }) => (searchTerm ? key.toLowerCase().includes(searchTerm.toLowerCase()) : true)),
    map(({ key, value, color, isClickable = true }: IOption) => (
      <a
        style={{
          background: color ?? '#ffffff',
        }}
        key={`${value}`}
        onClick={() => {
          if (isClickable) {
            onSelect(value, key)
            selectKey(key)
            setOpen(false)
            setSearchTerm('')
          }
        }}>
        {key}
      </a>
    ))
  )(options)

  const variants = {
    open: { opacity: 1, display: 'flex' },
    closed: { opacity: 0, transitionEnd: { display: 'none' } },
  }

  useClickAway(ref, () => {
    if (isOpen) {
      onBlur?.()
      setOpen(false)
      setSearchTerm('')
    }
  })

  useEffect(() => {
    if (selectedKey === null || selectedKey) selectKey(selectedKey)
  }, [selectedKey])

  const handleEnter = (e: RKeyboardEvent) => {
    if (e.key === 'Enter' && searchTerm) {
      const searchedOptions: IOption[] = filter(({ key }: IOption) =>
        key.toLowerCase().includes(searchTerm.toLowerCase())
      )(options)
      const selectedOption = searchedOptions?.[0]
      if (selectedOption) {
        onSelect(selectedOption.value, selectedOption.key)
        selectKey(selectedOption.key)
        setOpen(false)
        setSearchTerm('')
      } else if (custom) {
        onSelect(options.values.length, searchTerm)
        selectKey(searchTerm)
        setOpen(false)
        setSearchTerm('')
      }
    }
  }

  return (
    <CursorWrapper {...rest}>
      <Wrapper
        width={width}
        disabled={disabled}
        onClick={() => setOpen(!isOpen)}
        size={size}
        className='InputWrapper'
        displayFontSize={displayFontSize}
        displayColor={displayColor}>
        <DropMenu ref={ref} displayFontSize={displayFontSize}>
          <Flex justifyContent='space-between' alignItems='center'>
            {search && isOpen ? (
              <InvisibleInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleEnter}
                autoFocus
                placeholder={placeHolderText}
                className={'InvisibleInput'}
              />
            ) : key ? (
              <SelectedKey
                displayTextOverflow={displayTextOverflow}
                width={`calc(${width} - 35px)`}>
                {key}
              </SelectedKey>
            ) : (
              <Label placeHolderFontSize={placeHolderFontSize}>{name}</Label>
            )}
            <ArrowDiv showArrow={showArrow}>{isOpen ? <Up /> : <Down />}</ArrowDiv>
          </Flex>
          {!isOpen && showHorizontalRule && (
            <HorizontalRule horizontalRuleWidth={horizontalRuleWidth} />
          )}

          <DropMenuList
            maxHeight={maxHeight}
            placement={placement}
            animate={isOpen ? variants.open : variants.closed}
            className='DropMenuList'>
            {clearText ? (
              <a
                className='ListMenuItems'
                onClick={() => {
                  selectKey(null)
                  onClear?.()
                  setOpen(false)
                }}>
                {clearText}
              </a>
            ) : null}
            {values}
            {custom && (
              <a
                className='ListMenuItems'
                onClick={() => {
                  if (searchTerm) {
                    setOpen(false)
                    onSelect(options.values.length, searchTerm)
                    selectKey(searchTerm)
                    setSearchTerm('')
                  }
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <div>Create:</div>
                  {searchTerm && <div>{searchTerm}</div>}
                </div>
              </a>
            )}
          </DropMenuList>
        </DropMenu>
      </Wrapper>
    </CursorWrapper>
  )
}
/**
 * @deprecated Use Dropdown
 */
export const OldDropdown = Object.assign(DropdownComponent, {
  Wrapper,
  DropMenu,
  DropMenuList,
})
