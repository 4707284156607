import { Input } from '@invisible/ui/input'

type FieldProps = {
  name: string
  value: string
  isReadOnly: boolean
}

const InputField = ({ value, isReadOnly }: FieldProps) => (
  <Input width='100%' value={value} disabled={isReadOnly} />
)

export { InputField }
