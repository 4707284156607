import { v4 as uuidv4, v5 as uuidv5 } from 'uuid'

export const getUUIDFromNamespace = (tokens?: string[]): string => {
  //  the namespace will remain the same
  //  The purpose of this is to generate the same UUID if the tokens provided are same
  const namespace = '123e4567-e89b-12d3-a456-426614174000'

  if (tokens) {
    const joinedTokens = tokens.join('-')
    return uuidv5(joinedTokens, namespace)
  }

  return uuidv4()
}
