import { SVGProps } from 'react'

function AnnotateAudioWac(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='19'
      height='21'
      viewBox='0 0 19 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M4.37329 16.1484V4.14844H6.37329V16.1484H4.37329ZM8.37329 20.1484V0.148438H10.3733V20.1484H8.37329ZM0.373291 12.1484V8.14844H2.37329V12.1484H0.373291ZM12.3733 16.1484V4.14844H14.3733V16.1484H12.3733ZM16.3733 12.1484V8.14844H18.3733V12.1484H16.3733Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default AnnotateAudioWac
