import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { getUUIDFromNamespace } from '@invisible/common/helpers'
import { SnackbarContext } from '@invisible/common/providers'
import { IStepRunEventTypeEnum } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/server'
import { useQuery } from '@invisible/trpc/client'
import { Skeleton } from '@invisible/ui/skeleton'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Tab from '@mui/material/Tab'
import classNames from 'classnames'
import { differenceInSeconds } from 'date-fns'
import { useContext as useReactContext, useState } from 'react'

import { useStepRunEventLogger } from '../../hooks/useStepRunEventLogger'
import Markdown from '../common/Markdown'
import { useRLHFContext } from './context'
import { Metadata } from './Metadata'
import { DiffViewer } from './sub-components/DiffViewer'
import { TBaseRunVariables } from './types'

const COLOR_MAP: Record<string, { char: string; bg: string; text: string }> = {
  '1': {
    char: 'A',
    bg: '#3780C80D',
    text: '#3780C8',
  },
  '2': {
    char: 'B',
    bg: '#9159DD0D',
    text: '#9159DD',
  },
  '3': {
    char: 'C',
    bg: '#F19E7314',
    text: '#F4511E',
  },
  '4': {
    char: 'D',
    bg: '#BEF2CA1F',
    text: '#448D57',
  },
  '5': {
    char: 'E',
    bg: '#D9D9D924',
    text: '#000000',
  },
}

export interface IResponse {
  id: string
  text: string
  model: string
  index: number
  rank: number
  rating: number
  originalText: string
}

interface IProps {
  response: IResponse
  stepRunId: string
  activePromptId: string
  activePromptCreatedAt: Date
  numOfRank?: number
  isHighestRanked?: boolean
  selectedResponseId?: string
  baseRunVariables: TBaseRunVariables
  isLoadingBaseRunVariables?: boolean
  disableSelectionAndResponseEdit?: boolean
  disableResponseMetadataEdit?: boolean
  handleClose?: () => void
}

const PromptResponse = ({
  response: { id, text, index, model, originalText },
  activePromptId,
  baseRunVariables,
  activePromptCreatedAt,
  selectedResponseId,
  isHighestRanked,
  isLoadingBaseRunVariables,
  numOfRank,
  disableSelectionAndResponseEdit,
  disableResponseMetadataEdit,
  handleClose,
}: IProps) => {
  const [tab, setTab] = useState('1')
  const [responseText, setResponseText] = useState(text)
  const [isSelectingResponse, setIsSelectingResponse] = useState(false)
  const { dispatch } = useWizardState()
  const {
    config,
    responseMetadataValidationFailures,
    stepRunId,
    updateBaseRunVariables,
    isReadOnly,
    numOfPrompts,
  } = useRLHFContext()
  const { maybeLogStepRunEvent } = useStepRunEventLogger()
  const { showSnackbar } = useReactContext(SnackbarContext)

  const { data: promptDurationInSecsBaseVariable, isLoading: isFetching } = useQuery(
    [
      'baseRunVariable.findOneByBaseVariableIdAndBaseRunId',
      {
        baseRunId: activePromptId,
        baseVariableId: config?.promptDurationInSecsBaseVariableId as string,
      },
    ],
    { enabled: !!config?.promptDurationInSecsBaseVariableId }
  )

  const handleResponseSelection = async () => {
    if (responseMetadataValidationFailures?.includes(activePromptId)) {
      showSnackbar({
        message: 'Please fill out all required metadata fields before selecting a response.',
        variant: 'error',
      })
      return
    }
    const responseSelectedEventPayload = {
      name: 'response_selected',
      stepRunId: stepRunId as string,
      spanId: getUUIDFromNamespace([stepRunId as string, String(numOfPrompts)]),
      spanType: 'TURN',
      spanLocation: numOfPrompts,
      type: IStepRunEventTypeEnum.Span,
      timestamp: new Date(),
    } as const

    maybeLogStepRunEvent(responseSelectedEventPayload)

    setIsSelectingResponse(true)
    // compute prompt duration in seconds
    const curentDateTime = new Date()

    const updateResponseDuration = differenceInSeconds(curentDateTime, activePromptCreatedAt)
    const canUpdateDurationInSec =
      !isFetching &&
      promptDurationInSecsBaseVariable &&
      (!promptDurationInSecsBaseVariable?.value ||
        isNaN(Number(promptDurationInSecsBaseVariable?.value)))
    const extraBrvToUpdate = canUpdateDurationInSec
      ? [
          {
            baseRunId: activePromptId,
            baseVariableId: config?.promptDurationInSecsBaseVariableId as string,
            value: updateResponseDuration,
          },
        ]
      : []

    try {
      await updateBaseRunVariables?.([
        {
          baseRunId: activePromptId,
          baseVariableId: config?.promptResponseBaseVariableId as string,
          value: responseText,
        },
        {
          baseRunId: activePromptId,
          baseVariableId: config?.promptAcceptedModelBaseVariableId as string,
          value: model,
        },
        {
          baseRunId: activePromptId,
          baseVariableId: config?.responseIdBaseVariableId as string,
          value: id,
        },
        {
          baseRunId: id,
          baseVariableId: config?.responseTextBaseVariableId as string,
          value: responseText,
        },
        ...extraBrvToUpdate,
      ])
      maybeLogStepRunEvent({
        name: 'selected_response_saved',
        stepRunId: stepRunId as string,
        spanId: getUUIDFromNamespace([stepRunId as string, String(numOfPrompts)]),
        spanType: 'TURN',
        spanLocation: numOfPrompts,
        type: IStepRunEventTypeEnum.Span,
        timestamp: new Date(),
      })
    } catch (error) {
      logger.error('Error Updating Response', error as Error)
    }
    setIsSelectingResponse(false)

    dispatch({
      type: 'setReadyForSubmit',
      key: 'RLHFPLUS',
      value: true,
    })

    handleClose?.()
  }

  const handleSaveResponseText = async () => {
    await updateBaseRunVariables?.([
      {
        baseRunId: id,
        baseVariableId: config?.responseTextBaseVariableId as string,
        value: responseText,
      },
      // update the Accepted Response text in the prompt base if this response is edited
      ...(selectedResponseId === id
        ? [
            {
              baseRunId: activePromptId,
              baseVariableId: config?.promptResponseBaseVariableId as string,
              value: responseText,
            },
          ]
        : []),
    ])
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const textDirection = config.promptResponseTextDirection ?? 'auto'
  const color = COLOR_MAP[`${index}`]

  return (
    <div
      className={classNames(
        'relative pt-6',
        isHighestRanked
          ? 'before:absolute before:top-[-4px] before:w-full before:rounded-tl-md before:rounded-tr-md before:bg-slate-400 before:py-1 before:text-center before:font-bold before:text-white before:content-["BEST"]'
          : null
      )}>
      <div
        className={classNames('relative box-border flex  h-[798px] flex-col overflow-auto p-3')}
        style={{ backgroundColor: color?.bg, borderTop: `4px solid ${color?.text}` }}>
        <div className='mb-2 flex  items-center justify-between'>
          <div className='font-medium' style={{ color: color?.text }}>
            Response {color?.char}
            {!config?.hideResponseModelName ? (
              <span className='text-gray-400'> - {model}</span>
            ) : null}
          </div>
          {(config.allowSelectResponse || config.allowEditResponse) &&
          isHighestRanked &&
          !disableSelectionAndResponseEdit &&
          !isReadOnly ? (
            isSelectingResponse ? (
              <CircularProgress color='inherit' size={20} />
            ) : (
              <div
                style={{
                  border: `1px ${selectedResponseId === id ? 'dashed' : 'solid'} ${color?.text}`,
                  color: color?.text,
                }}
                onClick={handleResponseSelection}
                className='hover:border-w-2 cursor-pointer p-1'>
                {responseText !== text ? 'Select & Save' : 'Select'}
              </div>
            )
          ) : null}
          {selectedResponseId === id && (!isHighestRanked || disableSelectionAndResponseEdit) && (
            <div
              style={{ border: `1px dashed gray`, color: 'gray' }}
              className='hover:border-w-2 p-1'>
              Selected
            </div>
          )}
        </div>
        <div className='mt-4 flex max-h-[55%] flex-initial overflow-y-auto'>
          {isLoadingBaseRunVariables ? (
            <div className='my-4 flex flex-col justify-center gap-4'>
              <Skeleton.Rectangle height={40} width='100%' />
              <Skeleton.Rectangle height={40} width='100%' />
              <Skeleton.Rectangle height={40} width='100%' />
              <Skeleton.Rectangle height={40} width='100%' />
            </div>
          ) : (
            <Metadata
              isReadOnly={isReadOnly || disableResponseMetadataEdit}
              numOfRank={numOfRank}
              data={[
                ...(config?.responseMetadata?.fields ?? []).map((field) =>
                  field.baseVariableId === config?.responseRankBaseVariableId
                    ? {
                        ...field,
                        type: 'ranking',
                      }
                    : field.baseVariableId === config?.responseRatingBaseVariableId
                    ? {
                        ...field,
                        type: 'rating',
                      }
                    : field
                ),
              ]}
              baseRunId={id}
              baseRunVariables={baseRunVariables}
              updateBaseRunVariables={updateBaseRunVariables}
              selectedValueColor={color?.text}
            />
          )}
        </div>
        <div className='flex-1 overflow-y-auto'>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange}>
                <Tab label='Original' value='1' className='px-2' />
                <Tab label='Original (Raw)' value='2' className='px-2' />
                <Tab label='Edited (Preview)' value='3' className='px-2' />
                <Tab label='Edited (Raw)' value='4' className='px-2' />
                <Tab label='Diff changes' value='5' className='px-2' />
              </TabList>
            </Box>
            <TabPanel value='1' sx={{ padding: '2px' }}>
              <Markdown
                dir={textDirection}
                className='my-3 overflow-auto'
                components={{
                  p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
                }}>
                {originalText}
              </Markdown>
            </TabPanel>
            <TabPanel value='2' sx={{ padding: '2px' }}>
              <div
                dir={textDirection}
                className={
                  ' my-3 box-border inline-block min-h-[60px] w-full whitespace-pre-wrap rounded-sm p-2 pb-4'
                }>
                {originalText}
              </div>
            </TabPanel>
            <TabPanel value='3' sx={{ padding: '2px' }}>
              <Markdown
                dir={textDirection}
                className='my-3 overflow-auto'
                components={{
                  p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>,
                }}>
                {text}
              </Markdown>
            </TabPanel>
            <TabPanel value='4' sx={{ padding: '2px' }}>
              {config.allowEditResponse &&
              isHighestRanked &&
              !disableSelectionAndResponseEdit &&
              !isReadOnly ? (
                <div
                  dir={textDirection}
                  className={
                    ' my-3 box-border inline-block min-h-[60px] w-full whitespace-pre-wrap rounded-sm border-dashed border-gray-300 bg-white p-2 pb-4'
                  }
                  contentEditable={true}
                  onPaste={(e) => {
                    e.preventDefault()
                    if (!config?.disablePaste) {
                      // Fixes issue with gobbled text when pasting
                      document.execCommand(
                        'inserttext',
                        false,
                        e.clipboardData.getData('text/plain')
                      )
                    }
                  }}
                  onInput={(e) => setResponseText(e?.currentTarget?.textContent ?? '')}>
                  {text}
                </div>
              ) : (
                <div
                  dir={textDirection}
                  className={
                    ' my-3 box-border inline-block min-h-[60px] w-full whitespace-pre-wrap rounded-sm p-2 pb-4'
                  }>
                  {text}
                </div>
              )}
            </TabPanel>
            <TabPanel value="5" sx={{ padding: "2px", overflow: "hidden" }}>
              <DiffViewer originalText={originalText} editedText={text?.trim()} />
            </TabPanel>
          </TabContext>
        </div>
        {config.allowEditResponse &&
        responseText !== text &&
        isHighestRanked &&
        !disableSelectionAndResponseEdit &&
        !isReadOnly ? (
          <div className='absolute bottom-0 mb-1 box-border flex justify-end bg-white'>
            <IconButton onClick={() => setResponseText(text)}>
              <CloseIcon />{' '}
            </IconButton>
            <IconButton disabled={responseText === text} onClick={() => handleSaveResponseText()}>
              <CheckIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export { PromptResponse }
