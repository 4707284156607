import { attributeHasDefaultValue, formatInTimeZone, formatUnit } from '@invisible/common/helpers'
import {
  IAttribute,
  IDataFilter,
  IFilterColumnOption,
  IFormattedReport,
  IInterval,
  ILineChartData,
  IPartialReportMeta,
  IReportMeta,
  IVisualization,
  Line,
  LineDatum,
  TDateViewLevel,
  TFilterValue,
  TVisualizationType,
} from '@invisible/common/types'
import { ColorSchemeId, colorSchemes } from '@nivo/colors'
import { differenceInCalendarDays, getWeekOfMonth } from 'date-fns'
import {
  filter,
  find,
  findIndex,
  flow,
  includes,
  isArray,
  last,
  map,
  mapValues,
  reduce,
} from 'lodash/fp'

import { CHART_TYPE, DATE_TIME_FORMATS, FILTER_TYPE, VIEW_TYPES } from '../../constants'
import { BRIGHT_THEME } from '../../constants/colorThemes'
import {
  formatBarChartData,
  formatChoroplethData,
  formatGridData,
  formatLinearData,
  formatLineChartData,
  formatPieChartData,
  formatTableData,
  formatTileData,
} from './chart-formatters'
import { DEFAULT_VISUALIZATION_DATA } from './constants'

/**
 * Formats a collection of color schemes represented as key-value pairs where each
 * value is an array of colors. If the last element of the array is itself an array,
 * it is returned as the color scheme. Otherwise, the entire array is returned.
 *
 * @param {Object.<string, Array.<string | Array<string>>>} colorSchemes - The collection of color schemes.
 * Each key is a unique identifier for the scheme, and each value is an array of colors.
 * @returns {Object.<string, Array.<string | Array<string>>>} - The formatted collection of color schemes.
 */

export const formatColorSchemes = mapValues((value: Array<string | Array<string>>) =>
  isArray(last(value)) ? last(value) : value
)

export const COLOR_SCHEMES: Record<ColorSchemeId | 'bright', string[]> = {
  ...(formatColorSchemes(colorSchemes) as Record<ColorSchemeId, string[]>),
  bright: BRIGHT_THEME,
}

export const isCompeleteReportMetaData = (obj: IPartialReportMeta): obj is IReportMeta =>
  !!obj?.id && !!obj.visualizations

export const findFilterByName = <T extends TFilterValue>(
  name: typeof FILTER_TYPE[keyof typeof FILTER_TYPE],
  filters: IDataFilter<TFilterValue>[]
) =>
  find((filter: IDataFilter<TFilterValue>) => filter.name === name)(filters) as
    | IDataFilter<T>
    | undefined

export const formatReportTitle = (metadata: {
  title: string
  interval?: IInterval
  data?: Record<string, unknown>
}) => {
  const token = metadata.title.substring(
    metadata.title.indexOf('[') + 1,
    metadata.title.indexOf(']')
  )
  switch (token) {
    case 'date':
      if (metadata.interval?.from && metadata.interval?.to) {
        const numOfDays = differenceInCalendarDays(
          new Date(metadata.interval.to),
          new Date(metadata.interval.from)
        )
        const dateFormateString =
          numOfDays === 0
            ? 'MMMM d, yyyy'
            : numOfDays > 0 && numOfDays <= 31
            ? 'MMMM, yyyy'
            : 'yyyy'
        return metadata.title.replace(
          /\[.*?\]/g,
          formatInTimeZone({
            date: metadata?.interval?.from,
            format: dateFormateString,
          })
        )
      } else return metadata.title.replace(/\[.*?\]/g, '')
    case 'client_user_name':
      return metadata.title.replace(/\[.*\]/g, metadata.data ? String(metadata.data[token]) : '')
    default:
      return String(metadata.title)
  }
}

export const formatVisualizations = ({
  visualizations,
  sharedColorScheme,
  dateViewLevel,
  colorSchemePerChart,
}: {
  visualizations: IVisualization[]
  sharedColorScheme: string[]
  dateViewLevel: TDateViewLevel
  colorSchemePerChart?: Record<TVisualizationType, string[]>
}) =>
  reduce(
    (
      acc: IFormattedReport['visualizations'] & {
        filterColumnOptions: IFilterColumnOption[]
      },
      curr: IVisualization
    ): IFormattedReport['visualizations'] & { filterColumnOptions: IFilterColumnOption[] } => {
      let formattedData = { ...acc }
      if (curr.type === CHART_TYPE.PieChart) {
        const pieChart = formatPieChartData({
          visualization: curr,
          colorScheme: colorSchemePerChart?.[CHART_TYPE.PieChart] ?? sharedColorScheme ?? [],
        })
        formattedData = {
          ...formattedData,
          pieChart,
        }
      } else if (curr.type === CHART_TYPE.Table) {
        const table = formatTableData(curr)
        formattedData = {
          ...formattedData,
          table,
        }
      } else if (curr.type === CHART_TYPE.BarChart) {
        const barChart = formatBarChartData({
          visualization: curr,
          colorScheme: colorSchemePerChart?.[CHART_TYPE.BarChart] ?? sharedColorScheme ?? [],
          dateViewLevel,
        })
        formattedData = {
          ...formattedData,
          barChart,
        }
      } else if (curr.type === CHART_TYPE.Choropleth) {
        const choroplethChart = formatChoroplethData(curr)
        formattedData = {
          ...formattedData,
          choroplethChart,
        }
      } else if (curr.type === CHART_TYPE.Tile) {
        const tileChart = formatTileData(curr)
        formattedData = {
          ...formattedData,
          tileChart,
        }
      } else if (curr.type === CHART_TYPE.Linear) {
        const linearChart = formatLinearData(curr)
        formattedData = {
          ...formattedData,
          linearChart,
        }
      } else if (curr.type === CHART_TYPE.Grid) {
        const grid = formatGridData(curr)
        formattedData = {
          ...formattedData,
          grid,
        }
      } else if (curr.type === CHART_TYPE.LineChart) {
        const lineChart = formatLineChartData({
          visualization: curr,
          colorScheme: colorSchemePerChart?.[CHART_TYPE.LineChart] ?? sharedColorScheme ?? [],
          dateViewLevel,
        })
        formattedData = {
          ...formattedData,
          lineChart,
        }
      }

      return {
        ...formattedData,
        filterColumnOptions: [...(formattedData.filterColumnOptions ?? [])],
      }
    },
    { ...DEFAULT_VISUALIZATION_DATA, filterColumnOptions: [] }
  )(visualizations)

const isFilterAttributeName = (attributeName: string) =>
  flow(Object.values, includes(attributeName))(FILTER_TYPE)

export const parseFilterColumnOptions = (attributesMapping: IAttribute[]): IFilterColumnOption[] =>
  flow(
    filter(
      (attr: IAttribute) => attributeHasDefaultValue(attr) || !isFilterAttributeName(attr.name)
    ),
    reduce((attributeList: IAttribute[], attribute: IAttribute) => {
      const index = findIndex((existingAttr: IAttribute) => existingAttr.value === attribute.value)(
        attributeList
      )
      if (index < 0) return [...attributeList, attribute]
      if (attributeList[index]?.defaultValue) return attributeList
      const newAttributeList = [...attributeList]
      newAttributeList[index] = {
        ...attribute,
        valueType: newAttributeList[index].valueType,
      }
      return newAttributeList
    }, []),
    map(
      (attr: IAttribute): IFilterColumnOption => ({
        key: attr.value,
        label: attr.label,
        valueType: attr.valueType,
        filterName: !attributeHasDefaultValue(attr) ? FILTER_TYPE.Dynamic : attr.name,
        isVisible: attr.isVisible,
        isEditable: attr.isEditable,
        operator: attr.operator,
        defaultValue: attr.defaultValue,
        isConfigurable: attributeHasDefaultValue(attr),
      })
    )
  )(attributesMapping)

export const formatDateByDateViewLevel = ({
  date,
  dateViewLevel,
  timeZone,
}: {
  date: string | Date
  dateViewLevel: TDateViewLevel
  timeZone?: string
}) => {
  // since date-fns's format function does not support a "week of the month" format notation string,
  // weekly format has to be handeled explicitly.
  if (dateViewLevel === VIEW_TYPES.WEEKLY) {
    // first remove date and time from date string in order to prevent date displacement while
    // creating a JS Date object
    const dailyFormattedDateString = formatInTimeZone({ date, format: DATE_TIME_FORMATS.daily })
    const weekOfMonth = getWeekOfMonth(new Date(dailyFormattedDateString))
    // format to monthly date string for other segment of weekly format.
    const monthlyFormattedDateString = formatInTimeZone({ date, format: 'MMM, yyyy' })
    return `W${weekOfMonth} ${monthlyFormattedDateString}`
  }
  return formatInTimeZone({
    date,
    format: DATE_TIME_FORMATS[dateViewLevel],
    tz: timeZone,
  })
}

export const replaceThemeNamesByColorSchemes = (
  colorThemePerChart: Partial<Record<TVisualizationType, ColorSchemeId | 'bright'>>
): Record<TVisualizationType, string[]> =>
  Object.entries(colorThemePerChart).reduce(
    (colorSchemes, [chartType, colorTheme]) => ({
      ...colorSchemes,
      [chartType]: COLOR_SCHEMES[colorTheme],
    }),
    {} as Record<TVisualizationType, string[]>
  )

// Function to format the x-value based on its type
export const formatXValueByAttributeValueType = ({
  value,
  valueType,
  dateViewLevel,
}: {
  value: unknown
  valueType: string
  dateViewLevel: TDateViewLevel
}) => {
  if (valueType === 'DateTime') {
    return formatDateByDateViewLevel({
      dateViewLevel,
      date: String(value),
    })
  } else {
    return String(value)
  }
}

export const getUpdatedLinePoint = ({
  point,
  yAxisValue,
  postfixUnit,
  prefixUnit,
}: {
  point: LineDatum
  yAxisValue: number
  prefixUnit?: string
  postfixUnit?: string
}) => {
  const updatedPoint = { ...point }
  const updatedYValue = (updatedPoint.y as number) + yAxisValue
  const formattedYValue = formatUnit({
    value: updatedYValue,
    valueType: 'Number',
    postfixUnit,
    prefixUnit,
  })
  updatedPoint.y = updatedYValue
  updatedPoint.yFormatted = formattedYValue
  return updatedPoint
}

type CreateOrUpdateLinesArgs = {
  lines: Line[]
  formattedXValue: string
  yAxisValue: number
  formattedYValue: string
  yAxisPostfixUnit?: string
  yAxisPrefixUnit?: string
  colorScheme: readonly string[]
  lineId: string
}

const createOrUpdateLines = ({
  colorScheme,
  formattedXValue,
  formattedYValue,
  lineId,
  yAxisValue,
  yAxisPostfixUnit,
  yAxisPrefixUnit,
  lines,
}: CreateOrUpdateLinesArgs) => {
  const lineIndex = lines.findIndex((line) => line.id === lineId)
  const lineExists = lineIndex >= 0
  const newPoint = {
    x: formattedXValue,
    y: yAxisValue,
    yFormatted: formattedYValue,
  }

  if (!lineExists) {
    // create new line
    const lineColor = colorScheme[Math.floor(Math.random() * colorScheme.length)]
    const newLine = {
      id: lineId,
      color: lineColor,
      data: [newPoint],
    }
    lines.push(newLine)
    return lines
  }

  const line = lines[lineIndex]
  const linePointIndex = line?.data.findIndex((linePoint) => linePoint.x === formattedXValue)
  const linePointExists = linePointIndex >= 0

  if (lineExists && linePointExists) {
    // update existing point's Y-Axis value
    const updatedLinePoint = getUpdatedLinePoint({
      point: line.data[linePointIndex],
      yAxisValue,
      postfixUnit: yAxisPostfixUnit,
      prefixUnit: yAxisPrefixUnit,
    })
    line.data[linePointIndex] = updatedLinePoint
  } else if (lineExists && !linePointExists) {
    // add new point to the line
    line.data.push(newPoint)
  }
  return lines
}

type ReduceLinesArgs = {
  xAttribute: IAttribute
  yAttribute: IAttribute
  dateViewLevel: TDateViewLevel
  colorScheme: readonly string[]
  isTargetLine?: boolean
}

// returns a reducer callback function
const reduceLinePerDataObject =
  ({ colorScheme, dateViewLevel, xAttribute, yAttribute, isTargetLine = false }: ReduceLinesArgs) =>
  (lines: Line[], datum: Record<string, unknown>) => {
    const { labelType: yAxisLabelType, labelValue: seriesKey, label: yAxisLabel } = yAttribute

    const isSeries = yAxisLabelType === 'Series'
    const lineId = isSeries ? (datum[seriesKey ?? ''] as string) : yAxisLabel

    // In case JSON data has null values i.e inconsistent data we can not create or update lines
    // therefore move to next JSON data object
    if (!lineId) return lines

    const { value: xAxisValueKey } = xAttribute
    const xAxisValue = datum[xAxisValueKey]

    // if a data object does not have an X-Axis value then it cannot be mapped in a visualization
    if (!datum[xAxisValueKey]) return lines

    const { valueType: xAxisValueType } = xAttribute
    const {
      prefixUnit: yAxisPrefixUnit,
      postfixUnit: yAxisPostfixUnit,
      [isTargetLine ? 'target' : 'value']: yAxisValueKey,
    } = yAttribute

    const yAxisValue = (datum[yAxisValueKey ?? ''] as number) ?? 0

    const formattedXValue = formatXValueByAttributeValueType({
      dateViewLevel,
      value: xAxisValue,
      valueType: xAxisValueType,
    })

    const formattedYValue = formatUnit({
      value: yAxisValue,
      valueType: 'Number',
      postfixUnit: yAxisPostfixUnit,
      prefixUnit: yAxisPrefixUnit,
    })

    return createOrUpdateLines({
      colorScheme,
      formattedXValue,
      formattedYValue,
      lineId,
      lines,
      yAxisValue,
      yAxisPostfixUnit,
      yAxisPrefixUnit,
    })
  }

type FormatLinesArgs = {
  data: Record<string, unknown>[]
  isTargetLine?: boolean
} & ReduceLinesArgs

export const formatLines = (args: FormatLinesArgs) => {
  const { data: visualizationData, ...reduceLinesArgs } = args
  return visualizationData.reduce(reduceLinePerDataObject({ ...reduceLinesArgs }), [])
}

export const sortVisualizationDataByXAxisAttribute = (
  data: Record<string, unknown>[],
  xAttribute: IAttribute
) => {
  const { valueType, value: valueKey } = xAttribute
  return data.sort((objA: Record<string, unknown>, objB: Record<string, unknown>) => {
    if (valueType === 'DateTime' || valueType === 'Date') {
      const stringDateA = String(objA[valueKey])
      const stringDateB = String(objB[valueKey])

      const dateA = new Date(stringDateA)
      const dateB = new Date(stringDateB)

      return dateA.getTime() - dateB.getTime()
    } else {
      const numA = objA[valueKey] as number
      const numB = objB[valueKey] as number
      return numA - numB
    }
  })
}
