import { z } from '@invisible/zod'

const schema = z.object({
  inputBaseVariableId: z.string().optional(),
  outputBaseVariableId: z.string().optional(),
  tags: z.array(z.string()).optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
