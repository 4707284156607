import { Input } from '@invisible/ui/input'
import { TagInput } from '@invisible/ui/tag-input'
import TextField from '@mui/material/TextField'
import { Prisma } from '@prisma/client'
import classNames from 'classnames'
import { debounce } from 'lodash/fp'
import { ChangeEvent, useMemo, useState } from 'react'

import { CustomMultiSelect } from '../sub-components/CustomMultiSelect'
import { CustomRadio } from '../sub-components/CustomRadio'
import { CustomSelect } from '../sub-components/CustomSelect'
import { NumberRank } from '../sub-components/NumberRank'
import { NumberRating } from '../sub-components/NumberRating'
import { YesNoSwitch } from '../sub-components/YesNoSwitch'
import { IMetadataProps } from '../types'

const Metadata = ({
  baseRunId,
  baseRunVariables,
  data: metadata,
  updateBaseRunVariables,
  selectedValueColor,
  numOfRank,
  isReadOnly,
}: IMetadataProps) => {
  const [isSaving, setIsSaving] = useState(false)
  const [brvs, setBrvs] = useState<Record<string, any>>(() =>
    baseRunVariables.reduce((acc, brv) => ({ ...acc, [brv.baseVariable.id]: brv.value }), {})
  )

  const changesMade = useMemo(
    () =>
      Object.keys(brvs).some((baseVariableId) => {
        const brv = baseRunVariables.find((v) => v.baseVariable.id === baseVariableId)
        return (
          brv?.value !== brvs[baseVariableId] &&
          metadata?.find((f) => f.baseVariableId === baseVariableId)
        )
      }),
    [brvs, baseRunVariables, metadata]
  )

  const handleBaseRunVariableUpdate = ({
    value,
    baseVariableId,
  }: {
    value: Prisma.JsonValue
    baseVariableId: string
  }) => {
    updateBaseRunVariables([
      {
        baseRunId,
        baseVariableId,
        value,
      },
    ])
    setBrvs((prev) => ({ ...prev, [baseVariableId]: value }))
  }

  const handleAllSaveFields = async () => {
    if (isSaving) return
    setIsSaving(true)
    await updateBaseRunVariables(
      Object.keys(brvs)
        // Exclude unchanged values and non metadata base variables
        .filter((bv) => {
          const brv = baseRunVariables.find((v) => v.baseVariable.id === bv)
          return brv?.value !== brvs[bv] && metadata?.find((f) => f.baseVariableId === bv)
        })
        .map((baseVariableId) => ({
          baseVariableId,
          value: brvs[baseVariableId],
          baseRunId,
        }))
    )
    setIsSaving(false)
  }

  const singleRowTypes = [
    'a_string',
    'boolean',
    'radio',
    'number',
    'rating',
    'ranking',
    'multiselect',
  ]
  return (
    <div className='flex w-full flex-col gap-2'>
      {(metadata ?? []).map((item) => (
        <div
          className={classNames(
            'flex  bg-white p-2',
            singleRowTypes.includes(item.type) ? 'items-center' : 'flex-col gap-2'
          )}
          key={item.baseVariableId}>
          <div className='mr-2'>
            {item.label}{' '}
            {item?.required ? (
              <span style={{ color: selectedValueColor ?? 'darkred' }}>*</span>
            ) : null}
          </div>
          {item.type === 'a_string' ? (
            <TagInput
              tags={
                (baseRunVariables.find((v) => v.baseVariable.id === item.baseVariableId)?.value ??
                  []) as string[]
              }
              disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              onChange={(value) =>
                handleBaseRunVariableUpdate({
                  baseVariableId: item.baseVariableId as string,
                  value,
                })
              }
            />
          ) : item.type === 'boolean' ? (
            <YesNoSwitch
              disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              value={
                (baseRunVariables.find((v) => v.baseVariable.id === item.baseVariableId)?.value ??
                  null) as boolean | null
              }
              selectedValueColor={selectedValueColor}
              onChange={(value) =>
                handleBaseRunVariableUpdate({
                  baseVariableId: item.baseVariableId as string,
                  value,
                })
              }
            />
          ) : item.type === 'dropdown' ? (
            <CustomSelect
              options={item.options ?? []}
              disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              selectedKey={
                (baseRunVariables.find((v) => v.baseVariable.id === item.baseVariableId)?.value ??
                  '') as string
              }
              onSelect={(selectedValue) => {
                handleBaseRunVariableUpdate({
                  baseVariableId: item.baseVariableId as string,
                  value: selectedValue,
                })
              }}
            />
          ) : item.type === 'multiselect' ? (
            <CustomMultiSelect
              showSelectKeys
              selectedValueColor={selectedValueColor}
              name={item.label as string}
              options={item.options ?? []}
              disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              selectedKeys={
                (
                  (baseRunVariables.find((v) => v.baseVariable.id === item.baseVariableId)?.value ??
                    '') as string
                )
                  .split(',')
                  .filter((v: string) => v) ?? []
              }
              onSelect={(selectedValues) => {
                handleBaseRunVariableUpdate({
                  baseVariableId: item.baseVariableId as string,
                  value: selectedValues.toString(),
                })
              }}
            />
          ) : item.type === 'radio' ? (
            <CustomRadio
              selectedValueColor={selectedValueColor}
              options={item.options ?? []}
              disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              value={
                (baseRunVariables.find((v) => v.baseVariable.id === item.baseVariableId)?.value ??
                  '') as string
              }
              onChange={({ value }) =>
                handleBaseRunVariableUpdate({
                  baseVariableId: item.baseVariableId as string,
                  value,
                })
              }
            />
          ) : item.type === 'rating' ? (
            <NumberRating
              disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              value={
                (baseRunVariables.find((v) => v.baseVariable.id === item.baseVariableId)?.value ??
                  null) as number | null
              }
              selectedValueColor={selectedValueColor}
              onChange={(value) =>
                handleBaseRunVariableUpdate({
                  baseVariableId: item.baseVariableId as string,
                  value,
                })
              }
            />
          ) : item.type === 'ranking' ? (
            <NumberRank
              disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              value={
                (baseRunVariables.find((v) => v.baseVariable.id === item.baseVariableId)?.value ??
                  null) as number | null
              }
              numOfRank={numOfRank}
              selectedValueColor={selectedValueColor}
              onChange={(value) =>
                handleBaseRunVariableUpdate({
                  baseVariableId: item.baseVariableId as string,
                  value,
                })
              }
            />
          ) : item.type === 'number' ? (
            <Input
              disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              value={(brvs[item.baseVariableId ?? ''] ?? '') as string}
              type='number'
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setBrvs((prev) => ({ ...prev, [item.baseVariableId ?? '']: e.target.value }))
                debounce(500, (value) =>
                  handleBaseRunVariableUpdate({
                    value,
                    baseVariableId: item.baseVariableId as string,
                  })
                )(e.target.value)
              }}
            />
          ) : (
            <div className='flex flex-col'>
              <TextField
                value={(brvs[item.baseVariableId ?? ''] ?? '') as string}
                onChange={(e) => {
                  setBrvs((prev) => ({ ...prev, [item.baseVariableId ?? '']: e.target.value }))
                }}
                multiline
                fullWidth
                maxRows={4}
                minRows={1}
                disabled={isReadOnly ? true : item.editable === undefined ? false : !item.editable}
              />
            </div>
          )}
        </div>
      ))}
      {(metadata ?? []).length > 0 && changesMade && (
        <div className='mb-1 flex justify-start'>
          <span
            onClick={() => handleAllSaveFields()}
            className='cursor-pointer'
            style={{
              color: '#fff',
              backgroundColor: isSaving ? 'gray' : selectedValueColor,
              padding: '6px',
            }}>
            {isSaving ? 'saving...' : 'save metadata'}
          </span>
        </div>
      )}
    </div>
  )
}

export { Metadata }
