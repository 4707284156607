import { classNames } from '@invisible/common/helpers'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { startCase } from 'lodash/fp'
import { FC, forwardRef, MutableRefObject } from 'react'
import type { ToastProps } from 'react-toast-notifications'
import { ToastProvider, ToastProviderProps } from 'react-toast-notifications'

import { Button } from '../button'
import { DashCircleFilledIcon, FilledCheckIcon, InfoFilledIcon } from '../icons'

const appearanceStyles = {
  success: {
    background: 'bg-green-weak',
    icon: <FilledCheckIcon className='text-green-main h-5 w-5' />,
    title: 'Nice!',
    fillColor: 'bg-green-main',
    border: 'border-green-main border border-solid',
  },
  warning: {
    background: 'bg-amber-weak',
    icon: <DashCircleFilledIcon className='text-amber-main h-5 w-5' />,
    title: 'Almost There!',
    fillColor: 'bg-amber-main',
    border: 'border-amber-main border border-solid',
  },
  info: {
    background: 'bg-sky-weak',
    icon: <InfoFilledIcon className='text-sky-main h-5 w-5' />,
    title: 'Heads Up!',
    fillColor: 'bg-sky-main',
    border: 'border-sky-main border border-solid',
  },
  error: {
    background: 'bg-red-weak',
    icon: <ErrorOutlineIcon className='text-red-main h-5 w-5' />,
    title: 'Oops!',
    fillColor: 'bg-red-main',
    border: 'border-red-main border border-solid',
  },
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CustomToast: FC<ToastProps & { title?: string }> = ({
  appearance,
  children,
  transitionState,
  isRunning,
  onDismiss,
  title,
}) => (
  <div
    className={classNames(
      'bg-void relative mb-4 flex w-[350px] items-start gap-x-4 rounded-md p-4 shadow transition-transform ease-in-out',
      transitionState === 'exiting'
        ? 'animate-[slideOut_ease_0.5s]'
        : 'animate-[slideIn_ease_0.2s]',
      appearanceStyles[appearance].background,
      appearanceStyles[appearance].border
    )}
    data-cy={`toast${startCase(appearance)}`}
    onClick={() => onDismiss()}>
    <div className='flex items-center justify-center'>{appearanceStyles[appearance].icon}</div>
    <div className='flex flex-grow flex-col justify-start font-sans text-[#5F2120]'>
      <div className='font-medium'>{title ?? appearanceStyles[appearance].title}</div>
      <div className='overflow-hidden text-sm pt-1 leading-tight' style={{ maxHeight: '160px' }}>
        {children}
      </div>
    </div>
    <Button
      size='md'
      onClick={() => onDismiss()}
      icon='CloseIcon'
      variant='subtle'
      shape='square'
    />
    <div
      className={classNames(
        'absolute bottom-0 right-0 z-[10000] w-1 rounded-md',
        isRunning ? `h-0 animate-[shrink_linear_5000ms]` : 'h-full ',
        appearanceStyles[appearance].fillColor
      )}
    />
  </div>
)

interface IProps extends ToastProviderProps {
  ref?: ((instance: unknown) => void) | MutableRefObject<unknown> | null
  title?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const CustomProvider: FC<IProps> = forwardRef(({ children, autoDismiss = true, ...props }, ref) => (
  <ToastProvider autoDismiss={autoDismiss} components={{ Toast: CustomToast }} ref={ref} {...props}>
    {children}
  </ToastProvider>
))

export { CustomToast, CustomProvider as ToastProvider }
export { useToasts } from 'react-toast-notifications'
