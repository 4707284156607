import { BarDatum, ComputedDatum } from '@nivo/bar'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { BRIGHT_THEME, CHILL_THEME } from '../constants/colorThemes'
import { HorizontalBarChartSkeleton, VerticalBarChartSkeleton } from './skeleton-components'
import { BarChart } from './ui-bar-chart'

const SkeletonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`

type TDrillDownData = BarDatum & {
  [label: string | number]: string | number | object | undefined
  childData?: object
  indexValue?: string
  keys?: string[]
}

interface UiDrillDownChartProps {
  chartDataProp: TDrillDownData[]
  chartSubDataProp?: TDrillDownData
  chartSubSubDataProp?: TDrillDownData
  keys?: string[]
  height?: string
  isLoading?: boolean
  factor?: number
  width: number
  showLegends?: boolean
  handleNodeClick?: (node: ComputedDatum<BarDatum> & { color: string }) => void
  theme?: 'chill' | 'bright'
  colors?: string[]
  labelTextColor?: string
  layout?: 'horizontal' | 'vertical'
  groupMode?: 'stacked' | 'grouped'
  defs?: {
    id: string
    type: string
    background: string
    color: string
    size: number
    padding: number
    stagger: boolean
  }[]
  fill?: {
    match: {
      id: string
    }
    id: string
  }[]
  axisLeft?: {
    tickSize: number
    tickPadding: number
    tickRotation: number
    legend: string
    legendPosition: 'middle' | undefined
    legendOffset: number
  }
  axisRight?: {
    tickSize: number
    tickPadding: number
    tickRotation: number
    legend: string
    legendPosition: 'middle' | undefined
    legendOffset: number
  }
  axisTop?: {
    tickSize: number
    tickPadding: number
    tickRotation: number
    legend: string
    legendPosition: 'middle' | undefined
    legendOffset: number
  }
  axisBottom?: {
    tickSize: number
    tickPadding: number
    tickRotation: number
    legend: string
    legendPosition: 'middle' | undefined
    legendOffset: number
  }
  indexBy?: string
  minValue?: number | 'auto'
  maxValue?: number | 'auto'
  padding?: number
  enableLabel?: boolean
  enableGridY?: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const DrillDownBarChart: FC<UiDrillDownChartProps> = ({
  chartDataProp,
  chartSubDataProp,
  chartSubSubDataProp,
  keys,
  theme,
  colors,
  factor = 1,
  width,
  isLoading,
  showLegends,
  handleNodeClick,
  labelTextColor,
  layout,
  groupMode,
  defs,
  fill,
  indexBy,
  minValue,
  maxValue,
  padding,
  enableLabel,
  enableGridY,
  axisLeft,
  axisRight,
  axisTop,
  axisBottom,
  ...props
}) => {
  const [currentNode, setCurrentNode] = useState<
    (ComputedDatum<BarDatum> & { color: string }) | null
  >()

  const [currentData, setCurrentData] = useState(chartDataProp)
  const [dataLevel, setDataLevel] = useState('levelOneData')
  const [currentKeys, setCurrentKeys] = useState<string[] | undefined>(chartDataProp[0].keys)
  const [currentIndex, setCurrentIndex] = useState<string | undefined>('')

  useEffect(() => {
    if (dataLevel === 'levelOneData') {
      setCurrentKeys(chartDataProp[0].keys)
      setCurrentIndex(chartDataProp[0].indexValue)
      setCurrentData(chartDataProp)
    }
    if (currentNode) {
      if (dataLevel === 'levelTwoData') {
        const currentNodeLabel = currentNode.id
        const currentNodeIndex = currentNode.indexValue

        if (chartSubDataProp && currentNodeIndex) {
          const currentNodeChild =
            chartSubDataProp[currentNodeLabel as keyof typeof chartSubDataProp]
          const currentNodeChildData: any | null = currentNodeChild
            ? currentNodeChild[currentNodeIndex as keyof typeof currentNodeChild]
            : null

          if (currentNodeChildData) {
            setCurrentData(currentNodeChildData)
            setCurrentIndex(currentNodeChildData[0].indexValue)
            setCurrentKeys(currentNodeChildData[0].keys)
          } else {
            setCurrentData(chartDataProp)
            setCurrentKeys(chartDataProp[0].keys)
            setCurrentIndex(chartDataProp[0].indexValue)
          }
        }
      }
      if (dataLevel === 'levelThreeData') {
        const currentNodeLabel = currentNode.id
        const currentNodeIndex = currentNode.indexValue
        if (chartSubSubDataProp && currentNodeIndex) {
          const currentNodeChild =
            chartSubSubDataProp[currentNodeLabel as keyof typeof chartSubSubDataProp]
          const currentNodeChildData: any | null = currentNodeChild
            ? currentNodeChild[currentNodeIndex as keyof typeof currentNodeChild]
            : null

          if (currentNodeChildData) {
            setCurrentData(currentNodeChildData)

            setCurrentIndex(currentNodeChildData[0].indexValue)
            setCurrentKeys(currentNodeChildData[0].keys)
          } else {
            setCurrentData(chartDataProp)
            setCurrentKeys(chartDataProp[0].keys)
            setCurrentIndex(chartDataProp[0].indexValue)
          }
        }
      }
    }
  }, [currentNode, dataLevel])

  const handleNode = (node: ComputedDatum<BarDatum> & { color: string }) => {
    setCurrentNode(node)
    if (chartSubDataProp) {
      dataLevel === 'levelOneData'
        ? setDataLevel('levelTwoData')
        : dataLevel === 'levelTwoData'
        ? setDataLevel('levelThreeData')
        : setDataLevel('levelOneData')
    }
  }
  return (
    <div>
      {isLoading && layout === 'vertical' ? (
        <SkeletonDiv>
          <VerticalBarChartSkeleton factor={factor} />
        </SkeletonDiv>
      ) : isLoading ? (
        <SkeletonDiv>
          <HorizontalBarChartSkeleton />
        </SkeletonDiv>
      ) : (
        <BarChart
          width={width}
          chartDataProp={currentData}
          keys={keys ? keys : currentKeys}
          handleNodeClick={(node) => handleNode(node)}
          indexBy={indexBy ? indexBy : currentIndex}
          labelTextColor={labelTextColor}
          padding={padding}
          layout={layout}
          showLegends={showLegends}
          minValue={minValue}
          maxValue={maxValue}
          groupMode={groupMode}
          enableGridY={enableGridY}
          colors={theme === 'bright' ? BRIGHT_THEME : CHILL_THEME}
          defs={defs}
          fill={fill}
          axisTop={axisTop && axisTop}
          axisRight={axisRight && axisRight}
          axisBottom={axisBottom && axisBottom}
          axisLeft={axisLeft && axisLeft}
        />
      )}
    </div>
  )
}

export default DrillDownBarChart
