import { logger } from '@invisible/logger/client'
import axios, { AxiosError } from 'axios'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useGate } from 'statsig-react'

import { PING_INTERVAL, PING_TIMEOUT } from './constants'

interface OnlineStatusContextProps {
  isOnline: boolean
  isLoading: boolean
  refetch: () => Promise<boolean>
}

const OnlineStatusContext = createContext<OnlineStatusContextProps | undefined>(undefined)

export const OnlineStatusProvider = ({ children }: { children: React.ReactNode }) => {
  const { value: enableOnlineStatus } = useGate('enable-online-status-check')
  const [isOnline, setIsOnline] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const intervalIdRef = useRef<number | null>(null)

  const pingAndSetOnlineStatus = async (): Promise<boolean> => {
    setIsLoading(true)
    let onlineStatus = true

    return axios
      .get('/api/ping', {
        headers: { 'Cache-Control': 'no-store' },
        timeout: PING_TIMEOUT,
      })
      .then(() => {
        onlineStatus = true
        logger.info('useOnlineStatus: ping successful')
        return onlineStatus
      })
      .catch((error: AxiosError) => {
        if (error.code === 'ECONNABORTED') {
          // Handling timeout separately
          onlineStatus = true
          logger.error('useOnlineStatus: ping timed out')
        } else if (!error.response && error.request) {
          // Handling network error
          onlineStatus = false
          logger.error('useOnlineStatus: ping network error')
        } else {
          // Handling all other types of errors (e.g., server responded with 4xx/5xx)
          onlineStatus = true
          logger.error('useOnlineStatus: ping internal server error')
        }
        return onlineStatus
      })
      .finally(() => {
        setIsOnline(onlineStatus)
        setIsLoading(false)
        handleInterval()
      })
  }

  const handleInterval = () => {
    logger.info('useOnlineStatus: clearing and setting ping interval')
    if (intervalIdRef.current) clearInterval(intervalIdRef.current)

    const interval = window.setInterval(pingAndSetOnlineStatus, PING_INTERVAL)
    intervalIdRef.current = interval
  }

  const handleNavigatorChange = () => {
    logger.info('useOnlineStatus: handling navigator change')
    pingAndSetOnlineStatus()
  }

  useEffect(() => {
    if (enableOnlineStatus) {
      // Listening to navigator changes
      window.addEventListener('online', handleNavigatorChange)
      window.addEventListener('offline', handleNavigatorChange)

      // Periodically check user status
      handleInterval()
    }

    // Cleanup
    return () => {
      if (enableOnlineStatus) {
        window.removeEventListener('online', handleNavigatorChange)
        window.removeEventListener('offline', handleNavigatorChange)
        if (intervalIdRef.current) clearInterval(intervalIdRef.current)
      }
    }
  }, [enableOnlineStatus])

  return (
    <OnlineStatusContext.Provider value={{ isOnline, isLoading, refetch: pingAndSetOnlineStatus }}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

export const useOnlineStatus = () => {
  const context = useContext(OnlineStatusContext)
  if (!context) {
    throw new Error('useOnlineStatus must be used within an OnlineStatusProvider')
  }
  return context
}
