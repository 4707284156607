import { Input } from '@invisible/ui/input'

type FieldProps = {
  value: string
  isReadOnly: boolean
}

const URLField = ({ value, isReadOnly }: FieldProps) => (
  <Input type='url' width='100%' value={value} readOnly={isReadOnly} />
)

export { URLField }
