import { logout } from '@invisible/authentication/client'
import { isEmpty } from 'lodash/fp'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useRef, useState } from 'react'

import { Avatar } from '../avatar'
import { useClickAway } from '../hooks/use-click-away'
import { TLoggedInUser, useLoggedInUser } from '../hooks/use-logged-in-user'
import { useUserActivity } from '../hooks/use-user-activity'
import { CaretDownIcon, DocumentIcon, SignOutIcon } from '../icons'

interface IOption {
  title: string
  path: string
  renderIcon: (filled: boolean) => JSX.Element
}

interface IProfileListRouteProps {
  option: {
    title: string
    path: string
    renderIcon: (filled: boolean) => JSX.Element
  }
  exact?: boolean
  setIsClosed: (v: boolean) => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ProfileListRoute: FC<IProfileListRouteProps> = ({
  option: { title, renderIcon, path },
  exact,
  setIsClosed,
  ...props
}) => {
  const { pathname, push } = useRouter()
  const isActive = exact ? pathname === path : pathname.startsWith(path)

  const handleClick = () => {
    push(path)
    setIsClosed(true)
  }

  return (
    <Link href={path}>
      <a onClick={handleClick} {...props} className='h-10 w-full no-underline hover:no-underline'>
        <div
          className={`box-border flex h-full w-full items-center justify-start space-x-3 px-5 text-gray-700  ${
            isActive
              ? 'border-theme-main bg-theme-weak-3 text-theme-main  border-0 border-r-2 border-solid'
              : 'hover:bg-weak'
          } transition-all duration-200 ease-in-out`}>
          {renderIcon(isActive)}
          {<div className={`flex-grow ${isActive ? 'font-bold' : 'font-normal'}`}>{title}</div>}
        </div>
      </a>
    </Link>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ProfileListItem: FC<{
  title: string
  renderIcon: () => JSX.Element
  onClick: () => void
}> = ({ title, renderIcon, onClick, children }) => {
  const handleClick = () => {
    onClick()
  }

  return (
    <div
      onClick={handleClick}
      className={`box-border flex h-10 w-full cursor-pointer items-center justify-start space-x-3 px-5 text-gray-700 transition-all
         duration-200 ease-in-out hover:bg-gray-200`}>
      {renderIcon()}
      {<div className={`flex-grow font-normal`}>{title}</div>}
    </div>
  )
}

/* eslint-disable-next-line */
export interface IProps {
  profilePicture: string
  profileText: string
  profileSubText: string
  profileOptions: IOption[]
  textColor?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ProfileMenu: FC<IProps> = ({
  profilePicture,
  profileText,
  profileSubText,
  profileOptions,
  textColor,
}) => {
  const ref = useRef<null | HTMLDivElement>(null)
  const [isClosed, setIsClosed] = useState(true)
  const [loggedInUser] = useLoggedInUser()
  const { isActive: isUserActive } = useUserActivity()
  const { push } = useRouter()

  useClickAway(ref, () => {
    setIsClosed(true)
  })

  return (
    <div className='relative ' ref={ref}>
      <div
        className='flex cursor-pointer items-center space-x-2 transition-all duration-200 ease-in-out'
        onClick={() => setIsClosed((prev) => !prev)}>
        <div className='flex'>
          <Avatar
            marginLeft={0}
            size={32}
            src={isEmpty(profilePicture.trim()) ? undefined : profilePicture} // Avoid passing blank string as props as it will crash Avatar component
            objectFit='cover'
            isOnline={isUserActive}
          />
        </div>
        <div className={`${textColor ? `text-${textColor}` : ''}`}>{profileText}</div>
        <div
          className={`flex h-6 w-6 cursor-pointer items-center justify-center  ${
            isClosed ? '' : 'rotate-180'
          } transition-all duration-200 ease-in-out`}>
          <CaretDownIcon width={11} height={11} color={textColor ?? ''} />
        </div>
      </div>

      {!isClosed && (
        <div className='absolute top-11 right-0 z-50 min-w-[220px] rounded shadow-md'>
          <div className='bg-void flex flex-col items-center justify-center space-y-1 p-4'>
            <Avatar
              marginLeft={0}
              size={52}
              src={isEmpty(profilePicture.trim()) ? undefined : profilePicture} // Avoid passing blank string as props as it will crash Avatar component
              objectFit='cover'
            />
            <div className='text-theme-main text-base'>{profileText}</div>
            <div className='text-paragraphs text-xs'>{profileSubText}</div>
            <div className='border-theme-weak-2 text-theme-main bg-theme-weak-3 rounded border border-solid py-0.5 px-1.5 text-xs'>
              Contributor
            </div>
          </div>
          <div className='bg-void flex h-full w-full flex-col items-center space-y-2'>
            {profileOptions.map((option) => (
              <ProfileListRoute option={option} key={option.path} setIsClosed={setIsClosed} />
            ))}
            {(loggedInUser as TLoggedInUser)?.acknowledgedTos && (
              <ProfileListItem
                title='Terms of Service'
                renderIcon={() => <DocumentIcon className='h-4 w-4' />}
                onClick={() => {
                  setIsClosed(true)
                  push('/terms-of-service')
                }}
              />
            )}
            <ProfileListItem
              title='Logout'
              renderIcon={() => <SignOutIcon className='h-4 w-4' />}
              onClick={() => {
                logout()
              }}
            />
          </div>
          <div className='bg-void flex items-center justify-evenly p-2'>
            {/* Commenting this part out as we are missing UX for these */}
            {/* <a href='/' className='text-paragraphs hover:text-muted text-xs no-underline'>
              Privacy Policy
            </a>
            <a href='/' className='text-paragraphs hover:text-muted text-xs no-underline'>
              Terms of Service
            </a> */}
          </div>
        </div>
      )}
    </div>
  )
}

export { ProfileMenu }
