import { Button, Stack,TextField } from '@mui/material'
import Avatar from '@mui/material/Avatar'

import { PromptInputProps } from '../types'

export const PromptInput = ({
  promptText,
  handleInputChange,
  handlePromptSubmission,
  isDisabled,
  isReadOnly,
  loggedInUser,
}: PromptInputProps) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="center"
    sx={{
      p: 1,
      bgcolor: 'white',
      borderTop: '2px solid',
      borderColor: 'grey.400',
      zIndex: 1,
      marginBottom: '0.75rem'
    }}
  >
    <Avatar
      sx={{
        height: 20,
        width: 20,
      }}
      src={loggedInUser?.image || ''}
    />
    <TextField
      placeholder='Enter your prompt here...'
      value={promptText}
      onChange={handleInputChange}
      multiline
      fullWidth
      maxRows={15}
      minRows={1}
      disabled={isDisabled || isReadOnly}
      size='small'
    />
    <Button
      variant='contained'
      onClick={() => handlePromptSubmission(promptText)}
      disabled={isDisabled || !promptText || isReadOnly}
      sx={{
        backgroundColor: '#2196F3',
        '&:hover': {
          backgroundColor: '#2196F3',
        },
      }}>
      Send
    </Button>
  </Stack>
)
