import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import styles from './CustomScrollbar.module.css'
import { CEMCodeStatus } from './types'

interface ICodeOutputProps {
  stdout?: string
  stderr?: string
  result?: string
  status: CEMCodeStatus
  sx?: React.CSSProperties
}

export const CodeOutput = ({ stdout, stderr, result, status, sx }: ICodeOutputProps) => (
  <Box sx={{ backgroundColor: '#29272F', ...sx }}>
    <Box sx={{ position: 'relative', height: '100%', pt: 2, pl: 6, color: 'white' }}>
      {(stdout || stderr) && (
        <Typography
          variant='h4'
          sx={{
            borderWidth: '0px',
            borderBottomWidth: '2px',
            borderStyle: 'solid',
            borderColor: 'rgb(255 255 255)',
            pb: 2,
          }}>
          Output
        </Typography>
      )}
      <Box className={styles['custom-scrollbar']} sx={{ mt: 2, height: 'calc(100% - 96px)', overflow: 'auto', scrollbarWidth: 'thin', scrollbarColor:  '#555 #29272F'}}>
        {stdout && (
          <Typography sx={{ textWrap: 'wrap', whiteSpace: 'pre-wrap' }} variant='body2'>
            {stdout}
          </Typography>
        )}
        {stderr && (
          <Typography
            sx={{ textWrap: 'wrap', whiteSpace: 'pre-wrap', color: 'rgb(239 68 68)' }}
            variant='body2'>
            {stderr}
          </Typography>
        )}
        {result && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant='h6'
              sx={{
                my: 2,
                borderWidth: '0px',
                borderBottomWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'rgb(255 255 255)',
                pb: 2,
              }}>
              Result
            </Typography>
            <Typography sx={{ textWrap: 'wrap', whiteSpace: 'pre-wrap' }}>
              {JSON.stringify(result)}
            </Typography>
          </Box>
        )}
      </Box>
      {status === CEMCodeStatus.Executing && (
        <Box
          sx={{
            position: 'absolute',
            inset: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <CircularProgress size={100} color='primary' />
        </Box>
      )}
    </Box>
  </Box>
)
