import { SnackbarContext } from '@invisible/common/providers'
import { MUIThemeProvider } from '@invisible/ui/mui-theme-v2'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  TStepRun,
  useWizardState,
} from 'libs/common/components/providers/active-wizard-provider/src/lib/ActiveWizardProvider'
import { isNil } from 'lodash/fp'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import RegionsPlugin, { Region } from 'wavesurfer.js/dist/plugins/regions'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline'

import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { TBaseRunQueryData } from '../../hooks/useGetBaseRuns'
import { Controls } from './Controls'
import { Guidelines } from './Guidelines'
import { formatDuration } from './helpers'
import { Segments } from './Segments'
import { useWaveSurfer } from './useWaveSurfer'

export type TSegment = {
  id: string
  start: number
  end: number
  note: string | null
  tags: string[]
}
type TConfig = WizardSchemas.ProcessAudio.TSchema
type TBaseRun = TBaseRunQueryData['items'][number]

const WAVEFORM_STYLE_OVERRIDES = {
  '& ::part(wrapper)': { backgroundColor: '#EDE7F6', borderRadius: '4px' },
  '& ::part(progress)': {
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
  },
  '& ::part(region)': {
    zIndex: 10,
    backdropFilter: 'invert(1) brightness(2) contrast(2)',
  },
  '& ::part(region-handle)': {
    borderLeftColor: '#584999',
    borderRightColor: '#584999',
  },
  '& ::part(cursor):after': {
    content: '""',
    position: 'absolute',
    top: '-4px',
    left: '-4px',
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '8px solid rgba(255, 0, 0, 0.5)',
  },
}

interface IProps {
  processAudio: TConfig
  baseRun: TBaseRun
  stepRun: TStepRun
}

const ProcessAudioWac = ({ processAudio, baseRun, stepRun }: IProps) => {
  const inputBaseRunVariableValue = baseRun.baseRunVariables.find(
    (v) => v.baseVariableId === processAudio.inputBaseVariableId
  )?.value as { url: string; fileName: string } | null

  const { dispatch } = useWizardState()
  const { showSnackbar } = useContext(SnackbarContext)

  const reactQueryClient = useQueryClient()

  const [playbackContainer, setPlaybackContainer] = useState<HTMLDivElement | null>(null)
  const [regionPlugin, setRegionPlugin] = useState<RegionsPlugin | null>(null)

  const [segmentBeingPlayed, setSegmentBeingPlayed] = useState<string | null>(null)

  const options = useMemo(
    () => ({
      progressColor: '#9c8fcc',
      waveColor: '#9c8fcc',
      cursorColor: 'rgba(255, 0, 0, 0.5)',
      cursorWidth: 2,
      barWidth: 4,
      barGap: 10,
      barRadius: 4,
      height: 100,
      barHeight: 0.7,
      normalize: true,
      url: inputBaseRunVariableValue?.url,
      plugins: [
        TimelinePlugin.create({
          insertPosition: 'beforebegin',
          formatTimeCallback: formatDuration,
        }),
      ],
    }),
    [inputBaseRunVariableValue?.url]
  )

  const segments = useMemo(() => {
    const baseRunVariable = baseRun.baseRunVariables.find(
      (v) => v.baseVariableId === processAudio.outputBaseVariableId
    )
    return {
      value: baseRunVariable?.value as TSegment[],
      id: baseRunVariable?.id as string,
    }
  }, [baseRun.baseRunVariables, processAudio.outputBaseVariableId])

  const { mutateAsync: updateVariable } = useBaseRunVariablesWizardUpdate({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
    },
    onMutate: async (variables) => {
      dispatch({
        type: 'setBaseRunVariableValue',
        baseRunVariableId: segments.id,
        value: variables[0].value,
      })
    },
  })

  const setSegments = useCallback(
    (updatedSegments: TSegment[]) => {
      try {
        updateVariable({
          stepRunId: stepRun.id,
          data: [
            {
              baseRunId: stepRun.baseRunId,
              baseVariableId: processAudio.outputBaseVariableId as string,
              value: updatedSegments,
            },
          ],
        })
      } catch (_) {
        showSnackbar({ message: 'Something went wrong. Please try again.', variant: 'error' })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [processAudio.outputBaseVariableId, stepRun.baseRunId, stepRun.id]
  )

  const handleSegmentCreate = useCallback(
    (segment: TSegment) => {
      // Don't recreate segment if it already exists
      if (segments.value?.some((s) => s.id === segment.id)) return

      const updatedSegments = [...(segments.value ?? []), segment]
      setSegments(updatedSegments)
    },
    [segments, setSegments]
  )

  const handleSegmentEdit = useCallback(
    ({ id, note, tags, start, end }: { id: string } & Partial<TSegment>) => {
      const updatedSegments = segments.value.map((s) =>
        s.id === id
          ? {
              ...s,
              note: isNil(note) ? s.note : note,
              tags: isNil(tags) ? s.tags : tags,
              start: isNil(start) ? s.start : start,
              end: isNil(end) ? s.end : end,
            }
          : s
      )
      setSegments(updatedSegments)
    },
    [segments, setSegments]
  )

  const handleSegmentDelete = (id: string) => {
    const wavesurferRegions = regionPlugin?.getRegions()
    const region = wavesurferRegions?.find((r) => r.id === id)
    const updatedSegments = segments.value.filter((s) => s.id !== id)

    region?.remove()
    setSegments(updatedSegments)
  }

  const { waveSurfer, audioLength, playbackProgress } = useWaveSurfer({
    container: playbackContainer,
    options,
  })

  // Create regions plugin
  useEffect(() => {
    if (!waveSurfer) return

    const plugin = RegionsPlugin.create()
    waveSurfer?.registerPlugin(plugin)
    plugin.enableDragSelection({ color: 'rgb(148 122 255 / 60%)' })

    setRegionPlugin(plugin)

    return () => {
      setRegionPlugin(null)
      plugin.destroy()
    }
  }, [waveSurfer])

  // Setup listeners for region events
  useEffect(() => {
    if (!regionPlugin) return

    const handleRegionCreate = (region: Region) => {
      const { start, end, id } = region
      region.on('over', () => setSegmentBeingPlayed(id))
      region.on('leave', () => setSegmentBeingPlayed(null))

      handleSegmentCreate({ id, start, end, note: null, tags: [] })
    }

    const handleRegionUpdate = (region: Region) => {
      const { start, end } = region
      const id = region.id
      handleSegmentEdit({ id, start, end })
    }

    const handleSegmentEntry = (region: Region) => {
      setSegmentBeingPlayed(region.id)
    }

    const handleSegmentExit = (segment: Region) => {
      setSegmentBeingPlayed((prev) => (prev === segment.id ? null : prev))
    }

    regionPlugin.on('region-created', handleRegionCreate)
    regionPlugin.on('region-updated', handleRegionUpdate)
    regionPlugin.on('region-in', handleSegmentEntry)
    regionPlugin.on('region-out', handleSegmentExit)

    return () => {
      regionPlugin.un('region-created', handleRegionCreate)
      regionPlugin.un('region-updated', handleRegionUpdate)
      regionPlugin.un('region-in', handleSegmentEntry)
      regionPlugin.un('region-out', handleSegmentExit)
    }
  }, [regionPlugin, handleSegmentCreate, handleSegmentEdit, waveSurfer])

  // Add initial regions
  useEffect(() => {
    if (
      !regionPlugin ||
      !waveSurfer ||
      !segments.value ||
      segments.value.length === regionPlugin.getRegions().length
    )
      return

    const handleInitialRegions = () => {
      segments.value.forEach((segment) => {
        regionPlugin.addRegion({
          id: segment.id,
          start: segment.start,
          end: segment.end,
          color: 'rgb(148 122 255 / 60%)',
        })
      })
    }
    waveSurfer?.once('decode', handleInitialRegions)

    return () => {
      waveSurfer?.un('decode', handleInitialRegions)
    }
  }, [waveSurfer, regionPlugin, segments])

  return (
    <MUIThemeProvider>
      <Stack
        width='100%'
        height='100%'
        bgcolor='white'
        borderRadius='4px'
        p='8px 16px'
        boxSizing='border-box'
        overflow='hidden'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography fontSize='18px' fontWeight={500} mt='8px' mb='16px'>
            Process audio: {inputBaseRunVariableValue?.fileName}
          </Typography>
          <Guidelines />
        </Stack>
        <Box
          ref={(node: HTMLDivElement) => node && setPlaybackContainer(node)}
          sx={WAVEFORM_STYLE_OVERRIDES}
        />
        <Stack direction='row' justifyContent='space-between' alignItems='center' mt='4px'>
          <Controls waveSurfer={waveSurfer} />
          <Typography
            fontWeight={500}
            fontSize='16px'
            position='absolute'
            left='50%'
            sx={{ transform: 'translateX(-50%)' }}>
            {formatDuration(playbackProgress)}/{formatDuration(audioLength)}
          </Typography>
        </Stack>

        <Segments
          configuredTags={processAudio?.tags ?? []}
          segments={segments.value}
          segmentBeingPlayed={segmentBeingPlayed}
          handleSegmentEdit={handleSegmentEdit}
          handleSegmentDelete={handleSegmentDelete}
        />
      </Stack>
    </MUIThemeProvider>
  )
}

export { ProcessAudioWac }
