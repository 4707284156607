/* eslint-disable @typescript-eslint/no-var-requires */
import 'leaflet/dist/leaflet.css'

import { ErrorBoundary } from '@invisible/common/components/error-boundary'
import { FC, useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'

import { BRIGHT_THEME, CHILL_THEME } from '../constants/colorThemes'
import { styled } from '../themes'
import Legend from './Legend'
import { ChoroplethSkeleton } from './skeleton-components'

/* eslint-disable-next-line */

export interface IChoroplethChartProps {
  center: { lat: number; lng: number }
  height?: number
  width?: number
  zoom?: number
  colors?: 'bright' | 'chill'
  geoJsonDataProp?: {
    type: string
    features: {
      type: string
      id: string
      properties: {
        name: string
        density: number
      }
      geometry: {
        type: string
        coordinates: number[][][]
      }
    }[]
  }
  gradesProp?: number[]
  calcColor?: (quantity: number) => string
  isLoading: boolean
}

const StyledChoroplethChart = styled.div<{ height?: number; width?: number }>`
  height: ${({ height }) => (height ? `${height}px` : '500px')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  margin-top: 24px;
  overflow: hidden;
  pointer-events: all;
  && .info {
    padding: 6px 8px;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    line-height: 19px;
  }
  && .info h4 {
    margin: 0 0 5px;
    color: #777;
  }
  && .legend {
    line-height: 18px;
    color: #555;
  }
  && .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 45px;
    opacity: 0.7;
  }
`

// eslint-disable-next-line @typescript-eslint/ban-types
export const ChoroplethChart: FC<IChoroplethChartProps> = ({
  calcColor,
  height,
  width,
  geoJsonDataProp,
  gradesProp,
  zoom,
  center,
  colors,
  isLoading,
}) => {
  const [map, setMap] = useState(null)
  const colorTheme = colors === 'bright' ? BRIGHT_THEME : CHILL_THEME
  const [reactLeaflet, setReactLeaflet] = useState<any>(null)
  const [L, setL] = useState<any>(null)
  const getColor = (d: number) =>
    d > 1000
      ? colorTheme[8]
      : d > 500
      ? colorTheme[7]
      : d > 200
      ? colorTheme[6]
      : d > 100
      ? colorTheme[5]
      : d > 50
      ? colorTheme[4]
      : d > 20
      ? colorTheme[3]
      : d > 10
      ? colorTheme[2]
      : colorTheme[1]

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { MapContainer, Polygon, Tooltip } = require('react-leaflet')
      const L = require('leaflet')
      setReactLeaflet({ MapContainer, Polygon, Tooltip })
      setL(L)
    }
  }, [window])

  if (!geoJsonDataProp) return null
  const mapData = geoJsonDataProp
  if (reactLeaflet && L) {
    const { MapContainer, Polygon, Tooltip } = reactLeaflet
    return (
      <ErrorBoundary>
        <StyledChoroplethChart height={height} width={width} style={{ backgroundColor: 'white' }}>
          {!isLoading ? (
            <MapContainer
              center={center}
              style={{ width: '100%', height: height, backgroundColor: 'white' }}
              zoom={zoom ? zoom : 4}
              zoomControl={true}
              minZoom={1}
              maxZoom={6}
              attributionControl={false}
              scrollWheelZoom={false}
              whenCreated={setMap as any}>
              {mapData.features?.map((state) => {
                const coordinates =
                  state?.geometry?.type === 'Polygon'
                    ? state?.geometry?.coordinates[0]?.map((item) => [item[1], item[0]])
                    : (state?.geometry?.coordinates as any as number[][][][]).map((pArr) =>
                        pArr?.map((arr) => arr?.map((item) => [item[1], item[0]]))
                      )
                return (
                  <ContentLoader>
                    <Polygon
                      key={state.id}
                      pathOptions={{
                        fillColor: calcColor
                          ? calcColor(state.properties.density)
                          : getColor(state.properties.density),
                        fillOpacity: 0.7,
                        weight: 2,
                        opacity: 1,
                        color: 'white',
                      }}
                      positions={coordinates as any}
                      eventHandlers={{
                        mouseover: (e: { target: any }) => {
                          const layer = e.target
                          layer.setStyle({
                            fillOpacity: 0.4,
                            weight: 5,
                          })
                        },
                        mouseout: (e: { target: any }) => {
                          const layer = e.target
                          layer.setStyle({
                            fillOpacity: 0.7,
                            weight: 2,
                          })
                        },
                      }}>
                      <Tooltip>{`${state.properties.name} ${state.properties.density}`}</Tooltip>
                    </Polygon>
                  </ContentLoader>
                )
              })}
              <div>
                <Legend map={map} gradesProp={gradesProp} colorTheme={colorTheme} />
              </div>
            </MapContainer>
          ) : (
            <ChoroplethSkeleton height={height} />
          )}
        </StyledChoroplethChart>
      </ErrorBoundary>
    )
  } else return null
}

export default ChoroplethChart
