import { useEffect } from 'react'
import WaveSurfer from 'wavesurfer.js'

const useKeyboardShortcuts = ({
  waveSurfer,
  toggleSpeedOptions,
  toggleMute,
}: {
  toggleSpeedOptions: (speed: number) => void
  toggleMute: () => void
  waveSurfer?: WaveSurfer | null
}) => {
  useEffect(() => {
    if (!waveSurfer) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === ' ') waveSurfer.playPause()
      if (e.key.toLowerCase() === 'arrowleft') waveSurfer.skip(-10)
      if (e.key.toLowerCase() === 'arrowright') waveSurfer.skip(10)
      if (e.key.toLowerCase() === 'm') toggleMute()
      if (e.key.toLowerCase() === 's') toggleSpeedOptions(waveSurfer.getPlaybackRate())
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [toggleMute, toggleSpeedOptions, waveSurfer])
}

export { useKeyboardShortcuts }
