import { classNames, formatFilterValueLabel } from '@invisible/common/helpers'

import { DropdownMenuTrigger } from '../dropdown'
import { CaretDownIcon, CaretUpIcon, CloseIcon } from '../icons'
import { useFilterWizardContext } from './filter-wizard'

// eslint-disable-next-line @typescript-eslint/ban-types
export const FilterPillButton: React.FC<{ onClose?: () => void; disable?: boolean }> = ({
  onClose,
  disable = false,
}) => {
  const { filterLabel, inputValue, open, selectedFilterTypeKey, filterValueType } =
    useFilterWizardContext()

  const valueLabel = formatFilterValueLabel({
    filterOperatorType: selectedFilterTypeKey,
    filterValue: inputValue,
    filterValueType,
  })

  return (
    <div
      className={classNames(
        'box-content flex h-[30px] cursor-pointer flex-row items-center gap-2 whitespace-nowrap rounded-md border border-solid bg-white',
        open ? 'border-theme-main' : 'border-main'
      )}>
      <DropdownMenuTrigger
        disabled={disable}
        className='h-[44px] cursor-pointer border-none bg-transparent p-0 outline-none'>
        <div
          title={`${filterLabel}${valueLabel ? ` ${valueLabel}` : ''}`}
          className={classNames(
            'items-center  overflow-hidden text-ellipsis pl-3 leading-[1]',
            disable ? 'pr-3' : ''
          )}>
          {`${filterLabel}${valueLabel ? ` ${valueLabel}` : ''}`}
        </div>
      </DropdownMenuTrigger>
      {!disable && (
        <div className='py-1 pr-3 leading-[0]'>
          {open ? (
            <CaretUpIcon width={16} height={16} className='text-theme-main rotate-180' />
          ) : onClose ? (
            <CloseIcon
              className='hover:text-theme-main z-[300]'
              width={16}
              height={14}
              onClick={onClose}
            />
          ) : (
            <CaretDownIcon width={16} height={16} className='text-theme-main rotate-180' />
          )}
        </div>
      )}
    </div>
  )
}
