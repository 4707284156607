import * as Popover from '@radix-ui/react-popover'
import { trim } from 'lodash'
import { ChangeEvent, CSSProperties, FC, FocusEvent, useEffect, useState } from 'react'
import { Box, BoxProps, Flex } from 'rebass'

import { CopyOutlineIcon } from '../../icons'
import { Text, TEXT_TYPE } from '../../text'
import { css, fontSizes, gray, purple, styled, textColor, TTheme, useTheme } from '../../themes'

const StyledInput = styled.input`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
  color: ${textColor('title')};
  ::placeholder {
    color: ${textColor('disabled')};
  }
  border: 0;
  width: 100%;
  outline: none;
  &:disabled {
    color: ${textColor('disabled')};
  }
  padding: 0;
`

const InputWrapper = styled.div<{
  disabled?: boolean
}>`
  border: 1px solid ${gray(4)};
  box-sizing: border-box;
  height: 32px;
  min-width: 100px;
  padding: 5px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  &:focus-within {
    background: ${gray(1)};
    border: 1px solid ${purple(6)};
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 2px rgba(85, 175, 177, 0.1);
  }
  background: ${({ disabled, theme }) => (disabled ? theme.grayScale[3] : '')};
`

type IInputComponentProps = Omit<BoxProps, 'prefix' | 'size' | 'onBlur'> & {
  id?: string
  value?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Input: FC<IInputComponentProps> = ({
  id,
  placeholder,
  type = 'text',
  disabled = false,
  onChange,
  onBlur,
  width,
  required = false,
  pattern,
  ...rest
}) => {
  const [, setIsValid] = useState(true)
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsValid(e.target.validity.valid)
    if (onBlur) onBlur(e)
  }
  return (
    <InputWrapper>
      <StyledInput
        {...rest}
        type={type}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        required={required}
        pattern={pattern}
        onBlur={handleBlur}
      />
    </InputWrapper>
  )
}

type TextType = typeof TEXT_TYPE[keyof typeof TEXT_TYPE]
type TAlign = 'left' | 'normal'
export interface IInputVariable {
  id: number | string
  value?: string
  disabled?: boolean
  debounceDelay?: number
  onChange: ({ value, id }: { value: string; id: number | string }) => void
  placeholder?: string
  type?: TextType
  inputType?: 'text' | 'number' | 'url' | 'password' | 'tel' | 'date' | 'email'
  align?: TAlign
  style?: CSSProperties
  variableType?: string
  expandable?: boolean
}

const getColor = ({ theme, type }: { theme: TTheme; type: TextType }) => theme.textColor[type]

const borderStyles = css`
  border: 1px solid ${gray(4)};
`

const VariableText = styled(Text)<{ type: TextType; disabled: boolean }>`
  font-size: ${fontSizes(1)};
  color: ${getColor};
  padding: 6px 8px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 32px;
  border: 1px solid transparent;
  ${({ disabled }) => (disabled ? '' : borderStyles)}
  &:hover {
    border: 1px solid ${gray(5)};
  }
`

const InputContainer = styled(Box)<{ align: TAlign }>`
  margin-left: ${({ align }) => (align === 'left' ? '-5px' : '0')};
`

// eslint-disable-next-line @typescript-eslint/ban-types
const InputVariable: FC<IInputVariable> = ({
  id,
  value,
  debounceDelay = 1000,
  onChange,
  disabled = false,
  placeholder,
  type = 'primary',
  inputType,
  align = 'left',
  variableType,
  expandable = false,
  ...rest
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [internalValue, setInternalValue] = useState(value ?? '')
  const { colors } = useTheme()
  useEffect(() => {
    setInternalValue(value ?? '')
  }, [value])

  const handleChange = (val: string) => {
    setInternalValue(val)
  }

  const handleBlur = () => {
    if (internalValue) setIsEditable(false)
    onChange({
      value: internalValue,
      id,
    })
  }

  return (
    <InputContainer align={align} data-cy='inputVariable' title={internalValue} {...rest}>
      <div className='flex items-center gap-1'>
        {variableType === 'string' && (expandable || /\r|\n/.exec(trim(internalValue))) ? (
          <div className='w-[14px]'>
            <Popover.Root>
              <Popover.Trigger asChild>
                <CopyOutlineIcon
                  height={17}
                  width={14}
                  color={colors.primary}
                  className='cursor-pointer'
                />
              </Popover.Trigger>
              <Popover.Anchor />
              <Popover.Portal>
                <Popover.Content className='!z-[10000]'>
                  <div className='bg-void h-[400px] w-[400px] overflow-scroll whitespace-pre-line rounded-md p-2 drop-shadow-md'>
                    {internalValue}
                  </div>
                </Popover.Content>
              </Popover.Portal>
            </Popover.Root>
          </div>
        ) : null}
        {disabled ? (
          <Flex pl='8px' width='300px' alignItems='center'>
            <div className='truncate'>{internalValue}</div>
          </Flex>
        ) : !isEditable ? (
          <VariableText
            onClick={() => {
              if (disabled) return
              setIsEditable(true)
            }}
            type={type}
            disabled={disabled}>
            {internalValue}
          </VariableText>
        ) : (
          <Input
            type={inputType}
            autoFocus={true}
            disabled={disabled}
            value={internalValue}
            onChange={(e) => handleChange((e.target as EventTarget & HTMLInputElement).value)}
            onBlur={handleBlur}
            placeholder={placeholder}
          />
        )}
      </div>
    </InputContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const InputCell: FC<IInputVariable & { variableType?: string }> = (props) => (
  <Box width='100%'>
    <InputVariable align='left' {...props} />
  </Box>
)
export { InputCell }
