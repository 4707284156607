import { SnackbarContext } from '@invisible/common/providers'
import { DateTimePicker } from '@invisible/ui/date-time-picker'
// import { format } from 'date-fns/fp'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useContext } from 'react'

type FieldProps = {
  value: string
  isReadOnly: boolean
}

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const DatePicker = ({ value, isReadOnly }: FieldProps) => {
  const { showSnackbar } = useContext(SnackbarContext)

  return (
    <DateTimePicker
      onChange={(date) => {
        if (date !== null && isNaN(date.getTime())) {
          showSnackbar({
            message: 'Please enter a valid date in the format MM/DD/YYYY.',
            variant: 'error',
          })
          return
        }

        // const formattedDate = date ? format('yyyy-MM-dd', date) : null

        // onChange(formattedDate ?? '')
      }}
      value={value ? zonedTimeToUtc(value, timeZone) : null}
      disabled={isReadOnly}
      fullWidth
      hideTime
    />
  )
}

export { DatePicker }
