import baseStyled, { ThemedStyledInterface } from 'styled-components'

import type { TTheme } from './base-theme'

export * from './base-theme'
export { theme as baseTheme } from './base-theme'
export { default as dark } from './dal3-dark-theme'
export { default as light } from './dal3-light-theme'
export { useTheme } from './hooks-use-theme'
export { SetThemeContext, default as ThemeProvider } from './theme-provider'
export type { ThemedStyledInterface } from 'styled-components'
export { createGlobalStyle, css, keyframes, ServerStyleSheet, withTheme } from 'styled-components'
const styled = baseStyled as any as ThemedStyledInterface<TTheme>

export { styled }
