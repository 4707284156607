import { mapTypeToInputType } from '@invisible/common/helpers'
import { TFilterInputType } from '@invisible/common/types'
import { flow, map } from 'lodash/fp'

import { REPORT_FILTER_MAPPING } from '../../constants'

export const parseFilterMappingsToFilterTypeOptions = (filterMappings: any) =>
  map(({ FAC, label, operator }: any) => ({
    value: label,
    key: operator,
    FAC: FAC,
  }))(filterMappings)

export const getFilterMappings = (filterValueType: string) =>
  flow(mapTypeToInputType, (inputType: TFilterInputType) => [
    ...REPORT_FILTER_MAPPING[inputType],
    ...REPORT_FILTER_MAPPING.common,
  ])(filterValueType)
