import Check from '@mui/icons-material/Check'
import MicIcon from '@mui/icons-material/Mic'
import Button from '@mui/material/Button'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { uniqBy } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record'

interface IProps {
  selectedMicId: string
  setSelectedMicId: (device: { id: string; label: string }) => void
}

const MicSwitcher = ({ selectedMicId, setSelectedMicId }: IProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const { data: availableMicrophones } = useQuery('audio-devices', async () => {
    const devices = (await RecordPlugin.getAvailableAudioDevices()) as MediaDeviceInfo[]
    return uniqBy((d) => d.groupId, devices)
  })

  // Set default mic
  useEffect(() => {
    if (selectedMicId || !availableMicrophones) return
    const defaultMic = availableMicrophones[0]
    setSelectedMicId({ id: defaultMic.deviceId, label: defaultMic.label })
  }, [availableMicrophones, selectedMicId, setSelectedMicId])

  return (
    <>
      <Button
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        startIcon={<MicIcon />}
        sx={{ fontWeight: 'normal' }}>
        Voice settings
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}>
        {availableMicrophones?.map((mic) => (
          <MenuItem
            key={mic.deviceId}
            onClick={() => setSelectedMicId({ id: mic.deviceId, label: mic.label })}>
            {mic.deviceId === selectedMicId ? (
              <ListItemIcon>
                <Check fontSize='small' />
              </ListItemIcon>
            ) : null}
            <ListItemText inset={mic.deviceId !== selectedMicId}>{mic.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export { MicSwitcher }
