import { Card } from '@invisible/ui/card'
import { Progress as UIProgress } from '@invisible/ui/progress'

type ProgressProps = {
  showReview: boolean
  progress: number
}

const Progress = ({ showReview, progress }: ProgressProps) => (
  <Card className='!min-h-0'>
    <UIProgress color='#4DBC25' progress={showReview ? 1 : progress} width='100%' />
  </Card>
)

export { Progress }
