import { SVGProps } from 'react'

function DocNeedsAttention(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M10.0789 14C10.2719 13.6167 10.4956 13.2583 10.75 12.925C11.0044 12.5917 11.2895 12.2833 11.6053 12H6.21053V14H10.0789Z'
        fill='#5F4FA1'
      />
      <path
        d='M9.44737 18C9.39474 17.6667 9.36842 17.3333 9.36842 17C9.36842 16.6667 9.39474 16.3333 9.44737 16H6.21053V18H9.44737Z'
        fill='#5F4FA1'
      />
      <path
        d='M4.10526 22C3.52632 22 3.0307 21.8042 2.61842 21.4125C2.20614 21.0208 2 20.55 2 20V4C2 3.45 2.20614 2.97917 2.61842 2.5875C3.0307 2.19583 3.52632 2 4.10526 2H12.5263L18.8421 8V10.3C18.5088 10.2 18.1667 10.125 17.8158 10.075C17.4649 10.025 17.1053 10 16.7368 10V9H11.4737V4H4.10526V20H10.0789C10.2719 20.3833 10.4956 20.7417 10.75 21.075C11.0044 21.4083 11.2895 21.7167 11.6053 22H4.10526Z'
        fill='#5F4FA1'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M16.7368 12C18.193 12 19.4342 12.4875 20.4605 13.4625C21.4868 14.4375 22 15.6167 22 17C22 18.3833 21.4868 19.5625 20.4605 20.5375C19.4342 21.5125 18.193 22 16.7368 22C15.2807 22 14.0395 21.5125 13.0132 20.5375C11.9868 19.5625 11.4737 18.3833 11.4737 17C11.4737 15.6167 11.9868 14.4375 13.0132 13.4625C14.0395 12.4875 15.2807 12 16.7368 12ZM16.7368 20C17.0275 20 17.2632 19.7761 17.2632 19.5C17.2632 19.2239 17.0275 19 16.7368 19C16.4462 19 16.2105 19.2239 16.2105 19.5C16.2105 19.7761 16.4462 20 16.7368 20ZM16.2105 14H17.2632V18H16.2105V14Z'
        fill='#5F4FA1'
      />
    </svg>
  )
}

export default DocNeedsAttention
