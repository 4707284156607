import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Skeleton } from '../skeleton'

const HorizorizontalSkeletonDiv = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`
const VerticalSkeletonDiv = styled.div`
  margin-top: 5px;
  display: flex;
  transform: rotateX(180deg);
`

const LoaderDiv = styled.div`
  padding: 2px;
`
interface ISekeletonProps {
  layout?: string
  factor: number
  animate?: boolean
}

const HorizontalBarChartSkeleton = () => (
  <HorizorizontalSkeletonDiv>
    <LoaderDiv>
      <Skeleton.Rectangle width={300} height={35} />
    </LoaderDiv>
    <LoaderDiv>
      <Skeleton.Rectangle width={400} height={35} />
    </LoaderDiv>
    <LoaderDiv>
      <Skeleton.Rectangle width={600} height={35} />
    </LoaderDiv>
    <LoaderDiv>
      <Skeleton.Rectangle width={230} height={35} />
    </LoaderDiv>
    <LoaderDiv>
      <Skeleton.Rectangle width={290} height={35} />
    </LoaderDiv>
    <LoaderDiv>
      <Skeleton.Rectangle width={150} height={35} />
    </LoaderDiv>
    <LoaderDiv>
      <Skeleton.Rectangle width={350} height={35} />
    </LoaderDiv>
    <LoaderDiv>
      <Skeleton.Rectangle width={230} height={35} />
    </LoaderDiv>
  </HorizorizontalSkeletonDiv>
)

const VerticalBarChartSkeleton = ({ factor, animate = true }: ISekeletonProps) => {
  const animationSpeed = animate ? 2 : 0

  const [show, setShow] = useState(true)

  useEffect(() => {
    setShow(false)
    const showTimeout = setTimeout(() => {
      setShow(true)
    }, 5)

    return () => {
      clearTimeout(showTimeout)
    }
  }, [animate])

  if (!show) return null
  return (
    <VerticalSkeletonDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={150 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={100 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={50 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={150 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={200 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={180 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={120 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={200 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
      <LoaderDiv>
        <Skeleton.Rectangle height={150 * factor} width={35} speed={animationSpeed} />
      </LoaderDiv>
    </VerticalSkeletonDiv>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-types
const BarChartSkeleton: FC<ISekeletonProps> = ({ layout, factor, animate = true }) => {
  if (layout === 'horizontal') {
    return <HorizontalBarChartSkeleton />
  } else {
    return <VerticalBarChartSkeleton factor={factor} animate={animate} />
  }
}

export { BarChartSkeleton, HorizontalBarChartSkeleton, VerticalBarChartSkeleton }
