import { FC, useState } from 'react'
// had to use a portal because react table has a css transform, see: https://www.darrenlester.com/blog/why-fixed-position-element-not-relative-to-viewport
import { Portal } from 'react-portal'
import styled from 'styled-components'

import { RichTextIcon } from '../../icons'
import { Modal } from '../../modal'
import { IQuillEditor, QuillEditor } from '../../quill-editor'
import { color, gray } from '../../themes'

const TransparentButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${gray(7)};
  &:hover {
    color: ${color('primary')};
  }
`

// eslint-disable-next-line @typescript-eslint/ban-types
export const RichTextCell: FC<IQuillEditor> = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <TransparentButton onClick={() => setIsOpen(true)}>
        <RichTextIcon width={20} height={20} />
      </TransparentButton>
      {isOpen ? (
        <Portal>
          <Modal minWidth={props.readOnly ? '42.3%' : undefined} onClose={() => setIsOpen(false)}>
            <QuillEditor {...props} />
          </Modal>
        </Portal>
      ) : null}
    </>
  )
}
