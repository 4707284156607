import { UnauthenticatedError } from '@invisible/errors'
import axios from 'axios'
import { NextApiRequest } from 'next'

import { HEIMDALL_URL } from './config/env'
import { getExternalUser } from './helpers'

// Exporting for tests
export const defaultToBearer = (token: string) =>
  token.match(/^(Bearer|Basic)/i) ? token : `Bearer ${token}`

// Exporting for tests
export const fetchUser = async (jwt: string) => {
  if (jwt.toLowerCase()?.startsWith('basic inv-') || jwt.toLowerCase()?.startsWith('bearer inv-')) {
    // Tokens starting with "inv-" will be used by third-parties & clients.
    return { email: getExternalUser(jwt) }
  } else {
    // Regular Auth tokens for internal use. These get validated in Heimdall.
    return axios
      .get(`${HEIMDALL_URL}/auth`, { headers: { authorization: defaultToBearer(jwt) } })
      .then((res) => res.data)
  }
}

export const isAuthenticated = async (req: NextApiRequest) => {
  if (!req.headers.authorization) throw new UnauthenticatedError()
  const user = await fetchUser(req.headers.authorization)
  if (!user) throw new UnauthenticatedError()
  return !!user
}
