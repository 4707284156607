import classNames from 'classnames'
import { useState } from 'react'

import { Button } from '../../button'
import { TextArea } from '../../form'
import { Modal } from '../../modal'

type MultiLineInputProps = {
  value: string
  onChange: (value: string) => Promise<void>
  disabled?: boolean
  expandable?: boolean
  width?: number
  title?: string
}

export const MultiLineInputCell = ({
  value,
  onChange,
  disabled,
  expandable,
  title,
  width,
  ...props
}: MultiLineInputProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [internalValue, setInternalValue] = useState(value)

  const handleSave = async () => {
    setIsSaving(true)
    await onChange(internalValue)
    setIsOpen(false)
    setIsSaving(false)
  }

  return (
    <div className={`flex w-full items-center`}>
      <div
        className={classNames('flex items-center gap-1')}
        style={{ maxWidth: width ? `${width}px` : '300px' }}>
        {/* show button if text is expandable or contains new line or is editable */}
        {(disabled && expandable) || /\r|\n/.exec(internalValue) || !disabled ? (
          <Button
            variant='subtle'
            size='md'
            color='theme'
            icon={disabled ? 'EyeOpenedIcon' : 'EditOutlineIcon'}
            shape='circle'
            onClick={() => setIsOpen(true)}
          />
        ) : null}

        <div className='flex items-center pl-[8px]'>
          <div className='truncate' title={internalValue}>
            {internalValue}
          </div>
        </div>

        {isOpen ? (
          <Modal
            title={title}
            onClose={() => {
              setInternalValue(value)
              setIsOpen(false)
            }}>
            {disabled ? (
              <div className='bg-void h-[400px] w-[400px] overflow-scroll whitespace-pre-line rounded-md p-2'>
                {internalValue}
              </div>
            ) : (
              <>
                <TextArea
                  disabled={isSaving || disabled}
                  value={internalValue}
                  className='h-[400px] max-h-[60vh] !max-w-[60vw] !resize'
                  style={{ width: '400px' }}
                  onChange={(e) => setInternalValue(e.target.value)}
                  {...props}
                />
                {disabled ? null : (
                  <div className='flex justify-end py-2'>
                    <Button onClick={() => handleSave()} disabled={isSaving}>
                      Save
                    </Button>
                  </div>
                )}
              </>
            )}
          </Modal>
        ) : null}
      </div>
    </div>
  )
}
