import { Radio } from '@invisible/ui/radio'
import { Box } from '@mui/material'

type FieldProps = {
  value: string
  isReadOnly: boolean
  options: Array<{ key: string; value: string }>
}

const RadioButton = ({ value, isReadOnly, options = [] }: FieldProps) => (
  <Box
    sx={{
      maxHeight: '200px',
      overflowY: 'auto',
    }}>
    <Radio.Group
      name=''
      // onChange={(selected) => onChange(selected)}
      selected={value}
      orientation='vertical'>
      {options.map(({ key, value }) => (
        <Radio label={key} key={key} value={value} disabled={isReadOnly} />
      ))}
    </Radio.Group>
  </Box>
)

export { RadioButton }
