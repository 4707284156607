import { SVGProps } from 'react'
function CopyStepId(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <mask
        id='mask0_2_1539'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'>
        <rect width='24' height='24' transform='matrix(1 0 0 -1 0 24)' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_2_1539)'>
        <path
          d='M5 2C4.45 2 3.97917 2.19583 3.5875 2.5875C3.19583 2.97917 3 3.45 3 4V18H5V4H16V2H5Z'
          fill='black'
          fill-opacity='0.56'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M17.5713 7.09091H13.7041V18H17.6032C18.7006 18 19.6452 17.7816 20.4371 17.3448C21.229 16.9116 21.838 16.2884 22.2641 15.4751C22.6938 14.6619 22.9086 13.6889 22.9086 12.5561C22.9086 11.4197 22.6938 10.4432 22.2641 9.62642C21.838 8.80966 21.2254 8.18288 20.4264 7.74609C19.6309 7.3093 18.6792 7.09091 17.5713 7.09091ZM16.0106 9.06712H17.4754C18.1572 9.06712 18.7307 9.18786 19.1959 9.42933C19.6647 9.67436 20.0162 10.0526 20.2506 10.5639C20.4885 11.0788 20.6075 11.7429 20.6075 12.5561C20.6075 13.3622 20.4885 14.021 20.2506 14.5323C20.0162 15.0437 19.6665 15.4201 19.2013 15.6616C18.7361 15.9031 18.1626 16.0238 17.4807 16.0238H16.0106V9.06712Z'
          fill='black'
          fill-opacity='0.56'
        />
        <path d='M11.3065 7.09091V18H9V7.09091H11.3065Z' fill='black' fill-opacity='0.56' />
      </g>
    </svg>
  )
}

export default CopyStepId
