import type { IBaseRunVariableTypeList } from '@invisible/concorde/gql-client'
import {
  toGlobalId,
  useBaseRunVariablesFromStepRunVariableReferenceQuery,
  useBaseRunVariablesQuery,
} from '@invisible/concorde/gql-client'
import { isEmpty } from 'radash'
import { useMemo } from 'react'
import { useGate } from 'statsig-react'

import type { TBaseRunQueryData } from '../../../../hooks/useGetBaseRuns'

type BaseRun = TBaseRunQueryData['items'][number]
type StepRun = BaseRun['stepRuns'][number]

const useQABaseRunVariables = ({ baseRun, stepRun }: { baseRun: BaseRun; stepRun: StepRun }) => {
  const { value: isQueryByStepRunIdEnabled } = useGate(
    'enable-graphql-QA-WAC-hidden-base-variables'
  )

  const filters = {
    baseRunId: toGlobalId('BaseRun', baseRun.id),
    baseVariableIds: (stepRun?.step?.stepVariableReferences ?? []).map((stvr) =>
      toGlobalId('BaseVariable', stvr.baseVariableId)
    ),
  }
  const enabled = !isEmpty(stepRun?.step?.stepVariableReferences) && !!baseRun

  /**
   * original query.After security updates it does not load hidden base variables.
   * TODO: remove when the new query is at 100%
   */
  const { data: qaBaseRunVariables, isLoading: loadingQaBaseRunVariables } =
    useBaseRunVariablesQuery(
      {
        filters,
      },
      {
        enabled: enabled && !isQueryByStepRunIdEnabled,
      }
    )

  /**
   * new query that includes hidden base run variables when supplied with a step run Id and
   */
  const { data: qaHiddenBaseRunVariables, isLoading: isLoadingHiddenBaseRunVariables } =
    useBaseRunVariablesFromStepRunVariableReferenceQuery(
      {
        filters,
        stepRunId: toGlobalId('StepRun', stepRun?.id),
      },
      {
        enabled: enabled && isQueryByStepRunIdEnabled,
      }
    )

  /**
   * transform the hidden variables to match the original hook.
   * we use useMemo to return a stable object reference.
   * so it can be used in the dependency array of a useEffect.
   */
  const transformedHiddenVariables = useMemo(
    () =>
      qaHiddenBaseRunVariables && {
        baseRunVariables: (
          qaHiddenBaseRunVariables.baseRunVariablesFromStepRunVariableReference as IBaseRunVariableTypeList
        )?.baseRunVariableList,
      },
    [qaHiddenBaseRunVariables]
  )

  /**
   * return the same values as the original hook.
   */
  return {
    qaBaseRunVariables: isQueryByStepRunIdEnabled ? transformedHiddenVariables : qaBaseRunVariables,
    loadingQaBaseRunVariables: loadingQaBaseRunVariables || isLoadingHiddenBaseRunVariables,
  }
}

export { useQABaseRunVariables }
