import { classNames } from '@invisible/common/helpers'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { FC, useState } from 'react'

import { MenuContent } from '../dropdown'
import { IOption } from '../dropdown'
import { TIconName } from '../icons'
import { Icons } from '../icons'

export interface IProps {
  size?: 'sm' | 'md' | 'lg'
  icon?: TIconName
  dropdownWidth?: string
  name?: string
  options: IOption[]
  onChange?: (arg: { key: string; value: string | number | string[] }) => void
  maxHeight?: string
  onBlur?: () => void
  disabled?: boolean
  className?: string
  search?: boolean
  selectedKey?: string
}

// eslint-disable-next-line @typescript-eslint/ban-types
const IconDropdown: FC<IProps> = ({
  size = 'md',
  icon = 'HamburgerIcon',
  dropdownWidth = '150px',
  name,
  options,
  onChange,
  maxHeight = '400px',
  onBlur,
  disabled = false,
  className,
  search,
  selectedKey,
}) => {
  const [open, setOpen] = useState(false)
  const [internalKey, setInternalKey] = useState<string | null>('')
  const [searchInput, setSearchInput] = useState('')

  const ParsedIcon = icon ? Icons?.[icon] : undefined

  return (
    <div>
      <DropdownMenu.Root
        open={open && !disabled}
        onOpenChange={(state) => {
          if (!state) onBlur?.()
          setOpen(state)
        }}>
        <DropdownMenu.Trigger
          disabled={disabled}
          className={classNames(
            !disabled ? 'hover:bg-weak-2 hover:cursor-pointer' : '',
            'bg-void box-border flex h-10 w-10 items-center justify-center rounded-md border-none p-3',
            open ? 'bg-weak-2' : '',
            className ?? ''
          )}
          name={name}>
          <div className={classNames('flex justify-center', open ? 'text-theme-main' : '')}>
            {ParsedIcon ? (
              <ParsedIcon
                width={size === 'lg' ? '32' : size === 'md' ? '20' : size === 'sm' ? '16' : ''}
                height={16}
              />
            ) : null}
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <MenuContent
            {...{
              maxHeight,
              dropdownWidth,
              options,
              internalKey,
              setInternalKey,
              onChange,
              search,
              searchInput,
              setSearchInput,
              selectedKey,
            }}
          />
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  )
}

export { IconDropdown }
