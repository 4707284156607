// When adding an icon, make sure to add it to import, the Icons constant, and export
// We have to do it this way to preserve types
//
// If we do just `export { default as AccessRecordIcon } from './assets/AccessRecord' etc
// it breaks because we can no longer get an icon by its string name when importing

import { keys } from 'lodash/fp'

import { default as ActivateFilledIcon } from './assets/ActivateFilled'
import { default as ActivateOutlineIcon } from './assets/ActivateOutline'
import { default as AddBadgeFilledIcon } from './assets/AddBadgeFilled'
import { default as AddBadgeOutlineIcon } from './assets/AddBadgeOutline'
import { default as AddProcessFilledIcon } from './assets/AddProcessFilled'
import { default as AddProcessOutlineIcon } from './assets/AddProcessOutline'
import { default as AddToTeamFilledIcon } from './assets/AddToTeamFilled'
import { default as AddToTeamOutlineIcon } from './assets/AddToTeamOutline'
import { default as AddUserFilledIcon } from './assets/AddUserFilled'
import { default as AddUserOutlineIcon } from './assets/AddUserOutline'
import { default as AimIcon } from './assets/Aim'
import { default as AirtableOriginalIcon } from './assets/AirtableOriginal'
import { default as AnnotateAudioWacIcon } from './assets/AnnotateAudioWac'
import { default as ArchiveIcon } from './assets/Archive'
import { default as ArrowDownIcon } from './assets/ArrowDown'
import { default as ArrowIndicatorIcon } from './assets/ArrowIndicator'
import { default as ArrowInSquareFilledIcon } from './assets/ArrowInSquareFilled'
import { default as ArrowUpIcon } from './assets/ArrowUp'
import { default as AsteriskIcon } from './assets/Asterisk'
import { default as AtlassianOriginalIcon } from './assets/AtlassianOriginal'
import { default as AttachDocument } from './assets/AttachDocument'
import { default as AttachFileIcon } from './assets/AttachFile'
import { default as AutoLayoutIcon } from './assets/AutoLayout'
import { default as BadgeFilledIcon } from './assets/BadgeFilled'
import { default as BadgeOutlinedIcon } from './assets/BadgeOutlined'
import { default as BankIcon } from './assets/Bank'
import { default as BaseFilledIcon } from './assets/BaseFilled'
import { default as BaseOutlineIcon } from './assets/BaseOutline'
import { default as BbotOriginalIcon } from './assets/BbotOriginal'
import { default as BellFilledIcon } from './assets/BellFilled'
import { default as BellOutlinedIcon } from './assets/BellOutlined'
import { default as BicycleIcon } from './assets/Bicycle'
import { default as BillingIcon } from './assets/Billing'
import { default as BoltFoodOriginalIcon } from './assets/BoltFoodOriginal'
import { default as BookFilledIcon } from './assets/BookFilled'
import { default as BooleanIcon } from './assets/Boolean'
import { default as BoxIncOriginalIcon } from './assets/BoxIncOriginal'
import { default as BranchIcon } from './assets/Branch'
import { default as BrazeOriginalIcon } from './assets/BrazeOriginal'
import { default as BuildIcon } from './assets/Build'
import { default as BuildEditFilledIcon } from './assets/BuildEditFilled'
import { default as BuildEditOutlineIcon } from './assets/BuildEditOutline'
import { default as BuildFilledIcon } from './assets/BuildFilled'
import { default as BungalowOriginalIcon } from './assets/BungalowOriginal'
import { default as CalendarCheckIcon } from './assets/CalendarCheck'
import { default as CalendarFilledIcon } from './assets/CalendarFilled'
import { default as CalendarOutlineIcon } from './assets/CalendarOutline'
import { default as CalendlyOriginalIcon } from './assets/CalendlyOriginal'
import { default as CancelBaseRunFilledIcon } from './assets/CancelBaseRunFilled'
import { default as CancelBaseRunOutlineIcon } from './assets/CancelBaseRunOutline'
import { default as CaretBackIcon } from './assets/CaretBack'
import { default as CaretDownIcon } from './assets/CaretDown'
import { default as CaretLeftIcon } from './assets/CaretLeft'
import { default as CaretRightIcon } from './assets/CaretRight'
import { default as CaretUpIcon } from './assets/CaretUp'
import { default as CategoryIcon } from './assets/Category'
import { default as CelebrateFilledIcon } from './assets/CelebrateFilled'
import { default as CelebrateOutlinedIcon } from './assets/CelebrateOutlined'
import { default as CheckIcon } from './assets/Check'
import { default as CheckCircleFilledIcon } from './assets/CheckCircleFilled'
import { default as CheckCircleOutlineIcon } from './assets/CheckCircleOutline'
import { default as CheckListIcon } from './assets/Checklist'
import { default as CircleArrowLeftIcon } from './assets/CircleArrowLeft'
import { default as CircledExclamationIcon } from './assets/CircledExclamation'
import { default as CircledPauseIcon } from './assets/CircledPause'
import { default as CircledPlayIcon } from './assets/CircledPlay'
import { default as CircleMinusIcon } from './assets/CircleMinus'
import { default as CleanDataIcon } from './assets/CleanData'
import { default as ClientIcon } from './assets/Client'
import { default as ClientFilledIcon } from './assets/ClientFilled'
import { default as ClockFilledIcon } from './assets/ClockFilled'
import { default as ClockOutlineIcon } from './assets/ClockOutline'
import { default as CloseIcon } from './assets/Close'
import { default as CloseCircleIcon } from './assets/CloseCircle'
import { default as CloseEjectIcon } from './assets/CloseEject'
import { default as CoffeeMugIcon } from './assets/CoffeeMug'
import { default as ColoredBurgerIcon } from './assets/ColoredBurger'
import { default as ColoredPaletteIcon } from './assets/ColoredPalette'
import { default as ConditionIcon } from './assets/Condition'
import { default as ControlFilledIcon } from './assets/ControlFilled'
import { default as ControlOutlineIcon } from './assets/ControlOutline'
import { default as CopyFilledIcon } from './assets/CopyFilled'
import { default as CopyOutlineIcon } from './assets/CopyOutline'
import { default as CopyStepIdIcon } from './assets/CopyStepId'
import { default as CraigslistOriginalIcon } from './assets/CraiglistOriginal'
import { default as CrazyCallOriginalIcon } from './assets/CrazyCallOriginal'
import { default as CycleIcon } from './assets/Cycle'
import { default as DangerOutlineIcon } from './assets/DangerOutline'
import { default as DashCircleFilledIcon } from './assets/DashCircleFilled'
import { default as DashCircleOutlineIcon } from './assets/DashCircleOutline'
import { default as DatabricksOriginalIcon } from './assets/DatabricksOriginal'
import { default as DataProtectionIcon } from './assets/DataProtection'
import { default as DataSourceIcon } from './assets/DataSource'
import { default as DataTransformIcon } from './assets/DataTransform'
import { default as DateSeparatorIcon } from './assets/DateSeparator'
import { default as DeadlineIcon } from './assets/Deadline'
import { default as DeleteFilledIcon } from './assets/DeleteFilled'
import { default as DeleteOutlineIcon } from './assets/DeleteOutline'
import { default as DirectionIconDown } from './assets/DirectionIconDown'
import { default as DirectionIconUp } from './assets/DirectionIconUp'
import { default as DiscoverIcon } from './assets/Discover'
import { default as DiscoverFilledIcon } from './assets/DiscoverFilled'
import { default as DocLinkIcon } from './assets/DocLink'
import { default as DocNeedsAttention } from './assets/DocNeedsAttention'
import { default as DocumentIcon } from './assets/Document'
import { default as DocumentationIcon } from './assets/Documentation'
import { default as DocumentationFilledIcon } from './assets/DocumentationFilled'
import { default as DocUploadIcon } from './assets/DocUpload'
import { default as DogIcon } from './assets/Dog'
import { default as DollarIcon } from './assets/Dollar'
import { default as DoorDashOriginalIcon } from './assets/DoorDashOriginal'
import { default as DoubleCaratDownIcon } from './assets/DoubleCaratDown'
import { default as DoubleCaratLeftIcon } from './assets/DoubleCaratLeft'
import { default as DoubleCaratRightIcon } from './assets/DoubleCaratRight'
import { default as DoubleCaratUpIcon } from './assets/DoubleCaratUp'
import { default as DownloadFilledIcon } from './assets/DownloadFilled'
import { default as DownloadOutlineIcon } from './assets/DownloadOutline'
import { default as DragHandleVerticalIcon } from './assets/DragHandleVertical'
import { default as DropdownIcon } from './assets/Dropdown'
import { default as DumbellFilledIcon } from './assets/DumbellFilled'
import { default as DumbellOutlinedIcon } from './assets/DumbellOutlined'
import { default as EditDataFilledIcon } from './assets/EditDataFilled'
import { default as EditDataOutlineIcon } from './assets/EditDataOutline'
import { default as EditDocFilledIcon } from './assets/EditDocFilled'
import { default as EditDocOutlineIcon } from './assets/EditDocOutline'
import { default as EditFilledIcon } from './assets/EditFilled'
import { default as EditOutlineIcon } from './assets/EditOutline'
import { default as EmailComIcon } from './assets/EmailCom'
import { default as EnterOutlineIcon } from './assets/EnterOutline'
import { default as EnvelopeFilledIcon } from './assets/EnvelopeFilled'
import { default as EnvelopeOutlineIcon } from './assets/EnvelopeOutline'
import { default as ExportFilledIcon } from './assets/ExportFilled'
import { default as ExportOutlineIcon } from './assets/ExportOutline'
import { default as EyeCancelledIcon } from './assets/EyeCancelled'
import { default as EyeClosedIcon } from './assets/EyeClosed'
import { default as EyeOpenedIcon } from './assets/EyeOpened'
import { default as FieldNumberIcon } from './assets/FieldNumber'
import { default as FileFilledIcon } from './assets/FileFilled'
import { default as FileImageIcon } from './assets/FileImage'
import { default as FileOutlineIcon } from './assets/FileOutline'
import { default as FilledCheckIcon } from './assets/FilledCheck'
import { default as FilledCloseCircleIcon } from './assets/FilledCloseCircle'
import { default as FilledFilterIcon } from './assets/FilledFilter'
import { default as FilledPlayIcon } from './assets/FilledPlay'
import { default as FilterIcon } from './assets/Filter'
import { default as FiltersIcon } from './assets/Filters'
import { default as FinanceIcon } from './assets/Finance'
import { default as FinanceFilledIcon } from './assets/FinanceFilled'
import { default as FlagFilledIcon } from './assets/FlagFilled'
import { default as FlagOutlineIcon } from './assets/FlagOutline'
import { default as FlowIcon } from './assets/Flow'
import { default as FolderFilledIcon } from './assets/FolderFilled'
import { default as FolderOutlineIcon } from './assets/FolderOutline'
import { default as ForkIcon } from './assets/Fork'
import { default as FormatIcon } from './assets/Format'
import { default as FullScreenIcon } from './assets/FullScreen'
import { default as FunnelIcon } from './assets/Funnel'
import { default as GaugeIcon } from './assets/Gauge'
import { default as GaugeFilledIcon } from './assets/GaugeFilled'
import { default as GetroOriginalIcon } from './assets/GetroOriginal'
import { default as GetTaskIcon } from './assets/GetTask'
import { default as GetTaskFilledIcon } from './assets/GetTaskFilled'
import { default as GiftIcon } from './assets/Gift'
import { default as GiftFilledIcon } from './assets/GiftFilled'
import { default as GitHubOriginalIcon } from './assets/GitHubOriginal'
import { default as GlobalFilledIcon } from './assets/GlobalFilled'
import { default as GlobalOutlineIcon } from './assets/GlobalOutline'
import { default as GlobeIcon } from './assets/Globe'
import { default as GlobeFilledIcon } from './assets/GlobeFilled'
import { default as GoogleIcon } from './assets/Google'
import { default as GoogleMonoColorIcon } from './assets/GoogleMonoColor'
import { default as GoogleSheetIcon } from './assets/GoogleSheet'
import { default as GoogleSheetEditIcon } from './assets/GoogleSheetEdit'
import { default as GoogleSheetOriginalIcon } from './assets/GoogleSheetOriginal'
import { default as GrammarlyOriginalIcon } from './assets/GrammarlyOriginal'
import { default as GrubhubOriginalIcon } from './assets/GrubhubOriginal'
import { default as GustoOriginalIcon } from './assets/GustoOriginal'
import { default as HamburgerIcon } from './assets/Hamburger'
import { default as HandShakeIcon } from './assets/HandShake'
import { default as HeartOffIcon } from './assets/HeartOff'
import { default as HeartOnIcon } from './assets/HeartOn'
import { default as HeartSkeleton } from './assets/HeartVector'
import { default as HelpIcon } from './assets/Help'
import { default as HistoryIcon } from './assets/History'
import { default as HomeIcon } from './assets/Home'
import { default as HomeFilledIcon } from './assets/HomeFilled'
import { default as HotpadsOriginalIcon } from './assets/HotpadsOriginal'
import { default as HourglassFilledIcon } from './assets/HourglassFilled'
import { default as IncompleteIcon } from './assets/Incomplete'
import { default as InfoFilledIcon } from './assets/InfoFilled'
import { default as InfoOutlineIcon } from './assets/InfoOutline'
import { default as InputSheetIcon } from './assets/InputSheet'
import { default as InputVarIcon } from './assets/InputVar'
import { default as InstructionsFilledIcon } from './assets/InstructionsFilled'
import { default as InstructionsOutlineIcon } from './assets/InstructionsOutline'
import { default as InvisibleLogoWithTextIcon } from './assets/InvisibleLogoWithText'
import { default as KlarnaOriginalIcon } from './assets/KlarnaOriginal'
import { default as LanguageFilledIcon } from './assets/LanguageFilled'
import { default as LanguageOutlineIcon } from './assets/LanguageOutline'
import { default as LanguageRibbonIcon } from './assets/LanguageRibbon'
import { default as LaunchIcon } from './assets/Launch'
import { default as LinkIcon } from './assets/Link'
import { default as LinkedInIcon } from './assets/LinkedIn'
import { default as ListIcon } from './assets/List'
import { default as LoadMenuIcon } from './assets/LoadMenu'
import { default as LockFilledIcon } from './assets/LockFilled'
import { default as LockOutlineIcon } from './assets/LockOutline'
import { default as LockPlusIcon } from './assets/LockPlus'
import { default as LogicFlowIcon } from './assets/LogicFlow'
import { default as InvisibleIcon } from './assets/Logo'
import { default as LogoIcon } from './assets/Logo'
import { default as LogoutIcon } from './assets/Logout'
import { default as LoomOriginalIcon } from './assets/LoomOriginal'
import { default as MailchimpOriginalIcon } from './assets/MailchimpOriginal'
import { default as MenuIcon } from './assets/Menu'
import { default as MessageIcon } from './assets/Message'
import { default as MessageFilledIcon } from './assets/MessageFilled'
import { default as MicrosoftOfficeOriginalIcon } from './assets/MicrosoftOfficeOriginal'
import { default as MinusIcon } from './assets/Minus'
import { default as NewGlobeOutlinedIcon } from './assets/NewGlobeOutlined'
import { default as NotionOriginalIcon } from './assets/NotionOriginal'
import { default as OnboardingRibbonIcon } from './assets/OnboardingRibbon'
import { default as OpenAiOriginalIcon } from './assets/OpenAiOriginal'
import { default as OrchestrationIcon } from './assets/Orchestration'
import { default as OrchestrationFilledIcon } from './assets/OrchestrationFilled'
import { default as ParagraphIcon } from './assets/Paragraph'
import { default as PauseCircleFilledIcon } from './assets/PauseCircleFilled'
import { default as PauseCircleOutlineIcon } from './assets/PauseCircleOutline'
import { default as PenAndPaperIcon } from './assets/PenAndPaper'
import { default as PiggyBankOutlinedIcon } from './assets/PiggyBankOutlined'
import { default as PlaneTakeoffFilledIcon } from './assets/PlaneTakeoffFilled'
import { default as PlaneTakeoffOutlinedIcon } from './assets/PlaneTakeoffOutlined'
import { default as PlayIcon } from './assets/Play'
import { default as PlusIcon } from './assets/Plus'
import { default as PlusCameraIcon } from './assets/PlusCamera'
import { default as PopOutFilledIcon } from './assets/PopOutFilled'
import { default as PopOutOutlineIcon } from './assets/PopOutOutline'
import { default as PortalDashboardIcon } from './assets/PortalDashboard'
import { default as ProcessFilledIcon } from './assets/ProcessFilled'
import { default as ProductRibbonIcon } from './assets/ProductRibbon'
import { default as ProgressIcon } from './assets/Progress'
import { default as QuestionMarkIcon } from './assets/QuestionMark'
import { default as RankingIcon } from './assets/Ranking'
import { default as RecordAudioWacIcon } from './assets/RecordAudioWac'
import { default as RedoIcon } from './assets/Redo'
import { default as ReplyIcon } from './assets/Reply'
import { default as RichTextIcon } from './assets/RichText'
import { default as RLHFWACIcon } from './assets/RLHFWAC'
import { default as RobinhoodIcon } from './assets/Robinhood'
import { default as RobotIcon } from './assets/Robot'
import { default as RobotHeadIcon } from './assets/RobotHead'
import { default as RocketFilledIcon } from './assets/RocketFilled'
import { default as RocketOutlinedIcon } from './assets/RocketOutlined'
import { default as RotateLeftIcon } from './assets/RotateLeft'
import { default as RotateRightIcon } from './assets/RotateRight'
import { default as SaasIcon } from './assets/Saas'
import { default as SalesIcon } from './assets/Sales'
import { default as SalesFilledIcon } from './assets/SalesFilled'
import { default as SalesforceOriginalIcon } from './assets/SalesforceOriginal'
import { default as SaveAsIcon } from './assets/SaveAs'
import { default as SaveFilledIcon } from './assets/SaveFilled'
import { default as SaveOutlineIcon } from './assets/SaveOutline'
import { default as SavingIcon } from './assets/Saving'
import { default as ScaleIcon } from './assets/Scale'
import { default as ScissorsIcon } from './assets/Scissors'
import { default as SearchFilledIcon } from './assets/SearchFilled'
import { default as SearchOutlineIcon } from './assets/SearchOutline'
import { default as SectionIcon } from './assets/Section'
import { default as SecurityIcon } from './assets/Security'
import { default as SendIcon } from './assets/SendIcon'
import { default as SettingsIcon } from './assets/Settings'
import { default as SettingsFilledIcon } from './assets/SettingsFilled'
import { default as SFTWACIcon } from './assets/SFTWAC'
import { default as SignOutIcon } from './assets/SignOut'
import { default as SkillFilledIcon } from './assets/SkillFilled'
import { default as SkillOutlineIcon } from './assets/SkillOutline'
import { default as SkillsRibbonIcon } from './assets/SkillsRibbon'
import { default as SlackIcon } from './assets/Slack'
import { default as SlackOriginalIcon } from './assets/SlackOriginal'
import { default as SmallRobotIcon } from './assets/SmallRobot'
import { default as SnoozeOutlineIcon } from './assets/SnoozeOutline'
import { default as SortDownIcon } from './assets/SortDown'
import { default as SortRemoveIcon } from './assets/SortRemove'
import { default as SortUpIcon } from './assets/SortUp'
import { default as SquaredUpArrowIcon } from './assets/SquaredUpArrow'
import { default as StarIcon } from './assets/Star'
import { default as StarShineIcon } from './assets/StarShine'
import { default as StepFilledIcon } from './assets/StepFilled'
import { default as StepTemplateIcon } from './assets/StepTemplate'
import { default as SuitcaseIcon } from './assets/Suitcase'
import { default as SumIcon } from './assets/Sum'
import { default as TagFilledIcon } from './assets/TagFilled'
import { default as TagOutlineIcon } from './assets/TagOutline'
import { default as TalkdeskOriginalIcon } from './assets/TalkdeskOriginal'
import { default as TaskIcon } from './assets/Task'
import { default as TaskFilledIcon } from './assets/TaskFilled'
import { default as TaskRibbonIcon } from './assets/TaskRibbon'
import { default as TaskTrainingIcon } from './assets/TaskTraining'
import { default as TeamIcon } from './assets/Team'
import { default as TeamFilledIcon } from './assets/TeamFilled'
import { default as TechnologiesIcon } from './assets/Technologies'
import { default as TechnologiesFilledIcon } from './assets/TechnologiesFilled'
import { default as TelephoneFilledIcon } from './assets/TelephoneFilled'
import { default as TelephoneOutlineIcon } from './assets/TelephoneOutline'
import { default as TextModeIcon } from './assets/TextMode'
import { default as ThreeDimensionIcon } from './assets/ThreeDimensionIcon'
import { default as ThumbsDownIcon } from './assets/ThumbsDown'
import { default as ThumbsUpIcon } from './assets/ThumbsUp'
import { default as ToastErrorIcon } from './assets/ToastError'
import { default as ToastInfoIcon } from './assets/ToastInfo'
import { default as ToastSuccessIcon } from './assets/ToastSuccess'
import { default as ToastTriangleIcon } from './assets/ToastTriangle'
import { default as ToggleAnnotationsIcon } from './assets/ToggleAnnotations'
import { default as ToolFilledIcon } from './assets/ToolFilled'
import { default as ToolOutlineIcon } from './assets/ToolOutline'
import { default as ToolsRibbonIcon } from './assets/ToolsRibbon'
import { default as TrainingGradIcon } from './assets/TrainingGrad'
import { default as TrainingGradFilledIcon } from './assets/TrainingGradFilled'
import { default as TrashFilledIcon } from './assets/TrashFilled'
import { default as TrashOutlineIcon } from './assets/TrashOutline'
import { default as TwoDimensionIcon } from './assets/TwoDimensionIcon'
import { default as TypeRibbonIcon } from './assets/TypeRibbon'
import { default as UndoIcon } from './assets/Undo'
import { default as UserFilledIcon } from './assets/UserFilled'
import { default as UserOutlineIcon } from './assets/UserOutline'
import { default as UserRolesFilledIcon } from './assets/UserRolesFilled'
import { default as UserRolesOutlinedIcon } from './assets/UserRolesOutlined'
import { default as UsersFilledIcon } from './assets/UsersFilled'
import { default as UserSwitchIcon } from './assets/UserSwitch'
import { default as VideoIcon } from './assets/Video'
import { default as VideoPlainIcon } from './assets/VideoPlain'
import { default as ViewProcessIcon } from './assets/ViewProcess'
import { default as WebHookIcon } from './assets/WebHook'
import { default as WizardHatIcon } from './assets/WizardHat'
import { default as WordModeIcon } from './assets/WordMode'
import { default as WrenchFilledIcon } from './assets/WrenchFilled'
import { default as WrenchOutlinedIcon } from './assets/WrenchOutlined'
import { default as ZillowOriginalIcon } from './assets/ZillowOriginal'
import { default as ZoomInIcon } from './assets/ZoomIn'
import { default as ZoomOutIcon } from './assets/ZoomOut'

const Icons = {
  AttachDocument,
  ActivateFilledIcon,
  ActivateOutlineIcon,
  AddBadgeFilledIcon,
  AddBadgeOutlineIcon,
  AddProcessFilledIcon,
  ViewProcessIcon,
  AddProcessOutlineIcon,
  AddToTeamFilledIcon,
  AddToTeamOutlineIcon,
  AddUserFilledIcon,
  AddUserOutlineIcon,
  UserRolesOutlinedIcon,
  UserRolesFilledIcon,
  AimIcon,
  AirtableOriginalIcon,
  AnnotateAudioWacIcon,
  ArchiveIcon,
  AutoLayoutIcon,
  ArrowIndicatorIcon,
  PopOutFilledIcon,
  PopOutOutlineIcon,
  ArrowInSquareFilledIcon,
  BadgeFilledIcon,
  BadgeOutlinedIcon,
  UsersFilledIcon,
  CaretLeftIcon,
  CaretRightIcon,
  AtlassianOriginalIcon,
  AttachFileIcon,
  AsteriskIcon,
  BankIcon,
  BaseFilledIcon,
  BaseOutlineIcon,
  BbotOriginalIcon,
  BellFilledIcon,
  BellOutlinedIcon,
  BooleanIcon,
  BoltFoodOriginalIcon,
  BoxIncOriginalIcon,
  BranchIcon,
  BookFilledIcon,
  BrazeOriginalIcon,
  BuildFilledIcon,
  BuildIcon,
  BuildEditFilledIcon,
  BuildEditOutlineIcon,
  BungalowOriginalIcon,
  BillingIcon,
  CalendarFilledIcon,
  CalendarOutlineIcon,
  CalendarCheckIcon,
  CalendlyOriginalIcon,
  CancelBaseRunFilledIcon,
  CancelBaseRunOutlineIcon,
  CaretBackIcon,
  CaretDownIcon,
  CaretUpIcon,
  CategoryIcon,
  CelebrateOutlinedIcon,
  CelebrateFilledIcon,
  CheckCircleFilledIcon,
  CheckCircleOutlineIcon,
  CheckIcon,
  DollarIcon,
  CircledExclamationIcon,
  CircledPauseIcon,
  FilledPlayIcon,
  CircleMinusIcon,
  CircledPlayIcon,
  CleanDataIcon,
  ClientIcon,
  ClockOutlineIcon,
  ClientFilledIcon,
  ClockFilledIcon,
  TypeRibbonIcon,
  ToolsRibbonIcon,
  TaskRibbonIcon,
  SkillsRibbonIcon,
  ProductRibbonIcon,
  OnboardingRibbonIcon,
  LanguageRibbonIcon,
  CloseIcon,
  CloseCircleIcon,
  CloseEjectIcon,
  CoffeeMugIcon,
  ConditionIcon,
  ControlFilledIcon,
  ControlOutlineIcon,
  CopyFilledIcon,
  CopyOutlineIcon,
  CraigslistOriginalIcon,
  CrazyCallOriginalIcon,
  ColoredBurgerIcon,
  ColoredPaletteIcon,
  BicycleIcon,
  CycleIcon,
  DangerOutlineIcon,
  DashCircleFilledIcon,
  DashCircleOutlineIcon,
  DatabricksOriginalIcon,
  DataProtectionIcon,
  DataSourceIcon,
  DataTransformIcon,
  DateSeparatorIcon,
  DeadlineIcon,
  DeleteFilledIcon,
  DeleteOutlineIcon,
  DirectionIconUp,
  DirectionIconDown,
  DiscoverFilledIcon,
  DumbellFilledIcon,
  DumbellOutlinedIcon,
  DiscoverIcon,
  EditDocFilledIcon,
  EditDocOutlineIcon,
  DocLinkIcon,
  DocNeedsAttention,
  DocumentationFilledIcon,
  DocumentationIcon,
  DocumentIcon,
  DocUploadIcon,
  DogIcon,
  DoorDashOriginalIcon,
  DoubleCaratDownIcon,
  DoubleCaratLeftIcon,
  DoubleCaratRightIcon,
  DoubleCaratUpIcon,
  DownloadFilledIcon,
  DownloadOutlineIcon,
  DragHandleVerticalIcon,
  DropdownIcon,
  EditFilledIcon,
  EditOutlineIcon,
  EditDataFilledIcon,
  EditDataOutlineIcon,
  EmailComIcon,
  EnterOutlineIcon,
  EnvelopeFilledIcon,
  EnvelopeOutlineIcon,
  ExportFilledIcon,
  ExportOutlineIcon,
  EyeCancelledIcon,
  EyeClosedIcon,
  EyeOpenedIcon,
  FieldNumberIcon,
  FileFilledIcon,
  FileOutlineIcon,
  FileImageIcon,
  FilledCheckIcon,
  FilledCloseCircleIcon,
  FilledFilterIcon,
  FilterIcon,
  FiltersIcon,
  FinanceFilledIcon,
  FinanceIcon,
  FlagFilledIcon,
  FlagOutlineIcon,
  FlowIcon,
  FolderFilledIcon,
  FolderOutlineIcon,
  ForkIcon,
  FormatIcon,
  FullScreenIcon,
  FunnelIcon,
  GaugeFilledIcon,
  GaugeIcon,
  GetroOriginalIcon,
  GetTaskIcon,
  GetTaskFilledIcon,
  GiftFilledIcon,
  GiftIcon,
  GitHubOriginalIcon,
  GlobalFilledIcon,
  GlobalOutlineIcon,
  GlobeFilledIcon,
  GlobeIcon,
  GoogleMonoColorIcon,
  GoogleIcon,
  GoogleSheetIcon,
  GoogleSheetEditIcon,
  GoogleSheetOriginalIcon,
  GrammarlyOriginalIcon,
  GrubhubOriginalIcon,
  GustoOriginalIcon,
  HamburgerIcon,
  HeartOffIcon,
  HeartOnIcon,
  HelpIcon,
  HistoryIcon,
  HomeFilledIcon,
  HomeIcon,
  HotpadsOriginalIcon,
  HourglassFilledIcon,
  IncompleteIcon,
  InfoFilledIcon,
  HeartSkeleton,
  InfoOutlineIcon,
  InputSheetIcon,
  InputVarIcon,
  InstructionsFilledIcon,
  InstructionsOutlineIcon,
  InvisibleLogoWithTextIcon,
  KlarnaOriginalIcon,
  LanguageFilledIcon,
  LanguageOutlineIcon,
  LinkedInIcon,
  LinkIcon,
  ListIcon,
  LaunchIcon,
  LoadMenuIcon,
  LockFilledIcon,
  LockOutlineIcon,
  LockPlusIcon,
  LogicFlowIcon,
  InvisibleIcon,
  LogoIcon,
  LogoutIcon,
  LoomOriginalIcon,
  MailchimpOriginalIcon,
  MenuIcon,
  MessageIcon,
  MessageFilledIcon,
  MicrosoftOfficeOriginalIcon,
  NotionOriginalIcon,
  NewGlobeOutlinedIcon,
  HandShakeIcon,
  OpenAiOriginalIcon,
  OrchestrationIcon,
  OrchestrationFilledIcon,
  ParagraphIcon,
  PauseCircleFilledIcon,
  PauseCircleOutlineIcon,
  PenAndPaperIcon,
  PlaneTakeoffFilledIcon,
  PlaneTakeoffOutlinedIcon,
  PlayIcon,
  PiggyBankOutlinedIcon,
  PlusIcon,
  PlusCameraIcon,
  MinusIcon,
  PortalDashboardIcon,
  ProcessFilledIcon,
  ProgressIcon,
  QuestionMarkIcon,
  RankingIcon,
  RecordAudioWacIcon,
  RedoIcon,
  ReplyIcon,
  RichTextIcon,
  RLHFWACIcon,
  RobinhoodIcon,
  RocketOutlinedIcon,
  RobotIcon,
  RobotHeadIcon,
  RocketFilledIcon,
  RotateLeftIcon,
  RotateRightIcon,
  SaasIcon,
  SalesFilledIcon,
  SalesIcon,
  SalesforceOriginalIcon,
  SaveAsIcon,
  SaveFilledIcon,
  SaveOutlineIcon,
  SavingIcon,
  ScissorsIcon,
  SearchFilledIcon,
  SearchOutlineIcon,
  SecurityIcon,
  SendIcon,
  SettingsIcon,
  SettingsFilledIcon,
  SFTWACIcon,
  SignOutIcon,
  SkillFilledIcon,
  SkillOutlineIcon,
  SlackIcon,
  SlackOriginalIcon,
  SmallRobotIcon,
  SnoozeOutlineIcon,
  SortDownIcon,
  SortUpIcon,
  SortRemoveIcon,
  SquaredUpArrowIcon,
  StarIcon,
  StepTemplateIcon,
  SumIcon,
  TagFilledIcon,
  TagOutlineIcon,
  TalkdeskOriginalIcon,
  TaskFilledIcon,
  TaskIcon,
  TelephoneFilledIcon,
  TelephoneOutlineIcon,
  TextModeIcon,
  ToastErrorIcon,
  ToastInfoIcon,
  ToastSuccessIcon,
  ToastTriangleIcon,
  ToggleAnnotationsIcon,
  ToolFilledIcon,
  ToolOutlineIcon,
  TrainingGradFilledIcon,
  TrainingGradIcon,
  TrashFilledIcon,
  TrashOutlineIcon,
  TechnologiesFilledIcon,
  TechnologiesIcon,
  TaskTrainingIcon,
  TeamFilledIcon,
  TeamIcon,
  TwoDimensionIcon,
  ThreeDimensionIcon,
  UndoIcon,
  UserFilledIcon,
  UserOutlineIcon,
  UserSwitchIcon,
  VideoIcon,
  VideoPlainIcon,
  WebHookIcon,
  WordModeIcon,
  WrenchOutlinedIcon,
  WrenchFilledIcon,
  SuitcaseIcon,
  ZillowOriginalIcon,
  ZoomInIcon,
  ZoomOutIcon,
  SectionIcon,
  StepFilledIcon,
  CheckListIcon,
  ScaleIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  StarShineIcon,
  CircleArrowLeftIcon,
  CopyStepIdIcon,
} as const

type TIconName = keyof typeof Icons
type TIcon = typeof Icons[TIconName]

const IconNames = keys(Icons) as TIconName[]
const isIconName = (s: unknown): s is TIconName =>
  typeof s === 'string' && IconNames.includes(s as TIconName)
const getIcon = (s: TIconName): TIcon => Icons[s]

export type { TIcon, TIconName }

export {
  ActivateFilledIcon,
  ActivateOutlineIcon,
  AddBadgeFilledIcon,
  AddBadgeOutlineIcon,
  AddProcessFilledIcon,
  AddProcessOutlineIcon,
  AddToTeamFilledIcon,
  AddToTeamOutlineIcon,
  AddUserFilledIcon,
  AddUserOutlineIcon,
  AimIcon,
  AirtableOriginalIcon,
  AnnotateAudioWacIcon,
  ArchiveIcon,
  ArrowDownIcon,
  ArrowIndicatorIcon,
  ArrowInSquareFilledIcon,
  ArrowUpIcon,
  AsteriskIcon,
  AtlassianOriginalIcon,
  AttachFileIcon,
  AutoLayoutIcon,
  BadgeFilledIcon,
  BadgeOutlinedIcon,
  BankIcon,
  BaseFilledIcon,
  BaseOutlineIcon,
  BbotOriginalIcon,
  BellFilledIcon,
  BellOutlinedIcon,
  BicycleIcon,
  BillingIcon,
  BookFilledIcon,
  BooleanIcon,
  BranchIcon,
  BuildEditFilledIcon,
  BuildEditOutlineIcon,
  BuildFilledIcon,
  BuildIcon,
  BungalowOriginalIcon,
  CalendarCheckIcon,
  CalendarFilledIcon,
  CalendarOutlineIcon,
  CalendlyOriginalIcon,
  CancelBaseRunFilledIcon,
  CancelBaseRunOutlineIcon,
  CaretBackIcon,
  CaretDownIcon,
  CaretLeftIcon,
  CaretRightIcon,
  CaretUpIcon,
  CategoryIcon,
  CelebrateFilledIcon,
  CelebrateOutlinedIcon,
  CheckCircleFilledIcon,
  CheckCircleOutlineIcon,
  CheckIcon,
  CheckListIcon,
  CircleArrowLeftIcon,
  CircledExclamationIcon,
  CircledPauseIcon,
  CircledPlayIcon,
  CircleMinusIcon,
  CleanDataIcon,
  ClientFilledIcon,
  ClientIcon,
  ClockFilledIcon,
  ClockOutlineIcon,
  CloseCircleIcon,
  CloseEjectIcon,
  CloseIcon,
  CoffeeMugIcon,
  ColoredBurgerIcon,
  ColoredPaletteIcon,
  ConditionIcon,
  ControlFilledIcon,
  ControlOutlineIcon,
  CopyFilledIcon,
  CopyOutlineIcon,
  CopyStepIdIcon,
  CycleIcon,
  DangerOutlineIcon,
  DashCircleFilledIcon,
  DashCircleOutlineIcon,
  DataProtectionIcon,
  DataSourceIcon,
  DataTransformIcon,
  DateSeparatorIcon,
  DeadlineIcon,
  DeleteFilledIcon,
  DeleteOutlineIcon,
  DirectionIconDown,
  DirectionIconUp,
  DiscoverFilledIcon,
  DiscoverIcon,
  DocLinkIcon,
  DocNeedsAttention,
  DocumentationFilledIcon,
  DocumentationIcon,
  DocumentIcon,
  DocUploadIcon,
  DogIcon,
  DollarIcon,
  DoorDashOriginalIcon,
  DoubleCaratDownIcon,
  DoubleCaratLeftIcon,
  DoubleCaratRightIcon,
  DoubleCaratUpIcon,
  DownloadFilledIcon,
  DownloadOutlineIcon,
  DragHandleVerticalIcon,
  DropdownIcon,
  DumbellFilledIcon,
  DumbellOutlinedIcon,
  EditDataFilledIcon,
  EditDataOutlineIcon,
  EditDocFilledIcon,
  EditDocOutlineIcon,
  EditFilledIcon,
  EditOutlineIcon,
  EmailComIcon,
  EnterOutlineIcon,
  EnvelopeFilledIcon,
  EnvelopeOutlineIcon,
  ExportFilledIcon,
  ExportOutlineIcon,
  EyeCancelledIcon,
  EyeClosedIcon,
  EyeOpenedIcon,
  FieldNumberIcon,
  FileFilledIcon,
  FileImageIcon,
  FileOutlineIcon,
  FilledCheckIcon,
  FilledCloseCircleIcon,
  FilledFilterIcon,
  FilledPlayIcon,
  FilterIcon,
  FiltersIcon,
  FinanceFilledIcon,
  FinanceIcon,
  FlagFilledIcon,
  FlagOutlineIcon,
  FlowIcon,
  FolderFilledIcon,
  FolderOutlineIcon,
  ForkIcon,
  FormatIcon,
  FullScreenIcon,
  FunnelIcon,
  GaugeFilledIcon,
  GaugeIcon,
  getIcon,
  GetroOriginalIcon,
  GetTaskFilledIcon,
  GetTaskIcon,
  GiftFilledIcon,
  GiftIcon,
  GitHubOriginalIcon,
  GlobalFilledIcon,
  GlobalOutlineIcon,
  GlobeFilledIcon,
  GlobeIcon,
  GoogleIcon,
  GoogleMonoColorIcon,
  GoogleSheetEditIcon,
  GoogleSheetIcon,
  GoogleSheetOriginalIcon,
  GrammarlyOriginalIcon,
  GrubhubOriginalIcon,
  GustoOriginalIcon,
  HamburgerIcon,
  HandShakeIcon,
  HeartOffIcon,
  HeartOnIcon,
  HeartSkeleton,
  HelpIcon,
  HistoryIcon,
  HomeFilledIcon,
  HomeIcon,
  HotpadsOriginalIcon,
  HourglassFilledIcon,
  IconNames,
  Icons,
  IncompleteIcon,
  InfoFilledIcon,
  InfoOutlineIcon,
  InputSheetIcon,
  InputVarIcon,
  InstructionsFilledIcon,
  InstructionsOutlineIcon,
  InvisibleIcon,
  InvisibleLogoWithTextIcon,
  isIconName,
  KlarnaOriginalIcon,
  LanguageFilledIcon,
  LanguageOutlineIcon,
  LanguageRibbonIcon,
  LaunchIcon,
  LinkedInIcon,
  LinkIcon,
  ListIcon,
  LoadMenuIcon,
  LockFilledIcon,
  LockOutlineIcon,
  LockPlusIcon,
  LogicFlowIcon,
  LogoIcon,
  LogoutIcon,
  LoomOriginalIcon,
  MailchimpOriginalIcon,
  MenuIcon,
  MessageFilledIcon,
  MessageIcon,
  MicrosoftOfficeOriginalIcon,
  MinusIcon,
  NewGlobeOutlinedIcon,
  NotionOriginalIcon,
  OnboardingRibbonIcon,
  OpenAiOriginalIcon,
  OrchestrationFilledIcon,
  OrchestrationIcon,
  ParagraphIcon,
  PauseCircleFilledIcon,
  PauseCircleOutlineIcon,
  PenAndPaperIcon,
  PiggyBankOutlinedIcon,
  PlaneTakeoffFilledIcon,
  PlaneTakeoffOutlinedIcon,
  PlayIcon,
  PlusCameraIcon,
  PlusIcon,
  PopOutFilledIcon,
  PopOutOutlineIcon,
  PortalDashboardIcon,
  ProcessFilledIcon,
  ProductRibbonIcon,
  ProgressIcon,
  QuestionMarkIcon,
  RankingIcon,
  RecordAudioWacIcon,
  RedoIcon,
  ReplyIcon,
  RichTextIcon,
  RLHFWACIcon,
  RobinhoodIcon,
  RobotHeadIcon,
  RobotIcon,
  RocketFilledIcon,
  RocketOutlinedIcon,
  RotateLeftIcon,
  RotateRightIcon,
  SaasIcon,
  SalesFilledIcon,
  SalesforceOriginalIcon,
  SalesIcon,
  SaveAsIcon,
  SaveFilledIcon,
  SaveOutlineIcon,
  SavingIcon,
  ScaleIcon,
  ScissorsIcon,
  SearchFilledIcon,
  SearchOutlineIcon,
  SectionIcon,
  SecurityIcon,
  SendIcon,
  SettingsFilledIcon,
  SettingsIcon,
  SFTWACIcon,
  SignOutIcon,
  SkillFilledIcon,
  SkillOutlineIcon,
  SkillsRibbonIcon,
  SlackIcon,
  SlackOriginalIcon,
  SmallRobotIcon,
  SnoozeOutlineIcon,
  SortDownIcon,
  SortRemoveIcon,
  SortUpIcon,
  SquaredUpArrowIcon,
  StarIcon,
  StarShineIcon,
  StepFilledIcon,
  StepTemplateIcon,
  SuitcaseIcon,
  SumIcon,
  TagFilledIcon,
  TagOutlineIcon,
  TalkdeskOriginalIcon,
  TaskFilledIcon,
  TaskIcon,
  TaskRibbonIcon,
  TaskTrainingIcon,
  TeamFilledIcon,
  TeamIcon,
  TechnologiesFilledIcon,
  TechnologiesIcon,
  TelephoneFilledIcon,
  TelephoneOutlineIcon,
  TextModeIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  ToastErrorIcon,
  ToastInfoIcon,
  ToastSuccessIcon,
  ToastTriangleIcon,
  ToggleAnnotationsIcon,
  ToolFilledIcon,
  ToolOutlineIcon,
  ToolsRibbonIcon,
  TrainingGradFilledIcon,
  TrainingGradIcon,
  TrashFilledIcon,
  TrashOutlineIcon,
  TypeRibbonIcon,
  UndoIcon,
  UserFilledIcon,
  UserOutlineIcon,
  UserRolesFilledIcon,
  UserRolesOutlinedIcon,
  UsersFilledIcon,
  UserSwitchIcon,
  VideoIcon,
  VideoPlainIcon,
  ViewProcessIcon,
  WebHookIcon,
  WizardHatIcon,
  WordModeIcon,
  WrenchFilledIcon,
  WrenchOutlinedIcon,
  ZillowOriginalIcon,
  ZoomInIcon,
  ZoomOutIcon,
}
