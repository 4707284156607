import { UuidSchema, z } from '@invisible/zod'

import { schema as ChildBaseViewSchema } from './ChildBaseView'
import { schema as CodeWAC2 } from './CodeWAC2'
import { schema as CohereOperateSchema } from './CohereOperate'
import { schema as CompareValues } from './CompareValues'
import { schema as EmailFormSchema } from './EmailForm'
import { schema as EmbedSchema } from './Embed'
import { schema as FormSchema } from './Form'
import { schema as ImageSchema } from './Image'
import { schema as InformationSchema } from './Information'
import { schema as InformationBaseSchema } from './InformationBase'
import { schema as JSONSchema } from './JSON'
import { schema as MultimodalRLHFSchema } from './MultimodalRLHF'
import { schema as Orchestration2Schema } from './Orchestration2'
import { schema as ProcessAudioSchema } from './ProcessAudio'
import { schema as QaSchema } from './Qa'
import { schema as RagRLHFSchema } from './RagRLHF'
import { schema as RankingSchema } from './Ranking'
import { schema as RecordAudioSchema } from './RecordAudio'
import { schema as RLHF2Schema } from './RLHF2'
import { schema as RLHFPlusSchema } from './RLHFPlus'
import { schema as RLHFPlusMetaSchema } from './RLHFPlusMeta'
import { schema as RLHFSingleUserSchema } from './RlhfSingleUser'
import { schema as SFT2Schema } from './SFT2'
import { schema as TableSchema } from './Table'
import { schema as TextAnnotationSchema } from './TextAnnotation'
import { schema as VideoSchema } from './Video'
import { schema as WACTypeSchema } from './WACType'

const DynamicValue = z.object({
  dynamic: z.boolean(),
  /**
   * Exists when meta exists on the step template
   */
  stepTemplateVariableId: UuidSchema.optional(),
  /**
   * Exists when meta exists on step
   */
  baseVariableId: UuidSchema.optional(),
})
type TDynamicValue = z.infer<typeof DynamicValue>

const Value = z.union([z.string(), z.number(), z.array(z.string()), DynamicValue]).optional()
type TValue = z.infer<typeof Value>

const hasDynamicValue = (v: TValue): v is TDynamicValue =>
  typeof v === 'object' && !Array.isArray(v) && v.dynamic

/**
 * The configuration of a single Wizard Atomic Component (WAC)
 */
const schema = z
  .object({
    id: UuidSchema,
    /**
     * The name of the wizard component.
     */
    name: z.string(),

    /**
     * The type of wizard component
     */
    type: WACTypeSchema,
    value: Value,
    // Needs to live inside a compareValues key because it's not a part of the general wac config
    values: z.array(CompareValues.optional()).optional(),
    showName: z.boolean().optional(),

    form: FormSchema.optional(),
    childBaseView: ChildBaseViewSchema.optional(),
    informationBase: InformationBaseSchema.optional(),
    emailForm: EmailFormSchema.optional(),
    embed: EmbedSchema.optional(),
    textAnnotation: TextAnnotationSchema.optional(),
    image: ImageSchema.optional(),
    video: VideoSchema.optional(),
    information: InformationSchema.optional(),
    json: JSONSchema.optional(),
    code2: CodeWAC2.optional(),
    qa: QaSchema.optional(),
    sft2: SFT2Schema.optional(),
    ranking: RankingSchema.optional(),
    rlhf2: RLHF2Schema.optional(),
    rlhfPlus: RLHFPlusSchema.optional(),
    multimodalRLHF: MultimodalRLHFSchema.optional(),
    rlhfPlusMeta: RLHFPlusMetaSchema.optional(),
    orchestration2: Orchestration2Schema.optional(),
    table: TableSchema.optional(),
    recordAudio: RecordAudioSchema.optional(),
    processAudio: ProcessAudioSchema.optional(),

    // custom wac configuration
    rlhfSingleUser: RLHFSingleUserSchema.optional(),
    cohereOperate: CohereOperateSchema.optional(),
    ragRLHF: RagRLHFSchema.optional(),
  })
  .passthrough()

type TSchema = z.infer<typeof schema>

export { hasDynamicValue, schema }
export type { TSchema, TValue }
