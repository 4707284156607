import { useSession } from '@invisible/authentication/client'
import { Header, PageHeader, useBreadcrumbsContext } from '@invisible/common/layouts'
import { useQuery, withTRPC } from '@invisible/trpc/client'
import { Banner, BannerSkeleton } from '@invisible/ui/banner'
import { useGate } from 'statsig-react'

import { CompaniesTable } from '../views/companies/CompaniesTable'
import { CompaniesTableGQL } from '../views/companies/CompaniesTableGQL'
import { UseCaseCategoriesTable } from '../views/use-case-categories/UseCaseCategoriesTable'

interface BannerFields {
  style: 'primary' | 'secondary'
  title: string
  subtitle: string
  ctaLabel: string
  ctaUrl: string
  bannerImageUrl: string
}

const HOME_BANNER_FIELDS: BannerFields = {
  style: 'secondary',
  title: 'Finally - everything is all in one place!',
  subtitle: 'Read the latest from our Finance Team',
  ctaLabel: 'Learn More',
  ctaUrl: 'https://www.notion.so/Manticore-User-Guide-28eff0002a39406fbb70ed8d28b4fe4d',
  bannerImageUrl:
    'https://storage.googleapis.com/inv-lambdas/40c8355fdced25222aa88b8cb4bcd8ea28a03135.jpeg',
}

const useAdminBanner = () => {
  const { data, isLoading } = useQuery([
    'appContent.findByName',
    {
      name: 'AdminBanner',
    },
  ])

  const adminBannerData = data?.data as unknown as BannerFields | null | undefined

  if (!adminBannerData && isLoading) {
    return <BannerSkeleton />
  }

  if (!adminBannerData && !isLoading) {
    return (
      <Banner
        variant={HOME_BANNER_FIELDS.style}
        title={HOME_BANNER_FIELDS.title}
        subtitle={HOME_BANNER_FIELDS.subtitle}
        bannerImageUrl={HOME_BANNER_FIELDS.bannerImageUrl}
        ctaLabel={HOME_BANNER_FIELDS.ctaLabel}
        ctaOnClick={() => window.open(HOME_BANNER_FIELDS.ctaUrl, '_newtab')}
      />
    )
  }

  return (
    <Banner
      variant={adminBannerData?.style ?? HOME_BANNER_FIELDS.style}
      title={adminBannerData?.title ?? HOME_BANNER_FIELDS.title}
      subtitle={adminBannerData?.subtitle ?? HOME_BANNER_FIELDS.subtitle}
      bannerImageUrl={adminBannerData?.bannerImageUrl ?? HOME_BANNER_FIELDS.bannerImageUrl}
      ctaLabel={adminBannerData?.ctaLabel ?? HOME_BANNER_FIELDS.ctaLabel}
      ctaOnClick={() => window.open(adminBannerData?.ctaUrl, '_newtab')}
    />
  )
}

const PAGE_SIZE = 10

const Index = () => {
  const { data: session } = useSession()

  useBreadcrumbsContext(['Home'])
  const banner = useAdminBanner()

  const { value: enableGraphQLCompaniesTable } = useGate(
    'enable-graphql-companiestable-on-admin-homepage'
  )

  return (
    <>
      <Header>
        <PageHeader
          title={`Welcome, ${(session?.user?.name ?? '').split(' ')[0]}!`}
          subtitle='View and manage the data you need to serve our clients'
        />
      </Header>
      <div className='box-border flex w-full max-w-screen-2xl flex-col items-center space-y-4 p-5'>
        {banner}
      </div>
      <div className='flex max-w-screen-2xl flex-col gap-y-12 p-4'>
        {enableGraphQLCompaniesTable ? (
          <CompaniesTableGQL pageSize={PAGE_SIZE} />
        ) : (
          <CompaniesTable pageSize={PAGE_SIZE} />
        )}
        <UseCaseCategoriesTable pageSize={PAGE_SIZE} />
      </div>
    </>
  )
}

export default withTRPC(Index)
