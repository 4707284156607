import { logger } from '@invisible/logger/client'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { ReactNode, useState } from 'react'

interface IConfirmDialogProps {
  open: boolean
  children?: ReactNode
  onClose: () => void
  onConfirm: () => Promise<void>
}

export const ConfirmDialog = ({ open, onClose, onConfirm, children }: IConfirmDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    setIsLoading(true)
    try {
      await onConfirm()
    } catch (error) {
      logger.error('Error in ConfirmDialog:', error as object)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Autogenerate Steps</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{children}</DialogContentText>
      </DialogContent>
      <Box
        sx={{ mt: 2, display: 'flex', justifyContent: 'end', paddingRight: 2, paddingBottom: 2 }}>
        <Button color='secondary' onClick={onClose} variant='contained'>
          Cancel
        </Button>
        <LoadingButton
          color='primary'
          sx={{ ml: 2 }}
          onClick={handleConfirm}
          loading={isLoading}
          variant='contained'>
          Confirm
        </LoadingButton>
      </Box>
    </Dialog>
  )
}
