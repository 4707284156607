export const AUTO_SNOOZE_STOP_REASON = 'auto_snooze_idle_check'

export const IDLE_CHECK_INTERVAL = 350
export const IDLE_CHECK_TRIGGER_TIMER = 2 * 60 * 1000 // 2 minutes
export const FORCE_AUTO_SNOOZE_TIMER = 2 * 60 * 1000 // 2 minutes
export const OFFLINE_TIMER = 1 * 45 * 1000 // 45 seconds
export const ACTIVITY_STATUS_TIMEOUT = 15 * 60 * 1000 // 15 minutes
export const ONLINE_CHECK_INTERVAL = 5 * 60 * 1000 // 5 minutes

export const LOCAL_STORAGE_KEYS = {
  BROWSER_ACTIVITY: 'browser-activity',
  IDLE_CHECK: 'idle-check',
  OFFLINE_MODAL: 'offline-modal',
  LEADER_HEARTBEAT: 'leader-heartbeat',
  LAST_ONLINE: 'last-online',
  DISMISS_SNOOZE_INFO_MODAL: 'dismiss-snooze-info-modal',
}
