import { FC } from 'react'
import type { BoxProps } from 'rebass'
import { Box, Flex } from 'rebass'

import { LogoSpinner } from '../logo-spinner'
import { Text } from '../text'
import { styled } from '../themes'

// eslint-disable-next-line @typescript-eslint/ban-types
const CardNew = styled(Box as FC<BoxProps>).attrs({
  'data-cy': 'cardNew',
})`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  padding: 18px 30px;
`

const Wrapper = styled(Flex)`
  position: fixed;
  z-index: 100;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Backdrop = styled(Flex)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(0, 133, 255, 0.28);
`

const LoadingCard = styled(CardNew)`
  height: 127px;
  width: 221px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface IModal extends BoxProps {
  isActive?: boolean
  text?: string
}
// eslint-disable-next-line @typescript-eslint/ban-types
const LoadingModal: FC<IModal> = (props) => {
  const { isActive = false, text = 'Loading', ...rest } = props
  if (!isActive) return null
  return (
    <Wrapper {...rest} data-cy='loadingModal'>
      <Backdrop />
      <LoadingCard p={4} data-cy='loadingCard'>
        <Text mb={3}>{text}</Text>
        <LogoSpinner height={32} width={32} />
      </LoadingCard>
    </Wrapper>
  )
}

export { LoadingModal }
