import type { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { z } from 'zod'

export const generateValidationSchema = (fields: WizardSchemas.FormField.TSchema[]) => {
  const schema: Record<string, z.ZodTypeAny> = {}

  fields.forEach((field) => {
    let fieldSchema
    switch (field.type) {
      case 'boolean':
        fieldSchema = z.boolean()
        break
      case 'number':
        fieldSchema = z.coerce
          .number({
            required_error: `Required`,
            invalid_type_error: `Must be a number`,
          })
          .gte(field.min ?? -Infinity, `Must be greater than or equal to ${field.min}`)
          .lte(field.max ?? Infinity, `Must be less than or equal to ${field.max}`)
        break
      case 'multiselect':
      case 'dropdown':
        fieldSchema = z.array(
          z.string().refine((value) => field.options?.some((opt) => opt.value === value))
        )
        break
      case 'url':
        fieldSchema = z.string().url()
        break
      case 'text':
        fieldSchema = z.string()
        break
      default:
        fieldSchema = z.never()
    }

    schema[field.id as string] = field.required ? fieldSchema : fieldSchema.optional()
  })

  return z.object(schema)
}
