import { classNames } from '@invisible/common/helpers'
import { IDataFilter, TFilterValue } from '@invisible/common/types'
import { useGate } from 'statsig-react'

import {
  DescriptionIcon,
  DownloadCSVIcon,
  FilterIcon,
  FullScreenLinkIcon,
} from '../../reports/icons'

interface IReportCard {
  title: string
  subtitle: string
  showFilters?: boolean
  toggleShowFilters?: () => void
  dataFilters?: IDataFilter<TFilterValue>[]
  description?: string | null
  reportName?: string
  disableDetailView?: boolean
  isLoading: boolean
  cardStyles?: string
}

const REPORT_DETAIL_VIEW_PATH = 'insights/detail'

// eslint-disable-next-line @typescript-eslint/ban-types
export const ReportCard: React.FC<IReportCard> = ({
  title,
  subtitle,
  showFilters,
  toggleShowFilters,
  dataFilters,
  reportName,
  disableDetailView,
  isLoading,
  children,
  description,
  cardStyles,
}) => {
  const { value: enableDynamicFilters } = useGate('enable-dynamic-filters')
  return (
    <div
      id='report-card'
      className={`bg-void border-weak mb-6 rounded-xl border border-solid p-6 ${cardStyles ?? ''}`}>
      <div className='flex items-start justify-between'>
        <div className='max-h-20'>
          <div className='flex items-center gap-2'>
            <h3 className='mb-1 mt-0 text-xl font-normal'>{title}</h3>
            {!!description && (
              <div className='pb-0.5'>
                <DescriptionIcon description={description} />
              </div>
            )}
          </div>

          {subtitle && (
            <p className={'text-grayScale-600 my-2 text-sm font-normal leading-6'}>{subtitle}</p>
          )}
        </div>
        {!isLoading && (
          <div
            className={classNames(
              'flex',
              !disableDetailView && !!dataFilters && !!reportName
                ? 'justify-between'
                : 'justify-end'
            )}
            style={{ width: disableDetailView ? '40px' : '60px' }}>
            {enableDynamicFilters && toggleShowFilters && (
              <FilterIcon
                selected={showFilters}
                onClick={toggleShowFilters}
                width={16}
                height={16}
              />
            )}
            {!!reportName && (
              <DownloadCSVIcon
                data-test='csv-download'
                dataFilters={dataFilters ?? []}
                reportName={reportName}
                fileName={title}
              />
            )}
            {!disableDetailView && (
              <FullScreenLinkIcon
                reportFullViewURL={`${REPORT_DETAIL_VIEW_PATH}?reportName=${reportName}`}
              />
            )}
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  )
}
