import { SnackbarContext } from '@invisible/common/providers'
import {
  getErrorMessage,
  toGlobalId,
  useBaseRunCreateManyWizardActionMutation,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { cloneDeep } from 'lodash'
import { useContext } from 'react'

import {
  Json
} from '../types/json'

type StepRunCreationFromBaseRunInput = {
  stepId: string
}

export type baseRunVariableCreationFromBaseRunInput = {
  baseVariableId: string
  value: Json
}

type MutationInput = {
  baseId: string
  parentBaseRunId: string
  sourceStepRunId: string
  initialValuesArray: baseRunVariableCreationFromBaseRunInput[][]
  deadline?: Date
  benchmark?: number
  stepRunCreateData?: StepRunCreationFromBaseRunInput
}

const useBaseRunCreateMany = ({
  onSettled,
  onSuccess,
  onError: handleError,
}: {
  onSettled?: () => void
  onSuccess?: () => void
  onError?: (error: Error) => void
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const showErrorMessage = (error: Error) => {
    if (handleError) {
      handleError(error)
      return
    }
    logger.error(`Mutation baseRun.createMany resulted in an error: ${error.message}`, error)
    showSnackbar({
      message: error.message,
      variant: 'error',
    })
  }

  const { mutateAsync: createBaseRunManyWizardGraphQL, isLoading } =
    useBaseRunCreateManyWizardActionMutation({
      onSuccess,
      onSettled,
      onError: (error) => {
        const _error = new Error(getErrorMessage(error))
        showErrorMessage(_error)
      },
    })

  return {
    mutateAsync: ({
      baseId,
      parentBaseRunId,
      sourceStepRunId,
      initialValuesArray,
      deadline,
      benchmark,
      stepRunCreateData,
    }: MutationInput) => {
      logger.info('Mutating from useBaseRunCreateMany via GraphQL', {
        stepRunId: sourceStepRunId,
        baseRunVariablesData: initialValuesArray,
      })

      const baseIdGlobal = toGlobalId('BaseType', baseId)
      const parentBaseRunIdGlobal = toGlobalId('BaseRunType', parentBaseRunId)
      const sourceStepRunIdGlobal = toGlobalId('StepRunType', sourceStepRunId)

      const clonedStepRunCreateData = cloneDeep(stepRunCreateData)
      if (clonedStepRunCreateData) {
        clonedStepRunCreateData.stepId = toGlobalId('StepType', clonedStepRunCreateData.stepId)
      }
      const stepRunCreateDataGlobal = clonedStepRunCreateData

      const clonedInitialValuesArray = cloneDeep(initialValuesArray)
      const baseRunVariablesDataGlobal = clonedInitialValuesArray.map((innerList) =>
        innerList.map((innerDict) => {
          innerDict.baseVariableId = toGlobalId('BaseVariableType', innerDict.baseVariableId)
          return innerDict
        })
      )

      return createBaseRunManyWizardGraphQL({
        input: {
          baseId: baseIdGlobal,
          parentBaseRunId: parentBaseRunIdGlobal,
          sourceStepRunId: sourceStepRunIdGlobal,
          baseRunVariablesData: baseRunVariablesDataGlobal,
          deadline,
          benchmark,
          stepRunCreateData: stepRunCreateDataGlobal,
        },
      })
    },
    isLoading,
  }
}

export { useBaseRunCreateMany }
